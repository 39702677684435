export default {
  "nl": {
    "route.home": "https://www.outspot.be/nl/",
    "route.fallback_domain": "https://www2.outspot.be",
    "language.code": "NL",
    "language.locale": "nl-NL",
    "language.name": "België (Nederlands)",
    "language.flag": "be",
    "language.date_input_format": "d,m,y",
    "language.currency": "EUR",
    "language.region": "eu",
    "countries.delivery": "BEL,LUX",
    "states.delivery": "",
    "tracking.aw_merchant_id": "521180818",
    "tracking.aw_feed_country": "BE",
    "countries.BEL": "België",
    "countries.LUX": "Luxemburg",
    "countries.NLD": "Nederland",
    "countries.FRA": "Frankrijk",
    "countries.DEU": "Duitsland",
    "countries.AUT": "Oostenrijk",
    "countries.ITA": "Italië",
    "countries.ESP": "Spanje",
    "countries.POL": "Polen",
    "countries.GBR": "Verenigd Koninkrijk",
    "countries.IRL": "Ierland",
    "countries.SWE": "Zweden",
    "countries.PRT": "Portugal",
    "countries.FIN": "Finland",
    "countries.USA": "",
    "states.AL": "",
    "states.AR": "",
    "states.AZ": "",
    "states.CA": "",
    "states.CO": "",
    "states.CT": "",
    "states.DE": "",
    "states.FL": "",
    "states.GA": "",
    "states.IA": "",
    "states.ID": "",
    "states.IL": "",
    "states.IN": "",
    "states.KS": "",
    "states.KY": "",
    "states.LA": "",
    "states.MA": "",
    "states.MD": "",
    "states.ME": "",
    "states.MI": "",
    "states.MN": "",
    "states.MO": "",
    "states.MS": "",
    "states.MT": "",
    "states.NC": "",
    "states.ND": "",
    "states.NE": "",
    "states.NH": "",
    "states.NJ": "",
    "states.NM": "",
    "states.NV": "",
    "states.NY": "",
    "states.OH": "",
    "states.OK": "",
    "states.OR": "",
    "states.PA": "",
    "states.RI": "",
    "states.SC": "",
    "states.SD": "",
    "states.TN": "",
    "states.TX": "",
    "states.UT": "",
    "states.VA": "",
    "states.VT": "",
    "states.WA": "",
    "states.WI": "",
    "states.WV": "",
    "states.WY": "",
    "trustpilot.businessunit_id": "509e887e00006400051e5b55",
    "trustpilot.show": "TRUE",
    "mollie.profile_id": "pfl_gJxKAw2cQe",
    "mollie.locale": "nl_NL",
    "facebook.url": "https://www.facebook.com/Outspot",
    "instagram.url": "https://www.instagram.com/outspot_nl/",
    "linkedin.url": "https://www.linkedin.com/company/outspot/mycompany/",
    "header.hello": "Welkom",
    "header.hello_name": "Dag <b>{firstName}</b>",
    "subscribe.title": "Schrijf je gratis in en mis geen enkele deal!",
    "subscribe.text": "Ontdek de beste deals van Outspot. Schrijf je gratis in en geniet van exclusieve en gepersonaliseerde kortingen op unieke producten en ervaringen.",
    "subscribe.agree": "Door in te schrijven op onze nieuwsbrief, verklaar je jezelf akkoord met de <a href=\"{conditions}\" target=\"_blank\">algemene voorwaarden</a> en <a href=\"{privacy}\" target=\"_blank\">privacyverklaring</a>.",
    "subscribe.sent": "Dankjewel! We hebben je aanvraag goed ontvangen. Je ontvangt zometeen een e-mail om je inschrijving te bevestigen.",
    "footer.follow": "Volg ons",
    "footer.payment_methods_logos": "bancontact,mastercard,visa,amex,paypal,applepay,bank",
    "footer.copyright": "Outspot — Sint-Martens-Latem, België — Confinity NV",
    "home.new": "Nieuwste deals",
    "home.ending": "Deze deals lopen bijna af",
    "home.other": "Alle deals",
    "countdown.prefix": "Nog",
    "countdown.days": "dag|dagen",
    "countdown.hours": "uur",
    "countdown.minutes": "min",
    "countdown.seconds": "sec",
    "countdown.days_compact": "d",
    "date.years": "{count} jaar geleden|{count} jaar geleden",
    "date.months": "{count} maand geleden|{count} maanden geleden",
    "date.weeks": "{count} week geleden|{count} weken geleden",
    "date.days": "{count} dag geleden|{count} dagen geleden",
    "date.hours": "{count} uur geleden|{count} uur geleden",
    "date.minutes": "{count} minuut geleden|{count} minuten geleden",
    "date.seconds": "Minder dan 1 minuut geleden",
    "countdown.suffix": "",
    "onboard.language_title": "Geniet van persoonlijke deals met scherpe kortingen",
    "onboard.language_text": "Kies in welk land en taal je wenst te winkelen:",
    "onboard.notifications_title": "Blijf op de hoogte van:",
    "onboard.notifications_usp_1": "De beste en nieuwste deals",
    "onboard.notifications_usp_2": "Updates over je bestellingen",
    "onboard.notifications_usp_3": "Persoonlijke kortingscodes & waardebonnen",
    "order.shipping_title": "Verzending",
    "order.shipping_text": "Omwille van de stijgende transportkosten en de impact op het milieu, zijn we genoodzaakt transportkosten aan te rekenen. Toch garandeert Outspot je nog steeds fantastische deal-prijzen waarbij je sowieso wint. Bovendien stijgt je transportkost niet als je meer stuks aankoopt. Als je Outspot PLUS lid bent of je koopt binnen het uur na je vorige aankoop, zullen deze kosten in een volgende stap in mindering gebracht worden.",
    "order.thank_you": "Bedankt voor je bestelling!",
    "order.overview": "Overzicht van je bestelling",
    "order.thank_you_bank": "Hartelijk dank voor jouw aankoop. Je krijgt de gegevens voor de overschrijving in je mailbox of bij ongewenste mails. Gelieve het bedrag zo snel mogelijk en in elk geval binnen de 7 dagen over te schrijven. Je ontvangt de bestelde voucher per e-mail van zodra de betaling toegekomen en verwerkt is.",
    "order.thank_you_with_shipping": "Je aankoop is voltooid. We sturen je bestelling naar onderstaand adres. Je krijgt je aankoopbevestiging in je mailbox of bij ongewenste mails. Veel plezier met je bestelling.",
    "order.thank_you_without_shipping": "Je aankoop is voltooid. Je krijgt je aankoopbevestiging in je mailbox of bij ongewenste mails. Veel plezier met je bestelling.",
    "order.code": "Bestelnummer: {code}",
    "order.shipping_address": "Leveringsadres",
    "order.save_payment_info_title": "Bewaar bankgegevens voor mijn volgende aankopen.",
    "order.save_payment_info_text": "Als je deze optie kiest hoef je niet telkens weer je gegevens in te voeren. Je gegevens worden beveiligd opgeslagen.",
    "order.shipped_to_service_point": "Leveren bij afhaalpunt",
    "my_outspot_plus.title": "Mijn Outspot PLUS",
    "my_outspot_plus.subtitle": "Op deze pagina kan je je Outspot PLUS lidmaatschap beheren.",
    "my_outspot_plus.overview": "Overzicht van je Outspot PLUS lidmaatschap",
    "my_outspot_plus.cancel_are_you_sure": "Ben je zeker dat je je lidmaatschap wil annuleren?",
    "my_outspot_plus.cancel_you_saved_prefix": "Je bespaarde reeds",
    "my_outspot_plus.cancel_you_saved_suffix": "aan verzend- en dossierkosten met je Outspot PLUS lidmaatschap.",
    "my_outspot_plus.no_membership": "Je hebt momenteel geen Outspot PLUS lidmaatschap.",
    "plus.title": "Outspot PLUS",
    "plus.popup_text": "Met Outspot PLUS profiteer je van gratis verzending op alle artikelen en betaal je geen dossierkosten op alle reizen. Na elke bestelling ontvang je een e-mail waarin je de mogelijkheid krijgt om Outspot PLUS te worden. Je moet echter snel zijn want deze actie is maar 7 dagen geldig. Vergeet niet je volgende aankoopbevestigingsmail te checken.",
    "plus.name": "Outspot PLUS lidmaatschap",
    "plus.highlight_1": "Geniet van zorgeloos bestellen zonder verzend- en dossierkosten.",
    "plus.highlight_2": "Jouw abonnement wordt automatisch verlengd voor {renewPrice} per drie maanden.",
    "plus.highlight_3": "Steeds mogelijk te annuleren.",
    "plus.question_1": "Wat zijn de voordelen van Outspot PLUS?",
    "plus.answer_1": "Met Outspot PLUS profiteer je van gratis verzending op alle artikelen en betaal je geen dossierkosten op alle reizen.",
    "plus.question_2": "Hoe word ik Outspot PLUS?",
    "plus.answer_2": "Na elke bestelling ontvang je een e-mail waarin je de mogelijkheid krijgt om Outspot PLUS te worden. Je moet echter snel zijn want deze actie is maar 7 dagen geldig. Als je besluit om Outspot PLUS te activeren, gaan de voordelen direct in werking. Dit betekent dat je bij je eerstvolgende bestelling meteen kunt genieten van gratis verzending op alle artikelen en dat je geen dossierkosten hoeft te betalen voor al je reizen.",
    "plus.question_3": "Hoeveel kost Outspot PLUS?",
    "plus.answer_3": "Outspot PLUS kost slechts {price} voor 3 maanden. Dit is een eenmalige betaling. Na deze periode wordt je lidmaatschap automatisch elke 3 maanden verlengd voor {renewPrice}. Zo kan je aanzienlijk besparen op verzend- en dossierkosten.",
    "plus.question_4": "Wanneer en hoe wordt mijn Outspot PLUS verlengd?",
    "plus.answer_4": "Outspot PLUS wordt elke drie maanden automatisch verlengd. Om dit te beheren kan je altijd naar <a href=\"{myOutspotPlus}\">Mijn Outspot PLUS</a> gaan. Het bedrag wordt per 3 maanden automatisch van je door jouw gekozen rekening afgehouden.",
    "plus.question_5": "Hoe annuleer ik mijn Outspot PLUS? Zal ik een terugbetaling ontvangen?",
    "plus.answer_5": "Je kunt je Outspot PLUS lidmaatschap op elk moment opzeggen via: <a href=\"{myOutspotPlus}\">Mijn Outspot PLUS</a>. Het is belangrijk om te weten dat er geen terugbetaling wordt verstrekt voor de maanden waarin je lidmaatschap actief was. Echter, als je besluit Outspot PLUS op te zeggen, zal de volgende geplande facturering niet in rekening worden gebracht.",
    "plus.thank_you": "Je aankoop is voltooid. Vanaf nu geniet je van zorgeloos bestellen zonder verzend- en dossierkosten. Je kunt jouw lidmaatschap bekijken en beheren via <a href=\"{myOutspotPlus}\">Mijn Outspot PLUS</a>.",
    "my_coupons.title": "Mijn waardebonnen",
    "my_coupons.subtitle": "Op deze pagina kan je je waardebonnen terugvinden.",
    "my_coupons.no_coupons": "Je hebt momenteel geen waardebonnen.",
    "deal.sold": "Al {sold} keer gekocht",
    "deal.retour_label": "30 dagen retourneren voor slechts € 0,95",
    "deal.retour_title": "Retourrecht",
    "deal.retour_text": "Bevalt je product toch niet zo? Neem binnen de 30 dagen na ontvangst contact op met onze helpdesk en retourneer je product. En dit voor om het even welke reden! Wij betalen je aankoop terug en jij kan zorgeloos bestellen. Nog zo'n topidee, van Outspot natuurlijk!",
    "deal.guarantee_label": "6 maanden terugbetaalgarantie",
    "deal.guarantee_title": "Uniek : 100% terugbetalingsgarantie",
    "deal.guarantee_text": "Ongelooflijk maar waar. Je hoeft helemaal niet meer te twijfelen om een belevenis voor je vrienden/collega’s/familie of jezelf aan te kopen. Bedenk je je achteraf, of is de ontvanger niet helemaal tevreden met de geschenkvoucher, dan is er geen probleem.<br><br>Bij Outspot.be kan je namelijk afzien van je aankoop tot zelfs 6 maanden na de bestelling! Jijzelf of de persoon die de voucher heeft ontvangen kan deze dus zonder enig probleem volledig terugbetaald krijgen binnen de 6 maanden. Dit uiteraard voor zover de voucher nog niet gereserveerd, opgebruikt of vervallen is. Dit is als garantie uitzonderlijk en nooit gezien. Maar zo is Outspot nu eenmaal.<br><br> Let wel op: voor alle acties waar niet uitdrukkelijk de terugbetalingsgarantie vermeld staat, geldt de garantie niet. In sommige gevallen kunnen we namelijk zelf niet de leveringen/tickets bij onze partner terugvorderen.",
    "deal.option_count": "{count} optie|{count} opties",
    "deal.campaign_number": "Campagnenummer",
    "deal.your_details": "Jouw gegevens",
    "deal.payment_methods_title": "Betaalwijze",
    "deal.shipping_methods_title": "Verzendwijze",
    "deal.privacy": "Door je gegevens in te geven, ga je akkoord met <a target='_blank' href='{privacy}'>onze privacyverklaring</a>.",
    "deal.payment_methods_subtitle": "Hoe wil je jouw bestelling betalen?",
    "deal.your_order": "Jouw bestelling",
    "deal.from": "Vanaf",
    "help.title": "Hulp nodig?",
    "help.subtitle": "Laat ons weten hoe we je kunnen helpen.",
    "help.subject": "Jouw vraag via onze website of app",
    "help.sent": "We hebben je bericht goed ontvangen. Je krijgt ten laatste binnen 4 werkdagen een antwoord van ons.",
    "login.title": "Log in bij Outspot",
    "login.subtitle": "Hoe wil je inloggen?",
    "login.or": "of",
    "login.usps_title": "Waarom inloggen bij Outspot?",
    "login.usps_title_cta": "Maak het jezelf gemakkelijker en log nu in of maak een account aan.",
    "login.usps_text": "Klik bovenaan op \"Log in\".",
    "login.usp_manage_orders": "Je bestellingen bekijken en opvolgen",
    "login.usp_remember_info": "Je adresgegevens bewaren voor later",
    "login.usp_returns": "Een defect melden of een retour aanvragen",
    "login.usp_store_payments": "Je betaalmethodes opslaan en eenvoudiger betalen",
    "login.short_cta": "<a href=\"{loginLink}\">Log in</a> of ga hieronder verder als gast.",
    "forgot.title": "Vraag een nieuw wachtwoord aan",
    "forgot.subtitle": "Vul hieronder je e-mailadres in en we sturen je een link om je wachtwoord opnieuw in te stellen.",
    "forgot.sent": "We hebben je een e-mail gestuurd met een link om je wachtwoord opnieuw in te stellen.",
    "password.title": "Wijzig wachtwoord",
    "password.subtitle": "Kies een nieuw en veilig wachtwoord.",
    "password.updated": "Je wachtwoord is met succes gewijzigd.",
    "register.title": "Registreer",
    "register.subtitle": "Maak een account aan om nog makkelijker te bestellen en je bestellingen op te volgen.",
    "register.personal_info": "Persoonlijke gegevens",
    "register.login_info": "Inloggegevens",
    "register.agree": "Ik ga akkoord met de <a href=\"{conditions}\" target=\"_blank\">algemene voorwaarden</a> en <a href=\"{privacy}\" target=\"_blank\">privacyverklaring</a>.",
    "register.sent": "Je bent er bijna. We hebben je een e-mail gestuurd waarmee je je account kunt activeren.",
    "register.email_known": "Is je e-mailadres al gekend bij Outspot? <a href=\"{forgot}\">Klik dan hier</a> om een wachtwoord aan te vragen.",
    "my_orders.title": "Mijn bestellingen",
    "my_orders.subtitle": "Dit is een overzicht van alle bestellingen die je bij Outspot plaatste.",
    "my_orders.title_detail": "Details van je bestelling",
    "my_travels.title": "Mijn reizen",
    "my_info.title": "Mijn gegevens",
    "my_info.subtitle": "Hier kan je jouw persoonlijke gegevens aanpassen.",
    "my_info.sent": "Je gegevens zijn aangepast.",
    "notifications.title": "Nieuwsbrief en meldingen",
    "notifications.subscription_mail": "Hoe vaak wil je onze nieuwsbrief ontvangen?",
    "notifications.subscription_notification": "Hoe vaak wil je notificaties ontvangen?",
    "notifications.frequency_0": "Nooit",
    "notifications.frequency_4": "1 keer per week",
    "notifications.frequency_56": "2 keer per dag",
    "notifications.sent": "Je voorkeuren werden bijgewerkt.",
    "combined_purchase_banner.text": "Betaal geen verzend- of dossierkosten voor je volgende bestellingen gedurende:",
    "plus_banner.text_welcome": "{firstName}, welkom bij Outspot PLUS.",
    "plus_banner.text_welcome_anonimous": "Welkom bij Outspot PLUS.",
    "plus_banner.text_savings_prefix": "{firstName}, je bespaarde reeds",
    "plus_banner.text_savings_prefix_anonimous": "Je bespaarde reeds",
    "plus_banner.text_savings_suffix": "aan verzend- en dossierkosten via Outspot PLUS.",
    "cookie_banner.title": "Cookies",
    "cookie_banner.text": "Deze website maakt gebruik van cookies. De strikt noodzakelijke cookies zorgen ervoor dat de website goed functioneert en kunnen niet worden geweigerd. Functionele , analytische & advertising cookies worden respectievelijk voor functionele, statistische en marketing doeleinden gebruikt. Zij worden enkel gedownload als je daarmee instemt. Lees er meer over in ons <a href=\"{cookies}\" target=\"_blank\">cookiebeleid</a>. ",
    "cookie_banner.decline": "Weiger optionele cookies",
    "cookie_banner.accept_all": "Accepteer alle cookies",
    "cookie_banner.accept_selected": "Accepteer geselecteerde",
    "cookie_banner.settings": "Instellingen",
    "cookie_banner.functional_cookies_title": "Strikt noodzakelijke cookies",
    "cookie_banner.functional_cookies_text": "Cookies nodig voor basisfunctionaliteit van de website.",
    "cookie_banner.analytical_cookies_title": "Analytische & functionele cookies",
    "cookie_banner.analytical_cookies_text": "Cookies stellen de website in staat om extra functies en persoonlijke instellingen aan te bieden alsook bezoeken en traffic.",
    "cookie_banner.personal_cookies_title": "Advertising cookies",
    "cookie_banner.personal_cookies_text": "Cookies ingesteld door adverteerders om een profiel van uw interesses samen te stellen zonder directe persoonlijke informatie op te slaan.",
    "review.title": "Praat erover en win",
    "review.subtitle": "Plaats hieronder je beoordeling van je aankoop en maak zo meteen kans op een waardebon van € 250 (elk kwartaal verloten we zo’n waardebon).",
    "review.agree": "Ik ga ermee akkoord dat mijn beoordeling mogelijks gepubliceerd wordt op de website",
    "review.sent": "Bedankt voor je beoordeling. Je maakt nu kans op een waardebon van € 250.",
    "review.title_thank_you": "Bedankt. We waarderen jouw feedback.",
    "review.subtitle_thank_you": "Dankzij jouw feedback kunnen we Outspot nog beter maken. Plaats hieronder je beoordeling van je aankoop en maak zo meteen kans op een waardebon van € 250 (elk kwartaal verloten we zo'n waardebon).",
    "confirm.title": "Je inschrijving is bevestigd",
    "confirm.subtitle": "Je inschrijving op de nieuwsbrief is hierbij bevestigd. Bedankt! Om je nog beter van dienst te kunnen zijn, bieden wij de mogelijkheid om volgende gegevens vrijblijvend met ons te delen.",
    "popup_widget.title": "Deals op z’n best, voor jou uitgetest.",
    "popup_widget.usp_1": "Ontvang dagelijks onze beste aanbiedingen.",
    "popup_widget.usp_2": "Geselecteerd en getest op beste prijs en kwaliteit.",
    "popup_widget.usp_3": "Fikse kortingen tot wel -70%.",
    "popup_widget.footer_1": "Schrijf je nu gratis in voor onze nieuwsbrief en bespaar direct.",
    "popup_widget.footer_2": "Je kan op elk moment opnieuw uitschrijven.",
    "promo.title": "Onze toppromo's",
    "promo.countdown": "2024-12-31 23:59:59",
    "promo.empty": "Momenteel hebben we geen toppromo's. Ga naar onze startpagina voor onze recentste deals.",
    "stars.info_title": "Productscore",
    "stars.info_text": "Dit cijfer is een gemiddelde van onze eigen verzamelde beoordelingen en die van andere partijen.",
    "stars.counter": "1 beoordeling|{count} beoordelingen",
    "label.accept_selected": "Accepteer geselecteerde",
    "label.edit": "Wijzig",
    "label.edit_address": "Wijzig adres",
    "label.address": "Adres",
    "label.accept_all": "Accepteer alle",
    "label.account": "Account",
    "label.help": "Help",
    "label.my_orders": "Mijn bestellingen",
    "label.my_travels": "Mijn reizen",
    "label.my_coupons": "Mijn waardebonnen",
    "label.my_info": "Mijn gegevens",
    "label.change_password": "Wijzig wachtwoord",
    "label.notifications": "Nieuwsbrief en meldingen",
    "label.logout": "Log uit",
    "label.login": "Log in",
    "label.login_have_account": "Heb je al een account? Log in",
    "label.login_with_facebook": "Log in met Facebook",
    "label.login_with_google": "Log in met Google",
    "label.login_with_apple": "Log in met Apple",
    "label.password": "Wachtwoord",
    "label.new_password": "Nieuw wachtwoord",
    "label.repeat_password": "Herhaal wachtwoord",
    "label.repeat_new_password": "Herhaal nieuw wachtwoord",
    "label.register_no_account": "Nog geen account? Registreer nu",
    "label.register": "Registreer",
    "label.forgot_password": "Nog geen wachtwoord of wachtwoord vergeten?",
    "label.general_conditions": "Algemene voorwaarden",
    "label.jobs": "Jobs bij Outspot",
    "label.faq": "Veelgestelde vragen",
    "label.returns": "Retourneren",
    "label.travel_conditions": "Specifieke reisvoorwaarden",
    "label.privacy_policy": "Privacyverklaring",
    "label.cookie_policy": "Cookieverklaring",
    "label.subscribe": "Schrijf me in",
    "label.all_deals": "Alle deals",
    "label.other_deals": "Andere deals",
    "label.discount": "Korting",
    "label.close": "Sluit",
    "label.order_now": "Bestel nu",
    "label.add": "Voeg toe",
    "label.book": "Boek nu",
    "label.upselling_next_step": "Vergeet ook straks dit niet",
    "label.upselling": "Vergeet ook dit niet",
    "label.also_available": "Ook beschikbaar",
    "label.reviews": "Wat onze klanten zeggen",
    "label.automatically_translated": "Automatisch vertaald",
    "label.show_all_reviews": "Bekijk alle beoordelingen",
    "label.back": "Terug",
    "label.make_your_choice": "Maak je keuze",
    "label.sold_out": "Uitverkocht",
    "label.order_more": "Bestel meer",
    "label.subtotal": "Subtotaal",
    "label.total_shipping": "Verzendkosten",
    "label.total_file": "Dossierkosten",
    "label.total_payable": "Totaal",
    "label.no_options_selected": "Je bestelling is momenteel leeg. Klik hieronder op \"Voeg toe\" om verder te gaan.",
    "label.no_options_selected_extra": "Je kan deze optie enkel selecteren bij aankoop van het product.",
    "label.total_saved_prefix": "(Je bespaart ",
    "label.total_saved_suffix": ")",
    "label.continue": "Verder",
    "label.usp_quality": "Geselecteerd op prijs en kwaliteit",
    "label.usp_discounts": "Fikse kortingen tot wel -70%",
    "label.usp_payment_methods": "Betaal veilig en eenvoudig met",
    "label.person": "Persoon",
    "label.free": "Gratis",
    "label.first_name": "Voornaam",
    "label.last_name": "Achternaam",
    "label.birthdate": "Geboortedatum",
    "label.birthdate_why": "",
    "label.birthdate_why_text": "",
    "label.email": "E-mailadres",
    "label.phone": "Telefoonnummer",
    "label.address_finder": "Adreszoeker",
    "label.address_finder_help": "Typ je volledig leveradres in ",
    "label.select": "Selecteer",
    "label.street": "Straat",
    "label.building": "",
    "label.number": "Huisnummer (& bus)",
    "label.zip": "Postcode",
    "label.city": "Gemeente",
    "label.state": "",
    "label.country": "Land",
    "label.finish_order": "Bestellen & betalen ",
    "label.finish_order_help": "Klik op de knop \"Bestellen & betalen \" om je betaling af te ronden.",
    "label.enter_address": "Manueel mijn adres ingeven",
    "label.add_address": "Voeg een adres toe",
    "label.add_payment_method": "Voeg een betaalwijze toe",
    "label.year": "JJJJ",
    "label.month": "MM",
    "label.day": "DD",
    "label.business_order": "Zakelijke bestelling",
    "label.company_name": "Bedrijfsnaam",
    "label.tax_id": "BTW-nummer",
    "label.invoice_reason": "Reden",
    "label.invoice_reasons": "Relatiegeschenken,Teambuilding,Incentives,Seminarie,Andere",
    "label.delete": "Verwijder",
    "label.i_have_coupons": "Ik heb waardebonnen",
    "label.email_outspot_plus": "Ben je al Outspot PLUS-klant? Vul dan je e-mailadres in.",
    "label.please_wait": "Even geduld...",
    "label.please_wait_2": "Nog heel even geduld...",
    "label.please_wait_3": "Sorry, het duurt wat langer dan verwacht...",
    "label.please_wait_4": "Nog even, we zijn er bijna...",
    "label.please_wait_5": "Excuses voor het ongemak en bedankt voor je geduld, we zijn er bijna...",
    "label.continue_shopping": "Verder winkelen",
    "label.my_code": "Mijn code",
    "label.validate": "Valideer",
    "label.total_coupons": "Totaal waardebonnen",
    "label.coupons": "Waardebonnen",
    "label.total_payable_with_coupons_prefix": "(Nog te betalen ",
    "label.total_payable_with_coupons_suffix": ")",
    "label.rest_coupon_info": "Het te betalen bedrag is kleiner dan de ingegeven waardebonnen. Je zal een nieuwe waardebon met de restwaarde ontvangen.",
    "label.contact_extra_info": "Bestelnummer, vouchernummer of gestructureerde mededeling",
    "label.contact": "Contacteer ons",
    "label.message": "Bericht",
    "label.send": "Verstuur",
    "label.save": "Bewaar",
    "label.my_outspot_plus": "Mijn Outspot PLUS",
    "label.order_date_prefix": "Besteld op",
    "label.order_date_suffix": "",
    "label.total_paid_with": "Totaal betaald met {method}",
    "label.track_order": "Volg je bestelling met",
    "label.return_order": "Retourneer en/of meld een defect",
    "label.download_invoice": "Download factuur",
    "label.ask_question": "Stel een vraag",
    "label.happy_hour_discount": "Happy Hour voordeel",
    "label.outspot_plus_discount": "Outspot PLUS voordeel",
    "label.cancel_membership": "Annuleer lidmaatschap",
    "label.membership_start_date": "Startdatum lidmaatschap",
    "label.membership_price": "Prijs voor 3 maanden",
    "label.membership_next_payment": "Volgende betaling",
    "label.membership_end_date": "Einddatum lidmaatschap",
    "label.total_savings": "Totaal bespaarde verzend- en dossierkosten",
    "label.confirm_cancel_membership": "Bevestig annulatie",
    "label.continue_membership": "Annuleer niet",
    "label.valid_till_prefix": "Geldig tot",
    "label.valid_till_suffix": "",
    "label.card_payment": "Kaartbetaling",
    "label.place_review": "Plaats beoordeling",
    "label.name": "Naam",
    "label.name_help": "deze tonen we bij de beoordeling",
    "label.review": "Beoordeling",
    "label.self_service": "",
    "label.update_cookie_preferences": "Wijzig mijn cookievoorkeuren",
    "label.more_info": "Meer info",
    "label.review_source": "Via {source}",
    "label.amount": "Bedrag",
    "label.iban": "IBAN",
    "label.bic": "BIC",
    "label.beneficiary": "Begunstigde",
    "label.structured_communication": "Gestructureerde mededeling",
    "label.later": "Later",
    "label.enable_notifications": "Ja, ik wil deze meldingen ontvangen",
    "label.card_number": "Kaartnummer",
    "label.card_expiry": "Vervaldatum",
    "label.card_cvc": "CVV",
    "label.cvc_info": "",
    "label.sales_tax": "",
    "label.calculating_sales_tax": "",
    "label.sales_tax_will_be_calculated": "",
    "label.ship_to_selected_address": "Verzend naar mijn adres",
    "label.ship_to_service_point": "Verzend naar afhaalpunt",
    "label.change_service_point": "Wijzig afhaalpunt",
    "label.choose_rooms": "Kies kamer(s)",
    "label.room": "Kamer {number}",
    "label.room_occupancy": "Bezetting kamer {number}",
    "label.add_another_room": "Voeg nog een kamer toe",
    "label.number_of_adults": "{count} volwassene|{count} volwassenen",
    "label.number_of_adults_title": "Aantal volwassenen",
    "label.number_of_children": "{count} kind|{count} kinderen",
    "label.number_of_children_title": "Aantal kinderen",
    "label.choose_arrival_and_departure_date": "Kies aankomst- en vertrekdatum",
    "label.arrival_date": "Aankomstdatum",
    "label.select_arrival_date": "Selecteer aankomstdatum",
    "label.departure_date": "Vetrekdatum",
    "label.select_departure_date": "Selecteer vertrekdatum",
    "label.rate_type_pc": "Per stuk",
    "label.rate_type_pcpd": "Per stuk per dag",
    "label.rate_type_pcpn": "Per stuk per nacht",
    "label.rate_type_pp": "Per persoon",
    "label.rate_type_pppd": "Per persoon per dag",
    "label.rate_type_pppn": "Per persoon per nacht",
    "label.rate_type_pp_": "Per persoon per dag",
    "label.rate_type_pa": "Per verblijf",
    "label.rate_type_papd": "Per verblijf per dag",
    "label.rate_type_papn": "Per verblijf per nacht",
    "label.rate_type_pa_": "Per verblijf per dag",
    "label.choose_supplements": "Maak je verblijf compleet",
    "label.opengds_invalid": "Vervolledig je reservering op deze pagina om verder te gaan.",
    "error.unknown": "Onbekende fout, probeer het later opnieuw.",
    "error.required": "{field} kan niet leeg zijn.",
    "error.required_email": "{field} kan niet leeg zijn en moet een geldig e-mailadres zijn.",
    "error.required_password": "{field} moet tenminste 8 tekens lang zijn en tenminste één hoofdletter, kleine letter en cijfer bevatten.",
    "error.required_repeat_password": "{field} moet gelijk zijn aan het wachtwoord opgegeven in het veld {field2}.",
    "error.payment": "Jammer genoeg is je betaling mislukt. Dit kan gebeuren als er een probleem is met de betalingsgegevens, de betaling geannuleerd is, je betaallimiet is bereikt of om andere redenen. Overweeg om contact op te nemen met je bank of probeer een andere kaart of betaalmethode.",
    "error.payment_creditcard": "Jammer. Je betaling is mislukt. Controleer onderstaande gegevens en probeer het opnieuw.",
    "error.incomplete_order": "We kunnen deze waardebon pas valideren wanneer je alle bovenstaande velden hebt ingevuld.",
    "error.coupon_already_added": "Je hebt deze waardebon al toegevoegd.",
    "error.invalid_coupon": "Deze waardebon is ongeldig.",
    "error.coupon_not_cumulative": "Deze waardebon kan je niet samen gebruiken met andere waardebonnen.",
    "error.other_coupon_not_cumulative": "De waardebon die je al hebt toegevoegd kan je niet samen gebruiken met deze waardebon.",
    "error.invalid_username_or_password": "Het opgegeven e-mailadres en/of wachtwoord is ongeldig.",
    "error.test_order": "Dit is een testbestelling. Normaal zou de klant nu naar de betaalpagina gaan.",
    "error.required_agree": "Je moet akkoord gaan met onze algemene voorwaarden en privacyverklaring.",
    "error.required_review_agree": "Je moet ermee akkoord gaan dat je beoordeling mogelijks gepubliceerd wordt op onze website.",
    "error.register": "Er is al een account met dit e-mailadres. Klik op \"Verder\" om in te loggen of gebruik een ander e-mailadres.",
    "error.email_in_use": "Er is al een account met dit e-mailadres. Gebruik een ander e-mailadres.",
    "error.already_reviewed": "Je hebt al een beoordeling geplaatst voor deze bestelling.",
    "error.zip_blocked": "Het is niet mogelijk om naar de opgegeven postcode te verzenden.",
    "error.details": "Het lijkt er op dat één of meerdere producten die je probeert te bestellen niet langer op voorraad zijn. Keer terug naar de vorige stap, pas je bestelling aan en probeer het opnieuw.",
    "error.incomplete_cvc": "CVV is te kort.",
    "error.incomplete_date": "Vervaldatum kan niet leeg zijn.",
    "error.date_in_past": "Kaart is vervallen.",
    "error.invalid_expiration_year": "Kaart is vervallen.",
    "error.incomplete_card_number": "Kaartnummer is te kort.",
    "error.incorrect_card_number": "Kaartnummer is ongeldig.",
    "unsubscription.your_mail_address": "Jouw e-mailadres: ",
    "unsubscription.sad_message": "Hierbij bevestigen we je uitschrijving.",
    "unsubscription.have_not_participated": "Heb je je nooit ingeschreven?",
    "unsubscription.sad_message_2": "We vinden het jammer dat je ons verlaten hebt, maar uiteraard blijf je altijd welkom.",
    "unsubscription.resubscribe_question": "Ben je van gedachten veranderd? <span class='unsub-important-message'>Klik hieronder om je opnieuw in te schrijven voor onze nieuwsbrief:</span>",
    "unsubscription.form_message": "Waarom verlaat je onze Outspot-familie? Je antwoord kan ons helpen om onze dienstverlening te verbeteren.",
    "unsubscription.report_not_participated": "Meld misbruik.",
    "unsubscription.coreg_date_no_screenshot": "Je nam op {date} deel aan een actie (<a href=\"{coreg_site}\">{coreg_site}</a>) en gaf zo de toestemming om onze nieuwsbrief met topaanbiedingen tegen uitzonderlijke prijzen te ontvangen.",
    "unsubscription.coreg_date_no_coreg_data": "Je nam op {date} deel aan onderstaande actie en gaf zo de toestemming om onze nieuwsbrief met topaanbiedingen tegen uitzonderlijke prijzen te ontvangen.",
    "unsubscription.coreg_date_screenshot": "Je nam op {date} deel aan onderstaande actie en gaf zo de toestemming om onze nieuwsbrief met topaanbiedingen tegen uitzonderlijke prijzen te ontvangen.",
    "unsubscription.coreg_no_date_no_screenshot": "Je nam deel aan onderstaande actie en gaf je toestemming om onze nieuwsbrieven met topaanbiedingen voor uitzonderlijke prijzen te ontvangen.",
    "unsubscription.coreg_no_date_screenshot": "Je nam deel aan onderstaande actie en gaf je toestemming om onze nieuwsbrieven met topaanbiedingen voor uitzonderlijke prijzen te ontvangen.",
    "unsubscription.main_title": "Afmelden voor de nieuwsbrief",
    "unsubscription.report_title": "Meld een misbruik",
    "unsubscription.report_sent_message": "Bedankt, je melding is ontvangen.",
    "claim_coupon.welcome_message": "Speciaal voor jou",
    "claim_coupon.info_line_message": "Voer bovenstaande persoonlijke code in bij het afrekenen.",
    "claim_coupon.info_line_message_bis": "Deze waardebon werd je ook per mail toegestuurd.",
    "claim_coupon.main_title": "Jouw coupon",
    "about.title": "Over Ons",
    "about.intro": "Welkom bij Outspot, jouw nummer één bestemming voor onweerstaanbare deals! Sinds de oprichting in 2008 is Outspot uitgegroeid tot dé toonaangevende Europese flash deal-website. Outspot biedt dagelijks kortingen aan op kwalitatieve producten en reizen, waardoor jij, als klant, geweldig kan besparen. Dankzij ons magazijn in België brengen wij onze pakketjes zo snel mogelijk bij jouw thuis.",
    "about.mission_title": "Mission",
    "about.mission_text": "Bij Outspot geloven we in het toegankelijk maken van uitzonderlijke aanbiedingen voor iedereen. Ons doel is om onze klanten de best mogelijke waarde te bieden door zorgvuldig geselecteerde deals aan te bieden tegen ongeëvenaarde prijzen.",
    "about.history_title": "History",
    "about.history_text": "Het Outspot avontuur begon in België, waar het hoofdkantoor nog steeds is gevestigd. Met een toegewijd team van 25 medewerkers verspreid over Europa, werken we dagelijks aan het vinden en aanbieden van de beste deals. Ondertussen zijn we in 13 landen actief en hebben we dagelijks 100.000 tevreden bezoekers.",
    "about.quality_title": "Ons aanbod is geselecteerd op basis van prijs en kwaliteit",
    "about.quality_text": "Bij Outspot vinden we dat je het beste van twee werelden verdient: topkwaliteit tegen een betaalbare prijs. Daarom gaan we elke dag opnieuw voor jou op zoek naar de meest aantrekkelijke deals. Door nauwe samenwerkingen met onze leveranciers zorgen we ervoor dat we de beste prijzen kunnen onderhandelen, zonder concessies te doen aan de kwaliteit. <br /><br />  Ieder product en elke reis die we aanbieden, wordt zorgvuldig geselecteerd op basis van een strenge evaluatie van prijs en kwaliteit. Zo weet je zeker dat je bij ons altijd de beste waarde krijgt voor je geld. Bij Outspot draait alles om jouw voordeel én tevredenheid! Hier ziet u een foto waarbij het geluid van de smartbel wordt getest met een decibelmeter.",
    "about.tested_title": "Alle deals zijn uitvoerig getest door onze Outspot collega's",
    "about.tested_text": "Bij Outspot geloven we in het leveren van alleen het allerbeste. Voordat een deal op onze site verschijnt, wordt deze uitvoerig getest door onze eigen Outspot-collega's. Zij nemen geen genoegen met oppervlakkige tests: producten worden volledig uit elkaar gehaald en elk onderdeel wordt zorgvuldig beoordeeld op stevigheid, geluidsniveau, materiaalkwaliteit en meer.<br /><br />  Ook bezoeken onze collega’s persoonlijk veel hotels om de kwaliteit van de accommodaties te controleren. Zo garanderen we dat alles aan onze hoge standaarden voldoet. Of het nu om een product of een reis gaat, je kunt er zeker van zijn dat je bij Outspot altijd met vertrouwen je aankoop doet.<br /><br />  Zo ziet u hier op de foto hoe collega’s producten tot in detail bekijken en testen: bv stofkwaliteit check.",
    "about.guarantee_title": "6 maanden terugbetaal-garantie op meeste reizen",
    "about.guarantee_text": "Wanneer je een reis boekt, wil je er zeker van zijn dat je keuze de juiste is en dat je investering goed besteed is. Daarom bieden wij een terugbetaalgarantie van 6 maanden op de meeste van onze reizen. Met deze garantie kun je met een gerust hart plannen, in de wetenschap dat je altijd recht hebt op een terugbetaling, mits er nog geen reservering is gemaakt. Dit geeft je de vrijheid om flexibel te blijven in je beslissingen, zonder het risico op extra kosten zolang er geen definitieve boeking is.",
    "about.returns_title": "30 dagen retourrecht op meeste producten",
    "about.returns_text": "We begrijpen dat het soms wat tijd kan kosten om te beslissen of je een product wilt behouden. Daarom bieden wij op de meeste van onze producten een retourrecht van 30 dagen aan. Het is belangrijk dat het product ongebruikt en ongeopend blijft om in aanmerking te komen voor een retour. Dit geeft je de kans om je aankoop op je eigen tempo te overwegen, terwijl we zorgen voor een soepele retourprocedure. Zo kun je zonder zorgen beslissen, zolang het product in originele staat blijft.",
    "about.support_title": "Klantendienst in het Nederlands",
    "about.support_text": "Bij Outspot staat klanttevredenheid voorop, en we doen er alles aan om ervoor te zorgen dat je een uitstekende ervaring hebt. Mocht je vragen hebben of hulp nodig hebben met je bestelling, dan staat onze klantendienst altijd voor je klaar. Onze Nederlandstalige medewerkers zijn beschikbaar om je snel, vriendelijk en efficiënt te ondersteunen. We streven ernaar om je op een persoonlijke en begrijpelijke manier te helpen, zodat je zonder zorgen kunt genieten van je aankoop. Jouw tevredenheid is onze prioriteit, en we zijn er om ervoor te zorgen dat je optimaal kunt profiteren van onze service.",
    "about.socials_title": "Blijf verbonden",
    "about.socials_text": "Blijf op de hoogte van onze nieuwste deals en exclusieve aanbiedingen door je aan te melden voor onze nieuwsbrief. Volg ons op social media en deel jouw Outspot-ervaringen met ons!<br /><br />  Heb je vragen of opmerkingen? Onze helpdesk staat klaar om je te helpen. Neem contact met ons op via <a href=\"{help}\">onze contactpagina</a>.<br /><br />  Bedankt voor jouw vertrouwen in Outspot. We kijken ernaar uit om je telkens weer te verrassen met onze fantastische aanbiedingen!",
    "vat.error_required": "Het veld \"{field}\" mag niet leeg zijn bij een zakelijke bestelling. ",
    "vat.validation_message_format": "Het veld \"{field}\" is niet het correcte formaat.",
    "vat.help_title": "BTW nummer ",
    "vat.help_text": "Het Belgische BTW nummer moet uit 12 tekens bestaan e.g. BE0897991257.",
    "label.download": "Download",
    "error.download_pdf": "Er ging iets mis bij het downloaden van de PDF. Probeer het later nog eens.",
    "manage_address.title": "Mijn bezorgadressen",
    "error.select": "Selecteer een {field}",
    "manage_cards.title": "Mijn betaalmethoden",
    "deal.quality_text_1": "Geselecteerd op prijs en kwaliteit ",
    "deal.quality_text_2": "Voorraad in België",
    "deal.delivery_text_days": "Levertijd: {days} werkdagen",
    "deal.delivery_text": "Op werkdagen voor 16u besteld, volgende werkdag geleverd",
    "maintenance.going_offline": "Over enkele minuten gaat onze website tijdelijk offline voor gepland onderhoud. Rond je bestelling nu af, want tijdens het onderhoud is bestellen even niet mogelijk.",
    "maintenance.offline": "Onze website is momenteel offline voor gepland onderhoud. Probeer het over enkele minuten opnieuw – we zijn zo snel mogelijk weer online! Als attentie krijg je van ons GMJ2QPZ3 voor €5 korting op je volgende aankoop. ",
    "sustainability.carpark_title": "Groener maken van onze wagenpark",
    "sustainability.carpark_text": "In onze voortdurende inspanningen om onze ecologische voetafdruk te verkleinen, transformeren we onze bedrijfswagens. Tegen 2026 streven we naar een volledig elektrische vloot. Deze overgang is een cruciale stap om onze uitstoot te verlagen en schoner, groener vervoer te bevorderen.",
    "sustainability.energy_title": "Kantoor op zonne-energie",
    "sustainability.energy_text": "Duurzaamheid begint thuis, en voor ons betekent dat ons kantoor. We hebben 30 zonnepanelen geïnstalleerd, die bijna de helft van de elektriciteit produceren die we nodig hebben voor onze activiteiten op kantoor. Door gebruik te maken van de kracht van de zon, verminderen we onze afhankelijkheid van niet-hernieuwbare energiebronnen aanzienlijk en verlagen we onze totale CO2-uitstoot.",
    "sustainability.eco_packaging_title": "Milieuvriendelijke verpakkingen",
    "sustainability.eco_packaging_text": "Wij zijn ons bewust van de impact van verpakkingsafval op het milieu. Daarom is al onze verpakking gemaakt 100% recycleerbaar plastic. Dit initiatief helpt op de manier een duurzamere cyclus van gebruik en hergebruik te creëren.",
    "sustainability.combined_packaging_title": "Geconsolideerde Verzending",
    "sustainability.combined_packaging_text": "Om onze impact op het milieu verder te verkleinen, moedigen we onze klanten aan om hun bestellingen te consolideren. Door zo veel mogelijk aankopen in één zending te combineren, kunnen we het aantal leveringen verminderen. Zo worden op zijn beurt de uitstoot verlaagd en de ecologische voetafdruk verkleind.",
    "sustainability.title": "Duurzaamheid bij Outspot",
    "sustainability.vision_title": "Onze visie",
    "sustainability.vision_text": "Outspot is zich bewust van haar impact op de leefomgeving. We maken dagelijks duizenden mensen blij met ons aanbod, terwijl onze ecologische en sociale verantwoordelijkheid in elk aspect van onze activiteit verweven zit. Wij geloven dat door het nemen van proactieve stappen vandaag, we effectief impact kunnen hebben op de gezondheid van onze planeet en het welzijn van toekomstige generaties.",
    "sustainability.action_title": "Doe met ons mee ",
    "sustainability.action_text": "We nodigen je uit om mee aan boord te gaan van onze duurzaamheidsreis. Van het kiezen van milieuvriendelijke producten tot deelname aan onze recyclingprogramma's, elke kleine actie maakt een groot verschil.  ",
    "sustainability.plant_text": "Bij je aankoop kun je ervoor kiezen om voor slechts €1 extra een boom te laten planten. Dit helpt niet alleen bij het compenseren van CO2-uitstoot, maar draagt ook bij aan herbebossing en een betere toekomst voor lokale gemeenschappen.",
    "sustainability.treeplanters": "We werken samen met Tree-Planters, een organisatie die zich inzet voor slimme en duurzame herbebossing. Zij planten bomen op zorgvuldig geselecteerde locaties in Malawi, waar ze niet alleen de natuur herstellen, maar ook de lokale bevolking ondersteunen met extra inkomsten en voedselvoorziening",
    "sustainability.action_text_believe": "Bij Outspot geloven we dat duurzaamheid een gedeelde verantwoordelijkheid is. Samen met onze klanten, werknemers en partners streven we naar een bewuste omgang met onze planeet. Doe mee en maak samen met ons het verschil!",
    "sustainability.plant_title": "Plant een boom - maak het verschil",
    "sustainability.trees": "bomen",
    "sustainability.tree": "boom",
    "deal.advice_price": "",
    "sustainability.checkbox_title": "Plant samen met Outspot",
    "sustainability.checkbox_detail_text": "We nodigen je uit om samen met Outspot bomen te planten voor € {price}."
  },
  "fr": {
    "route.home": "https://www.outspot.be/fr/",
    "route.fallback_domain": "https://www2.outspot.be",
    "language.code": "FR",
    "language.locale": "fr-FR",
    "language.name": "Belgique (Français)",
    "language.flag": "be",
    "language.date_input_format": "d,m,y",
    "language.currency": "EUR",
    "language.region": "eu",
    "countries.delivery": "BEL,LUX",
    "states.delivery": "",
    "tracking.aw_merchant_id": "521180818",
    "tracking.aw_feed_country": "BE",
    "countries.BEL": "Belgique",
    "countries.LUX": "Luxembourg",
    "countries.NLD": "Pays-Bas",
    "countries.FRA": "France",
    "countries.DEU": "Allemagne",
    "countries.AUT": "Autriche",
    "countries.ITA": "Italie",
    "countries.ESP": "Espagne",
    "countries.POL": "Pologne",
    "countries.GBR": "Royaume-Uni",
    "countries.IRL": "Irlande",
    "countries.SWE": "Suède",
    "countries.PRT": "Portugal",
    "countries.FIN": "Finlande",
    "countries.USA": "",
    "states.AL": "",
    "states.AR": "",
    "states.AZ": "",
    "states.CA": "",
    "states.CO": "",
    "states.CT": "",
    "states.DE": "",
    "states.FL": "",
    "states.GA": "",
    "states.IA": "",
    "states.ID": "",
    "states.IL": "",
    "states.IN": "",
    "states.KS": "",
    "states.KY": "",
    "states.LA": "",
    "states.MA": "",
    "states.MD": "",
    "states.ME": "",
    "states.MI": "",
    "states.MN": "",
    "states.MO": "",
    "states.MS": "",
    "states.MT": "",
    "states.NC": "",
    "states.ND": "",
    "states.NE": "",
    "states.NH": "",
    "states.NJ": "",
    "states.NM": "",
    "states.NV": "",
    "states.NY": "",
    "states.OH": "",
    "states.OK": "",
    "states.OR": "",
    "states.PA": "",
    "states.RI": "",
    "states.SC": "",
    "states.SD": "",
    "states.TN": "",
    "states.TX": "",
    "states.UT": "",
    "states.VA": "",
    "states.VT": "",
    "states.WA": "",
    "states.WI": "",
    "states.WV": "",
    "states.WY": "",
    "trustpilot.businessunit_id": "509e887e00006400051e5b55",
    "trustpilot.show": "TRUE",
    "mollie.profile_id": "pfl_gJxKAw2cQe",
    "mollie.locale": "fr_FR",
    "facebook.url": "https://www.facebook.com/Outspot",
    "instagram.url": "https://www.instagram.com/outspot_nl/",
    "linkedin.url": "https://www.linkedin.com/company/outspot/mycompany/",
    "header.hello": "Bienvenue",
    "header.hello_name": "Bonjour <b>{firstName}</b>,",
    "subscribe.title": "Inscrivez-vous gratuitement et ne manquez aucune de nos offres !",
    "subscribe.text": "Découvrez les meilleures offres Outspot en vous inscrivant gratuitement sur notre site. Vous profiterez de cette manière de réductions exclusives et personnalisées sur des produits et des expériences uniques.",
    "subscribe.agree": "En vous abonnant à notre newsletter, vous acceptez les <a href=\"{conditions}\" target=\"_blank\">conditions générales</a> et la <a href=\"{privacy}\" target=\"_blank\">déclaration de confidentialité</a>.",
    "subscribe.sent": "Merci beaucoup ! Nous avons bien reçu votre demande et vous envoyons immédiatement l'e-mail de confirmation de votre inscription.",
    "footer.follow": "Suivez-nous sur",
    "footer.payment_methods_logos": "bancontact,mastercard,visa,amex,paypal,applepay,bank",
    "footer.copyright": "Outspot — Sint-Martens-Latem, Belgique — Confinity SA",
    "home.new": "Offres récentes",
    "home.ending": "Ces offres sont sur le point d'expirer",
    "home.other": "Toutes les offres",
    "countdown.prefix": "Encore",
    "countdown.days": "jour|jours",
    "countdown.hours": "heure|heures",
    "countdown.minutes": "min",
    "countdown.seconds": "sec",
    "countdown.days_compact": "j",
    "date.years": "Il y a {count} an|Il y a {count} ans",
    "date.months": "Il y a {count} mois",
    "date.weeks": "Il y a {count} semaine|Il y a {count} semaines",
    "date.days": "Il y a {count} jour|Il y a {count} jours",
    "date.hours": "Il y a {count} heure|Il y a {count} heures",
    "date.minutes": "Il y a {count} minute|Il y a {count} minutes",
    "date.seconds": "Il y a moins d'une minute",
    "countdown.suffix": "",
    "onboard.language_title": "Profitez d'offres personnalisées au meilleur prix",
    "onboard.language_text": "Choisissez le pays et la langue de votre choix pour faire vos achats :",
    "onboard.notifications_title": "Restez au courant de :",
    "onboard.notifications_usp_1": "Les meilleures offres les plus récentes.",
    "onboard.notifications_usp_2": "Statut de vos commandes.",
    "onboard.notifications_usp_3": "Codes de réduction personnels et bons d'achat.",
    "order.shipping_title": "Livraison",
    "order.shipping_text": "En raison de l'augmentation des coûts liés au transport et de l'impact sur l'environnement, nous sommes contraints de facturer des frais de port. Outspot garantit, néanmoins, toujours des prix réduits fantastiques grâce auxquels vous êtes de toutes façons toujours gagnant(e). En outre, les frais de ports n'augmenteront pas si vous achetez plusieurs fois le même article. Si vous êtes affilié(e) à Outspot PLUS ou si vous achetez dans l'heure qui suit votre précédent achat, les frais de livraison seront déduits à l'étape suivante.",
    "order.thank_you": "Merci pour votre commande!",
    "order.overview": "Aperçu de votre commande",
    "order.thank_you_bank": "Nous vous remercions pour votre achat. Vous recevrez les détails du virement par e-mail. Pensez également à consulter vos courriers indésirables. Veuillez effectuer le virement le plus rapidement possible et dans tous les cas dans un délai de 7 jours. Vous recevrez le bon que vous avez commandé par e-mail dès que le paiement nous sera parvenu et qu'il aura été traité.",
    "order.thank_you_with_shipping": "Votre achat a été effectué avec succès. Nous enverrons votre commande à l'adresse mentionnée ci-dessous. Vous recevrez votre confirmation d'achat par e-mail. Pensez également à consulter vos courriers indésirables. Profitez bien de votre achat !",
    "order.thank_you_without_shipping": "Votre achat a été effectué avec succès. Vous recevrez votre confirmation d'achat par e-mail. Pensez également à consulter vos courriers indésirables. Profitez bien de votre achat !",
    "order.code": "Numéro de la commande : {code}",
    "order.shipping_address": "Adresse de livraison",
    "order.save_payment_info_title": "Je sauvegarde mes coordonnées bancaires pour mes prochains achats.",
    "order.save_payment_info_text": "Si vous choisissez cette option, vous n'aurez pas à saisir vos données lors de chaque paiement. Vos données seront stockées en toute sécurité.",
    "order.shipped_to_service_point": "",
    "my_outspot_plus.title": "Mon abonnement Outspot PLUS",
    "my_outspot_plus.subtitle": "Vous pouvez gérer votre abonnement sur cette page.",
    "my_outspot_plus.overview": "Aperçu de votre abonnement",
    "my_outspot_plus.cancel_are_you_sure": "Êtes-vous certain(e) de vouloir annuler votre abonnement ?",
    "my_outspot_plus.cancel_you_saved_prefix": "Vous avez déjà économisé",
    "my_outspot_plus.cancel_you_saved_suffix": "en frais d'envoi et de dossier grâce à votre abonnement Outspot PLUS.",
    "my_outspot_plus.no_membership": "Vous n'êtes pas encore abonné(e) à Outspot PLUS.",
    "plus.title": "Outspot PLUS",
    "plus.popup_text": "Avec Outspot PLUS, vous bénéficiez de la livraison gratuite sur tous les produits et ne payez pas de frais de dossier sur les voyages. Après chaque commande, vous recevrez un e-mail vous permettant de vous abonner à Outspot PLUS. Ne tardez pas car la proposition n'est valable que durant 7 jours. N'oubliez pas de vérifier votre dernière confirmation d'achat.",
    "plus.name": "Outspot PLUS",
    "plus.highlight_1": "Commandez en toute tranquillité sans frais de port et de dossier.",
    "plus.highlight_2": "Votre abonnement sera automatiquement renouvelé tous les 3 mois pour seulement  {renewPrice}.",
    "plus.highlight_3": "Il est toujours possible de résilier l'abonnement.\r",
    "plus.question_1": "Quels sont les avantages d'Outspot PLUS ?",
    "plus.answer_1": "Avec Outspot PLUS, vous bénéficiez de la livraison gratuite sur tous les produits et ne payez aucuns frais de dossier sur les voyages.",
    "plus.question_2": "Comment devenir Outspot PLUS ?",
    "plus.answer_2": "Après chaque commande, vous recevrez un e-mail vous permettant de devenir Outspot PLUS. Ne tardez pas car la promotion n'est valable que durant 7 jours. Si vous décidez d'activer Outspot PLUS, les avantages commencent immédiatement. Cela signifie que lors de votre prochaine commande, vous bénéficierez immédiatement de la livraison gratuite sur tous les produits et que vous n'aurez plus à payer de frais de dossier sur les voyages.",
    "plus.question_3": "Combien coûte Outspot PLUS ?",
    "plus.answer_3": "Outspot PLUS ne coûte que {price} pour 3 mois. Il s'agit d'un paiement unique. Après cette période, votre adhésion sera automatiquement renouvelée tous les 3 mois pour {renewPrice}. Cela vous permet d'économiser considérablement sur les frais de port et de dossier.",
    "plus.question_4": "Quand et comment mon abonnement Outspot PLUS sera-t-il renouvelé ?",
    "plus.answer_4": "Outspot PLUS est automatiquement renouvelé tous les trois mois. Si vous souhaitez gérer votre abonnement, vous pouvez toujours vous rendre sur <a href=\"{myOutspotPlus}\">Mon Outspot PLUS</a>. Le montant sera automatiquement déduit tous les trois mois du compte bancaire que vous avez choisi.",
    "plus.question_5": "Comment puis-je résilier mon abonnement Outspot PLUS ? Serai-je remboursé(e) ?",
    "plus.answer_5": "Vous pouvez résilier votre adhésion à Outspot PLUS à tout moment via : <a href=\"{myOutspotPlus}\">Mon Outspot PLUS</a>. Il est important de noter qu'aucun remboursement ne sera effectué pour les mois au cours desquels votre adhésion a été active. Toutefois, si vous décidez de résilier Outspot PLUS, le prochain paiement prévu ne sera pas facturé.",
    "plus.thank_you": "Votre achat a été effectué avec succès. Les frais de port et de dossier ne seront, désormais, plus facturés. Vous pouvez consulter et gérer votre abonnement via <a href=\"{myOutspotPlus}\">Mon Outspot PLUS</a>.",
    "my_coupons.title": "Mes bons d'achat",
    "my_coupons.subtitle": "Vous trouverez vos bons d'achat sur cette page.",
    "my_coupons.no_coupons": "Vous n'avez actuellement aucun bon d'achat.",
    "deal.sold": "Déjà {sold} acheteurs",
    "deal.retour_label": "Retour sous 30 jours pour seulement 0,95 €",
    "deal.retour_title": "Droit de retour",
    "deal.retour_text": "Le produit que vous avez reçu ne vous convient pas ? Contactez notre service clientèle dans les 30 jours suivant la réception de votre commande afin de nous la renvoyer. Et ce, quelle que soit la raison ! Nous vous rembourserons votre achat afin que vous puissiez passer une nouvelle commande en toute confiance. Une autre bonne idée d'Outspot, évidemment !",
    "deal.guarantee_label": "Garantie de remboursement de 6 mois",
    "deal.guarantee_title": "Unique : garantie de remboursement intégral",
    "deal.guarantee_text": "Incroyable mais vrai. N’hésitez plus une seconde pour acheter une expérience pour vos amis / collègues / famille ou vous-même. Vous changez d’avis ou le bénéficiaire du cadeau n’est pas entièrement satisfait ? Aucun problème !<br><br>Outspot accepte en effet un droit de rétractation jusqu’à 6 mois après la commande ! Vous-même ou la personne qui a reçu le bon pouvez donc être intégralement remboursé(e) sans problème dans les 6 mois. Pour autant bien entendu que le bon n’ait pas encore été réservé, utilisé ou ne soit pas encore expiré. Cette garantie est exceptionnelle et inédite. C’est ça Outspot.<br><br>Veuillez noter que pour toutes les promotions où la garantie de remboursement n'est pas explicitement mentionnée, celle-ci n'est pas d'application. En effet, il ne nous est pas toujours possible de réclamer les livraisons ou tickets auprès de nos partenaires.",
    "deal.option_count": "{count} option|{count} options",
    "deal.campaign_number": "Numéro de la campagne",
    "deal.your_details": "Vos coordonnées",
    "deal.payment_methods_title": "Mode de paiement",
    "deal.shipping_methods_title": "",
    "deal.privacy": "En indiquant vos coordonnées, vous acceptez <a target='_blank' href='{privacy}'>notre déclaration de confidentialité</a>.",
    "deal.payment_methods_subtitle": "Comment souhaitez-vous payer votre commande ?",
    "deal.your_order": "Votre commande",
    "deal.from": "À partir de",
    "help.title": "Besoin d'aide ?",
    "help.subtitle": "Faites-nous savoir comment nous pouvons vous aider.",
    "help.subject": "Votre question via notre site web ou notre application",
    "help.sent": "Nous avons bien reçu votre message et vous répondrons dans les 4 jours ouvrables.",
    "login.title": "Me connecter",
    "login.subtitle": "Comment souhaitez-vous vous connecter ?",
    "login.or": "ou",
    "login.usps_title": "Créez un compte et vous pourrez :",
    "login.usps_title_cta": "Facilitez-vous la vie en vous connectant ou en créant un compte Outspot !",
    "login.usps_text": "Cliquez sur le bouton \"Je me connecte\" en haut de la page pour vous connecter. Si vous n'avez pas encore de compte, vous pouvez également vous inscrire sur la page de connexion. Avoir un compte Outspot n'a que des avantages :",
    "login.usp_manage_orders": "consulter et suivre vos commandes en ligne ;",
    "login.usp_remember_info": "sauvegarder vos adresses de livraison ;",
    "login.usp_returns": "signaler un défaut et/ou renvoyer un produit ;",
    "login.usp_store_payments": "enregistrer vos modes de paiement favoris et payer rapidement.",
    "login.short_cta": "<a href=\"{loginLink}\">Connectez-vous</a> ou continuez ci-dessous en tant qu'invité.",
    "forgot.title": "Demandez un nouveau mon mot de passe.",
    "forgot.subtitle": "Saisissez votre adresse e-mail ci-dessous afin que nous puissions vous envoyer un lien pour réinitialiser votre mot de passe.",
    "forgot.sent": "Nous vous avons envoyé un e-mail avec un lien pour réinitialiser votre mot de passe.",
    "password.title": "Modifiez votre mot de passe.",
    "password.subtitle": "Choisissez un nouveau mot de passe sécurisé.",
    "password.updated": "Vous avez modifié votre mot de passe. Vous pouvez désormais vous connecter avec le nouveau.",
    "register.title": "Je m'inscris",
    "register.subtitle": "Créez un compte pour passer commande facilement et suivre vos achats en ligne.",
    "register.personal_info": "Données personnelles",
    "register.login_info": "Données de connexion",
    "register.agree": "J'accepte les <a href=\"/fr/help/general-conditions\" target=\"_blank\">conditions générales</a> et la <a href=\"/fr/help/privacy\" target=\"_blank\">déclaration de confidentialité</a>.",
    "register.sent": "C'est bientôt terminé ! Nous vous avons envoyé un e-mail pour activer votre compte.",
    "register.email_known": "Votre adresse e-mail est déjà renseignée sur notre site ? <a href=\"{forgot}\">Cliquez alors ici</a> pour demander un mot de passe.",
    "my_orders.title": "Mes achats",
    "my_orders.subtitle": "Voici un aperçu de toutes les commandes que vous avez passées sur le site d'Outspot.",
    "my_orders.title_detail": "Détails de votre commande.",
    "my_travels.title": "Mes voyages",
    "my_info.title": "Mes coordonnées",
    "my_info.subtitle": "Vous pouvez modifier vos données personnelles sur cette page.",
    "my_info.sent": "Vos données ont été mises à jour.",
    "notifications.title": "Newsletter et notifications",
    "notifications.subscription_mail": "Combien de fois souhaitez-vous recevoir notre bulletin d'information ?",
    "notifications.subscription_notification": "Combien de fois souhaitez-vous recevoir des notifications ?",
    "notifications.frequency_0": "Jamais",
    "notifications.frequency_4": "Une fois par semaine",
    "notifications.frequency_56": "Deux fois par jour",
    "notifications.sent": "Vos préférences ont été mises à jour.",
    "combined_purchase_banner.text": "Ne payez aucuns frais de port ni de dossier sur vos prochaines commandes durant",
    "plus_banner.text_welcome": "{firstName}, bienvenue chez Outspot PLUS.",
    "plus_banner.text_welcome_anonimous": "Bienvenue chez Outspot PLUS.",
    "plus_banner.text_savings_prefix": "{firstName}, vous avez déjà économisé",
    "plus_banner.text_savings_prefix_anonimous": "Vous avez déjà économisé",
    "plus_banner.text_savings_suffix": "en frais d'envoi et de dossier grâce à votre abonnement Outspot PLUS.",
    "cookie_banner.title": "Cookies",
    "cookie_banner.text": "Ce site utilise des cookies. Les cookies strictement nécessaires garantissent le bon fonctionnement du site et ne peuvent pas être refusés. Les cookies fonctionnels, analytiques et publicitaires sont utilisés respectivement à des fins fonctionnelles, statistiques et de marketing. Ils ne sont téléchargés que si vous y consentez. Pour en savoir plus, nous vous invitons à consulter notre <a href=\"{cookies}\" target=\"_blank\">Déclaration des cookies</a>.",
    "cookie_banner.decline": "Refuser les cookies optionnels",
    "cookie_banner.accept_all": "Accepter tous les cookies",
    "cookie_banner.accept_selected": "Accepter les cookies sélectionnés",
    "cookie_banner.settings": "Paramètres",
    "cookie_banner.functional_cookies_title": "Cookies strictement nécessaires",
    "cookie_banner.functional_cookies_text": "Cookies nécessaires au bon fonctionnement du site.",
    "cookie_banner.analytical_cookies_title": "Cookies analytiques et fonctionnels",
    "cookie_banner.analytical_cookies_text": "Ces cookies permettent au site de fournir des fonctionnalités supplémentaires et des paramètres personnalisés, ainsi que des visites et du trafic.",
    "cookie_banner.personal_cookies_title": "Cookies publicitaires",
    "cookie_banner.personal_cookies_text": "Ces cookies sont définis par les annonceurs pour établir un profil de vos intérêts sans stocker d'informations personnelles directes.",
    "review.title": "Donnez votre avis et remportez un bon d'achat",
    "review.subtitle": "Donnez ci-dessous votre avis sur votre achat et tentez de remporter un bon d'achat de 250 € (nous tirons un client au sort chaque trimestre).",
    "review.agree": "J'accepte que mon avis soit publié sur le site.",
    "review.sent": "Merci pour votre commentaire. Vous avez maintenant une chance de remporter un bon d'achat de 250 €.",
    "review.title_thank_you": "Merci pour votre commentaire.",
    "review.subtitle_thank_you": "C'est grâce à vos commentaires que nous pouvons nous améliorer. Donnez ci-dessous votre avis sur votre achat et tentez de remporter un bon d'achat de 250 € (nous tirons chaque trimestre un client au sort).",
    "confirm.title": "Votre inscription est confirmée",
    "confirm.subtitle": "Votre inscription à la lettre d'information est confirmée. Nous vous remercions ! Afin de mieux vous servir, nous vous offrons la possibilité de nous communiquer les données suivantes sans aucune obligation.",
    "popup_widget.title": "Les meilleures offres, testées pour vous.",
    "popup_widget.usp_1": "Recevez chaque jour nos meilleures offres.",
    "popup_widget.usp_2": "Le choix du prix et de la qualité.",
    "popup_widget.usp_3": "Des réductions spectaculaires allant jusqu'à -70 %.",
    "popup_widget.footer_1": "Inscrivez-vous gratuitement dès maintenant à notre newsletter et commencez à économiser.",
    "popup_widget.footer_2": "Vous pouvez vous désinscrire à tout moment.",
    "promo.title": "Nos meilleures promotions",
    "promo.countdown": "2024-12-31 23:59:59",
    "promo.empty": "Actuellement, nous n'avons pas d'offres promotionnelles. Rendez-vous sur notre page d'accueil pour connaître nos dernières offres.",
    "stars.info_title": "Note du produit",
    "stars.info_text": "Cette note est une moyenne des avis que nous avons collectés nous-mêmes et de ceux provenant d'autres sources.",
    "stars.counter": "1 avis | {count} avis",
    "label.accept_selected": "Accepter la sélection",
    "label.edit": "Modifiez",
    "label.edit_address": "Modifiez l'adresse",
    "label.address": "Adresse",
    "label.accept_all": "Tout accepter",
    "label.account": "Mon compte",
    "label.help": "Aide",
    "label.my_orders": "Mes achats",
    "label.my_travels": "Mes voyages",
    "label.my_coupons": "Mes bons d'achat",
    "label.my_info": "Mes coordonnées",
    "label.change_password": "Modifier mon mot de passe",
    "label.notifications": "Newsletter et notifications",
    "label.logout": "Me déconnecter",
    "label.login": "Je me connecte",
    "label.login_have_account": "Vous avez déjà un compte ? Connectez-vous",
    "label.login_with_facebook": "Je me connecte avec Facebook",
    "label.login_with_google": "Je me connecte avec Google",
    "label.login_with_apple": "Je me connecte avec Apple",
    "label.password": "Mot de passe",
    "label.new_password": "Nouveau mot de passe",
    "label.repeat_password": "Répétez votre mot de passer",
    "label.repeat_new_password": "Répétez votre nouveau mot de passe",
    "label.register_no_account": "Vous n'avez pas encore de compte ? Inscrivez-vous",
    "label.register": "Je m'inscris",
    "label.forgot_password": "Vous n'avez pas encore de mot de passe ou l'avez simplement oublié ?",
    "label.general_conditions": "Conditions générales",
    "label.jobs": "Offres d'emploi",
    "label.faq": "Questions fréquemment posées",
    "label.returns": "Retour",
    "label.travel_conditions": "Conditions spécifiques de voyage",
    "label.privacy_policy": "Déclaration de confidentialité",
    "label.cookie_policy": "Déclaration des cookies",
    "label.subscribe": "Je m'inscris",
    "label.all_deals": "Toutes les offres",
    "label.other_deals": "Autres offres",
    "label.discount": "de réduction",
    "label.close": "Fermer",
    "label.order_now": "J'achète",
    "label.add": "Ajouter",
    "label.book": "Réserver",
    "label.upselling_next_step": "N'oubliez pas ceci tout à l'heure",
    "label.upselling": "N'oubliez pas ceci",
    "label.also_available": "Également disponible",
    "label.reviews": "Ce que disent nos clients",
    "label.automatically_translated": "Traduction automatique",
    "label.show_all_reviews": "Voir tous les commentaires",
    "label.back": "Retour",
    "label.make_your_choice": "Faites votre choix",
    "label.sold_out": "En rupture de stock",
    "label.order_more": "Commander plus",
    "label.subtotal": "Sous-total",
    "label.total_shipping": "Livraison",
    "label.total_file": "Frais de dossier",
    "label.total_payable": "Total",
    "label.no_options_selected": "Vous n'avez pas encore fait votre choix. Cliquez ci-dessous sur « Ajouter » pour continuer.",
    "label.no_options_selected_extra": "Vous pouvez sélectionner cette option uniquement si vous achetez le produit.",
    "label.total_saved_prefix": "(Vous avez économisé ",
    "label.total_saved_suffix": ")",
    "label.continue": "Continuer",
    "label.usp_quality": "Le choix du prix et de la qualité",
    "label.usp_discounts": "Des réductions exceptionnelles pouvant aller jusqu'à -70 %.",
    "label.usp_payment_methods": "Paiement facile et sécurisé avec",
    "label.person": "Personne",
    "label.free": "Gratuit",
    "label.first_name": "Prénom",
    "label.last_name": "Nom de famille",
    "label.birthdate": "Date de naissance",
    "label.birthdate_why": "",
    "label.birthdate_why_text": "",
    "label.email": "Adresse e-mail",
    "label.phone": "Numéro de téléphone",
    "label.address_finder": "Recherche d'adresse",
    "label.address_finder_help": "Saisissez votre adresse de livraison complète",
    "label.select": "Sélectionner",
    "label.street": "Rue",
    "label.building": "",
    "label.number": "Numéro/boîte",
    "label.zip": "Code postal",
    "label.city": "Commune",
    "label.state": "",
    "label.country": "Pays",
    "label.finish_order": "Commander & payer",
    "label.finish_order_help": "Cliquez sur le \"Commander & payer\" pour confimer votre paiement.",
    "label.enter_address": "Saisir mon adresse manuellement",
    "label.add_address": "Ajoutez une autre adresse",
    "label.add_payment_method": "Ajoutez un mode de paiement",
    "label.year": "AAAA",
    "label.month": "MM",
    "label.day": "JJ",
    "label.business_order": "Commande professionnelle",
    "label.company_name": "Nom de la société",
    "label.tax_id": "Numéro de TVA",
    "label.invoice_reason": "Motif",
    "label.invoice_reasons": "Cadeau d'affaire, Teambuilding, Incentives, Séminaire, Autre",
    "label.delete": "Supprimer",
    "label.i_have_coupons": "J'ai des bons d'achat",
    "label.email_outspot_plus": "Vous êtes déjà abonné(e) à Outspot PLUS ? Saisissez alors votre adresse e-mail.",
    "label.please_wait": "Un peu de patience",
    "label.please_wait_2": "Encore un peu de patience...",
    "label.please_wait_3": "Désolé, cela prend un peu plus de temps que prévu...",
    "label.please_wait_4": "Encore un peu de patience, nous y sommes presque...",
    "label.please_wait_5": "Toutes nos excuses pour la gêne occasionnée et merci de votre patience, nous y sommes presque.",
    "label.continue_shopping": "Continuer mon shopping",
    "label.my_code": "Code du bon d'achat",
    "label.validate": "Valider",
    "label.total_coupons": "Total des bons d'achat",
    "label.coupons": "Bons d'achat",
    "label.total_payable_with_coupons_prefix": "(Reste à payer ",
    "label.total_payable_with_coupons_suffix": ")",
    "label.rest_coupon_info": "Le montant à payer est inférieur à la valeur des bons d'achat utilisés. Vous recevrez un nouveau bon d'achat avec la valeur restante.",
    "label.contact_extra_info": "Numéro de la commande ou communication structurée",
    "label.contact": "Contactez-nous",
    "label.message": "Message",
    "label.send": "Envoyer",
    "label.save": "Sauvegarder",
    "label.my_outspot_plus": "Mon abonnement Outspot PLUS",
    "label.order_date_prefix": "Commande passée le",
    "label.order_date_suffix": "",
    "label.total_paid_with": "Total payé avec {method}",
    "label.track_order": "Suivez votre commande avec",
    "label.return_order": "Renvoyer et/ou signaler un défaut",
    "label.download_invoice": "Télécharger la facture",
    "label.ask_question": "Poser une question",
    "label.happy_hour_discount": "Happy Hour",
    "label.outspot_plus_discount": "Outspot PLUS",
    "label.cancel_membership": "Annuler l'adhésion",
    "label.membership_start_date": "Date de début de l'adhésion",
    "label.membership_price": "Prix pour 3 mois",
    "label.membership_next_payment": "Paiement suivant",
    "label.membership_end_date": "Date de fin d'adhésion",
    "label.total_savings": "Total des frais d'expédition et de dossier économisés",
    "label.confirm_cancel_membership": "Confirmer l'annulation",
    "label.continue_membership": "Ne pas annuler",
    "label.valid_till_prefix": "Valable jusqu'au",
    "label.valid_till_suffix": "",
    "label.card_payment": "Carte de crédit",
    "label.place_review": "Publier mon avis",
    "label.name": "Nom",
    "label.name_help": "Nous l'indiquons dans votre avis",
    "label.review": "Avis",
    "label.self_service": "",
    "label.update_cookie_preferences": "Modifier mes préférences en matière de cookies",
    "label.more_info": "Plus d'informations",
    "label.review_source": "Via {source}",
    "label.amount": "Montant",
    "label.iban": "IBAN",
    "label.bic": "BIC",
    "label.beneficiary": "Bénéficiaire",
    "label.structured_communication": "Communication structurée",
    "label.later": "Plus tard",
    "label.enable_notifications": "Oui, je veux recevoir ces notifications",
    "label.card_number": "Numéro sur la carte",
    "label.card_expiry": "Date d'expiration",
    "label.card_cvc": "CVV",
    "label.cvc_info": "",
    "label.sales_tax": "",
    "label.calculating_sales_tax": "",
    "label.sales_tax_will_be_calculated": "",
    "label.ship_to_selected_address": "",
    "label.ship_to_service_point": "",
    "label.change_service_point": "",
    "label.choose_rooms": "",
    "label.room": "",
    "label.room_occupancy": "",
    "label.add_another_room": "",
    "label.number_of_adults": "",
    "label.number_of_adults_title": "",
    "label.number_of_children": "",
    "label.number_of_children_title": "",
    "label.choose_arrival_and_departure_date": "",
    "label.arrival_date": "",
    "label.select_arrival_date": "",
    "label.departure_date": "",
    "label.select_departure_date": "",
    "label.rate_type_pc": "",
    "label.rate_type_pcpd": "",
    "label.rate_type_pcpn": "",
    "label.rate_type_pp": "",
    "label.rate_type_pppd": "",
    "label.rate_type_pppn": "",
    "label.rate_type_pp_": "",
    "label.rate_type_pa": "",
    "label.rate_type_papd": "",
    "label.rate_type_papn": "",
    "label.rate_type_pa_": "",
    "label.choose_supplements": "",
    "label.opengds_invalid": "",
    "error.unknown": "Erreur inconnue, veuillez réessayer plus tard.",
    "error.required": "Le champ \"{field}\" ne peut pas être vide.",
    "error.required_email": "Le champ\"{field}\" ne peut pas être vide et l'adresse e-mail doit être valide.",
    "error.required_password": "Le champ \"{field}\" doit comporter au minimum 8 caractères et contenir au moins une majuscule, une minuscule et un chiffre.",
    "error.required_repeat_password": "Le mot de passe que vous répétez doit correspondre au mot de passe mentionné dans le champ \"{field2}\".",
    "error.payment": "Votre paiement a, malheureusement, échoué. Cela peut arriver si les coordonnées bancaires sont erronées, si vous avez annulé le paiement ou encore si votre plafond de paiement a été atteint. Contactez votre banque ou essayez avec une autre carte ou un autre mode de paiement.",
    "error.payment_creditcard": "Votre paiement a, malheureusement, échoué. Veuillez vérifier les données ci-dessous et réessayer.",
    "error.incomplete_order": "Nous validerons votre bon d'achat lorsque vous aurez rempli les champs ci-dessus.",
    "error.coupon_already_added": "Vous avez déjà ajouté ce bon d'achat.",
    "error.invalid_coupon": "Ce bon d'achat n'est pas valable.",
    "error.coupon_not_cumulative": "Ce bon d'achat ne peut pas être cumulé avec d'autres bons.",
    "error.other_coupon_not_cumulative": "Le bon d'achat que vous avez déjà ajouté ne peut pas être utilisé en même temps que ce bon.",
    "error.invalid_username_or_password": "L'adresse électronique et/ou le mot de passe fournis ne sont pas valides.",
    "error.test_order": "Il s'agit d'une commande test. Le client devrait normalement maintenant accéder à la page de paiement.",
    "error.required_agree": "Vous devez accepter nos conditions générales et la déclaration de confidentialité.",
    "error.required_review_agree": "Vous devez accepter que votre avis soit publié sur notre site.",
    "error.register": "Il existe déjà un compte avec cette adresse e-mail. Cliquez sur \"Continuer\" pour vous connecter ou utiliser une autre adresse e-mail.",
    "error.email_in_use": "Il existe déjà un compte avec cette adresse e-mail. Veuillez utiliser une autre adresse e-mail.",
    "error.already_reviewed": "Vous avez déjà publié un avis sur cette commande.",
    "error.zip_blocked": "Il est impossible de faire un envoi au code postal introduit.",
    "error.details": "Il semble qu'un ou plusieurs produits que vous essayez de commander ne soient plus en stock. Veuillez revenir à l'étape précédente, mettre à jour votre commande et réessayer.",
    "error.incomplete_cvc": "CVV est trop court.",
    "error.incomplete_date": "La date d’expiration ne peut pas être vide.",
    "error.date_in_past": "La carte est expirée.",
    "error.invalid_expiration_year": "La carte est expirée.",
    "error.incomplete_card_number": "Le numéro de carte est trop court.",
    "error.incorrect_card_number": "Le numéro de carte est invalide.",
    "unsubscription.your_mail_address": "Votre adresse e-mail :",
    "unsubscription.sad_message": "Vous n'êtes désormais plus inscrit(e) sur notre site.",
    "unsubscription.have_not_participated": "Vous ne vous êtes jamais inscrit(e) ?",
    "unsubscription.sad_message_2": "Nous regrettons de vous voir partir, mais vous restez bien sûr toujours le ou la bienvenu(e).",
    "unsubscription.resubscribe_question": "Vous avez changé d'avis ? <span class='unsub-important-message'>Cliquez ci-dessous pour vous réinscrire à notre newsletter :</span>",
    "unsubscription.form_message": "Pourquoi quittez-vous la famille Outspot ? Votre réponse peut nous aider à améliorer nos services.",
    "unsubscription.report_not_participated": "Signalez alors cet abus",
    "unsubscription.coreg_date_no_screenshot": "Le {date}, vous avez participé à une campagne (<a href=\"{coreg_site}\">{coreg_site}</a>) et donné votre accord pour recevoir nos newsletters dans lesquelles nous vous proposons des offres à un prix incroyable.",
    "unsubscription.coreg_date_no_coreg_data": "Le {date}, vous avez participé à la campagne ci-dessous et donné votre accord pour recevoir nos newsletters dans lesquelles nous vous proposons des offres à un prix incroyable.",
    "unsubscription.coreg_date_screenshot": "Le {date}, vous avez participé à la campagne ci-dessous et donné votre accord pour recevoir nos newsletters dans lesquelles nous vous proposons des offres à un prix incroyable.",
    "unsubscription.coreg_no_date_no_screenshot": "Vous avez participé à la campagne ci-dessous et donné votre accord pour recevoir nos newsletters dans lesquelles nous vous proposons des offres à un prix incroyable.",
    "unsubscription.coreg_no_date_screenshot": "Vous avez participé à la campagne ci-dessous et donné votre accord pour recevoir nos newsletters dans lesquelles nous vous proposons des offres à un prix incroyable.",
    "unsubscription.main_title": "Me désabonner de la newsletter",
    "unsubscription.report_title": "Signalez un abus",
    "unsubscription.report_sent_message": "Merci, nous avons bien reçu votre notification.",
    "claim_coupon.welcome_message": "Un cadeau rien que pour vous",
    "claim_coupon.info_line_message": "Indiquez le code personnel ci-dessus lors du paiement.",
    "claim_coupon.info_line_message_bis": "Le bon d'achat vous a également été envoyé par e-mail.",
    "claim_coupon.main_title": "Votre bon d'achat",
    "about.title": "Qui sommes nous",
    "about.intro": "Bienvenue chez Outspot, votre destination numéro un pour des offres irrésistibles ! Depuis sa création en 2008, Outspot est devenu le premier site de ventes flash en Europe où l'on vous propose chaque jour des réductions sur des produits et voyages de qualité, vous permettant, en tant que client, de faire de belles économies. Depuis notre entrepôt en Belgique, nous livrons le plus rapidement possible à votre domicile.",
    "about.mission_title": "Notre mission",
    "about.mission_text": "Chez Outspot, nous estimons qu'il est essentiel de rendre des offres exceptionnelles accessibles à tous. Notre objectif est d'offrir à nos clients la meilleure qualité possible en proposant des offres soigneusement sélectionnées à des prix inégalés. ",
    "about.history_title": "Notre histoire",
    "about.history_text": "L'aventure Outspot a commencé en Belgique, où le siège social est toujours situé. Avec une équipe dévouée de 25 employés répartis à travers l'Europe, nous travaillons chaque jour pour dénicher et proposer les meilleures offres. Aujourd'hui, nous sommes actifs dans 13 pays et accueillons chaque jour 100 000 visiteurs satisfaits.",
    "about.quality_title": "Le choix du prix et de la qualité",
    "about.quality_text": "Chez Outspot, nous pensons que vous méritez le meilleur des deux mondes : une qualité supérieure à un prix abordable. C'est pourquoi, chaque jour, nous cherchons à vous proposer les offres les plus attractives. Grâce à des collaborations étroites avec nos fournisseurs, nous nous assurons de négocier les meilleurs prix, sans faire de compromis sur la qualité. <br /><br />  Les produits et voyages que nous proposons sont soigneusement sélectionnés selon une évaluation rigoureuse du rapport qualité-prix. Vous pouvez donc être sûr qu'avec nous, vous obtenez toujours le meilleur rapport qualité-prix. Chez Outspot, votre satisfaction et votre avantage sont nos priorités absolues ! Ici, vous voyez une photo où le son de la sonnette intelligente est testé avec un sonomètre.",
    "about.tested_title": "Toutes les offres sont minutieusement testées par les collègues d'Outspot",
    "about.tested_text": "Chez Outspot, nous estimons qu'il faut offrir le meilleur, rien de moins. Avant qu'une offre n'apparaisse sur notre site, elle est minutieusement testée par les collègues d'Outspot. Ils ne se contentent pas de tests superficiels : les produits sont complètement démontés, et chaque composant est soigneusement évalué pour sa solidité, son niveau sonore, la qualité des matériaux, et bien plus encore.<br /><br />  Nos collègues se rendent également dans de nombreux hôtels afin de vérifier la qualité des aménagements. Cela nous permet de garantir que tout répond à nos standards de qualité élevés. Qu'il s'agisse d'un produit ou d'un voyage, vous pouvez être certain qu'avec Outspot, vous achetez toujours en toute confiance.<br /><br />  Ici, vous voyez sur la photo comment nos collègues examinent et testent les produits en détail, par exemple en vérifiant la qualité des tissus.",
    "about.guarantee_title": "Garantie de remboursement de 6 mois sur la plupart des voyages",
    "about.guarantee_text": "Lorsque vous réservez un voyage, vous voulez être sûr que votre choix est le bon et que votre argent est bien dépensé. Nous offrons une garantie de remboursement de 6 mois sur la plupart de nos voyages. Grâce à cette garantie, vous pouvez planifier en toute tranquillité, en sachant que vous avez toujours droit à un remboursement, à condition qu'aucune réservation n'ait encore été effectuée. Cela vous offre la liberté de rester flexible dans vos décisions, sans risquer de frais supplémentaires tant qu'il n'y a pas de réservation définitive.",
    "about.returns_title": "Politique de retour de 30 jours sur la plupart des produits",
    "about.returns_text": "Nous comprenons qu'il peut parfois être nécessaire de prendre un peu de temps pour décider si vous souhaitez conserver un produit. C'est pourquoi nous offrons un droit de retour de 30 jours sur la plupart de nos produits. Il est important que le produit reste inutilisé et non ouvert pour pouvoir bénéficier d'un retour. Cela vous permet de réfléchir à votre achat à votre propre rythme, tout en assurant une procédure de retour fluide. Vous pouvez ainsi décider en toute tranquillité, tant que le produit reste dans son état d'origine.",
    "about.support_title": "Un service clientèle prêt à vous aider en français",
    "about.support_text": "Chez Outspot, la satisfaction du client est primordiale et nous faisons tout notre possible pour vous garantir une expérience exceptionnelle. Si vous avez des questions ou besoin d'aide concernant votre commande, notre service client est toujours à votre disposition. Nos collaborateurs francophones sont disponibles pour vous aider rapidement et efficacement. Nous nous efforçons de vous aider de manière personnalisée et claire, afin que vous puissiez profiter de votre achat en toute tranquillité. Votre satisfaction est notre priorité, et nous sommes là pour nous assurer que vous en tiriez le meilleur parti.",
    "about.socials_title": "Restez connecté",
    "about.socials_text": "Restez informé de nos dernières offres et promotions exclusives en vous inscrivant à notre newsletter. Suivez-nous sur les réseaux sociaux et partagez avec nous vos expériences Outspot !<br /><br />  Avez-vous des questions ou des commentaires ? Notre service clientèle est prêt à vous aider. Contactez-nous via <a href=\"{help}\">notre page de contact</a>.<br /><br />  Merci de votre confiance en Outspot. Nous avons hâte de continuer à vous surprendre avec nos offres fantastiques !",
    "vat.error_required": "Le champ \"{field}\" ne peut pas être vide pour une commande professionnelle.",
    "vat.validation_message_format": "Le champ \"{field}\" n'est pas au format correct.",
    "vat.help_title": "Numéro de TVA",
    "vat.help_text": "Le numéro de TVA belge doit comporter 12 caractères, par exemple BE0897991257.",
    "label.download": "Télécharger",
    "error.download_pdf": "Un problème est survenu lors du téléchargement du fichier PDF. Veuillez réessayer plus tard.",
    "manage_address.title": "Mes adresses de livraison",
    "error.select": "Sélectionner une {field}",
    "manage_cards.title": "Mes moyens de paiement",
    "deal.quality_text_1": "Le choix du prix et de la qualité",
    "deal.quality_text_2": "Stock en Belgique",
    "deal.delivery_text_days": "Délai de livraison: {days} jours ouvrables",
    "deal.delivery_text": "Commandé un jour ouvrable avant 16 h, livré le jour ouvrable suivant",
    "maintenance.going_offline": "Dans quelques minutes, notre site web sera temporairement indisponible pour une maintenance programmée. Finalisez votre commande maintenant, car il ne sera pas possible de commander durant la maintenance.",
    "maintenance.offline": "Notre site web est actuellement indisponible pour une maintenance programmée. Veuillez réessayer dans quelques minutes - nous serons de nouveau en ligne dès que possible !À titre de compensation, nous vous offrons ce bon d'achat de 5 € valable sur votre prochain achat sur notre site : GMJ2QPZ3",
    "sustainability.carpark_title": "Transition vers une flotte de véhicules électriques",
    "sustainability.carpark_text": "Dans le cadre de nos efforts continus pour réduire notre empreinte écologique, nous transformons progressivement notre flotte de véhicules professionnels. D’ici 2026, notre objectif est de disposer d’une flotte entièrement électrique. Cette transition représente une étape clé vers une réduction significative de nos émissions et la promotion d’une mobilité plus propre et plus durable.",
    "sustainability.energy_title": "Des bureaux alimentés par l’énergie solaire",
    "sustainability.energy_text": "La durabilité commence chez nous, et cela signifie dans nos bureaux. Nous avons installé 30 panneaux solaires, qui produisent près de la moitié de l’électricité nécessaire à nos activités quotidiennes. En tirant parti de l’énergie solaire, nous réduisons considérablement notre dépendance aux sources d’énergie non renouvelables et diminuons notre empreinte carbone globale.",
    "sustainability.eco_packaging_title": "Des emballages respectueux de l'environnement",
    "sustainability.eco_packaging_text": "Conscients de l’impact des déchets d’emballage sur l’environnement, nous avons fait le choix d’utiliser uniquement du plastique 100 % recyclable pour tous nos emballages. Cette initiative contribue à créer un cycle d’utilisation et de réutilisation plus durable.",
    "sustainability.combined_packaging_title": "Expéditions groupées",
    "sustainability.combined_packaging_text": "Afin de réduire encore davantage notre impact environnemental, nous encourageons nos clients à regrouper leurs commandes. En combinant un maximum d’achats en un seul envoi, nous diminuons le nombre de livraisons nécessaires, ce qui réduit les émissions et l’empreinte écologique associée.",
    "sustainability.title": "La durabilité chez Outspot",
    "sustainability.vision_title": "Notre vision",
    "sustainability.vision_text": "Chez Outspot, nous sommes pleinement conscients de notre impact sur l’environnement. Chaque jour, nous faisons le bonheur de milliers de personnes grâce à nos offres, tout en intégrant la responsabilité écologique et sociale dans tous les aspects de notre activité. Nous croyons que des actions proactives aujourd’hui peuvent avoir un impact réel sur la santé de notre planète et le bien-être des générations futures.",
    "sustainability.action_title": "Rejoignez-nous",
    "sustainability.action_text": "Nous vous invitons à participer à notre démarche durable. Que ce soit en choisissant des produits éco-responsables ou en participant à nos programmes de recyclage, chaque petit geste compte.",
    "sustainability.plant_text": "Lors de votre achat, vous pouvez choisir de planter un arbre pour seulement 1 € de plus. Cela permet non seulement de compenser les émissions de CO₂, mais contribue également à la reforestation et à un avenir meilleur pour les communautés locales.",
    "sustainability.treeplanters": "Nous collaborons avec Tree-Planters, une organisation engagée dans la reforestation intelligente et durable. Les arbres sont plantés sur des sites soigneusement sélectionnés au Malawi, où ils contribuent non seulement à la restauration de la nature, mais aussi au soutien des populations locales grâce à des revenus et une sécurité alimentaire accrus.",
    "sustainability.action_text_believe": "Chez Outspot, nous croyons que la durabilité est une responsabilité partagée. Avec nos clients, nos collaborateurs et nos partenaires, nous œuvrons pour une relation plus consciente et respectueuse avec notre planète. Rejoignez-nous et faisons la différence ensemble !",
    "sustainability.plant_title": "Plantez un arbre – Faites la différence",
    "sustainability.trees": "arbres",
    "sustainability.tree": "arbre",
    "deal.advice_price": "",
    "sustainability.checkbox_title": "Plantez avec Outspot",
    "sustainability.checkbox_detail_text": "Nous vous invitons à planter des arbres avec Outspot pour {price} €."
  },
  "nd": {
    "route.home": "https://www.outspot.nl/nd/",
    "route.fallback_domain": "https://www2.outspot.nl",
    "language.code": "NL",
    "language.locale": "nl-NL",
    "language.name": "Nederland",
    "language.flag": "nl",
    "language.date_input_format": "d,m,y",
    "language.currency": "EUR",
    "language.region": "eu",
    "countries.delivery": "NLD",
    "states.delivery": "",
    "tracking.aw_merchant_id": "621068243",
    "tracking.aw_feed_country": "NL",
    "countries.BEL": "België",
    "countries.LUX": "Luxemburg",
    "countries.NLD": "Nederland",
    "countries.FRA": "Frankrijk",
    "countries.DEU": "Duitsland",
    "countries.AUT": "Oostenrijk",
    "countries.ITA": "Italië",
    "countries.ESP": "Spanje",
    "countries.POL": "Polen",
    "countries.GBR": "Verenigd Koninkrijk",
    "countries.IRL": "Ierland",
    "countries.SWE": "Zweden",
    "countries.PRT": "Portugal",
    "countries.FIN": "Finland",
    "countries.USA": "",
    "states.AL": "",
    "states.AR": "",
    "states.AZ": "",
    "states.CA": "",
    "states.CO": "",
    "states.CT": "",
    "states.DE": "",
    "states.FL": "",
    "states.GA": "",
    "states.IA": "",
    "states.ID": "",
    "states.IL": "",
    "states.IN": "",
    "states.KS": "",
    "states.KY": "",
    "states.LA": "",
    "states.MA": "",
    "states.MD": "",
    "states.ME": "",
    "states.MI": "",
    "states.MN": "",
    "states.MO": "",
    "states.MS": "",
    "states.MT": "",
    "states.NC": "",
    "states.ND": "",
    "states.NE": "",
    "states.NH": "",
    "states.NJ": "",
    "states.NM": "",
    "states.NV": "",
    "states.NY": "",
    "states.OH": "",
    "states.OK": "",
    "states.OR": "",
    "states.PA": "",
    "states.RI": "",
    "states.SC": "",
    "states.SD": "",
    "states.TN": "",
    "states.TX": "",
    "states.UT": "",
    "states.VA": "",
    "states.VT": "",
    "states.WA": "",
    "states.WI": "",
    "states.WV": "",
    "states.WY": "",
    "trustpilot.businessunit_id": "5197285f00006400053362df",
    "trustpilot.show": "TRUE",
    "mollie.profile_id": "pfl_umPhHqESm9",
    "mollie.locale": "nl_NL",
    "facebook.url": "https://www.facebook.com/Outspot",
    "instagram.url": "https://www.instagram.com/outspot_nl/",
    "linkedin.url": "https://www.linkedin.com/company/outspot/mycompany/",
    "header.hello": "Welkom",
    "header.hello_name": "Dag <b>{firstName}</b>",
    "subscribe.title": "Schrijf je gratis in en mis geen enkele deal!",
    "subscribe.text": "Ontdek de beste deals van Outspot. Schrijf je gratis in en geniet van exclusieve en gepersonaliseerde kortingen op unieke producten en ervaringen.",
    "subscribe.agree": "Door in te schrijven op onze nieuwsbrief, verklaar je jezelf akkoord met de <a href=\"{conditions}\" target=\"_blank\">algemene voorwaarden</a> en <a href=\"{privacy}\" target=\"_blank\">privacyverklaring</a>.",
    "subscribe.sent": "Dankjewel! We hebben je aanvraag goed ontvangen. Je ontvangt zometeen een e-mail om je inschrijving te bevestigen.",
    "footer.follow": "Volg ons",
    "footer.payment_methods_logos": "ideal,mastercard,visa,amex,trustly,paypal,applepay,bank",
    "footer.copyright": "Outspot — Sint-Martens-Latem, België — Confinity NV",
    "home.new": "Nieuwste deals",
    "home.ending": "Deze deals lopen bijna af",
    "home.other": "Alle deals",
    "countdown.prefix": "Nog",
    "countdown.days": "dag|dagen",
    "countdown.hours": "uur",
    "countdown.minutes": "min",
    "countdown.seconds": "sec",
    "countdown.days_compact": "d",
    "date.years": "{count} jaar geleden|{count} jaar geleden",
    "date.months": "{count} maand geleden|{count} maanden geleden",
    "date.weeks": "{count} week geleden|{count} weken geleden",
    "date.days": "{count} dag geleden|{count} dagen geleden",
    "date.hours": "{count} uur geleden|{count} uur geleden",
    "date.minutes": "{count} minuut geleden|{count} minuten geleden",
    "date.seconds": "Minder dan 1 minuut geleden",
    "countdown.suffix": "",
    "onboard.language_title": "Geniet van persoonlijke deals met scherpe kortingen",
    "onboard.language_text": "Kies in welk land en taal je wenst te winkelen:",
    "onboard.notifications_title": "Blijf op de hoogte van:",
    "onboard.notifications_usp_1": "De beste en nieuwste deals",
    "onboard.notifications_usp_2": "Updates over je bestellingen",
    "onboard.notifications_usp_3": "Persoonlijke kortingscodes & waardebonnen",
    "order.shipping_title": "Verzending",
    "order.shipping_text": "Omwille van de stijgende transportkosten en de impact op het milieu, zijn we genoodzaakt transportkosten aan te rekenen. Toch garandeert Outspot je nog steeds fantastische deal-prijzen waarbij je sowieso wint. Bovendien stijgt je transportkost niet als je meer stuks aankoopt. Als je Outspot PLUS lid bent of je koopt binnen het uur na je vorige aankoop, zullen deze kosten in een volgende stap in mindering gebracht worden.",
    "order.thank_you": "Bedankt voor je bestelling!",
    "order.overview": "Overzicht van je bestelling",
    "order.thank_you_bank": "Hartelijk dank voor jouw aankoop. Je krijgt de gegevens voor de overschrijving in je mailbox of bij ongewenste mails. Gelieve het bedrag zo snel mogelijk en in elk geval binnen de 7 dagen over te schrijven. Je ontvangt de bestelde voucher per e-mail van zodra de betaling toegekomen en verwerkt is.",
    "order.thank_you_with_shipping": "Je aankoop is voltooid. We sturen je bestelling naar onderstaand adres. Je krijgt je aankoopbevestiging in je mailbox of bij ongewenste mails. Veel plezier met je bestelling.",
    "order.thank_you_without_shipping": "Je aankoop is voltooid. Je krijgt je aankoopbevestiging in je mailbox of bij ongewenste mails. Veel plezier met je bestelling.",
    "order.code": "Bestelnummer: {code}",
    "order.shipping_address": "Leveringsadres",
    "order.save_payment_info_title": "Bewaar bankgegevens voor mijn volgende aankopen.",
    "order.save_payment_info_text": "Als je deze optie kiest hoef je niet telkens weer je gegevens in te voeren. Je gegevens worden beveiligd opgeslagen.",
    "order.shipped_to_service_point": "",
    "my_outspot_plus.title": "Mijn Outspot PLUS",
    "my_outspot_plus.subtitle": "Op deze pagina kan je je Outspot PLUS lidmaatschap beheren.",
    "my_outspot_plus.overview": "Overzicht van je Outspot PLUS lidmaatschap",
    "my_outspot_plus.cancel_are_you_sure": "Ben je zeker dat je je lidmaatschap wil annuleren?",
    "my_outspot_plus.cancel_you_saved_prefix": "Je bespaarde reeds",
    "my_outspot_plus.cancel_you_saved_suffix": "aan verzend- en dossierkosten met je Outspot PLUS lidmaatschap.",
    "my_outspot_plus.no_membership": "Je hebt momenteel geen Outspot PLUS lidmaatschap.",
    "plus.title": "Outspot PLUS",
    "plus.popup_text": "Met Outspot PLUS profiteer je van gratis verzending op alle artikelen en betaal je geen dossierkosten op alle reizen. Na elke bestelling ontvang je een e-mail waarin je de mogelijkheid krijgt om Outspot PLUS te worden. Je moet echter snel zijn want deze actie is maar 7 dagen geldig. Vergeet niet je volgende aankoopbevestigingsmail te checken.",
    "plus.name": "Outspot PLUS lidmaatschap",
    "plus.highlight_1": "Geniet van zorgeloos bestellen zonder verzend- en dossierkosten.",
    "plus.highlight_2": "Jouw abonnement wordt automatisch verlengd voor {renewPrice} per drie maanden.",
    "plus.highlight_3": "Steeds mogelijk te annuleren.",
    "plus.question_1": "Wat zijn de voordelen van Outspot PLUS?",
    "plus.answer_1": "Met Outspot PLUS profiteer je van gratis verzending op alle artikelen en betaal je geen dossierkosten op alle reizen.",
    "plus.question_2": "Hoe word ik Outspot PLUS?",
    "plus.answer_2": "Na elke bestelling ontvang je een e-mail waarin je de mogelijkheid krijgt om Outspot PLUS te worden. Je moet echter snel zijn want deze actie is maar 7 dagen geldig. Als je besluit om Outspot PLUS te activeren, gaan de voordelen direct in werking. Dit betekent dat je bij je eerstvolgende bestelling meteen kunt genieten van gratis verzending op alle artikelen en dat je geen dossierkosten hoeft te betalen voor al je reizen.",
    "plus.question_3": "Hoeveel kost Outspot PLUS?",
    "plus.answer_3": "Outspot PLUS kost slechts {price} voor 3 maanden. Dit is een eenmalige betaling. Na deze periode wordt je lidmaatschap automatisch elke 3 maanden verlengd voor {renewPrice}. Zo kan je aanzienlijk besparen op verzend- en dossierkosten.",
    "plus.question_4": "Wanneer en hoe wordt mijn Outspot PLUS verlengd?",
    "plus.answer_4": "Outspot PLUS wordt elke drie maanden automatisch verlengd. Om dit te beheren kan je altijd naar <a href=\"{myOutspotPlus}\">Mijn Outspot PLUS</a> gaan. Het bedrag wordt per 3 maanden automatisch van je door jouw gekozen rekening afgehouden.",
    "plus.question_5": "Hoe annuleer ik mijn Outspot PLUS? Zal ik een terugbetaling ontvangen?",
    "plus.answer_5": "Je kunt je Outspot PLUS lidmaatschap op elk moment opzeggen via: <a href=\"{myOutspotPlus}\">Mijn Outspot PLUS</a>. Het is belangrijk om te weten dat er geen terugbetaling wordt verstrekt voor de maanden waarin je lidmaatschap actief was. Echter, als je besluit Outspot PLUS op te zeggen, zal de volgende geplande facturering niet in rekening worden gebracht.",
    "plus.thank_you": "Je aankoop is voltooid. Vanaf nu geniet je van zorgeloos bestellen zonder verzend- en dossierkosten. Je kunt jouw lidmaatschap bekijken en beheren via <a href=\"{myOutspotPlus}\">Mijn Outspot PLUS</a>.",
    "my_coupons.title": "Mijn waardebonnen",
    "my_coupons.subtitle": "Op deze pagina kan je je waardebonnen terugvinden.",
    "my_coupons.no_coupons": "Je hebt momenteel geen waardebonnen.",
    "deal.sold": "Al {sold} keer gekocht",
    "deal.retour_label": "30 dagen retourneren voor slechts € 0,95",
    "deal.retour_title": "Retourrecht",
    "deal.retour_text": "Bevalt je product toch niet zo? Neem binnen de 30 dagen na ontvangst contact op met onze helpdesk en retourneer je product. En dit voor om het even welke reden! Wij betalen je aankoop terug en jij kan zorgeloos bestellen. Nog zo'n topidee, van Outspot natuurlijk!",
    "deal.guarantee_label": "6 maanden terugbetaalgarantie",
    "deal.guarantee_title": "Uniek : 100% terugbetalingsgarantie",
    "deal.guarantee_text": "Ongelooflijk maar waar. Je hoeft helemaal niet meer te twijfelen om een belevenis voor je vrienden/collega’s/familie of jezelf aan te kopen. Bedenk je je achteraf, of is de ontvanger niet helemaal tevreden met de geschenkvoucher, dan is er geen probleem.<br><br>Bij Outspot.be kan je namelijk afzien van je aankoop tot zelfs 6 maanden na de bestelling! Jijzelf of de persoon die de voucher heeft ontvangen kan deze dus zonder enig probleem volledig terugbetaald krijgen binnen de 6 maanden. Dit uiteraard voor zover de voucher nog niet gereserveerd, opgebruikt of vervallen is. Dit is als garantie uitzonderlijk en nooit gezien. Maar zo is Outspot nu eenmaal.<br><br> Let wel op: voor alle acties waar niet uitdrukkelijk de terugbetalingsgarantie vermeld staat, geldt de garantie niet. In sommige gevallen kunnen we namelijk zelf niet de leveringen/tickets bij onze partner terugvorderen.",
    "deal.option_count": "{count} optie|{count} opties",
    "deal.campaign_number": "Campagnenummer",
    "deal.your_details": "Jouw gegevens",
    "deal.payment_methods_title": "Betaalwijze",
    "deal.shipping_methods_title": "",
    "deal.privacy": "Door je gegevens in te geven, ga je akkoord met <a target='_blank' href='{privacy}'>onze privacyverklaring</a>.",
    "deal.payment_methods_subtitle": "Hoe wil je jouw bestelling betalen?",
    "deal.your_order": "Jouw bestelling",
    "deal.from": "Vanaf",
    "help.title": "Hulp nodig?",
    "help.subtitle": "Laat ons weten hoe we je kunnen helpen.",
    "help.subject": "Jouw vraag via onze website of app",
    "help.sent": "We hebben je bericht goed ontvangen. Je krijgt ten laatste binnen 4 werkdagen een antwoord van ons.",
    "login.title": "Log in bij Outspot",
    "login.subtitle": "Hoe wil je inloggen?",
    "login.or": "of",
    "login.usps_title": "Waarom inloggen bij Outspot?",
    "login.usps_title_cta": "Maak het jezelf gemakkelijker en log nu in of maak een account aan.",
    "login.usps_text": "Klik bovenaan op \"Log in\".",
    "login.usp_manage_orders": "Je bestellingen bekijken en opvolgen",
    "login.usp_remember_info": "Je adresgegevens bewaren voor later",
    "login.usp_returns": "Een defect melden of een retour aanvragen",
    "login.usp_store_payments": "Je betaalmethodes opslaan en eenvoudiger betalen",
    "login.short_cta": "<a href=\"{loginLink}\">Log in</a> of ga hieronder verder als gast.",
    "forgot.title": "Vraag een nieuw wachtwoord aan",
    "forgot.subtitle": "Vul hieronder je e-mailadres in en we sturen je een link om je wachtwoord opnieuw in te stellen.",
    "forgot.sent": "We hebben je een e-mail gestuurd met een link om je wachtwoord opnieuw in te stellen.",
    "password.title": "Wijzig wachtwoord",
    "password.subtitle": "Kies een nieuw en veilig wachtwoord.",
    "password.updated": "Je wachtwoord is met succes gewijzigd.",
    "register.title": "Registreer",
    "register.subtitle": "Maak een account aan om nog makkelijker te bestellen en je bestellingen op te volgen.",
    "register.personal_info": "Persoonlijke gegevens",
    "register.login_info": "Inloggegevens",
    "register.agree": "Ik ga akkoord met de <a href=\"{conditions}\" target=\"_blank\">algemene voorwaarden</a> en <a href=\"{privacy}\" target=\"_blank\">privacyverklaring</a>.",
    "register.sent": "Je bent er bijna. We hebben je een e-mail gestuurd waarmee je je account kunt activeren.",
    "register.email_known": "Is je e-mailadres al gekend bij Outspot? <a href=\"{forgot}\">Klik dan hier</a> om een wachtwoord aan te vragen.",
    "my_orders.title": "Mijn bestellingen",
    "my_orders.subtitle": "Dit is een overzicht van alle bestellingen die je bij Outspot plaatste.",
    "my_orders.title_detail": "Details van je bestelling",
    "my_travels.title": "Mijn reizen",
    "my_info.title": "Mijn gegevens",
    "my_info.subtitle": "Hier kan je jouw persoonlijke gegevens aanpassen.",
    "my_info.sent": "Je gegevens zijn aangepast.",
    "notifications.title": "Nieuwsbrief en meldingen",
    "notifications.subscription_mail": "Hoe vaak wil je onze nieuwsbrief ontvangen?",
    "notifications.subscription_notification": "Hoe vaak wil je notificaties ontvangen?",
    "notifications.frequency_0": "Nooit",
    "notifications.frequency_4": "1 keer per week",
    "notifications.frequency_56": "2 keer per dag",
    "notifications.sent": "Je voorkeuren werden bijgewerkt.",
    "combined_purchase_banner.text": "Betaal geen verzend- of dossierkosten voor je volgende bestellingen gedurende:",
    "plus_banner.text_welcome": "{firstName}, welkom bij Outspot PLUS.",
    "plus_banner.text_welcome_anonimous": "Welkom bij Outspot PLUS.",
    "plus_banner.text_savings_prefix": "{firstName}, je bespaarde reeds",
    "plus_banner.text_savings_prefix_anonimous": "Je bespaarde reeds",
    "plus_banner.text_savings_suffix": "aan verzend- en dossierkosten via Outspot PLUS.",
    "cookie_banner.title": "Cookies",
    "cookie_banner.text": "Deze website maakt gebruik van cookies. De strikt noodzakelijke cookies zorgen ervoor dat de website goed functioneert en kunnen niet worden geweigerd. Functionele , analytische & advertising cookies worden respectievelijk voor functionele, statistische en marketing doeleinden gebruikt. Zij worden enkel gedownload als je daarmee instemt. Lees er meer over in ons <a href=\"{cookies}\" target=\"_blank\">cookiebeleid</a>. ",
    "cookie_banner.decline": "Weiger optionele cookies",
    "cookie_banner.accept_all": "Accepteer alle cookies",
    "cookie_banner.accept_selected": "Accepteer geselecteerde",
    "cookie_banner.settings": "Instellingen",
    "cookie_banner.functional_cookies_title": "Strikt noodzakelijke cookies",
    "cookie_banner.functional_cookies_text": "Cookies nodig voor basisfunctionaliteit van de website.",
    "cookie_banner.analytical_cookies_title": "Analytische & functionele cookies",
    "cookie_banner.analytical_cookies_text": "Cookies stellen de website in staat om extra functies en persoonlijke instellingen aan te bieden alsook bezoeken en traffic.",
    "cookie_banner.personal_cookies_title": "Advertising cookies",
    "cookie_banner.personal_cookies_text": "Cookies ingesteld door adverteerders om een profiel van uw interesses samen te stellen zonder directe persoonlijke informatie op te slaan.",
    "review.title": "Praat erover en win",
    "review.subtitle": "Plaats hieronder je beoordeling van je aankoop en maak zo meteen kans op een waardebon van € 250 (elk kwartaal verloten we zo’n waardebon).",
    "review.agree": "Ik ga ermee akkoord dat mijn beoordeling mogelijks gepubliceerd wordt op de website",
    "review.sent": "Bedankt voor je beoordeling. Je maakt nu kans op een waardebon van € 250.",
    "review.title_thank_you": "Bedankt. We waarderen jouw feedback.",
    "review.subtitle_thank_you": "Dankzij jouw feedback kunnen we Outspot nog beter maken. Plaats hieronder je beoordeling van je aankoop en maak zo meteen kans op een waardebon van € 250 (elk kwartaal verloten we zo'n waardebon).",
    "confirm.title": "Je inschrijving is bevestigd",
    "confirm.subtitle": "Je inschrijving op de nieuwsbrief is hierbij bevestigd. Bedankt! Om je nog beter van dienst te kunnen zijn, bieden wij de mogelijkheid om volgende gegevens vrijblijvend met ons te delen.",
    "popup_widget.title": "Deals op z’n best, voor jou uitgetest.",
    "popup_widget.usp_1": "Ontvang dagelijks onze beste aanbiedingen.",
    "popup_widget.usp_2": "Geselecteerd en getest op beste prijs en kwaliteit.",
    "popup_widget.usp_3": "Fikse kortingen tot wel -70%.",
    "popup_widget.footer_1": "Schrijf je nu gratis in voor onze nieuwsbrief en bespaar direct.",
    "popup_widget.footer_2": "Je kan op elk moment opnieuw uitschrijven.",
    "promo.title": "Onze toppromo's",
    "promo.countdown": "2024-12-31 23:59:59",
    "promo.empty": "Momenteel hebben we geen toppromo's. Ga naar onze startpagina voor onze recentste deals.",
    "stars.info_title": "Productscore",
    "stars.info_text": "Dit cijfer is een gemiddelde van onze eigen verzamelde beoordelingen en die van andere partijen.",
    "stars.counter": "1 beoordeling|{count} beoordelingen",
    "label.accept_selected": "Accepteer geselecteerde",
    "label.edit": "Wijzig",
    "label.edit_address": "Wijzig adres",
    "label.address": "Adres",
    "label.accept_all": "Accepteer alle",
    "label.account": "Account",
    "label.help": "Help",
    "label.my_orders": "Mijn bestellingen",
    "label.my_travels": "Mijn reizen",
    "label.my_coupons": "Mijn waardebonnen",
    "label.my_info": "Mijn gegevens",
    "label.change_password": "Wijzig wachtwoord",
    "label.notifications": "Nieuwsbrief en meldingen",
    "label.logout": "Log uit",
    "label.login": "Log in",
    "label.login_have_account": "Heb je al een account? Log in",
    "label.login_with_facebook": "Log in met Facebook",
    "label.login_with_google": "Log in met Google",
    "label.login_with_apple": "Log in met Apple",
    "label.password": "Wachtwoord",
    "label.new_password": "Nieuw wachtwoord",
    "label.repeat_password": "Herhaal wachtwoord",
    "label.repeat_new_password": "Herhaal nieuw wachtwoord",
    "label.register_no_account": "Nog geen account? Registreer nu",
    "label.register": "Registreer",
    "label.forgot_password": "Nog geen wachtwoord of wachtwoord vergeten?",
    "label.general_conditions": "Algemene voorwaarden",
    "label.jobs": "Jobs bij Outspot",
    "label.faq": "Veelgestelde vragen",
    "label.returns": "Retourneren",
    "label.travel_conditions": "Specifieke reisvoorwaarden",
    "label.privacy_policy": "Privacyverklaring",
    "label.cookie_policy": "Cookieverklaring",
    "label.subscribe": "Schrijf me in",
    "label.all_deals": "Alle deals",
    "label.other_deals": "Andere deals",
    "label.discount": "Korting",
    "label.close": "Sluit",
    "label.order_now": "Bestel nu",
    "label.add": "Voeg toe",
    "label.book": "Boek nu",
    "label.upselling_next_step": "Vergeet ook straks dit niet",
    "label.upselling": "Vergeet ook dit niet",
    "label.also_available": "Ook beschikbaar",
    "label.reviews": "Wat onze klanten zeggen",
    "label.automatically_translated": "Automatisch vertaald",
    "label.show_all_reviews": "Bekijk alle beoordelingen",
    "label.back": "Terug",
    "label.make_your_choice": "Maak je keuze",
    "label.sold_out": "Uitverkocht",
    "label.order_more": "Bestel meer",
    "label.subtotal": "Subtotaal",
    "label.total_shipping": "Verzendkosten",
    "label.total_file": "Dossierkosten",
    "label.total_payable": "Totaal",
    "label.no_options_selected": "Je bestelling is momenteel leeg. Klik hieronder op \"Voeg toe\" om verder te gaan.",
    "label.no_options_selected_extra": "Je kan deze optie enkel selecteren bij aankoop van het product.",
    "label.total_saved_prefix": "(Je bespaart ",
    "label.total_saved_suffix": ")",
    "label.continue": "Verder",
    "label.usp_quality": "Geselecteerd op prijs en kwaliteit",
    "label.usp_discounts": "Fikse kortingen tot wel -70%",
    "label.usp_payment_methods": "Betaal veilig en eenvoudig met",
    "label.person": "Persoon",
    "label.free": "Gratis",
    "label.first_name": "Voornaam",
    "label.last_name": "Achternaam",
    "label.birthdate": "Geboortedatum",
    "label.birthdate_why": "",
    "label.birthdate_why_text": "",
    "label.email": "E-mailadres",
    "label.phone": "Telefoonnummer",
    "label.address_finder": "Adreszoeker",
    "label.address_finder_help": "Typ je volledig leveradres in ",
    "label.select": "Selecteer",
    "label.street": "Straat",
    "label.building": "",
    "label.number": "Huisnummer (& bus)",
    "label.zip": "Postcode",
    "label.city": "Gemeente",
    "label.state": "",
    "label.country": "Land",
    "label.finish_order": "Bestellen & betalen ",
    "label.finish_order_help": "Klik op de knop \"Bestellen & betalen \" om je betaling af te ronden.",
    "label.enter_address": "Manueel mijn adres ingeven",
    "label.add_address": "Voeg een adres toe",
    "label.add_payment_method": "Voeg een betaalwijze toe",
    "label.year": "JJJJ",
    "label.month": "MM",
    "label.day": "DD",
    "label.business_order": "Zakelijke bestelling",
    "label.company_name": "Bedrijfsnaam",
    "label.tax_id": "BTW-nummer",
    "label.invoice_reason": "Reden",
    "label.invoice_reasons": "Relatiegeschenken,Teambuilding,Incentives,Seminarie,Andere",
    "label.delete": "Verwijder",
    "label.i_have_coupons": "Ik heb waardebonnen",
    "label.email_outspot_plus": "Ben je al Outspot PLUS-klant? Vul dan je e-mailadres in.",
    "label.please_wait": "Even geduld...",
    "label.please_wait_2": "Nog heel even geduld...",
    "label.please_wait_3": "Sorry, het duurt wat langer dan verwacht...",
    "label.please_wait_4": "Nog even, we zijn er bijna...",
    "label.please_wait_5": "Excuses voor het ongemak en bedankt voor je geduld, we zijn er bijna...",
    "label.continue_shopping": "Verder winkelen",
    "label.my_code": "Mijn code",
    "label.validate": "Valideer",
    "label.total_coupons": "Totaal waardebonnen",
    "label.coupons": "Waardebonnen",
    "label.total_payable_with_coupons_prefix": "(Nog te betalen ",
    "label.total_payable_with_coupons_suffix": ")",
    "label.rest_coupon_info": "Ik heb waardebonnen",
    "label.contact_extra_info": "Bestelnummer, vouchernummer of gestructureerde mededeling",
    "label.contact": "Contacteer ons",
    "label.message": "Bericht",
    "label.send": "Verstuur",
    "label.save": "Bewaar",
    "label.my_outspot_plus": "Mijn Outspot PLUS",
    "label.order_date_prefix": "Besteld op",
    "label.order_date_suffix": "",
    "label.total_paid_with": "Totaal betaald met {method}",
    "label.track_order": "Volg je bestelling met",
    "label.return_order": "Retourneer en/of meld een defect",
    "label.download_invoice": "Download factuur",
    "label.ask_question": "Stel een vraag",
    "label.happy_hour_discount": "Happy Hour voordeel",
    "label.outspot_plus_discount": "Outspot PLUS voordeel",
    "label.cancel_membership": "Annuleer lidmaatschap",
    "label.membership_start_date": "Startdatum lidmaatschap",
    "label.membership_price": "Prijs voor 3 maanden",
    "label.membership_next_payment": "Volgende betaling",
    "label.membership_end_date": "Einddatum lidmaatschap",
    "label.total_savings": "Totaal bespaarde verzend- en dossierkosten",
    "label.confirm_cancel_membership": "Bevestig annulatie",
    "label.continue_membership": "Annuleer niet",
    "label.valid_till_prefix": "Geldig tot",
    "label.valid_till_suffix": "",
    "label.card_payment": "Kaartbetaling",
    "label.place_review": "Plaats beoordeling",
    "label.name": "Naam",
    "label.name_help": "deze tonen we bij de beoordeling",
    "label.review": "Beoordeling",
    "label.self_service": "",
    "label.update_cookie_preferences": "Wijzig mijn cookievoorkeuren",
    "label.more_info": "Meer info",
    "label.review_source": "Via {source}",
    "label.amount": "Bedrag",
    "label.iban": "IBAN",
    "label.bic": "BIC",
    "label.beneficiary": "Begunstigde",
    "label.structured_communication": "Gestructureerde mededeling",
    "label.later": "Later",
    "label.enable_notifications": "Ja, ik wil deze meldingen ontvangen",
    "label.card_number": "Kaartnummer",
    "label.card_expiry": "Vervaldatum",
    "label.card_cvc": "CVV",
    "label.cvc_info": "",
    "label.sales_tax": "",
    "label.calculating_sales_tax": "",
    "label.sales_tax_will_be_calculated": "",
    "label.ship_to_selected_address": "",
    "label.ship_to_service_point": "",
    "label.change_service_point": "",
    "label.choose_rooms": "",
    "label.room": "",
    "label.room_occupancy": "",
    "label.add_another_room": "",
    "label.number_of_adults": "",
    "label.number_of_adults_title": "",
    "label.number_of_children": "",
    "label.number_of_children_title": "",
    "label.choose_arrival_and_departure_date": "",
    "label.arrival_date": "",
    "label.select_arrival_date": "",
    "label.departure_date": "",
    "label.select_departure_date": "",
    "label.rate_type_pc": "",
    "label.rate_type_pcpd": "",
    "label.rate_type_pcpn": "",
    "label.rate_type_pp": "",
    "label.rate_type_pppd": "",
    "label.rate_type_pppn": "",
    "label.rate_type_pp_": "",
    "label.rate_type_pa": "",
    "label.rate_type_papd": "",
    "label.rate_type_papn": "",
    "label.rate_type_pa_": "",
    "label.choose_supplements": "",
    "label.opengds_invalid": "",
    "error.unknown": "Onbekende fout, probeer het later opnieuw.",
    "error.required": "{field} kan niet leeg zijn.",
    "error.required_email": "{field} kan niet leeg zijn en moet een geldig e-mailadres zijn.",
    "error.required_password": "{field} moet tenminste 8 tekens lang zijn en tenminste één hoofdletter, kleine letter en cijfer bevatten.",
    "error.required_repeat_password": "{field} moet gelijk zijn aan het wachtwoord opgegeven in het veld {field2}.",
    "error.payment": "Jammer genoeg is je betaling mislukt. Dit kan gebeuren als er een probleem is met de betalingsgegevens, de betaling geannuleerd is, je betaallimiet is bereikt of om andere redenen. Overweeg om contact op te nemen met je bank of probeer een andere kaart of betaalmethode.",
    "error.payment_creditcard": "Jammer. Je betaling is mislukt. Controleer onderstaande gegevens en probeer het opnieuw.",
    "error.incomplete_order": "We kunnen deze waardebon pas valideren wanneer je alle bovenstaande velden hebt ingevuld.",
    "error.coupon_already_added": "Je hebt deze waardebon al toegevoegd.",
    "error.invalid_coupon": "Deze waardebon is ongeldig.",
    "error.coupon_not_cumulative": "Deze waardebon kan je niet samen gebruiken met andere waardebonnen.",
    "error.other_coupon_not_cumulative": "De waardebon die je al hebt toegevoegd kan je niet samen gebruiken met deze waardebon.",
    "error.invalid_username_or_password": "Het opgegeven e-mailadres en/of wachtwoord is ongeldig.",
    "error.test_order": "Dit is een testbestelling. Normaal zou de klant nu naar de betaalpagina gaan.",
    "error.required_agree": "Je moet akkoord gaan met onze algemene voorwaarden en privacyverklaring.",
    "error.required_review_agree": "Je moet ermee akkoord gaan dat je beoordeling mogelijks gepubliceerd wordt op onze website.",
    "error.register": "Er is al een account met dit e-mailadres. Klik op \"Verder\" om in te loggen of gebruik een ander e-mailadres.",
    "error.email_in_use": "Er is al een account met dit e-mailadres. Gebruik een ander e-mailadres.",
    "error.already_reviewed": "Je hebt al een beoordeling geplaatst voor deze bestelling.",
    "error.zip_blocked": "Het is niet mogelijk om naar de opgegeven postcode te verzenden.",
    "error.details": "Het lijkt er op dat één of meerdere producten die je probeert te bestellen niet langer op voorraad zijn. Keer terug naar de vorige stap, pas je bestelling aan en probeer het opnieuw.",
    "error.incomplete_cvc": "CVV is te kort.",
    "error.incomplete_date": "Vervaldatum kan niet leeg zijn.",
    "error.date_in_past": "Kaart is vervallen.",
    "error.invalid_expiration_year": "Kaart is vervallen.",
    "error.incomplete_card_number": "Kaartnummer is te kort.",
    "error.incorrect_card_number": "Kaartnummer is ongeldig.",
    "unsubscription.your_mail_address": "Jouw e-mailadres: ",
    "unsubscription.sad_message": "Hierbij bevestigen we je uitschrijving.",
    "unsubscription.have_not_participated": "Heb je je nooit ingeschreven?",
    "unsubscription.sad_message_2": "We vinden het jammer dat je ons verlaten hebt, maar uiteraard blijf je altijd welkom.",
    "unsubscription.resubscribe_question": "Ben je van gedachten veranderd? <span class='unsub-important-message'>Klik hieronder om je opnieuw in te schrijven voor onze nieuwsbrief:</span>",
    "unsubscription.form_message": "Waarom verlaat je onze Outspot-familie? Je antwoord kan ons helpen om onze dienstverlening te verbeteren.",
    "unsubscription.report_not_participated": "Meld misbruik.",
    "unsubscription.coreg_date_no_screenshot": "Je nam op {date} deel aan een actie (<a href=\"{coreg_site}\">{coreg_site}</a>) en gaf zo de toestemming om onze nieuwsbrief met topaanbiedingen tegen uitzonderlijke prijzen te ontvangen.",
    "unsubscription.coreg_date_no_coreg_data": "Je nam op {date} deel aan onderstaande actie en gaf zo de toestemming om onze nieuwsbrief met topaanbiedingen tegen uitzonderlijke prijzen te ontvangen.",
    "unsubscription.coreg_date_screenshot": "Je nam op {date} deel aan onderstaande actie en gaf zo de toestemming om onze nieuwsbrief met topaanbiedingen tegen uitzonderlijke prijzen te ontvangen.",
    "unsubscription.coreg_no_date_no_screenshot": "Je nam deel aan onderstaande actie en gaf je toestemming om onze nieuwsbrieven met topaanbiedingen voor uitzonderlijke prijzen te ontvangen.",
    "unsubscription.coreg_no_date_screenshot": "Je nam deel aan onderstaande actie en gaf je toestemming om onze nieuwsbrieven met topaanbiedingen voor uitzonderlijke prijzen te ontvangen.",
    "unsubscription.main_title": "Afmelden voor de nieuwsbrief",
    "unsubscription.report_title": "Meld een misbruik",
    "unsubscription.report_sent_message": "Bedankt, je melding is ontvangen.",
    "claim_coupon.welcome_message": "Speciaal voor jou",
    "claim_coupon.info_line_message": "Voer bovenstaande persoonlijke code in bij het afrekenen.",
    "claim_coupon.info_line_message_bis": "Deze waardebon werd je ook per mail toegestuurd.",
    "claim_coupon.main_title": "Jouw coupon",
    "about.title": "Over Ons",
    "about.intro": "Welkom bij Outspot, jouw nummer één bestemming voor onweerstaanbare deals! Sinds de oprichting in 2008 is Outspot uitgegroeid tot dé toonaangevende Europese flash deal-website. Outspot biedt dagelijks kortingen aan op kwalitatieve producten en reizen, waardoor jij, als klant, geweldig kan besparen. Dankzij ons magazijn in België brengen wij onze pakketjes zo snel mogelijk bij jouw thuis.",
    "about.mission_title": "Mission",
    "about.mission_text": "Bij Outspot geloven we in het toegankelijk maken van uitzonderlijke aanbiedingen voor iedereen. Ons doel is om onze klanten de best mogelijke waarde te bieden door zorgvuldig geselecteerde deals aan te bieden tegen ongeëvenaarde prijzen.",
    "about.history_title": "History",
    "about.history_text": "Het Outspot avontuur begon in België, waar het hoofdkantoor nog steeds is gevestigd. Met een toegewijd team van 25 medewerkers verspreid over Europa, werken we dagelijks aan het vinden en aanbieden van de beste deals. Ondertussen zijn we in 13 landen actief en hebben we dagelijks 100.000 tevreden bezoekers.",
    "about.quality_title": "Ons aanbod is geselecteerd op basis van prijs en kwaliteit",
    "about.quality_text": "Bij Outspot vinden we dat je het beste van twee werelden verdient: topkwaliteit tegen een betaalbare prijs. Daarom gaan we elke dag opnieuw voor jou op zoek naar de meest aantrekkelijke deals. Door nauwe samenwerkingen met onze leveranciers zorgen we ervoor dat we de beste prijzen kunnen onderhandelen, zonder concessies te doen aan de kwaliteit. <br /><br />  Ieder product en elke reis die we aanbieden, wordt zorgvuldig geselecteerd op basis van een strenge evaluatie van prijs en kwaliteit. Zo weet je zeker dat je bij ons altijd de beste waarde krijgt voor je geld. Bij Outspot draait alles om jouw voordeel én tevredenheid! Onderstaand ziet u een foto waarbij het geluid van de smartbel wordt getest met een decibelmeter.",
    "about.tested_title": "Alle deals zijn uitvoerig getest door onze Outspot collega's",
    "about.tested_text": "Bij Outspot geloven we in het leveren van alleen het allerbeste. Voordat een deal op onze site verschijnt, wordt deze uitvoerig getest door onze eigen Outspot-collega's. Zij nemen geen genoegen met oppervlakkige tests: producten worden volledig uit elkaar gehaald en elk onderdeel wordt zorgvuldig beoordeeld op stevigheid, geluidsniveau, materiaalkwaliteit en meer.<br /><br />  Ook bezoeken onze collega’s persoonlijk veel hotels om de kwaliteit van de accommodaties te controleren. Zo garanderen we dat alles aan onze hoge standaarden voldoet. Of het nu om een product of een reis gaat, je kunt er zeker van zijn dat je bij Outspot altijd met vertrouwen je aankoop doet.<br /><br />  Zo ziet u hier in onderstaande foto hoe collega’s producten tot in detail bekijken en testen: bv stofkwaliteit check.",
    "about.guarantee_title": "6 maanden terugbetaal-garantie op meeste reizen",
    "about.guarantee_text": "Wanneer je een reis boekt, wil je er zeker van zijn dat je keuze de juiste is en dat je investering goed besteed is. Daarom bieden wij een terugbetaalgarantie van 6 maanden op de meeste van onze reizen. Met deze garantie kun je met een gerust hart plannen, in de wetenschap dat je altijd recht hebt op een terugbetaling, mits er nog geen reservering is gemaakt. Dit geeft je de vrijheid om flexibel te blijven in je beslissingen, zonder het risico op extra kosten zolang er geen definitieve boeking is.",
    "about.returns_title": "30 dagen retourrecht op meeste producten",
    "about.returns_text": "We begrijpen dat het soms wat tijd kan kosten om te beslissen of je een product wilt behouden. Daarom bieden wij op de meeste van onze producten een retourrecht van 30 dagen aan. Het is belangrijk dat het product ongebruikt en ongeopend blijft om in aanmerking te komen voor een retour. Dit geeft je de kans om je aankoop op je eigen tempo te overwegen, terwijl we zorgen voor een soepele retourprocedure. Zo kun je zonder zorgen beslissen, zolang het product in originele staat blijft.",
    "about.support_title": "Klantendienst in het Nederlands",
    "about.support_text": "Bij Outspot staat klanttevredenheid voorop, en we doen er alles aan om ervoor te zorgen dat je een uitstekende ervaring hebt. Mocht je vragen hebben of hulp nodig hebben met je bestelling, dan staat onze klantendienst altijd voor je klaar. Onze Nederlandstalige medewerkers zijn beschikbaar om je snel, vriendelijk en efficiënt te ondersteunen. We streven ernaar om je op een persoonlijke en begrijpelijke manier te helpen, zodat je zonder zorgen kunt genieten van je aankoop. Jouw tevredenheid is onze prioriteit, en we zijn er om ervoor te zorgen dat je optimaal kunt profiteren van onze service.",
    "about.socials_title": "Blijf verbonden",
    "about.socials_text": "Blijf op de hoogte van onze nieuwste deals en exclusieve aanbiedingen door je aan te melden voor onze nieuwsbrief. Volg ons op social media en deel jouw Outspot-ervaringen met ons!<br /><br />  Heb je vragen of opmerkingen? Onze helpdesk staat klaar om je te helpen. Neem contact met ons op via <a href=\"{help}\">onze contactpagina</a>.<br /><br />  Bedankt voor jouw vertrouwen in Outspot. We kijken ernaar uit om je telkens weer te verrassen met onze fantastische aanbiedingen!",
    "vat.error_required": "Het veld \"{field}\" mag niet leeg zijn bij een zakelijke bestelling. ",
    "vat.validation_message_format": "Het veld \"{field}\" is niet het correcte formaat.",
    "vat.help_title": "BTW nummer ",
    "vat.help_text": "Het Nederlandse btw-nummer moet uit 14 tekens bestaan, bijvoorbeeld NL000099998B57.",
    "label.download": "Download",
    "error.download_pdf": "Er ging iets mis bij het downloaden van de PDF. Probeer het later nog eens.",
    "manage_address.title": "Mijn bezorgadressen",
    "error.select": "Selecteer een {field}",
    "manage_cards.title": "Mijn betaalmethoden",
    "deal.quality_text_1": "Geselecteerd op prijs en kwaliteit",
    "deal.quality_text_2": "Voorraad in de Benelux",
    "deal.delivery_text_days": "Levertijd: {days} werkdagen",
    "deal.delivery_text": "Op werkdagen voor 16u besteld, volgende werkdag geleverd",
    "maintenance.going_offline": "Over enkele minuten gaat onze website tijdelijk offline voor gepland onderhoud. Rond je bestelling nu af, want tijdens het onderhoud is bestellen even niet mogelijk.",
    "maintenance.offline": "Onze website is momenteel offline voor gepland onderhoud. Probeer het over enkele minuten opnieuw – we zijn zo snel mogelijk weer online! Als attentie krijg je van ons GMJ2QPZ3 voor €5 korting op je volgende aankoop. ",
    "sustainability.carpark_title": "Groener maken van onze wagenpark",
    "sustainability.carpark_text": "In onze voortdurende inspanningen om onze ecologische voetafdruk te verkleinen, transformeren we onze bedrijfswagens. Tegen 2026 streven we naar een volledig elektrische vloot. Deze overgang is een cruciale stap om onze uitstoot te verlagen en schoner, groener vervoer te bevorderen.",
    "sustainability.energy_title": "Kantoor op zonne-energie",
    "sustainability.energy_text": "Duurzaamheid begint thuis, en voor ons betekent dat ons kantoor. We hebben 30 zonnepanelen geïnstalleerd, die bijna de helft van de elektriciteit produceren die we nodig hebben voor onze activiteiten op kantoor. Door gebruik te maken van de kracht van de zon, verminderen we onze afhankelijkheid van niet-hernieuwbare energiebronnen aanzienlijk en verlagen we onze totale CO2-uitstoot.",
    "sustainability.eco_packaging_title": "Milieuvriendelijke verpakkingen",
    "sustainability.eco_packaging_text": "Wij zijn ons bewust van de impact van verpakkingsafval op het milieu. Daarom is al onze verpakking gemaakt 100% recycleerbaar plastic. Dit initiatief helpt op de manier een duurzamere cyclus van gebruik en hergebruik te creëren.",
    "sustainability.combined_packaging_title": "Geconsolideerde Verzending",
    "sustainability.combined_packaging_text": "Om onze impact op het milieu verder te verkleinen, moedigen we onze klanten aan om hun bestellingen te consolideren. Door zo veel mogelijk aankopen in één zending te combineren, kunnen we het aantal leveringen verminderen. Zo worden op zijn beurt de uitstoot verlaagd en de ecologische voetafdruk verkleind.",
    "sustainability.title": "Duurzaamheid bij Outspot",
    "sustainability.vision_title": "Onze visie",
    "sustainability.vision_text": "Outspot is zich bewust van haar impact op de leefomgeving. We maken dagelijks duizenden mensen blij met ons aanbod, terwijl onze ecologische en sociale verantwoordelijkheid in elk aspect van onze activiteit verweven zit. Wij geloven dat door het nemen van proactieve stappen vandaag, we effectief impact kunnen hebben op de gezondheid van onze planeet en het welzijn van toekomstige generaties.",
    "sustainability.action_title": "Doe met ons mee ",
    "sustainability.action_text": "We nodigen je uit om mee aan boord te gaan van onze duurzaamheidsreis. Van het kiezen van milieuvriendelijke producten tot deelname aan onze recyclingprogramma's, elke kleine actie maakt een groot verschil.  ",
    "sustainability.plant_text": "Bij je aankoop kun je ervoor kiezen om voor slechts €1 extra een boom te laten planten. Dit helpt niet alleen bij het compenseren van CO2-uitstoot, maar draagt ook bij aan herbebossing en een betere toekomst voor lokale gemeenschappen.",
    "sustainability.treeplanters": "We werken samen met Tree-Planters, een organisatie die zich inzet voor slimme en duurzame herbebossing. Zij planten bomen op zorgvuldig geselecteerde locaties in Malawi, waar ze niet alleen de natuur herstellen, maar ook de lokale bevolking ondersteunen met extra inkomsten en voedselvoorziening",
    "sustainability.action_text_believe": "Bij Outspot geloven we dat duurzaamheid een gedeelde verantwoordelijkheid is. Samen met onze klanten, werknemers en partners streven we naar een bewuste omgang met onze planeet. Doe mee en maak samen met ons het verschil!",
    "sustainability.plant_title": "Plant een boom - maak het verschil",
    "sustainability.trees": "bomen",
    "sustainability.tree": "boom",
    "deal.advice_price": "adviesprijs",
    "sustainability.checkbox_title": "Plant samen met Outspot",
    "sustainability.checkbox_detail_text": "We nodigen je uit om samen met Outspot bomen te planten voor € {price}."
  },
  "de": {
    "route.home": "https://www.outspot.de/de/",
    "route.fallback_domain": "https://www2.outspot.de",
    "language.code": "DE",
    "language.locale": "de-DE",
    "language.name": "Deutschland",
    "language.flag": "de",
    "language.date_input_format": "d,m,y",
    "language.currency": "EUR",
    "language.region": "eu",
    "countries.delivery": "DEU",
    "states.delivery": "",
    "tracking.aw_merchant_id": "5335466686",
    "tracking.aw_feed_country": "DE",
    "countries.BEL": "Belgien",
    "countries.LUX": "Luxemburg",
    "countries.NLD": "Niederlande",
    "countries.FRA": "Frankreich",
    "countries.DEU": "Deutschland",
    "countries.AUT": "Österreich",
    "countries.ITA": "Italien",
    "countries.ESP": "Spanien",
    "countries.POL": "Polen",
    "countries.GBR": "Vereinigtes Königreich",
    "countries.IRL": "Irland",
    "countries.SWE": "Schweden",
    "countries.PRT": "Portugal",
    "countries.FIN": "Finnland",
    "countries.USA": "",
    "states.AL": "",
    "states.AR": "",
    "states.AZ": "",
    "states.CA": "",
    "states.CO": "",
    "states.CT": "",
    "states.DE": "",
    "states.FL": "",
    "states.GA": "",
    "states.IA": "",
    "states.ID": "",
    "states.IL": "",
    "states.IN": "",
    "states.KS": "",
    "states.KY": "",
    "states.LA": "",
    "states.MA": "",
    "states.MD": "",
    "states.ME": "",
    "states.MI": "",
    "states.MN": "",
    "states.MO": "",
    "states.MS": "",
    "states.MT": "",
    "states.NC": "",
    "states.ND": "",
    "states.NE": "",
    "states.NH": "",
    "states.NJ": "",
    "states.NM": "",
    "states.NV": "",
    "states.NY": "",
    "states.OH": "",
    "states.OK": "",
    "states.OR": "",
    "states.PA": "",
    "states.RI": "",
    "states.SC": "",
    "states.SD": "",
    "states.TN": "",
    "states.TX": "",
    "states.UT": "",
    "states.VA": "",
    "states.VT": "",
    "states.WA": "",
    "states.WI": "",
    "states.WV": "",
    "states.WY": "",
    "trustpilot.businessunit_id": "53c7d4b60000640005791a39",
    "trustpilot.show": "TRUE",
    "mollie.profile_id": "pfl_khCUrD7Cx9",
    "mollie.locale": "de_DE",
    "facebook.url": "https://www.facebook.com/OutspotDeutschland",
    "instagram.url": "",
    "linkedin.url": "",
    "header.hello": "Willkommen",
    "header.hello_name": "Guten Tag <b>{firstName}</b>",
    "subscribe.title": "Melden Sie sich kostenlos an und verpassen Sie kein einziges Angebot mehr!",
    "subscribe.text": "Entdecken Sie die besten Angebote von Outspot. Melden Sie sich kostenlos an und genießen Sie exklusive und personalisierte Rabatte auf einzigartige Produkte und Erlebnisse.",
    "subscribe.agree": "Indem Sie unseren Newsletter abonnieren, erklären Sie sich mit den <a href=\"{conditions}\" target=\"_blank\">Allgemeinen Bedingungen</a> und der <a href=\"{privacy}\" target=\"_blank\">Datenschutzerklärung</a> einverstanden.",
    "subscribe.sent": "Vielen Dank! Wir haben Ihre Anmeldung erfolgreich erhalten. Sie werden in Kürze eine Anmeldebestätigung per E-Mail erhalten.",
    "footer.follow": "Folgen Sie uns",
    "footer.payment_methods_logos": "visa,mastercard,amex,paypal,applepay",
    "footer.copyright": "Outspot — Sint-Martens-Latem, Belgien — Confinity NV",
    "home.new": "Neueste Angebote",
    "home.ending": "Diese Angebote laufen demnächst aus",
    "home.other": "Alle Angebote",
    "countdown.prefix": "Noch",
    "countdown.days": "Tag|Tage",
    "countdown.hours": "Stunde",
    "countdown.minutes": "min",
    "countdown.seconds": "sec",
    "countdown.days_compact": "d",
    "date.years": "vor {count} Jahr| vor {count} Jahren",
    "date.months": "vor {count} Monat| vor {count} Monaten",
    "date.weeks": "vor {count} Woche| vor {count} Wochen",
    "date.days": "vor {count} Tag| vor {count} Tagen",
    "date.hours": "vor {count} Stunde| vor {count} Stunden",
    "date.minutes": "vor {count} Minute|vor {count} Minuten",
    "date.seconds": "Vor weniger als 1 Minute",
    "countdown.suffix": "",
    "onboard.language_title": "Genießen Sie persönliche Angebote mit scharfen Rabatten.",
    "onboard.language_text": "Wählen Sie das Land und die Sprache, in der Sie einkaufen möchten:",
    "onboard.notifications_title": "Bleiben Sie informiert:",
    "onboard.notifications_usp_1": "Die besten und neuesten Angebote",
    "onboard.notifications_usp_2": "Aktualisierungen zu Ihren Bestellungen",
    "onboard.notifications_usp_3": "Personalisierte Rabattcodes & Gutscheine",
    "order.shipping_title": "Versand",
    "order.shipping_text": "Aufgrund der steigenden Transportkosten und der Umweltbelastung sind wir gezwungen, Transportkosten zu berechnen. Outspot garantiert Ihnen aber immer noch tolle Preise, bei denen Sie trotzdem gewinnen. Außerdem erhöhen sich Ihre Transportkosten nicht, wenn Sie mehr Artikel kaufen. Wenn Sie ein Outspot PLUS-Mitglied sind oder innerhalb einer Stunde nach Ihrem letzten Kauf bestellen, werden diese Kosten im nächsten Schritt abgezogen.",
    "order.thank_you": "Vielen Dank für Ihre Bestellung!",
    "order.overview": "Überblick über Ihre Bestellung",
    "order.thank_you_bank": "Vielen Dank für Ihren Kauf. Sie erhalten die Überweisungsdaten in Ihrem E-Mail Posteingang oder in Ihren Spam-Mails. Bitte überweisen Sie den Betrag so schnell wie möglich, auf jeden Fall aber innerhalb von 7 Tagen. Sie erhalten den von Ihnen bestellten Gutschein per E-Mail, sobald die Zahlung eingegangen ist und bearbeitet wurde.",
    "order.thank_you_with_shipping": "Ihr Einkauf ist abgeschlossen. Wir werden Ihre Bestellung an die unten angegebene Adresse senden. Sie erhalten Ihre Kaufbestätigung in Ihrem E-Mail Posteingang oder in den Spam-Mails. Viel Spaß mit Ihrer Bestellung.",
    "order.thank_you_without_shipping": "Ihr Kauf ist abgeschlossen. Sie erhalten Ihre Kaufbestätigung in Ihrem E-Mail Posteingang oder in den Spam-Mails. Viel Spaß mit Ihrer Bestellung.",
    "order.code": "Bestellnummer: {code}",
    "order.shipping_address": "Lieferadresse",
    "order.save_payment_info_title": "Speichern Sie die Bankdaten für meine nächsten Einkäufe.",
    "order.save_payment_info_text": "Wenn Sie diese Option wählen, müssen Sie Ihre Daten nicht jedes Mal eingeben. Ihre Daten werden sicher gespeichert.",
    "order.shipped_to_service_point": "",
    "my_outspot_plus.title": "Mein Outspot PLUS",
    "my_outspot_plus.subtitle": "Auf dieser Seite können Sie Ihre Outspot PLUS-Mitgliedschaft verwalten.",
    "my_outspot_plus.overview": "Überblick über Ihre Outspot PLUS-Mitgliedschaft",
    "my_outspot_plus.cancel_are_you_sure": "Sind Sie sicher, dass Sie Ihre Mitgliedschaft kündigen möchten?",
    "my_outspot_plus.cancel_you_saved_prefix": "Sie sparen bereits",
    "my_outspot_plus.cancel_you_saved_suffix": " Versand- und Bearbeitungsbeführen drch Ihre Outspot PLUS-Mitgliedschaft.",
    "my_outspot_plus.no_membership": "Sie haben derzeit keine Outspot PLUS-Mitgliedschaft.",
    "plus.title": "Outspot PLUS",
    "plus.popup_text": "Mit Outspot PLUS profitieren Sie von einem kostenlosen Versand für alle Artikel und zahlen keine Bearbeitungsgebühren auf alle Reisen. Nach jeder Bestellung erhalten Sie eine E-Mail, die Ihnen die Möglichkeit gibt, Outspot PLUS-Mitglied zu werden. Sie müssen jedoch schnell sein, da diese Aktion nur 7 Tage lang gültig ist. Vergessen Sie also nicht, Ihre nächste Bestellbestätigungs-E-Mail zu überprüfen.",
    "plus.name": "Outspot PLUS-Mitgliedschaft",
    "plus.highlight_1": "Genießen Sie eine einfache Bestellung ohne Versand- und Bearbeitungsgebühren.",
    "plus.highlight_2": "Ihr Abonnement wird automatisch alle drei Monate für {renewPrice} verlängert.",
    "plus.highlight_3": "Stornierung jederzeit möglich.",
    "plus.question_1": "Was sind die Vorteile von Outspot PLUS?",
    "plus.answer_1": "Mit Outspot PLUS profitieren Sie von einem kostenlosen Versand auf alle Artikel und zahlen keine Bearbeitungsgebühren für alle Reisen.",
    "plus.question_2": "Wie werde ich Outspot PLUS-Mitglied?",
    "plus.answer_2": "Nach jeder Bestellung erhalten Sie eine E-Mail, die Ihnen die Möglichkeit gibt, Outspot PLUS Mitglied zu werden. Sie müssen jedoch schnell sein, da diese Aktion nur 7 Tage lang gültig ist. Wenn Sie sich für die Aktivierung von Outspot PLUS entscheiden, treten die Vorteile sofort in Kraft. Das bedeutet, dass Sie bei Ihrer nächsten Bestellung sofort in den Genuss eines kostenlosen Versands für alle Artikel kommen und keine Bearbeitungsgebühren für alle Ihre Reisen zahlen müssen.",
    "plus.question_3": "Wie viel kostet Outspot PLUS?",
    "plus.answer_3": "Outspot PLUS kostet nur {Preis} für 3 Monate. Dies ist eine einmalige Zahlung. Danach verlängert sich Ihre Mitgliedschaft automatisch alle 3 Monate zum Preis von {renewPreis}. Dadurch sparen Sie erheblich bei den Versand- und Bearbeitungskosten.",
    "plus.question_4": "Wann und wie wird mein Outspot PLUS verlängert?",
    "plus.answer_4": "Outspot PLUS wird alle drei Monate automatisch erneuert. Um dies zu verwalten, können Sie jederzeit auf <a href=\"{myOutspotPlus}\">Mein Outspot PLUS</a> gehen. Der Betrag wird automatisch alle 3 Monate von dem von Ihnen gewählten Konto abgebucht.",
    "plus.question_5": "Wie kann ich mein Outspot PLUS kündigen? Bekomme ich eine Rückerstattung?",
    "plus.answer_5": "Sie können Ihre Outspot PLUS-Mitgliedschaft jederzeit kündigen über: <a href=\"{myOutspotPlus}\">Mijn Outspot PLUS</a>. Bitte beachten Sie, dass für die Monate, in denen Ihre Mitgliedschaft aktiv war, keine Erstattung erfolgt. Wenn Sie sich jedoch entschließen, Outspot PLUS zu kündigen, wird die nächste geplante Abrechnung nicht berechnet.",
    "plus.thank_you": "Ihr Kauf ist abgeschlossen. Ab jetzt können Sie ganz einfach und ohne Versandkosten bestellen. Sie können Ihre Mitgliedschaft über <a href=\"{myOutspotPlus}\">My Outspot PLUS</a> einsehen und verwalten.",
    "my_coupons.title": "Meine Wertgutscheine",
    "my_coupons.subtitle": "Sie können Ihre Wertgutscheine auf dieser Seite finden.",
    "my_coupons.no_coupons": "Sie haben derzeit keine Wertgutscheine.",
    "deal.sold": "Bereits {sold} mal gekauft ",
    "deal.retour_label": "Rücksendung innerhalb von 30 Tagen für nur 0,95 €",
    "deal.retour_title": "Rückgaberecht",
    "deal.retour_text": "Gefällt Ihnen Ihr Produkt doch nicht? Wenden Sie sich innerhalb von 30 Tagen nach Erhalt an unseren Helpdesk und senden Sie Ihr Produkt zurück. Und das aus jedem beliebigen Grund! Wir erstatten Ihnen den Kaufpreis und Sie können sorglos neu bestellen. Eine weitere tolle Idee, natürlich von Outspot!",
    "deal.guarantee_label": "6-monatige Rückzahlungsgarantie",
    "deal.guarantee_title": "Einzigartig: 100% Erstattungsgarantie",
    "deal.guarantee_text": "Unglaublich, aber wahr. Sie müssen nicht länger zögern, ein Erlebnis für Ihre Freunde/Kollegen/Familie oder sich selbst zu kaufen. Wenn Sie es sich im Nachhinein anders überlegen oder der Beschenkte mit dem Gutschein nicht ganz zufrieden ist, ist das kein Problem.<br><br>Bei Outspot.de können Sie Ihren Kauf bis zu 6 Monate nach der Bestellung widerrufen! Sie oder die Person, die den Gutschein erhalten hat, können innerhalb von 6 Monaten eine vollständige Rückerstattung erhalten. Dies natürlich nur, solange der Gutschein noch nicht reserviert, verbraucht oder abgelaufen ist. Als Garantie ist dies außergewöhnlich und nie gesehen. Aber so ist Outspot nun einmal.<br><br> Bitte beachten Sie, dass für alle Aktionen, bei denen die Rückerstattungsgarantie nicht ausdrücklich erwähnt wird, die Garantie nicht gilt. Das liegt daran, dass wir in manchen Fällen Lieferungen/Gutscheine von unserem Partner nicht selbst rückerstatten können.",
    "deal.option_count": "{count} Option|{count} Optionen",
    "deal.campaign_number": "Kampagnennummer",
    "deal.your_details": "Ihre Angaben",
    "deal.payment_methods_title": "Zahlungsmethode",
    "deal.shipping_methods_title": "",
    "deal.privacy": "Mit der Eingabe Ihrer Daten erklären Sie sich mit <a target='_blank' href='{privacy}'>unserer Datenschutzerklärung</a> einverstanden.",
    "deal.payment_methods_subtitle": "Wie möchten Sie Ihre Bestellung bezahlen?",
    "deal.your_order": "Ihre Bestellung",
    "deal.from": "Ab",
    "help.title": "Brauchen Sie Hilfe?",
    "help.subtitle": "Lassen Sie uns wissen, wie wir Ihnen helfen können.",
    "help.subject": "Ihre Frage über unsere Website oder App",
    "help.sent": "Wir haben Ihre Nachricht korrekt erhalten. Sie werden spätestens innerhalb von 4 Werktagen eine Antwort von uns erhalten.",
    "login.title": "Einloggen",
    "login.subtitle": "Willkommen zurück! Wie möchten Sie sich anmelden?",
    "login.or": "oder",
    "login.usps_title": "Warum bei Outspot anmelden?",
    "login.usps_title_cta": "Machen Sie es sich leichter und melden Sie sich an oder erstellen Sie jetzt ein Konto.",
    "login.usps_text": "Klicken Sie oben auf \"Anmelden\", um sich einzuloggen. Wenn Sie noch kein Konto bei Outspot haben, können Sie sich auch auf der Anmeldeseite registrieren. Nachfolgend finden Sie einige der Vorteile eines Outspot-Kontos:",
    "login.usp_manage_orders": "Ihre Bestellungen einsehen und verfolgen",
    "login.usp_remember_info": "Speichern Ihrer Adressdaten",
    "login.usp_returns": "Einen Defekt melden oder eine Rücksendung beantragen",
    "login.usp_store_payments": "Speichern Sie Ihre Bezahlmethoden und zahlen Sie schneller",
    "login.short_cta": "<a href=\"{loginLink}\">Melden Sie sich an</a> oder fahren Sie unten als Gast fort.",
    "forgot.title": "Ein neues Passwort anfordern",
    "forgot.subtitle": "Geben Sie unten Ihre E-Mail-Adresse ein und wir senden Ihnen einen Link, mit dem Sie Ihr Passwort zurücksetzen können.",
    "forgot.sent": "Wir haben Ihnen eine E-Mail mit einem Link zum Zurücksetzen Ihres Passworts geschickt.",
    "password.title": "Passwort ändern",
    "password.subtitle": "Wählen Sie ein neues und sicheres Passwort.",
    "password.updated": "Ihr Passwort wurde erfolgreich geändert. Sie können sich von nun an mit Ihrem neuen Passwort anmelden.",
    "register.title": "Registrieren",
    "register.subtitle": "Erstellen Sie ein Konto, um die Bestellung und die Verfolgung Ihrer Bestellungen noch einfacher zu machen.",
    "register.personal_info": "Persönliche Daten",
    "register.login_info": "Login-Daten",
    "register.agree": "Ich stimme zu <a href=\"/enl/help/general-conditions\" target=\"_blank\">general-conditions</a> und <a href=\"/enl/help/privacy\" target=\"_blank\">privacy statement</a> zu.",
    "register.sent": "Sie sind fast am Ziel. Wir haben Ihnen eine E-Mail zur Aktivierung Ihres Kontos geschickt.",
    "register.email_known": "Wenn Ihre E-Mail-Adresse Outspot bereits bekannt ist, <a href=\"{Vergessen}\">klicken Sie hier</a>, um ein Passwort anzufordern.",
    "my_orders.title": "Meine Bestellungen",
    "my_orders.subtitle": "Dies ist eine Übersicht über alle Bestellungen, die Sie bei Outspot getätigt haben.",
    "my_orders.title_detail": "Details Ihrer Bestellung",
    "my_travels.title": "Meine Reisen",
    "my_info.title": "Meine Angaben",
    "my_info.subtitle": "Hier können Sie Ihre persönlichen Daten bearbeiten.",
    "my_info.sent": "Ihre Angaben wurden aktualisiert.",
    "notifications.title": "Newsletter und Benachrichtigung",
    "notifications.subscription_mail": "Wie oft möchtest du unseren Newsletter erhalten?",
    "notifications.subscription_notification": "Wie oft möchtest du Benachrichtigungen erhalten?",
    "notifications.frequency_0": "Nie",
    "notifications.frequency_4": "Einmal pro Woche",
    "notifications.frequency_56": "Zweimal pro Tag",
    "notifications.sent": "Deine Einstellungen wurden aktualisiert.",
    "combined_purchase_banner.text": "Bezahlen Sie keine Versand- oder Bearbeitungsgebühren für Ihre nachfolgenden Bestellungen für:",
    "plus_banner.text_welcome": "{firstName}, willkommen bei Outspot PLUS.",
    "plus_banner.text_welcome_anonimous": "Willkommen bei Outspot PLUS.",
    "plus_banner.text_savings_prefix": "{firstName}, Sie sparen bereits",
    "plus_banner.text_savings_prefix_anonimous": "Sie sparen bereits",
    "plus_banner.text_savings_suffix": "Versand- und Bearbeitungsgebühren mit Outspot PLUS.",
    "cookie_banner.title": "Cookies",
    "cookie_banner.text": "Diese Website verwendet Cookies. Unbedingt erforderliche Cookies sorgen dafür, dass die Website ordnungsgemäß funktioniert und können nicht abgelehnt werden. Funktionale, analytische und Werbe-Cookies werden jeweils für funktionale, statistische und Marketingzwecke verwendet. Sie werden nur heruntergeladen, wenn Sie zustimmen. Lesen Sie mehr in unserer <a href=\"{cookies}\" target=\"_blank\">Cookie-Richtlinie</a>.",
    "cookie_banner.decline": "Optionale Cookies ablehnen",
    "cookie_banner.accept_all": "Alle Cookies akzeptieren",
    "cookie_banner.accept_selected": "Ausgewählte akzeptieren",
    "cookie_banner.settings": "Einstellungen",
    "cookie_banner.functional_cookies_title": "Unbedingt erforderliche Cookies",
    "cookie_banner.functional_cookies_text": "Cookies, die für die grundlegende Funktionalität der Website erforderlich sind.",
    "cookie_banner.analytical_cookies_title": "Analytische & funktionale Cookies",
    "cookie_banner.analytical_cookies_text": "Cookies ermöglichen es der Website, zusätzliche Funktionen und persönliche Einstellungen sowie Besuche und Traffic bereitzustellen.",
    "cookie_banner.personal_cookies_title": "Werbe-Cookies",
    "cookie_banner.personal_cookies_text": "Cookies, die von Werbetreibenden gesetzt werden, um ein Profil Ihrer Interessen zu erstellen, ohne direkte persönliche Informationen zu speichern.",
    "review.title": "Teilen und gewinnen",
    "review.subtitle": "Bewerten Sie Ihren Einkauf und erhalten Sie die Chance, einen Gutschein im Wert von 250 € zu gewinnen (wir verlosen jeden Quartal einen Gutschein).",
    "review.agree": "Ich bin damit einverstanden, dass meine Bewertung auf der Website veröffentlicht wird.",
    "review.sent": "Vielen Dank für Ihre Bewertung. Sie haben jetzt die Chance, einen 250 € Gutschein zu gewinnen.",
    "review.title_thank_you": "Vielen Dank! Wir schätzen Ihre Meinung.",
    "review.subtitle_thank_you": "Dank Ihres Feedbacks können wir Outspot noch besser machen. Schreiben Sie unten eine Bewertung Ihres Einkaufs und Sie haben die Chance, einen Gutschein im Wert von 250 € zu gewinnen (wir verlosen jeden Quartal einen solchen Gutschein).",
    "confirm.title": "Ihre Anmeldung ist bestätigt",
    "confirm.subtitle": "Ihr Abonnement des Newsletters wird hiermit bestätigt. Wir danken Ihnen! Um Sie noch besser bedienen zu können, bieten wir Ihnen die Möglichkeit, uns die folgenden Daten unverbindlich mitzuteilen.",
    "popup_widget.title": "Angebote vom Feinsten, für Sie getestet.",
    "popup_widget.usp_1": "Erhalten Sie jeden Tag unsere besten Angebote.",
    "popup_widget.usp_2": "Ausgewählt und getestet für besten Preis und Qualität.",
    "popup_widget.usp_3": "Riesenrabatte von bis zu -70%.",
    "popup_widget.footer_1": "Melden Sie sich jetzt kostenlos für unseren Newsletter an und sparen Sie sofort.",
    "popup_widget.footer_2": "Sie können sich jederzeit wieder abmelden.",
    "promo.title": "Unsere Top-Promos",
    "promo.countdown": "2024-12-31 23:59:59",
    "promo.empty": "Derzeit haben wir keine Top-Promos. Gehen Sie auf unsere Homepage für unsere neuesten Angebote.",
    "stars.info_title": "Produktbewertung",
    "stars.info_text": "Diese Bewertung ist ein Durchschnitt unserer eigenen gesammelten Bewertungen und der von anderen Beteiligten.",
    "stars.counter": "1 Bewertung | {count} Bewertungen",
    "label.accept_selected": "Auswahl akzeptieren",
    "label.edit": "Ändern Sie",
    "label.edit_address": "Adresse ändern",
    "label.address": "Adresse",
    "label.accept_all": "Alle akzeptieren",
    "label.account": "Konto",
    "label.help": "Hilfe",
    "label.my_orders": "Meine Bestellungen",
    "label.my_travels": "Meine Reisen",
    "label.my_coupons": "Meine Gutscheine",
    "label.my_info": "Meine Angaben",
    "label.change_password": "Passwort ändern",
    "label.notifications": "Newsletter und Benachrichtigung",
    "label.logout": "Abmelden",
    "label.login": "Einloggen",
    "label.login_have_account": "Sie haben bereits ein Konto? Anmelden",
    "label.login_with_facebook": "Mit Facebook anmelden",
    "label.login_with_google": "Mit Google anmelden",
    "label.login_with_apple": "Mit Apple anmelden",
    "label.password": "Passwort",
    "label.new_password": "Neues Passwort",
    "label.repeat_password": "Passwort wiederholen",
    "label.repeat_new_password": "Wiederholung des neuen Passworts",
    "label.register_no_account": "Noch kein Konto? Jetzt registrieren",
    "label.register": "Registrieren",
    "label.forgot_password": "Haben Sie Ihr Passwort vergessen?",
    "label.general_conditions": "AGB´s und Konditionen",
    "label.jobs": "Jobs bei Outspot",
    "label.faq": "Häufig gestellte Fragen",
    "label.returns": "Rücksendungen",
    "label.travel_conditions": "Besondere Reisebedingungen",
    "label.privacy_policy": "Erklärung zum Datenschutz",
    "label.cookie_policy": "Cookie-Anweisung",
    "label.subscribe": "Anmelden",
    "label.all_deals": "Alle Angebote",
    "label.other_deals": "Andere Angebote",
    "label.discount": "Rabatt",
    "label.close": "Schließen",
    "label.order_now": "Jetzt bestellen",
    "label.add": "hinzufügen",
    "label.book": "Jetzt buchen",
    "label.upselling_next_step": "Vergessen Sie später auch das nicht",
    "label.upselling": "Vergessen Sie auch das nicht",
    "label.also_available": "Ebenfalls verfügbar",
    "label.reviews": "Was unsere Kunden sagen",
    "label.automatically_translated": "Automatisch übersetzt",
    "label.show_all_reviews": "Alle Bewertungen sehen",
    "label.back": "Zurück",
    "label.make_your_choice": "Treffen Sie Ihre Wahl",
    "label.sold_out": "Ausverkauft",
    "label.order_more": "Mehr bestellen",
    "label.subtotal": "Zwischensumme",
    "label.total_shipping": "Versandkosten ",
    "label.total_file": "Bearbeitungsgebühren",
    "label.total_payable": "Insgesamt",
    "label.no_options_selected": "Ihre Bestellung ist derzeit leer. Klicken Sie unten auf \"Hinzufügen\", um fortzufahren.",
    "label.no_options_selected_extra": "Sie können diese Option nur beim Kauf des Produkts auswählen.",
    "label.total_saved_prefix": "(Sie sparen ",
    "label.total_saved_suffix": ")",
    "label.continue": "Weiter",
    "label.usp_quality": "Ausgewählt nach Preis und Qualität",
    "label.usp_discounts": "Erhebliche Rabatte von bis zu -70%",
    "label.usp_payment_methods": "Sicher und einfach bezahlen mit",
    "label.person": "Person",
    "label.free": "Kostenlos",
    "label.first_name": "Vornamen",
    "label.last_name": "Nachname",
    "label.birthdate": "Geburtsdatum",
    "label.birthdate_why": "Warum muss ich mein Geburtsdatum eingeben?",
    "label.birthdate_why_text": "Wir können Ihnen an Ihrem Geburtstag einen Gutschein auf der Grundlage Ihres Geburtsdatums zusenden.",
    "label.email": "E-Mail Adresse",
    "label.phone": "Telefonnummer",
    "label.address_finder": "Adressfinder",
    "label.address_finder_help": "Geben Sie Ihre vollständige Lieferadresse ein.",
    "label.select": "Auswählen ",
    "label.street": "Straße",
    "label.building": "",
    "label.number": "Hausnummer (optional: Wohnung,Stockwerk, P.O. box, etc.)",
    "label.zip": "Postleitzahl",
    "label.city": "Ort",
    "label.state": "",
    "label.country": "Land",
    "label.finish_order": "Bestellen & bezahlen",
    "label.finish_order_help": "Klicken Sie auf die Schaltfläche \"Bestellen & bezahlen\", um Ihre Zahlung abzuschließen.",
    "label.enter_address": "Meine Adresse manuell eingeben",
    "label.add_address": "Adresse hinzufügen",
    "label.add_payment_method": "Zahlungsmethode hinzufügen",
    "label.year": "JJJJ",
    "label.month": "MM",
    "label.day": "DD",
    "label.business_order": "geschäftliche Bestellung",
    "label.company_name": "Name des Unternehmens",
    "label.tax_id": "Umsatzsteueridentifikationsnummer",
    "label.invoice_reason": "Grund",
    "label.invoice_reasons": "Firmengeschenke,Teambuilding,Incentives,Seminar,Sonstiges",
    "label.delete": "entfernen",
    "label.i_have_coupons": "Ich habe Gutscheine",
    "label.email_outspot_plus": "Sie sind bereits Outspot PLUS-Kunde? Dann geben Sie Ihre E-Mail-Adresse ein.",
    "label.please_wait": "Bitte haben Sie Geduld...",
    "label.please_wait_2": "Nur noch wenig Geduld...",
    "label.please_wait_3": "Entschuldigung, es dauert etwas länger als erwartet...",
    "label.please_wait_4": "Nur noch ein bisschen, wir sind fast da...",
    "label.please_wait_5": "Wir entschuldigen uns für die Unannehmlichkeiten und danken Ihnen für Ihre Geduld, wir sind fast fertig.",
    "label.continue_shopping": "Weiter Einkaufen",
    "label.my_code": "Mein Code",
    "label.validate": "Validieren",
    "label.total_coupons": "Total Gutscheine",
    "label.coupons": "Gutscheine",
    "label.total_payable_with_coupons_prefix": "(Noch zu zahlen ",
    "label.total_payable_with_coupons_suffix": ")",
    "label.rest_coupon_info": "Der zu zahlende Betrag ist geringer als die eingegebenen Gutscheine. Sie erhalten einen neuen Gutschein mit dem Restwert.",
    "label.contact_extra_info": "Bestellnummer, Rechnungsnummer oder Verwendungszweck",
    "label.contact": "Kontaktieren Sie uns ",
    "label.message": "Nachricht",
    "label.send": "Senden",
    "label.save": "Speichern",
    "label.my_outspot_plus": "Mein Outspot PLUS",
    "label.order_date_prefix": "Bestellt am",
    "label.order_date_suffix": "",
    "label.total_paid_with": "Insgesamt bezahlt mit {method}",
    "label.track_order": "Verfolgen Sie Ihre Bestellung mit",
    "label.return_order": "Rücksendung und/oder Meldung eines Defekts",
    "label.download_invoice": "Rechnung herunterladen",
    "label.ask_question": "Eine Frage stellen",
    "label.happy_hour_discount": "Happy-Hour-Vorteil",
    "label.outspot_plus_discount": "Outspot PLUS Vorteil",
    "label.cancel_membership": "Mitgliedschaft kündigen",
    "label.membership_start_date": "Datum des Beginns der Mitgliedschaft",
    "label.membership_price": "Preis für 3 Monate",
    "label.membership_next_payment": "Nächste Zahlung",
    "label.membership_end_date": "Enddatum der Mitgliedschaft",
    "label.total_savings": "Insgesamt eingesparte Versand- und Bearbeitungskosten",
    "label.confirm_cancel_membership": "Stornierung bestätigen",
    "label.continue_membership": "Nicht abbrechen",
    "label.valid_till_prefix": "Gültig bis",
    "label.valid_till_suffix": "",
    "label.card_payment": "Kreditkarte",
    "label.place_review": "Bewertung schreiben ",
    "label.name": "Name",
    "label.name_help": "wir zeigen diese in der Bewertung",
    "label.review": "Beurteilung ",
    "label.self_service": "",
    "label.update_cookie_preferences": "Meine Cookie-Einstellungen ändern",
    "label.more_info": "Mehr Informationen",
    "label.review_source": "Über  {source}",
    "label.amount": "Betrag",
    "label.iban": "IBAN",
    "label.bic": "BIC",
    "label.beneficiary": "Begünstigter",
    "label.structured_communication": "Strukturierte Mitteilung",
    "label.later": "Später",
    "label.enable_notifications": "Ja, ich möchte diese Benachrichtigung erhalten",
    "label.card_number": "Kreditkartenummer",
    "label.card_expiry": "Gültig bis",
    "label.card_cvc": "CVV",
    "label.cvc_info": "",
    "label.sales_tax": "",
    "label.calculating_sales_tax": "",
    "label.sales_tax_will_be_calculated": "",
    "label.ship_to_selected_address": "",
    "label.ship_to_service_point": "",
    "label.change_service_point": "",
    "label.choose_rooms": "",
    "label.room": "",
    "label.room_occupancy": "",
    "label.add_another_room": "",
    "label.number_of_adults": "",
    "label.number_of_adults_title": "",
    "label.number_of_children": "",
    "label.number_of_children_title": "",
    "label.choose_arrival_and_departure_date": "",
    "label.arrival_date": "",
    "label.select_arrival_date": "",
    "label.departure_date": "",
    "label.select_departure_date": "",
    "label.rate_type_pc": "",
    "label.rate_type_pcpd": "",
    "label.rate_type_pcpn": "",
    "label.rate_type_pp": "",
    "label.rate_type_pppd": "",
    "label.rate_type_pppn": "",
    "label.rate_type_pp_": "",
    "label.rate_type_pa": "",
    "label.rate_type_papd": "",
    "label.rate_type_papn": "",
    "label.rate_type_pa_": "",
    "label.choose_supplements": "",
    "label.opengds_invalid": "",
    "error.unknown": "Unbekannter Fehler, bitte versuchen Sie es später noch einmal.",
    "error.required": "{field} darf nicht leer sein.",
    "error.required_email": "{field} darf nicht leer sein und muss eine gültige E-Mail-Adresse sein.",
    "error.required_password": "{field} muss mindestens 8 Zeichen lang sein und mindestens einen Großbuchstaben, einen Kleinbuchstaben und eine Zahl enthalten.",
    "error.required_repeat_password": "{field} muss mit dem im Feld {field2} angegebenen Passwort übereinstimmen.",
    "error.payment": "Leider ist Ihre Zahlung fehlgeschlagen. Dies kann passieren, wenn es ein Problem mit den Zahlungsdaten gibt, die Zahlung storniert wurde, Ihr Zahlungslimit erreicht wurde oder aus anderen Gründen. Wenden Sie sich an Ihre Bank oder versuchen Sie eine andere Karte oder Zahlungsmethode.",
    "error.payment_creditcard": "Schade.Ihre Zahlung ist fehlgeschlagen. Bitte überprüfen Sie die untenstehenden Angaben und versuchen Sie es erneut.",
    "error.incomplete_order": "Wir können diesen Gutschein erst validieren, wenn Sie alle oben genannten Felder ausgefüllt haben.",
    "error.coupon_already_added": "Sie haben diesen Gutschein bereits hinzugefügt.",
    "error.invalid_coupon": "Dieser Gutschein ist ungültig.",
    "error.coupon_not_cumulative": "Dieser Gutschein kann nicht mit anderen Gutscheinen kombiniert werden.",
    "error.other_coupon_not_cumulative": "Der Gutschein, den Sie bereits hinzugefügt haben, kann nicht zusammen mit diesem Gutschein verwendet werden.",
    "error.invalid_username_or_password": "Die angegebene E-Mail-Adresse und/oder das Passwort sind ungültig.",
    "error.test_order": "Dies ist eine Testbestellung. Normalerweise würde der Kunde jetzt auf die Zahlungsseite gehen.",
    "error.required_agree": "Sie müssen unseren Allgemeinen Geschäftsbedingungen und unserer Datenschutzerklärung zustimmen.",
    "error.required_review_agree": "Sie müssen zustimmen, dass Ihre Bewertung auf unserer Website veröffentlicht werden darf.",
    "error.register": "Es gibt bereits ein Konto mit dieser E-Mail-Adresse. Klicken Sie auf \"Weiter\", um sich anzumelden oder verwenden Sie eine andere E-Mail-Adresse.",
    "error.email_in_use": "Es gibt bereits ein Konto mit dieser E-Mail Adresse. Bitte verwenden Sie eine andere E-Mail-Adresse.",
    "error.already_reviewed": "Sie haben bereits eine Bewertung für diese Bestellung abgegeben.",
    "error.zip_blocked": "Es ist unmöglich, zur ausgefüllten Postleitzahl zu senden.",
    "error.details": "Es scheint, dass ein oder mehrere Produkte, die Sie zu bestellen versuchen, nicht mehr auf Lager sind. Bitte kehren Sie zum vorherigen Schritt zurück, aktualisieren Sie Ihre Bestellung und versuchen Sie es erneut.",
    "error.incomplete_cvc": "CVV ist zu kurz.",
    "error.incomplete_date": "Das Ablaufdatum darf nicht leer sein.",
    "error.date_in_past": "Die Karte ist abgelaufen.",
    "error.invalid_expiration_year": "Die Karte ist abgelaufen.",
    "error.incomplete_card_number": "Die Kartennummer ist zu kurz.",
    "error.incorrect_card_number": "Die Kartennummer ist ungültig.",
    "unsubscription.your_mail_address": "Ihre E-Mail Adresse:",
    "unsubscription.sad_message": "Sie sind hiermit abgemeldet",
    "unsubscription.have_not_participated": "Haben Sie sich nie angemeldet?",
    "unsubscription.sad_message_2": "Es tut uns leid, dass Sie uns verlassen haben, aber Sie sind natürlich immer willkommen.",
    "unsubscription.resubscribe_question": "Haben Sie Ihre Meinung geändert?<span class='unsub-important-message'> Klicken Sie unten, um sich erneut für unseren Newsletter anzumelden:</span>",
    "unsubscription.form_message": "Warum verlassen Sie unsere Outspot-Familie? Ihre Antwort kann uns helfen, unsere Dienste zu verbessern.",
    "unsubscription.report_not_participated": "Missbrauch melden",
    "unsubscription.coreg_date_no_screenshot": "Sie haben am {date} an einer Aktion (<a href=\"{coreg_site}\">{coreg_site}</a>) teilgenommen und so Genehmigung erteilt zum Erhalt unseres Newsletters mit Toppangeboten zu Sonderpreisen.",
    "unsubscription.coreg_date_no_coreg_data": "Sie haben am {date} an untengenannter Aktion teilgenommen und so Genehmigung erteilt zum Erhalt unseres Newsletters mit Toppangeboten zu Sonderpreisen.",
    "unsubscription.coreg_date_screenshot": "Sie haben am {date} an untengenannter Aktion teilgenommen und so Genehmigung erteilt zum Erhalt unseres Newsletters mit Toppangeboten zu Sonderpreisen.",
    "unsubscription.coreg_no_date_no_screenshot": "Sie haben an untengenannter Aktion teilgenommen und so Genehmigung erteilt zum Erhalt unseres Newsletters mit Toppangeboten zu Sonderpreisen.",
    "unsubscription.coreg_no_date_screenshot": "Sie haben an untengenannter Aktion teilgenommen und so Genehmigung erteilt zum Erhalt unseres Newsletters mit Toppangeboten zu Sonderpreisen.",
    "unsubscription.main_title": "Vom Newsletter abmelden",
    "unsubscription.report_title": "Einen Missbrauch melden",
    "unsubscription.report_sent_message": "Vielen Dank, wir haben Ihre Mitteilung erhalten.",
    "claim_coupon.welcome_message": "Speziell für dich",
    "claim_coupon.info_line_message": "Geben Sie den oben stehenden persönlichen Code an der Kasse ein.",
    "claim_coupon.info_line_message_bis": "Dieser Gutschein wurde Ihnen auch per E-Mail zugesandt.",
    "claim_coupon.main_title": "Ihr Gutschein",
    "about.title": "Über uns",
    "about.intro": "Willkommen bei Outspot, Ihrem Reiseziel Nummer eins für unwiderstehliche Angebote! Seit seiner Gründung im Jahr 2008 ist Outspot hat sich zur führenden europäischen Flash-Deal-Website entwickelt. Outspot bietet tägliche Rabatte auf Qualitätsprodukte und Reisen, wodurch Sie als Kunde enorm sparen können. Dank unseres Lagers in Europa liefern wir unsere Pakete so schnell wie möglich bei Ihnen zu Hause.",
    "about.mission_title": "Mission",
    "about.mission_text": "Bei Outspot glauben wir daran, es zugänglich zu machen außergewöhnliche Angebote für jedermann. Unser Ziel ist es, unser zu machen Wir bieten unseren Kunden durch Sorgfalt den bestmöglichen Wert Wir bieten ausgewählte Angebote zu konkurrenzlosen Preisen an.",
    "about.history_title": "Geschichte",
    "about.history_text": "Das Outspot-Abenteuer begann in Belgien, wo sich auch heute noch der Hauptsitz befindet. Mit einem engagierten Team von 25 Mitarbeitern, sind wir über ganz Europa verteilt und arbeiten jeden Tag daran die besten Angebote anzubieten. Mittlerweile sind wir in 13 Ländern aktiv und haben täglich 100.000 zufriedene Besucher.",
    "about.quality_title": "Unser Angebot wurde nach Preis und Qualität ausgewählt",
    "about.quality_text": "Wir bei Outspot glauben, dass Sie das Beste aus beiden Welten verdienen: Top-Qualität zu einem erschwinglichen Preis. Deshalb gehen wir jeden Tag wieder auf die Suche nach den attraktivsten Angeboten für Sie.  Wir stellen sicher, dass wir eng mit unseren Lieferanten zusammenarbeiten die besten Preise aushandeln zu können, ohne Zugeständnisse zu machen zur Qualität. <br /><br />  Jedes Produkt und jede Reise, die wir anbieten, ist sorgfältig gewählt. Die Auswahl erfolgt auf der Grundlage einer strengen Preis- und Leistungsbewertung. So können Sie sicher sein, dass Sie bei uns immer das beste Preis-Qualität-Verhältnis  für Ihr Geld erhalten. Bei Outspot dreht sich alles um Ihre Vorteile und Zufriedenheit! Unten sehen Sie ein Foto des Klangs der Smartbell, die mit einem Dezibelmessgerät getestet wird.",
    "about.tested_title": "Alle Angebote wurden von unseren Outspot-Kollegen ausgiebig getestet",
    "about.tested_text": "Bei Outspot glauben wir daran, nur das Allerbeste zu liefern. Bevor ein Angebot auf unserer Website erscheint, wird es detailliert beschrieben und von unseren eigenen Outspot-Kollegen getestet . Sie lassen sich nicht nieder bei oberflächlichen Tests: Produkte werden komplett zerlegt und jede Komponente wird sorgfältig bewertet. Festigkeit, Geräuschpegel, Materialqualität und mehr.<br /><br />  Unsere Kollegen besuchen viele Hotels auch persönlich, um die Qualität der Unterkünfte zu rprüfen. So garantieren wir, dass alles unseren hohen Standards entspricht. Ob es ein ist Produkt oder eine Reise ist, Sie können sicher sein, dass Sie bei Outspot Ihren Einkauf immer mit Vertrauen tätigen können. <br /><br />  Auf dem Foto unten können Sie sehen, wie Kollegen Produkte herstellen, im Detail betrachten und testen: z.B. Stoffqualitätsprüfung.",
    "about.guarantee_title": "6 Monate Rückerstattungsgarantie auf die meisten Reisen",
    "about.guarantee_text": "Wenn Sie eine Reise buchen, möchten Sie sicher sein, dass Ihre Wahl die richtige ist und dass Ihre Investition gut angelegt ist. Aus diesem Grund\r bieten wir eine 6-monatige Erstattungsgarantie für die meisten unserer\r Reisen. Mit dieser Garantie können Sie beruhigt planen, denn Sie wissen, dass Sie jederzeit Anspruch auf eine Rückerstattung haben, sofern noch keine Buchung erfolgt ist. Dies gibt Ihnen die Freiheit, Ihre Entscheidungen flexibel zu treffen, ohne das Risiko zusätzlicher Kosten, bis eine endgültige Buchung erfolgt ist.",
    "about.returns_title": "30-tägiges Rückgaberecht für die meisten Produkte",
    "about.returns_text": "Wir verstehen, dass es manchmal Zeit braucht, um zu entscheiden, ob Sie ein Produkt behalten möchten. Deshalb bieten wir bei den meisten unserer Produkte ein 30-tägiges Rückgaberecht an. Es ist wichtig, dass das Produkt unbenutzt und ungeöffnet bleibt, um für eine Rückgabe in Frage zu kommen. Dies gibt Ihnen die Möglichkeit, Ihren Kauf in Ihrem eigenen Tempo zu überdenken, während wir für einen reibungslosen Rückgabeprozess sorgen. So können Sie ohne Sorgen entscheiden, solange das Produkt im Originalzustand bleibt.",
    "about.support_title": "Kundenservice auf Deustch",
    "about.support_text": "Bei Outspot steht die Kundenzufriedenheit an erster Stelle, und wir tun alles um sicherzustellen, dass Sie eine ausgezeichnete Erfahrung machen. Sollten Sie Fragen haben oder Hilfe bei Ihrer Bestellung benötigen, ist unser Kundenservice-Team immer für Sie da. Unsere deutschsprachigen Mitarbeiter stehen Ihnen zur Verfügung, um Sie schnell, freundlich und effizient zu unterstützen. Unser Ziel ist es, Ihnen auf persönliche und verständliche Art und Weise zu helfen, so dass Sie damit Sie Ihren Kauf unbesorgt genießen können. Ihre Zufriedenheit ist unsere Priorität, und wir sind hier, um sicherzustellen, dass Sie den vollen Nutzen aus unserem Service ziehen. ",
    "about.socials_title": "Bleiben Sie in Verbindung",
    "about.socials_text": " Bleiben Sie auf dem Laufenden über unsere neuesten Angebote und exklusiven Angebote, indem Sie sich für unseren Newsletter anmelden. Folgen Sie uns auf den sozialen Medien und teilen Sie Ihre Outspot-Erfahrungen mit uns!<br /><br />  Haben Sie Fragen oder Anmerkungen? Unser Helpdesk ist bereit, Ihnen Ihnen zu helfen. Bitte kontaktieren Sie uns über <a href=„{help}“>unsere Kontaktseite</a>.<br /><br />  Vielen Dank für Ihr Vertrauen in Outspot. Wir freuen uns darauf Sie immer wieder mit unseren fantastischen Angeboten zu überraschen! ",
    "vat.error_required": "Das Feld \"{field}\" darf bei einer geschäftlichen Bestellung nicht leer sein.",
    "vat.validation_message_format": "Das Feld \"{field}\" hat nicht das richtige Format.",
    "vat.help_title": "Umsatzsteuer-Identifikationsnummer (USt-IdNr.) of MwSt-Nummer",
    "vat.help_text": "Die deutsche Umsatzsteuernummer muss aus 11 Zeichen bestehen, zum Beispiel DE123456789.",
    "label.download": "Herunterladen",
    "error.download_pdf": "Beim Herunterladen der PDF-Datei ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.",
    "manage_address.title": "Meine Lieferadressen",
    "error.select": "Wählen Sie eine {field}",
    "manage_cards.title": "Meine Zahlungsmittel",
    "deal.quality_text_1": "Nach Preis und Qualität ausgewählt",
    "deal.quality_text_2": "",
    "deal.delivery_text_days": "Lieferfrist: {days} Werktage",
    "deal.delivery_text": "",
    "maintenance.going_offline": "In wenigen Minuten wird unsere Website wegen geplanter Wartungsarbeiten vorübergehend offline gehen. Schließen Sie Ihre Bestellung jetzt ab, da die Bestellung während der Wartungsarbeiten eine Zeit lang nicht möglich sein wird. ",
    "maintenance.offline": "Unsere Website ist derzeit wegen geplanter Wartungsarbeiten offline. Bitte versuchen Sie es in ein paar Minuten erneut - wir werden so schnell wie möglich wieder online sein! Als kleine Aufmerksamkeit schenken wir Ihnen GMJ2QPZ3 für 5 € Rabatt auf Ihren nächsten Einkauf.",
    "sustainability.carpark_title": "Unseren Fuhrpark umweltfreundlicher gestalten",
    "sustainability.carpark_text": "In unseren kontinuierlichen Bemühungen, unseren ökologischen Fußabdruck zu verringern, modernisieren wir unsere Firmenfahrzeuge. Bis 2026 streben wir eine vollständig elektrische Flotte an. Dieser Übergang ist ein entscheidender Schritt, um unsere Emissionen zu reduzieren und sauberere, grünere Transportlösungen zu fördern.",
    "sustainability.energy_title": "Büro mit Solarstrom",
    "sustainability.energy_text": "Nachhaltigkeit beginnt zu Hause – und für uns bedeutet das unser Büro. Wir haben 30 Solarpaneele installiert, die fast die Hälfte unseres Strombedarfs decken. Durch die Nutzung der Sonnenenergie verringern wir unsere Abhängigkeit von nicht-erneuerbaren Energien und reduzieren unseren CO₂-Ausstoß.",
    "sustainability.eco_packaging_title": "Umweltfreundliche Verpackungen",
    "sustainability.eco_packaging_text": "Wir sind uns der Auswirkungen von Verpackungsmüll auf die Umwelt bewusst. Daher bestehen all unsere Verpackungen aus 100 % recyclebarem Kunststoff. Diese Initiative hilft, einen nachhaltigeren Nutzungs- und Wiederverwertungskreislauf zu schaffen.",
    "sustainability.combined_packaging_title": "Gebündelter Versand",
    "sustainability.combined_packaging_text": "Um unsere Umweltbelastung weiter zu reduzieren, ermutigen wir unsere Kunden, Bestellungen zu bündeln. Durch die Zusammenfassung mehrerer Einkäufe in einer Lieferung verringern wir die Anzahl der Fahrten und damit die Emissionen und den ökologischen Fußabdruck.",
    "sustainability.title": "Nachhaltigkeit bei Outspot",
    "sustainability.vision_title": "Unsere Vision",
    "sustainability.vision_text": "Outspot ist sich seiner Verantwortung für die Umwelt bewusst. Während wir täglich Tausende von Menschen mit unserem Angebot erfreuen, ist ökologische und soziale Verantwortung in jeden Aspekt unserer Tätigkeit integriert. Wir glauben, dass wir durch proaktives Handeln heute die Gesundheit unseres Planeten und das Wohl zukünftiger Generationen positiv beeinflussen können.",
    "sustainability.action_title": "Machen Sie mit",
    "sustainability.action_text": "Wir laden Sie ein, sich unserer Nachhaltigkeits-Reise anzuschließen. Ob durch die Wahl umweltfreundlicher Produkte oder die Teilnahme an unseren Recyclingprogrammen – jeder kleine Beitrag zählt.",
    "sustainability.plant_text": "Bei Ihrem Einkauf können Sie für nur 1 € zusätzlich einen Baum pflanzen lassen. Dies hilft nicht nur, CO₂-Emissionen auszugleichen, sondern unterstützt auch die Aufforstung und eine bessere Zukunft für lokale Gemeinschaften.",
    "sustainability.treeplanters": "Wir arbeiten mit Tree-Planters zusammen, einer Organisation, die sich für intelligente und nachhaltige Aufforstung einsetzt. Sie pflanzen Bäume in sorgfältig ausgewählten Gebieten in Malawi, wo sie nicht nur die Natur wiederherstellen, sondern auch die lokale Bevölkerung durch zusätzliche Einkommen und Nahrungsressourcen unterstützen.",
    "sustainability.action_text_believe": "Bei Outspot glauben wir, dass Nachhaltigkeit eine gemeinsame Verantwortung ist. Gemeinsam mit unseren Kunden, Mitarbeitern und Partnern streben wir einen bewussten Umgang mit unserem Planeten an. Machen Sie mit und lassen Sie uns gemeinsam einen Unterschied bewirken!",
    "sustainability.plant_title": "Pflanzen Sie einen Baum – machen Sie den Unterschied",
    "sustainability.trees": "Bäume",
    "sustainability.tree": "Baum ",
    "deal.advice_price": "",
    "sustainability.checkbox_title": "Pflanze gemeinsam mit Outspot",
    "sustainability.checkbox_detail_text": "Wir laden dich ein, gemeinsam mit Outspot Bäume für {price} zu pflanzen."
  },
  "en": {
    "route.home": "https://www.outspot.co.uk/en/",
    "route.fallback_domain": "https://www2.outspot.co.uk",
    "language.code": "EN",
    "language.locale": "en-GB",
    "language.name": "United Kingdom",
    "language.flag": "gb",
    "language.date_input_format": "y,m,d",
    "language.currency": "GBP",
    "language.region": "eu",
    "countries.delivery": "GBR",
    "states.delivery": "",
    "tracking.aw_merchant_id": "756646459",
    "tracking.aw_feed_country": "UK",
    "countries.BEL": "Belgium",
    "countries.LUX": "Luxembourg",
    "countries.NLD": "Netherlands",
    "countries.FRA": "France",
    "countries.DEU": "Germany",
    "countries.AUT": "Austria",
    "countries.ITA": "Italy",
    "countries.ESP": "Spain",
    "countries.POL": "Poland",
    "countries.GBR": "United Kingdom",
    "countries.IRL": "Ireland",
    "countries.SWE": "Sweden",
    "countries.PRT": "Portugal",
    "countries.FIN": "Finland",
    "countries.USA": "",
    "states.AL": "",
    "states.AR": "",
    "states.AZ": "",
    "states.CA": "",
    "states.CO": "",
    "states.CT": "",
    "states.DE": "",
    "states.FL": "",
    "states.GA": "",
    "states.IA": "",
    "states.ID": "",
    "states.IL": "",
    "states.IN": "",
    "states.KS": "",
    "states.KY": "",
    "states.LA": "",
    "states.MA": "",
    "states.MD": "",
    "states.ME": "",
    "states.MI": "",
    "states.MN": "",
    "states.MO": "",
    "states.MS": "",
    "states.MT": "",
    "states.NC": "",
    "states.ND": "",
    "states.NE": "",
    "states.NH": "",
    "states.NJ": "",
    "states.NM": "",
    "states.NV": "",
    "states.NY": "",
    "states.OH": "",
    "states.OK": "",
    "states.OR": "",
    "states.PA": "",
    "states.RI": "",
    "states.SC": "",
    "states.SD": "",
    "states.TN": "",
    "states.TX": "",
    "states.UT": "",
    "states.VA": "",
    "states.VT": "",
    "states.WA": "",
    "states.WI": "",
    "states.WV": "",
    "states.WY": "",
    "trustpilot.businessunit_id": "5ecf71ad586e780001be1f3a",
    "trustpilot.show": "TRUE",
    "mollie.profile_id": "pfl_MFAHnTJBme",
    "mollie.locale": "en_GB",
    "facebook.url": "",
    "instagram.url": "",
    "linkedin.url": "https://www.linkedin.com/company/outspot/mycompany/",
    "header.hello": "Welcome",
    "header.hello_name": "Hi <b>{firstName}</b>",
    "subscribe.title": "Sign up for free and don't miss a single deal!",
    "subscribe.text": "Discover the best deals from Outspot. Sign up for free and enjoy exclusive and personalized discounts on unique products and experiences.",
    "subscribe.agree": "By subscribing to our newsletter, you agree to the <a href=\"{conditions}\" target=\"_blank\">general conditions</a> and <a href=\"{privacy}\" target=\"_blank\">privacy statement</a>.",
    "subscribe.sent": "Thank you! We have received your application correctly. You will receive an email shortly to confirm your registration.",
    "footer.follow": "Follow Us",
    "footer.payment_methods_logos": "visa,mastercard,amex,paypal,applepay",
    "footer.copyright": "Outspot — Sint-Martens-Latem, Belgium — Confinity NV",
    "home.new": "Latest deals",
    "home.ending": "These deals are about to expire",
    "home.other": "All deals",
    "countdown.prefix": "Only ",
    "countdown.days": "day|days",
    "countdown.hours": "hours",
    "countdown.minutes": "min",
    "countdown.seconds": "sec left",
    "countdown.days_compact": "d",
    "date.years": "{count} years ago|{count} years ago",
    "date.months": "{count} month ago|{count} months ago",
    "date.weeks": "{count} week ago|{count} weeks ago",
    "date.days": "{count} day ago|{count} days ago",
    "date.hours": "{count} hours ago|{count} hours ago",
    "date.minutes": "{count} minute ago|{count} minutes ago",
    "date.seconds": "Less than 1 minute ago",
    "countdown.suffix": "",
    "onboard.language_title": "Enjoy personalised deals deals with high discounts",
    "onboard.language_text": "Choose the country and language you wish to shop in.",
    "onboard.notifications_title": "Stay informed:",
    "onboard.notifications_usp_1": "The best and latest deals",
    "onboard.notifications_usp_2": "Updates on your orders",
    "onboard.notifications_usp_3": "Personalised discount codes & vouchers",
    "order.shipping_title": "Shipping",
    "order.shipping_text": "Due to rising transportation costs and environmental impact, we are forced to charge shipping fees. However, Outspot still guarantees you great deals where you still benefit. Moreover, your shipping cost does not increase when you purchase more items. If you are an Outspot PLUS member or you buy within the hour of your previous purchase, these costs will be deducted in the next step.",
    "order.thank_you": "Thank you for your order!",
    "order.overview": "Overview of your order",
    "order.thank_you_bank": "Thank you very much for your purchase. You will receive the transfer details in your inbox or in spam. Please transfer the amount as soon as possible and always within 7 days. You will receive the voucher by e-mail as soon as the payment reaches us and has been processed.",
    "order.thank_you_with_shipping": "Your purchase is complete. We will send your order to the address below. You will get your purchase confirmation in your inbox or in spam. Enjoy your order.",
    "order.thank_you_without_shipping": "Your purchase is complete. You will get your purchase confirmation in your mailbox or in junk mail. Enjoy your order.",
    "order.code": "Order number: {code}",
    "order.shipping_address": "Delivery address",
    "order.save_payment_info_title": "Save bank details for my next purchases.",
    "order.save_payment_info_text": "If you choose this option, you won’t have to enter your details every time. Your information will be sorted securely.",
    "order.shipped_to_service_point": "",
    "my_outspot_plus.title": "My Outspot PLUS",
    "my_outspot_plus.subtitle": "This page allows you to manage your Outspot PLUS membership.",
    "my_outspot_plus.overview": "Overview of your Outspot PLUS membership",
    "my_outspot_plus.cancel_are_you_sure": "Are you sure you want to cancel your membership?",
    "my_outspot_plus.cancel_you_saved_prefix": "You already saved",
    "my_outspot_plus.cancel_you_saved_suffix": "shipping and filing fees with your Outspot PLUS membership.",
    "my_outspot_plus.no_membership": "You do not currently have an Outspot PLUS membership.",
    "plus.title": "Outspot PLUS",
    "plus.popup_text": "With Outspot PLUS, you benefit from free shipping on all items and pay no filing fees. After every order, you will receive an email giving you the opportunity to become an Outspot PLUS member. However, you have to be quick because this promotion is only valid for 7 days. Don't forget to check your next purchase confirmation email.",
    "plus.name": "Outspot PLUS",
    "plus.highlight_1": "Enjoy hassle-free ordering with no shipping or filing charges. ",
    "plus.highlight_2": "Your subscription will automatically renew for {renewPrice} every three months. ",
    "plus.highlight_3": "You may cancel at any time. ",
    "plus.question_1": "What are the benefits of Outspot PLUS? ",
    "plus.answer_1": "With Outspot PLUS, you get free shipping on all items and pay no filing fees.  ",
    "plus.question_2": "How do I become an Outspot PLUS member?  ",
    "plus.answer_2": "After each order you will receive an e-mail giving you the opportunity to become an Outspot PLUS member. Be quick because this promotion is only valid for 7 days. If you decide to activate Outspot PLUS, the benefits will start immediately. This means that on your next order, you can immediately enjoy free shipping on all items and you won't have to pay a filing fee.  ",
    "plus.question_3": "How much does Outspot PLUS membership cost?",
    "plus.answer_3": "Outspot PLUS costs just {price} for the first 3 months. This is a one-time payment. After this period, your membership will automatically renew every 3 months for {renewPrice}. This allows you to save significantly on shipping and filing costs.  ",
    "plus.question_4": "When and how will my Outspot PLUS be renewed?\r  ",
    "plus.answer_4": "Outspot PLUS is automatically renewed every three months. To manage this, you can always go to <a href=\"{myOutspotPlus}\">MY PURCHASES</a>. The amount will be automatically deducted from your account every 3 months.",
    "plus.question_5": "How do I cancel my Outspot PLUS? Will I receive a refund?\r  ",
    "plus.answer_5": "You can cancel your Outspot PLUS membership at any time via: <a href=\"{myOutspotPlus}\">MY PURCHASES</a>. Please note that no refund will be issued for the months when your membership was active. However, if you decide to cancel Outspot PLUS, the next scheduled bill will not be charged.  ",
    "plus.thank_you": "Your purchase is complete. From now on, you can enjoy hassle-free ordering without shipping and handling fees. You can view and manage your membership through <a href=\"{myOutspotPlus}\">My Outspot PLUS</a>.\r  ",
    "my_coupons.title": "My vouchers",
    "my_coupons.subtitle": "Your vouchers are on this page.",
    "my_coupons.no_coupons": "You currently have no vouchers.",
    "deal.sold": "Already bought {sold} times",
    "deal.retour_label": "30-day returns for just £ 0.95",
    "deal.retour_title": "Revocation",
    "deal.retour_text": "Don't like your product after all? Contact our helpdesk within 30 days of receiving it and return it. And no need to give a reason! We will refund your purchase and you can order stress-free. Another great idea, from Outspot of course!",
    "deal.guarantee_label": "6 months refund guarantee",
    "deal.guarantee_title": "Unique : 100% refund guarantee",
    "deal.guarantee_text": "Unbelievable but true. You no longer have to hesitate when buying an experience for your friends/colleagues/family or yourself. If you change your mind afterwards, or if the recipient is not completely satisfied with the gift voucher, there is no problem.<br><br>With Outspot.uk you can cancel your purchase up to 6 months after ordering! You or the person who received the voucher can get a full refund within 6 months. This is applicable provided that the voucher has not been reserved, used or expired. This is exceptional as a guarantee and never seen. But that's just how Outspot works.<br><br> Please note that for all actions where the refund guarantee is not explicitly mentioned, the guarantee does not apply. This is because in some cases we cannot recover deliveries/tickets from our partner ourselves.",
    "deal.option_count": "{count} option|{count} options",
    "deal.campaign_number": "Campaign number",
    "deal.your_details": "Your details",
    "deal.payment_methods_title": "Payment method",
    "deal.shipping_methods_title": "",
    "deal.privacy": "By providing your details you consent to <a target='_blank' href='{privacy}'>our privacy policy</a>.",
    "deal.payment_methods_subtitle": "How would you like to pay for your order?",
    "deal.your_order": "Your order",
    "deal.from": "From",
    "help.title": "Need help?",
    "help.subtitle": "Let us know how we can help you.",
    "help.subject": "Your question via our website or app",
    "help.sent": "We have received your message correctly. You will get a reply from us within 4 business days at the latest.",
    "login.title": "Log in",
    "login.subtitle": "Welcome back! How would you like to log in?",
    "login.or": "or",
    "login.usps_title": "Why should I create an account?",
    "login.usps_title_cta": "Make it easier for yourself and log in now or create an account.",
    "login.usps_text": "Click on the \"Log in\" button at the top of the page to log in. If you don't have an account yet, you can also register on the login page. Having an Outspot account is 100% advantages:",
    "login.usp_manage_orders": "Viewing and tracking orders",
    "login.usp_remember_info": "Saving contact details for later",
    "login.usp_returns": "Reporting defects and requesting product returns",
    "login.usp_store_payments": "Save payment methods and pay faster",
    "login.short_cta": "<a href=\"{loginLink}\">Log in</a> or continue below as guest.",
    "forgot.title": "Request a new password",
    "forgot.subtitle": "Enter your email address below and we will send you a link to reset your password.",
    "forgot.sent": "We have sent you an email with a link to reset your password.",
    "password.title": "Change password",
    "password.subtitle": "Choose a new and secure password.",
    "password.updated": "Your password has been changed. You can now log in with your new password.",
    "register.title": "Register",
    "register.subtitle": "Create an account to make it even easier to order and track your orders.",
    "register.personal_info": "Personal details",
    "register.login_info": "Login details",
    "register.agree": "I agree to the <a href=\"/enl/help/general-conditions</a> and <a href=\"/enl/help/privacy\" target=\"_blank\">privacy statement</a>.",
    "register.sent": "You're almost there. We've sent you an email to activate your account.",
    "register.email_known": "Is your email address already registered with Outspot? <a href=\"{forgot}\">Click here</a> to request a new password. ",
    "my_orders.title": "My orders",
    "my_orders.subtitle": "This is an overview of all the orders you have placed at Outspot.",
    "my_orders.title_detail": "Details of your order",
    "my_travels.title": "My travels",
    "my_info.title": "My data",
    "my_info.subtitle": "Here you can edit your personal information",
    "my_info.sent": "Your information has been updated.",
    "notifications.title": "Newsletter and notifications",
    "notifications.subscription_mail": "How often would you like to receive our newsletter?",
    "notifications.subscription_notification": "How often would you like to receive notifications?",
    "notifications.frequency_0": "Never",
    "notifications.frequency_4": "Once a week",
    "notifications.frequency_56": "Twice a day",
    "notifications.sent": "Your preferences have been updated.",
    "combined_purchase_banner.text": "Pay no shipping fees for your subsequent orders during:",
    "plus_banner.text_welcome": "{firstName}, welcome to Outspot PLUS.",
    "plus_banner.text_welcome_anonimous": "Welcome to Outspot PLUS.",
    "plus_banner.text_savings_prefix": "{firstName}, you already saved",
    "plus_banner.text_savings_prefix_anonimous": "You already saved",
    "plus_banner.text_savings_suffix": "to shipping and filing fees through Outspot PLUS.",
    "cookie_banner.title": "Cookies",
    "cookie_banner.text": "This website uses cookies. Strictly necessary cookies ensure the website functions properly and cannot be refused. Functional, analytical & advertising cookies are used for functional, statistical, and marketing purposes, respectively. They are only downloaded if you consent to them. Read more in our <a href=\"{cookies}\" target=\"_blank\">cookie policy</a>.",
    "cookie_banner.decline": "Reject optional cookies",
    "cookie_banner.accept_all": "Accept all cookies",
    "cookie_banner.accept_selected": "Accept selected",
    "cookie_banner.settings": "Settings",
    "cookie_banner.functional_cookies_title": "Strictly necessary cookies",
    "cookie_banner.functional_cookies_text": "Cookies necessary for the basic functionality of the website.",
    "cookie_banner.analytical_cookies_title": "Analytical & functional cookies",
    "cookie_banner.analytical_cookies_text": "Cookies enable the website to provide additional features and personal settings as well as visits and traffic.",
    "cookie_banner.personal_cookies_title": "Advertising cookies",
    "cookie_banner.personal_cookies_text": "Cookies set by advertisers to build a profile of your interests without storing direct personal information.",
    "review.title": "Share it and win",
    "review.subtitle": "Post your review of your purchase below to be instantly entered into a draw to win a voucher (we draw one voucher every quarter).",
    "review.agree": "I agree that my review may be published on the website",
    "review.sent": "Thank you for your review. You now have a chance to win a voucher.",
    "review.title_thank_you": "Thank you. We appreciate your feedback.",
    "review.subtitle_thank_you": "Thanks to your feedback, we can make Outspot even better. Leave your review of your purchase below and have the chance to win a voucher (every quarter we draw a voucher).",
    "confirm.title": "Your registration is confirmed",
    "confirm.subtitle": "Your subscription to the newsletter is hereby confirmed. Thank you! To serve you even better, we offer you the opportunity to share the following data with us without any obligation.",
    "popup_widget.title": "The best deals, tested for you.",
    "popup_widget.usp_1": "Receive our best offers daily.",
    "popup_widget.usp_2": "Selected and tested for best price and quality.",
    "popup_widget.usp_3": "Substantial discounts of up to -70%.",
    "popup_widget.footer_1": "Subscribe to our newsletter for free now and save immediately.",
    "popup_widget.footer_2": "You can unsubscribe again at any time.",
    "promo.title": "Our top promos",
    "promo.countdown": "2024-12-31 23:59:59",
    "promo.empty": "We currently have no top promos. Go to our home page for our latest deals.",
    "stars.info_title": "Product rating",
    "stars.info_text": "This rating is an average of our own collected reviews and those from other parties.",
    "stars.counter": "1 review | {count} reviews",
    "label.accept_selected": "Accept selected",
    "label.edit": "Change",
    "label.edit_address": "Change address",
    "label.address": "Address",
    "label.accept_all": "Accept all",
    "label.account": "Account",
    "label.help": "Help",
    "label.my_orders": "My orders",
    "label.my_travels": "My travels",
    "label.my_coupons": "My vouchers",
    "label.my_info": "My data",
    "label.change_password": "Change password",
    "label.notifications": "Newsletter and notifications",
    "label.logout": "Log out",
    "label.login": "Log in",
    "label.login_have_account": "Already have an account? Log in",
    "label.login_with_facebook": "Log in with Facebook",
    "label.login_with_google": "Log in with Google",
    "label.login_with_apple": "Log in with Apple",
    "label.password": "Password",
    "label.new_password": "New password",
    "label.repeat_password": "Repeat password",
    "label.repeat_new_password": "Repeat new password",
    "label.register_no_account": "No account yet? Register now",
    "label.register": "Register",
    "label.forgot_password": "Forgotten your password?",
    "label.general_conditions": "General conditions",
    "label.jobs": "Jobs at Outspot",
    "label.faq": "Frequently Asked Questions",
    "label.returns": "Return",
    "label.travel_conditions": "Specific travel conditions",
    "label.privacy_policy": "Privacy Statement",
    "label.cookie_policy": "Cookie Statement",
    "label.subscribe": "Sign me up",
    "label.all_deals": "All deals",
    "label.other_deals": "Other deals",
    "label.discount": "OFF",
    "label.close": "Close",
    "label.order_now": "Buy now",
    "label.add": "Add",
    "label.book": "Book now",
    "label.upselling_next_step": "Also, don't forget this later",
    "label.upselling": "Also, don't forget this",
    "label.also_available": "Also available ",
    "label.reviews": "What our customers say",
    "label.automatically_translated": "Automatically translated",
    "label.show_all_reviews": "See all reviews",
    "label.back": "Back",
    "label.make_your_choice": "Make your choice",
    "label.sold_out": "Sold Out",
    "label.order_more": "Order more",
    "label.subtotal": "Subtotal",
    "label.total_shipping": "Shipping costs",
    "label.total_file": "File costs",
    "label.total_payable": "Total",
    "label.no_options_selected": "Your order is currently empty. Click \"Add\" below to continue.",
    "label.no_options_selected_extra": "You can only select this option when purchasing the product.",
    "label.total_saved_prefix": "(You save ",
    "label.total_saved_suffix": ")",
    "label.continue": "Continue",
    "label.usp_quality": "Selected on price and quality",
    "label.usp_discounts": "Hefty discounts of up to 70%",
    "label.usp_payment_methods": "Pay safely and easily with",
    "label.person": "Person",
    "label.free": "Free",
    "label.first_name": "First name",
    "label.last_name": "Surname",
    "label.birthdate": "Date of birth",
    "label.birthdate_why": "",
    "label.birthdate_why_text": "",
    "label.email": "Email address",
    "label.phone": "Phone number",
    "label.address_finder": "Address Finder",
    "label.address_finder_help": "Enter your full delivery address",
    "label.select": "Select",
    "label.street": "Street / road",
    "label.building": "",
    "label.number": "House number / name",
    "label.zip": "Postal code",
    "label.city": "Town / City",
    "label.state": "",
    "label.country": "Country",
    "label.finish_order": "Order & pay",
    "label.finish_order_help": "Click the \"Order & pay\" button to complete your payment.",
    "label.enter_address": "Enter my address manually",
    "label.add_address": "Add an address",
    "label.add_payment_method": "Add a method of payment",
    "label.year": "YYYY",
    "label.month": "MM",
    "label.day": "DD",
    "label.business_order": "Business order",
    "label.company_name": "Company name",
    "label.tax_id": "VAT number",
    "label.invoice_reason": "Reason",
    "label.invoice_reasons": "Corporate gifts,Team building,Incentives,Seminar,Other",
    "label.delete": "Remove",
    "label.i_have_coupons": "I have vouchers",
    "label.email_outspot_plus": "Outspot PLUS member? Enter your email address.",
    "label.please_wait": "Please be patient...",
    "label.please_wait_2": "Please bear with us a little longer...",
    "label.please_wait_3": "Sorry, it's taking a little longer than expected....",
    "label.please_wait_4": "Just a few more minutes, we're almost there....",
    "label.please_wait_5": "Apologies for the inconvenience and thank you for your patience. We are almost there....",
    "label.continue_shopping": "Continue shopping",
    "label.my_code": "My code",
    "label.validate": "Validate",
    "label.total_coupons": "Total vouchers",
    "label.coupons": "Vouchers",
    "label.total_payable_with_coupons_prefix": "(Still to be paid ",
    "label.total_payable_with_coupons_suffix": ")",
    "label.rest_coupon_info": "The amount payable is less than the vouchers entered. You will receive a new voucher with the remaining value.",
    "label.contact_extra_info": "Order number, voucher number or structured communication",
    "label.contact": "Contact us",
    "label.message": "Message",
    "label.send": "Send",
    "label.save": "Save",
    "label.my_outspot_plus": "My Outspot PLUS",
    "label.order_date_prefix": "Ordered on",
    "label.order_date_suffix": "",
    "label.total_paid_with": "Total paid with {method}",
    "label.track_order": "Track your order with",
    "label.return_order": "Return and/or report defect",
    "label.download_invoice": "Download invoice",
    "label.ask_question": "Ask a question",
    "label.happy_hour_discount": "Happy Hour benefit",
    "label.outspot_plus_discount": "Outspot PLUS advantage",
    "label.cancel_membership": "Cancel membership",
    "label.membership_start_date": "Membership start date",
    "label.membership_price": "Price for 3 months",
    "label.membership_next_payment": "Next payment",
    "label.membership_end_date": "Membership end date",
    "label.total_savings": "Total shipping and filing costs saved",
    "label.confirm_cancel_membership": "Confirm cancellation",
    "label.continue_membership": "Do not cancel",
    "label.valid_till_prefix": "Valid until",
    "label.valid_till_suffix": "",
    "label.card_payment": "Card Payment",
    "label.place_review": "Place review",
    "label.name": "Name",
    "label.name_help": "we show these in the assessment",
    "label.review": "Review",
    "label.self_service": "",
    "label.update_cookie_preferences": "Change my cookie preferences",
    "label.more_info": "More info",
    "label.review_source": "Via {source}",
    "label.amount": "Amount",
    "label.iban": "IBAN",
    "label.bic": "BIC",
    "label.beneficiary": "Beneficiary",
    "label.structured_communication": "Structured communication",
    "label.later": "Later",
    "label.enable_notifications": "Yes, I want to receive notifications",
    "label.card_number": "Card number",
    "label.card_expiry": "Expiry",
    "label.card_cvc": "CVV",
    "label.cvc_info": "",
    "label.sales_tax": "",
    "label.calculating_sales_tax": "",
    "label.sales_tax_will_be_calculated": "",
    "label.ship_to_selected_address": "",
    "label.ship_to_service_point": "",
    "label.change_service_point": "",
    "label.choose_rooms": "",
    "label.room": "",
    "label.room_occupancy": "",
    "label.add_another_room": "",
    "label.number_of_adults": "",
    "label.number_of_adults_title": "",
    "label.number_of_children": "",
    "label.number_of_children_title": "",
    "label.choose_arrival_and_departure_date": "",
    "label.arrival_date": "",
    "label.select_arrival_date": "",
    "label.departure_date": "",
    "label.select_departure_date": "",
    "label.rate_type_pc": "",
    "label.rate_type_pcpd": "",
    "label.rate_type_pcpn": "",
    "label.rate_type_pp": "",
    "label.rate_type_pppd": "",
    "label.rate_type_pppn": "",
    "label.rate_type_pp_": "",
    "label.rate_type_pa": "",
    "label.rate_type_papd": "",
    "label.rate_type_papn": "",
    "label.rate_type_pa_": "",
    "label.choose_supplements": "",
    "label.opengds_invalid": "",
    "error.unknown": "Unknown error, please try again later.",
    "error.required": "{field} cannot be empty.",
    "error.required_email": "{field} cannot be empty and must be a valid e-mail address.",
    "error.required_password": "{field} must be at least 8 characters long and contain at least one uppercase, one lowercase, and one number.",
    "error.required_repeat_password": "{field} must be equal to the password specified in the {field2} field .",
    "error.payment": "Unfortunately, your payment has failed. This can happen if there is a problem with the payment details, the payment has been cancelled, your payment limit has been reached or for other reasons. Please contact your bank or try another card or payment method.",
    "error.payment_creditcard": "Sorry. Your payment has failed. Please check the details below and try again.",
    "error.incomplete_order": "We cannot validate this voucher until you have completed all the fields above.",
    "error.coupon_already_added": "You have already added this coupon.",
    "error.invalid_coupon": "This voucher is invalid.",
    "error.coupon_not_cumulative": "You cannot use this voucher together with other vouchers.",
    "error.other_coupon_not_cumulative": "You cannot use the coupon you have already added together with this coupon.",
    "error.invalid_username_or_password": "The email address and/or password provided is invalid.",
    "error.test_order": "This is a test order. Normally, the customer would now go to the payment page.",
    "error.required_agree": "You must agree to our terms and conditions and privacy policy.",
    "error.required_review_agree": "You must agree to your review being published on our website.",
    "error.register": "There is already an account registered to this email address. Click \"Continue\" to log in or use another email address.",
    "error.email_in_use": "There is already an account registered to this email address. Please use a different email address.",
    "error.already_reviewed": "You have already posted a review for this order.",
    "error.zip_blocked": "It is not possible to ship to the specified postcode.",
    "error.details": "It seems that one or more products you are trying to order are no longer in stock. Please return to the previous step, update your order and try again.",
    "error.incomplete_cvc": "CVV is too short.",
    "error.incomplete_date": "Expiration date cannot be empty.",
    "error.date_in_past": "Card has expired.",
    "error.invalid_expiration_year": "Card has expired.",
    "error.incomplete_card_number": "Card number is too short.",
    "error.incorrect_card_number": "Card number is invalid.",
    "unsubscription.your_mail_address": "Your email address: ",
    "unsubscription.sad_message": "You are now unsubscribed.",
    "unsubscription.have_not_participated": "Have you never signed up? ",
    "unsubscription.sad_message_2": "We're sorry you've left us, but of course you're always welcome to come back.",
    "unsubscription.resubscribe_question": "Have you changed your mind? <span class='unsub-important-message'> Click below to subscribe to our newsletter again:</span>",
    "unsubscription.form_message": "Why are you leaving our Outspot family? Your answer can help us improve our services.",
    "unsubscription.report_not_participated": "Report abuse.",
    "unsubscription.coreg_date_no_screenshot": "You participated in a promotion on {date} (<a href=\"{coreg_site}\">{coreg_site}</a>) and thereby gave your consent to receive our newsletter with top offers at exceptional prices.",
    "unsubscription.coreg_date_no_coreg_data": "You participated in the promotion below on {date} and gave your consent to receive our newsletter with top offers at exceptional prices.",
    "unsubscription.coreg_date_screenshot": "You participated in the promotion below on {date} and gave your consent to receive our newsletter with top offers at exceptional prices.",
    "unsubscription.coreg_no_date_no_screenshot": "You participated in the promotion below and gave your consent to receive our newsletters with top offers at exceptional prices.",
    "unsubscription.coreg_no_date_screenshot": "You participated in the promotion below and gave your consent to receive our newsletters with top offers at exceptional prices.",
    "unsubscription.main_title": "Unsubscribe from the newsletter",
    "unsubscription.report_title": "Report abuse",
    "unsubscription.report_sent_message": "Thank you, we have received your notification.",
    "claim_coupon.welcome_message": "Just for you",
    "claim_coupon.info_line_message": "Enter this personal code at checkout.",
    "claim_coupon.info_line_message_bis": "We have also sent you this voucher by email.",
    "claim_coupon.main_title": "Your coupon",
    "about.title": "About us",
    "about.intro": "Welcome to Outspot, your number one destination for irresistible deals! Outspot was founded in 2008 and  has grown into the leading European flash deal website. Outspot offers daily discounts on quality products and travel, which allows customers to save a lot. Our warehouse in Europe helps us deliver our packages to you quickly.",
    "about.mission_title": "Mission",
    "about.mission_text": "At Outspot we believe in making  exceptional offers accessible to everyone. Our goal is to  offer customers the best possible value through carefully chosen deals at unbeatable prices.",
    "about.history_title": "History",
    "about.history_text": "The Outspot adventure started in Belgium, where our HQ is still located. We have a dedicated team of 25 employees throughout Europe, working each day to find and offer you the best deals. We are now present in 13 countries and we have 100,000 satisfied visitors every day.",
    "about.quality_title": "Our offer has been selected based on price and quality",
    "about.quality_text": "At Outspot we believe you deserve the best of both worlds: top quality at affordable prices. That's why  look for the most attractive deals for you every day. We make sure that we are working closely with our suppliers to negotiate the best prices without compromising on quality. <br /><br />  Every product and trip we offer is carefully selected using a rigorous price and quality evaluation criteria, sp you can be sure that you always get the best value for money with us. At Outspot, everything revolves around offering you benefits and satisfaction! Here is a photo of the smartbell sound being tested with a decibel meter.",
    "about.tested_title": "All deals have been tested extensively by our Outspot colleagues",
    "about.tested_text": "At Outspot we believe in delivering only the very best. Before a deal appears on our site, it is thoroughly tested by our Outspot colleagues. They don't make do with basic tests: all products are investigated and each component  (strength, noise level, material quality etc) is carefully assessed.<br /><br />  Our colleagues also personally visit many hotels to check the quality of  accommodation. This is how we guarantee that everything meets our high standards. Whether you are looking to purchase a product or a trip from Outspot, you can buy with confidence.<br /><br />  Here is a photo of our colleagues thoroughly assessing and testing products: e.g. fabric quality check.",
    "about.guarantee_title": "",
    "about.guarantee_text": "",
    "about.returns_title": "30 day return policy on most products",
    "about.returns_text": "We understand that it can sometimes take time to decide whether you want to keep a product. That’s why we offer a 30-day return policy on most of our products. It is important that the product remains unused and unopened to be eligible for a return. This gives you the chance to reconsider your purchase at your own pace, while we ensure a smooth return process. This way, you can decide with peace of mind, as long as the product stays in its original condition.",
    "about.support_title": "Customer service in English",
    "about.support_text": "At Outspot, customer satisfaction comes first, and we do everything we can to ensure you have an excellent experience. If you have any questions or need help with your order, please contact our customer service team. Our English-speaking agents are on-hand to help you and offer friendly and efficient support. We aim to please and offer you personalised help that you can understand, so that you you can enjoy your purchase stress-free. Your satisfaction is our top priority, and we are here to make sure you get the most out of our services.",
    "about.socials_title": "Stay connected",
    "about.socials_text": "Stay up to date with our latest deals and exclusive offers by signing up to our newsletter. Follow us on social media and share your Outspot experiences with us!<br /><br />  Do you have any questions or comments? Our helpdesk is on-hand to assist you. Contact us via <a href=\"{help}\">our contact page</a>.<br /><br />  Thank you for your trust in Outspot. We look forward to surprising you again and again with our fantastic offers!",
    "vat.error_required": "The field \"{field}\" must not be empty for a business order.",
    "vat.validation_message_format": "The field \"{field}\" is not in the correct format.",
    "vat.help_title": "VAT number",
    "vat.help_text": "The UK VAT number must consist of 11 digits, for example GB123456789.",
    "label.download": "Download",
    "error.download_pdf": "Something went wrong while downloading the PDF. Please try again later. ",
    "manage_address.title": "My delivery addresses",
    "error.select": "Select an {field}",
    "manage_cards.title": "My payment methods",
    "deal.quality_text_1": "Selected on price and quality",
    "deal.quality_text_2": "",
    "deal.delivery_text_days": "Delivery time: {days} working days",
    "deal.delivery_text": "",
    "maintenance.going_offline": "Our website will go temporarily offline for scheduled maintenance in a few minutes. Complete your order now, as you will not be able to place orders until the maintenance work is complete.",
    "maintenance.offline": "Our website is currently offline for scheduled maintenance. Please try again in a few minutes - we will be back online as soon as possible! As a gesture, we’re giving you GMJ2QPZ4 for £5 off your next purchase.",
    "sustainability.carpark_title": "",
    "sustainability.carpark_text": "",
    "sustainability.energy_title": "",
    "sustainability.energy_text": "",
    "sustainability.eco_packaging_title": "",
    "sustainability.eco_packaging_text": "",
    "sustainability.combined_packaging_title": "",
    "sustainability.combined_packaging_text": "",
    "sustainability.title": "",
    "sustainability.vision_title": "",
    "sustainability.vision_text": "",
    "sustainability.action_title": "",
    "sustainability.action_text": "",
    "sustainability.plant_text": "",
    "sustainability.treeplanters": "",
    "sustainability.action_text_believe": "",
    "sustainability.plant_title": "",
    "sustainability.trees": "",
    "sustainability.tree": "",
    "deal.advice_price": "",
    "sustainability.checkbox_title": "Plant together with Outspot",
    "sustainability.checkbox_detail_text": "We invite you to plant trees together with Outspot for  {price}."
  },
  "es": {
    "route.home": "https://www.outspot.es/es/",
    "route.fallback_domain": "https://www2.outspot.es",
    "language.code": "ES",
    "language.locale": "es-ES",
    "language.name": "España",
    "language.flag": "es",
    "language.date_input_format": "d,m,y",
    "language.currency": "EUR",
    "language.region": "eu",
    "countries.delivery": "ESP",
    "states.delivery": "",
    "tracking.aw_merchant_id": "764658970",
    "tracking.aw_feed_country": "ES",
    "countries.BEL": "Bélgica",
    "countries.LUX": "Luxemburgo",
    "countries.NLD": "Países Bajos",
    "countries.FRA": "Francia",
    "countries.DEU": "Alemania",
    "countries.AUT": "Austria",
    "countries.ITA": "Italia",
    "countries.ESP": "España",
    "countries.POL": "Polonia",
    "countries.GBR": "Reino Unido",
    "countries.IRL": "Irlanda",
    "countries.SWE": "Suecia",
    "countries.PRT": "Portugal",
    "countries.FIN": "Finlandia",
    "countries.USA": "",
    "states.AL": "",
    "states.AR": "",
    "states.AZ": "",
    "states.CA": "",
    "states.CO": "",
    "states.CT": "",
    "states.DE": "",
    "states.FL": "",
    "states.GA": "",
    "states.IA": "",
    "states.ID": "",
    "states.IL": "",
    "states.IN": "",
    "states.KS": "",
    "states.KY": "",
    "states.LA": "",
    "states.MA": "",
    "states.MD": "",
    "states.ME": "",
    "states.MI": "",
    "states.MN": "",
    "states.MO": "",
    "states.MS": "",
    "states.MT": "",
    "states.NC": "",
    "states.ND": "",
    "states.NE": "",
    "states.NH": "",
    "states.NJ": "",
    "states.NM": "",
    "states.NV": "",
    "states.NY": "",
    "states.OH": "",
    "states.OK": "",
    "states.OR": "",
    "states.PA": "",
    "states.RI": "",
    "states.SC": "",
    "states.SD": "",
    "states.TN": "",
    "states.TX": "",
    "states.UT": "",
    "states.VA": "",
    "states.VT": "",
    "states.WA": "",
    "states.WI": "",
    "states.WV": "",
    "states.WY": "",
    "trustpilot.businessunit_id": "54b8e9e30000ff00057cc6b3",
    "trustpilot.show": "FALSE",
    "mollie.profile_id": "pfl_hHzQGyS4Rh",
    "mollie.locale": "es_ES",
    "facebook.url": "",
    "instagram.url": "",
    "linkedin.url": "https://www.linkedin.com/company/outspot/mycompany/",
    "header.hello": "Bienvenida",
    "header.hello_name": "Hola <b>{firstName}</b>",
    "subscribe.title": "Regístrate gratis y no te pierdas ni una sola oferta.",
    "subscribe.text": "Descubre las mejores ofertas de Outspot. Regístrate gratis y disfruta de descuentos exclusivos y personalizados en productos y experiencias únicas.",
    "subscribe.agree": "Al suscribirse a nuestro boletín, acepta las <a href=\"{conditions}\" target=\"_blank\">condiciones generales</a> y la <a href=\"{privacy}\" target=\"_blank\">declaración de privacidad</a>.",
    "subscribe.sent": "Muchas gracias. Hemos recibido correctamente tu solicitud. En breve recibirás un correo electrónico para confirmar tu inscripción.",
    "footer.follow": "Síguenos",
    "footer.payment_methods_logos": "visa,mastercard,amex,paypal,applepay",
    "footer.copyright": "Outspot — Sint-Martens-Latem, Bélgica — Confinity NV",
    "home.new": "Últimas ofertas",
    "home.ending": "Ofertas a punto de acabar",
    "home.other": "Todas las ofertas",
    "countdown.prefix": "Todavía",
    "countdown.days": "day|days",
    "countdown.hours": "hora",
    "countdown.minutes": "min",
    "countdown.seconds": "seg",
    "countdown.days_compact": "d",
    "date.years": "hace {count} año|hace {count} año(s)",
    "date.months": "hace {count} mes|hace {count} mes(es)",
    "date.weeks": "hace {count} semana|hace {count} semanas",
    "date.days": "hace {count} día |hace {count} días",
    "date.hours": "hace {count} hora|hace {count} horas",
    "date.minutes": "hace {count} minuto|hace {count} minutos",
    "date.seconds": "Hace menos de 1 minuto",
    "countdown.suffix": "",
    "onboard.language_title": "Disfruta de descuentos personalizados deals met scherpe descuentos",
    "onboard.language_text": "Elige el país y el idioma en el que desees comprar:",
    "onboard.notifications_title": "Ponerte al día: ",
    "onboard.notifications_usp_1": "Las mejores y últimas promociones",
    "onboard.notifications_usp_2": "Actualizaciones de tus pedidos",
    "onboard.notifications_usp_3": "Códigos de descuentos personalizados & vales ",
    "order.shipping_title": "Envío",
    "order.shipping_text": "Debido al aumento de los costes de transporte y al impacto medioambiental, nos vemos obligados a cobrar los gastos de transporte. Sin embargo, Outspot te sigue garantizando precios de oferta fantásticos en los que sigues ganando de todas formas. Además, tu coste de transporte no aumenta cuando compras más piezas. Si eres miembro de Outspot PLUS o compras dentro de la hora de tu compra anterior, estos costes se deducirán en el paso siguiente.",
    "order.thank_you": "Gracias por tu pedido.",
    "order.overview": "Resumen de tu pedido",
    "order.thank_you_bank": "Gracias por tu compra. Recibirás los detalles de la transferencia en tu buzón de correo o en tu buzón de correo no deseado. Por favor, transfiere el importe lo antes posible y, en cualquier caso, en un plazo de 7 días. Recibirás el vale que has pedido por correo electrónico en cuanto el pago haya llegado y se haya procesado.",
    "order.thank_you_with_shipping": "Tu compra se ha completado. Te enviaremos tu pedido a la dirección indicada más abajo. Recibirás la confirmación de tu compra en tu buzón de correo o en tu buzón de correo no deseado. Disfruta de su pedido.",
    "order.thank_you_without_shipping": "Tu compra se ha completado. Recibirás la confirmación de compra en tu buzón de correo o en tu buzón de correo no deseado. Disfruta de tu pedido.",
    "order.code": "Número de pedido: {code}",
    "order.shipping_address": "Dirección de entrega",
    "order.save_payment_info_title": "Guardar los datos bancarios para mis próximas compras.",
    "order.save_payment_info_text": "Si eliges esta opción, no tendrás que introducir tus datos cada vez. Tu información se almacenará de forma segura.",
    "order.shipped_to_service_point": "",
    "my_outspot_plus.title": "Mi Outspot PLUS",
    "my_outspot_plus.subtitle": "En esta página puedes gestionar tu suscripción a Outspot PLUS.",
    "my_outspot_plus.overview": "Descripción general de tu suscripción a Outspot PLUS",
    "my_outspot_plus.cancel_are_you_sure": "¿Seguro que quieres darte de baja?",
    "my_outspot_plus.cancel_you_saved_prefix": "Ya has salvado",
    "my_outspot_plus.cancel_you_saved_suffix": "en gastos de envío y presentación con su afiliación a Outspot PLUS.",
    "my_outspot_plus.no_membership": "Actualmente no eres miembro de Outspot PLUS.",
    "plus.title": "Outspot PLUS",
    "plus.popup_text": "Con Outspot PLUS todo son ventajas. Tendrás envío gratis en todos tus pedidos y no pagarás las tasas en viajes. Después de cada pedido recibirás un correo electrónico dándote la oportunidad de unirte a Outspot PLUS. Sin embargo, tienes que ser rápido, ya que esta promoción sólo es válida durante 7 días. No olvides comprobar tu próximo correo electrónico de confirmación de compra.",
    "plus.name": "Outspot PLUS",
    "plus.highlight_1": "Disfruta de un pedido sin complicaciones, sin gastos de envío o de archivo.",
    "plus.highlight_2": "Tu suscripción se renovará automáticamente por {renewPrice} cada tres meses.",
    "plus.highlight_3": "Siempre es posible cancelarla.",
    "plus.question_1": "¿Cuáles son las ventajas de Outspot PLUS?",
    "plus.answer_1": "Con Outspot PLUS, te beneficias de envíos gratuitos en todos los artículos y no pagas gastos de expediente en todos los viajes.",
    "plus.question_2": "¿Cómo pasar a Outspot PLUS?",
    "plus.answer_2": "Después de cada pedido recibirás un e-mail con la posibilidad de convertirte en Outspot PLUS. No tardes en hacerlo porque esta promoción es válida sólo durante 7 días. Si decides activar Outspot PLUS, los beneficios empezarán inmediatamente. Esto significa que en tu próximo pedido, podrás disfrutar inmediatamente de envíos gratuitos en todos los artículos y no tendrás que pagar unos gastos de expediente para todos tus viajes.",
    "plus.question_3": "¿Cuánto cuesta Outspot PLUS?",
    "plus.answer_3": "Outspot PLUS cuesta sólo {price} para los 3 primeros meses. Se trata de un pago único. Después de este periodo, tu suscripción se renovará automáticamente cada 3 meses por {renewPrice}. Esto te permite ahorrar considerablemente en gastos de envío y de expediente..",
    "plus.question_4": "¿Cuándo y cómo se renovará mi Outspot PLUS?",
    "plus.answer_4": "Outspot PLUS se renueva automáticamente cada tres meses. Para gestionarlo, siempre puedes ir a <a href=\"{myOutspotPlus}\">Mis compras PLUS</a>. El importe se deducirá automáticamente de la cuenta elegida cada 3 meses.",
    "plus.question_5": "¿Cómo cancelo mi Outspot PLUS? ¿Recibiré un reembolso?",
    "plus.answer_5": "Puedes cancelar su suscripción a Outspot PLUS en cualquier momento a través de: <a href=\"{myOutspotPlus}\">Mi Outspot PLUS</a>. Es importante tener en cuenta que no se efectuará ningún reembolso por los meses en los que tu afiliación estuvo activa. Sin embargo, si decides cancelar Outspot PLUS, no se te cobrará la siguiente facturación programada.",
    "plus.thank_you": "Tu compra se ha completado. A partir de ahora, puedes disfrutar de pedidos sin preocupaciones sin gastos de envío y expediente. Puedes ver y gestionar tu membresía a través de <a href=\"{myOutspotPlus}\">Mi Outspot PLUS</a>.",
    "my_coupons.title": "Mis vales",
    "my_coupons.subtitle": "Puedes encontrar tus vales en esta página.",
    "my_coupons.no_coupons": "Actualmente no tienes vales.",
    "deal.sold": "Ya he comprado {sold} veces",
    "deal.retour_label": "Devoluciones en 30 días por sólo 0,95 €",
    "deal.retour_title": "Derecho de devolución",
    "deal.retour_text": "¿No te gusta el producto? Ponte en contacto con nuestro servicio de asistencia en un plazo de 30 días a partir de la fecha de recepción y devuelve el producto ¡ y por cualquier motivo! Te devolveremos el importe de tu compra y podrás realizar tu pedido sin preocupaciones. Otra gran idea, ¡de Outspot por supuesto!",
    "deal.guarantee_label": "Garantía de reembolso de 6 meses",
    "deal.guarantee_title": "Único: 100% garantía de reembolso",
    "deal.guarantee_text": "Increíble pero cierto. Ya no tienes que dudar a la hora de comprar une experiencia para tus amigos/compañeros/familiares o para ti mismo. Si cambias de opinión luego, o si el destinatario no está completamente satisfecho con el vale regalo, no hay ningún problema.<br><br>¡En Outspot.es puedes cancelar tu compra hasta 6 meses después de hacer el pedido! Tú o la persona que recibió el vale puede obtener un reembolso completo dentro de 6 meses. Esto, por supuesto, siempre y cuando el vale aún no haya sido reservado, utilizado o caducado. Esta garantía es excepcional y nunca vista. Pero así es Outspot.<br><br> Ten en cuenta que para todas las acciones en las que la garantía de reembolso no se menciona explícitamente, la garantía no se aplica. Esto se debe a que, en algunos casos, no podemos recuperar las entregas / entradas de nuestro socio nosotros mismos.",
    "deal.option_count": "{count} opción|{count} opciones",
    "deal.campaign_number": "Número de campaña",
    "deal.your_details": "Tus datos",
    "deal.payment_methods_title": "Forma de pago",
    "deal.shipping_methods_title": "",
    "deal.privacy": "Al introducir tus datos, estás de acuerdo con <a target='_blank' href='{privacy}'>nuestra declaración de privacidad</a>.",
    "deal.payment_methods_subtitle": "¿Cómo deseas pagar tu pedido?",
    "deal.your_order": "Tu pedido",
    "deal.from": "Desde",
    "help.title": "¿Necesitas ayuda?",
    "help.subtitle": "Díganos cómo podemos ayudarte.",
    "help.subject": "Tu pregunta a través de nuestro sitio web o aplicación",
    "help.sent": "Hemos recibido correctamente tu mensaje. Recibirás nuestra respuesta en un plazo máximo de 4 días laborables.",
    "login.title": "Conectarse",
    "login.subtitle": "¡Bienvenido de nuevo! ¿Cómo quieres conectarte?",
    "login.or": "o",
    "login.usps_title": "¿Por qué crear una cuenta?",
    "login.usps_title_cta": "Hazlo más fácil para ti e inicia sesión ahora o crea una cuenta.",
    "login.usps_text": "Haz clic en el botón \"Conectarme\" en la parte superior de la página para iniciar sesión. Si aún no tienes una cuenta, también puedes registrarte en la página de inicio de sesión. Tener una cuenta Outspot sólo tiene ventajas:",
    "login.usp_manage_orders": "Visualización y seguimiento de pedidos",
    "login.usp_remember_info": "Guardar los datos de contacto para más tarde",
    "login.usp_returns": "Informar de defectos y solicitar devoluciones de productos",
    "login.usp_store_payments": "Guardar métodos de pago y pagar más rápido",
    "login.short_cta": "<a href=\"{loginLink}\">Inicia sesión</a> o continúa abajo como invitado.",
    "forgot.title": "Solicita una nueva contraseña.",
    "forgot.subtitle": "Introduce tu dirección de correo electrónico y te enviaremos un enlace para restablecer tu contraseña.",
    "forgot.sent": "Te hemos enviado un correo electrónico con un enlace para restablecer tu contraseña.",
    "password.title": "Cambiar contraseña",
    "password.subtitle": "Elige una contraseña nueva y segura.",
    "password.updated": "Tu contraseña se ha modificado. A partir de ahora puedes iniciar sesión con tu nueva contraseña.",
    "register.title": "Registrarse",
    "register.subtitle": "Crea una cuenta para que los pedidos y el seguimiento sean aún más fáciles.",
    "register.personal_info": "Datos personales",
    "register.login_info": "Datos de conexión",
    "register.agree": "Acepto las <a href=\"/enl/help/general-conditions\" target=\"_blank\">condiciones generales</a> y <a href=\"/enl/help/privacy\" target=\"_blank\">declaración de privacidad</a>.",
    "register.sent": "Ya casi está. Te hemos enviado un correo electrónico para activar tu cuenta.",
    "register.email_known": "¿Tu dirección de correo electrónico ya es conocida por Outspot? <a href=\"{forgot}\">Haz clic aquí</a> para solicitar una contraseña.",
    "my_orders.title": "Mis pedidos",
    "my_orders.subtitle": "Este es un resumen de todos los pedidos que has realizado en Outspot.",
    "my_orders.title_detail": "Detalles de tu pedido.",
    "my_travels.title": "Mis viajes",
    "my_info.title": "Mis datos",
    "my_info.subtitle": "Aquí puede modificar tus datos personales.",
    "my_info.sent": "Tus datos han sido modificados.",
    "notifications.title": "Boletín y notificaciones",
    "notifications.subscription_mail": "¿Con qué frecuencia te gustaría recibir nuestro boletín informativo?",
    "notifications.subscription_notification": "¿Con qué frecuencia te gustaría recibir notificaciones?",
    "notifications.frequency_0": "Nunca",
    "notifications.frequency_4": "Una vez por semana",
    "notifications.frequency_56": "Dos veces al día",
    "notifications.sent": "Tus preferencias han sido actualizadas.",
    "combined_purchase_banner.text": "No pague gastos de envío o de expediente por tus pedidos siguientes durante:",
    "plus_banner.text_welcome": "{firstName}, bienvenido a Outspot PLUS.",
    "plus_banner.text_welcome_anonimous": "Bienvenido a Outspot PLUS.",
    "plus_banner.text_savings_prefix": "{firstName}, ya has salvado",
    "plus_banner.text_savings_prefix_anonimous": "Ya has salvado",
    "plus_banner.text_savings_suffix": "a los gastos de envío y de expediente a través de Outspot PLUS.",
    "cookie_banner.title": "Cookies",
    "cookie_banner.text": "Este sitio web utiliza cookies. Las cookies estrictamente necesarias aseguran que el sitio web funcione correctamente y no pueden ser rechazadas. Las cookies funcionales, analíticas y publicitarias se utilizan respectivamente con fines funcionales, estadísticos y de marketing. Solo se descargan si das tu consentimiento. Lee más en nuestra <a href=\"{cookies}\" target=\"_blank\">política de cookies</a>.",
    "cookie_banner.decline": "Rechazar cookies opcionales",
    "cookie_banner.accept_all": "Aceptar todas las cookies",
    "cookie_banner.accept_selected": "Aceptar seleccionadas",
    "cookie_banner.settings": "Configuración",
    "cookie_banner.functional_cookies_title": "Cookies estrictamente necesarias",
    "cookie_banner.functional_cookies_text": "Cookies necesarias para la funcionalidad básica del sitio web.",
    "cookie_banner.analytical_cookies_title": "Cookies analíticas y funcionales",
    "cookie_banner.analytical_cookies_text": "Las cookies permiten que el sitio web ofrezca funciones adicionales y configuraciones personales, así como visitas y tráfico.",
    "cookie_banner.personal_cookies_title": "Cookies publicitarias",
    "cookie_banner.personal_cookies_text": "Cookies establecidas por los anunciantes para crear un perfil de tus intereses sin almacenar información personal directa.",
    "review.title": "Habla de ello y gana",
    "review.subtitle": "Publica a continuación tu opinión sobre tu compra y tendrás la oportunidad instantánea de ganar un vale de 250 euros (sorteamos un vale de este tipo cada trimestre).",
    "review.agree": "Acepto que mi reseña se publique en el sitio web",
    "review.sent": "Gracias por tu comentario. Ahora tiene la oportunidad de ganar un vale de 250 €.",
    "review.title_thank_you": "Gracias. Apreciamos tus comentarios.",
    "review.subtitle_thank_you": "Gracias a tus comentarios, podemos hacer que Outspot sea aún mejor. Deja tu opinión sobre tu compra a continuación y tendrás la oportunidad de ganar un vale de 250 € (cada trimestre sorteamos uno).",
    "confirm.title": "Su inscripción está confirmada",
    "confirm.subtitle": "Queda confirmada su suscripción al boletín. Muchas gracias. Para servirle aún mejor, le ofrecemos la oportunidad de compartir con nosotros los siguientes datos sin ningún compromiso.",
    "popup_widget.title": "Las mejores ofertas, probadas para ti.",
    "popup_widget.usp_1": "Recibe nuestras mejores ofertas cada día.",
    "popup_widget.usp_2": "Están seleccionadas y probadas para ofrecerte el mejor precio y la mejor calidad.",
    "popup_widget.usp_3": "Grandes descuentos hasta 70%.",
    "popup_widget.footer_1": "Suscríbete al boletín y ahorra inmediatamente.",
    "popup_widget.footer_2": "Puedes darte de baja en cada momento.",
    "promo.title": "Nuestras mejores promociones",
    "promo.countdown": "2024-12-31 23:59:59",
    "promo.empty": "Actualmente, no tenemos promociones. Visita nuestra página de inicio para ver nuestras últimas ofertas.",
    "stars.info_title": "Puntuación del producto",
    "stars.info_text": "Esta puntuación es un promedio de nuestras propias reseñas recopiladas y las de otras partes.",
    "stars.counter": "1 reseña | {count} reseñas",
    "label.accept_selected": "Aceptar seleccionado",
    "label.edit": "Cambiar",
    "label.edit_address": "Cambiar dirección",
    "label.address": "Dirección",
    "label.accept_all": "Aceptar todo",
    "label.account": "Cuenta",
    "label.help": "Ayuda",
    "label.my_orders": "Mis pedidos",
    "label.my_travels": "Mis viajes",
    "label.my_coupons": "Mis vales",
    "label.my_info": "Mis datos",
    "label.change_password": "Cambiar contraseña",
    "label.notifications": "Boletín y notificaciones",
    "label.logout": "Cerrar sesión",
    "label.login": "Conectarse",
    "label.login_have_account": "¿Ya tienes una cuenta? Iniciar sesión",
    "label.login_with_facebook": "Iniciar sesión con Facebook",
    "label.login_with_google": "Iniciar sesión con Google",
    "label.login_with_apple": "Iniciar sesión con Apple",
    "label.password": "Contraseña",
    "label.new_password": "Nueva contraseña",
    "label.repeat_password": "Repetir contraseña",
    "label.repeat_new_password": "Repetir nueva contraseña",
    "label.register_no_account": "¿Aún no tienes cuenta? Regístrate ahora",
    "label.register": "Regístrate",
    "label.forgot_password": "¿Has olvidado tu contraseña?",
    "label.general_conditions": "Condiciones generales",
    "label.jobs": "Trabajar en Outspot",
    "label.faq": "Preguntas frecuentes",
    "label.returns": "Devolución",
    "label.travel_conditions": "Condiciones específicas de envio",
    "label.privacy_policy": "Declaración de confidencialidad",
    "label.cookie_policy": "Declaración sobre cookies",
    "label.subscribe": "Inscríbeme",
    "label.all_deals": "Todas las ofertas",
    "label.other_deals": "Otras ofertas",
    "label.discount": "Descuento",
    "label.close": "Cerrar",
    "label.order_now": "Compra ahora",
    "label.add": "Añade",
    "label.book": "Reserve ahora",
    "label.upselling_next_step": "Tampoco olvides esto más adelante",
    "label.upselling": "Tampoco olvides esto",
    "label.also_available": "También disponible.",
    "label.reviews": "La opinión de nuestros clientes",
    "label.automatically_translated": "Traducido automáticamente",
    "label.show_all_reviews": "Ver todas las opiniones",
    "label.back": "Volver",
    "label.make_your_choice": "Haga tu elección",
    "label.sold_out": "Agotado",
    "label.order_more": "Pedir más",
    "label.subtotal": "Subtotal",
    "label.total_shipping": "Gastos de envío",
    "label.total_file": "Gastos de expediente",
    "label.total_payable": "Total",
    "label.no_options_selected": "Tu pedido está actualmente vacío. Haz clic en \"Añade\" para continuar.",
    "label.no_options_selected_extra": "Sólo puedes seleccionar esta opción al comprar el producto.",
    "label.total_saved_prefix": "(Te ahorras ",
    "label.total_saved_suffix": ")",
    "label.continue": "Seguir",
    "label.usp_quality": "Seleccionados por precio y calidad",
    "label.usp_discounts": "Grandes descuentos de hasta el -70%.",
    "label.usp_payment_methods": "Paga de forma segura y sencilla con",
    "label.person": "Persona",
    "label.free": "Gratis",
    "label.first_name": "Nombre",
    "label.last_name": "Apellido",
    "label.birthdate": "Fecha de nacimiento",
    "label.birthdate_why": "",
    "label.birthdate_why_text": "",
    "label.email": "Dirección de correo electrónico",
    "label.phone": "Número de teléfono",
    "label.address_finder": "Buscador de direcciones",
    "label.address_finder_help": "Ingresa tu dirección de entrega completa",
    "label.select": "Selecciona",
    "label.street": "Calle",
    "label.building": "",
    "label.number": "N°/buzón",
    "label.zip": "Código postal",
    "label.city": "Municipio",
    "label.state": "",
    "label.country": "País",
    "label.finish_order": "Pedir & pagar",
    "label.finish_order_help": "Haz clic en el botón \"Pedir & pagar\" para completar tu pago.",
    "label.enter_address": "Introducir mi dirección manualmente",
    "label.add_address": "Añadir una dirección",
    "label.add_payment_method": "Añadir un método de pago",
    "label.year": "AAAA",
    "label.month": "MM",
    "label.day": "DD",
    "label.business_order": "Pedido empresarial",
    "label.company_name": "Nombre de la empresa",
    "label.tax_id": "Número de IVA",
    "label.invoice_reason": "Motivos",
    "label.invoice_reasons": "Regalos de empresa, Team building, Incentivos, Seminario, Otros",
    "label.delete": "Eliminar",
    "label.i_have_coupons": "Tengo vales",
    "label.email_outspot_plus": "¿Ya eres cliente de Outspot PLUS? Introduce tu dirección de correo electrónico.",
    "label.please_wait": "Por favor, ten paciencia...",
    "label.please_wait_2": "Sólo un poco más de paciencia...",
    "label.please_wait_3": "Lo siento, está tardando un poco más de lo esperado...",
    "label.please_wait_4": "Sólo un poco más, ya casi estamos listo...",
    "label.please_wait_5": "Disculpa la molestia y gracias por tu paciencia, ya casi estamos listo.",
    "label.continue_shopping": "Sigue comprando",
    "label.my_code": "Mi código",
    "label.validate": "Validar",
    "label.total_coupons": "Total de vales",
    "label.coupons": "Vales",
    "label.total_payable_with_coupons_prefix": "(Pendiente de pago ",
    "label.total_payable_with_coupons_suffix": ")",
    "label.rest_coupon_info": "El importe por pagar es inferior a los vales introducidos. Recibirás un nuevo vale con el valor restante.",
    "label.contact_extra_info": "Número de pedido, número de vale o comunicación estructurada",
    "label.contact": "Contáctanos",
    "label.message": "Mensaje",
    "label.send": "Enviar",
    "label.save": "Guardar",
    "label.my_outspot_plus": "Mi Outspot PLUS",
    "label.order_date_prefix": "Pedido el",
    "label.order_date_suffix": "",
    "label.total_paid_with": "Total pagado con {method}",
    "label.track_order": "Sigue tu pedido con",
    "label.return_order": "Devolver y/o notificar defecto",
    "label.download_invoice": "Descargar factura",
    "label.ask_question": "Hacer una pregunta",
    "label.happy_hour_discount": "Ventaja Hora Feliz",
    "label.outspot_plus_discount": "Ventaja Outspot PLUS",
    "label.cancel_membership": "Cancelar suscripción",
    "label.membership_start_date": "Fecha de inicio de la suscripción",
    "label.membership_price": "Precio por 3 meses",
    "label.membership_next_payment": "Próximo pago",
    "label.membership_end_date": "Fecha de finalización de la afiliación",
    "label.total_savings": "Ahorro total en gastos de envío y de expediente",
    "label.confirm_cancel_membership": "Confirmar anulación",
    "label.continue_membership": "No cancelar",
    "label.valid_till_prefix": "Válido hasta el",
    "label.valid_till_suffix": "",
    "label.card_payment": "Pago con tarjeta",
    "label.place_review": "Revisión de la ubicación",
    "label.name": "Nombre",
    "label.name_help": "los mostramos en la revisón",
    "label.review": "Consulte",
    "label.self_service": "",
    "label.update_cookie_preferences": "Cambiar mis preferencias de cookies",
    "label.more_info": "Más informaciones",
    "label.review_source": "A través de {source}",
    "label.amount": "Cantidad",
    "label.iban": "IBAN",
    "label.bic": "BIC",
    "label.beneficiary": "Beneficiario",
    "label.structured_communication": "Mensaje estructurado",
    "label.later": "Más tarde",
    "label.enable_notifications": "Si, quiero recibir notificaciones",
    "label.card_number": "Número de tarjeta",
    "label.card_expiry": "Fecha de caducidad",
    "label.card_cvc": "CVV",
    "label.cvc_info": "",
    "label.sales_tax": "",
    "label.calculating_sales_tax": "",
    "label.sales_tax_will_be_calculated": "",
    "label.ship_to_selected_address": "",
    "label.ship_to_service_point": "",
    "label.change_service_point": "",
    "label.choose_rooms": "",
    "label.room": "",
    "label.room_occupancy": "",
    "label.add_another_room": "",
    "label.number_of_adults": "",
    "label.number_of_adults_title": "",
    "label.number_of_children": "",
    "label.number_of_children_title": "",
    "label.choose_arrival_and_departure_date": "",
    "label.arrival_date": "",
    "label.select_arrival_date": "",
    "label.departure_date": "",
    "label.select_departure_date": "",
    "label.rate_type_pc": "",
    "label.rate_type_pcpd": "",
    "label.rate_type_pcpn": "",
    "label.rate_type_pp": "",
    "label.rate_type_pppd": "",
    "label.rate_type_pppn": "",
    "label.rate_type_pp_": "",
    "label.rate_type_pa": "",
    "label.rate_type_papd": "",
    "label.rate_type_papn": "",
    "label.rate_type_pa_": "",
    "label.choose_supplements": "",
    "label.opengds_invalid": "",
    "error.unknown": "Error desconocido, por favor inténtalo más tarde.",
    "error.required": "{field} no puede estar vacío.",
    "error.required_email": "{field} no puede estar vacío y debe ser una dirección de correo electrónico válida.",
    "error.required_password": "{field} debe tener al menos 8 caracteres y contener al menos una letra mayúscula, una minúscula y un número.",
    "error.required_repeat_password": "{field} debe ser igual a la contraseña especificada en el campo {field2}.",
    "error.payment": "Lamentablemente, tu pago ha fallado. Esto puede ocurrir si hay algún problema con los datos de pago, si se ha cancelado el pago, si se ha alcanzado el límite de pago o por otros motivos. Ponte en contacto con tu banco o prueba con otra tarjeta o método de pago.",
    "error.payment_creditcard": "Lástima. Tu pago ha fallado. Por favor, verifica los datos a continuación y vuelve a intentarlo.",
    "error.incomplete_order": "Sólo podremos validar este vale una vez que hayas rellenado todos los campos anteriores.",
    "error.coupon_already_added": "Ya has añadido este vale.",
    "error.invalid_coupon": "Este vale no es válido.",
    "error.coupon_not_cumulative": "No puedes utilizar este vale junto con otros vales.",
    "error.other_coupon_not_cumulative": "El vale que ya has añadido no puede utilizarse junto con este vale.",
    "error.invalid_username_or_password": "La dirección de correo electrónico y/o la contraseña proporcionada no son válidas.",
    "error.test_order": "Se trata de un pedido de prueba. Normalmente, el cliente iría ahora a la página de pago.",
    "error.required_agree": "Debes aceptar nuestros términos y condiciones y nuestra política de privacidad.",
    "error.required_review_agree": "Debes aceptar que tu reseña se publique en nuestro sitio web.",
    "error.register": "Ya existe una cuenta con esta dirección de correo electrónico. Haz clic en \"Continuar\" para iniciar sesión o utilizar otra dirección de correo electrónico.",
    "error.email_in_use": "Ya existe una cuenta con esta dirección de correo electrónico. Utiliza otra dirección de correo electrónico.",
    "error.already_reviewed": "Ya has publicado una opinión para este pedido.",
    "error.zip_blocked": "No es posible enviar un paquete al código postal introducido.",
    "error.details": "Parece que uno o más productos que está intentando pedir ya no están en stock. Por favor, vuelva al paso anterior, actualice su pedido e inténtelo de nuevo.",
    "error.incomplete_cvc": "CVV es demasiado corto.",
    "error.incomplete_date": "La fecha de vencimiento no puede estar vacía.",
    "error.date_in_past": "La tarjeta ha caducado.",
    "error.invalid_expiration_year": "La tarjeta ha caducado.",
    "error.incomplete_card_number": "El número de tarjeta es demasiado corto.",
    "error.incorrect_card_number": "El número de tarjeta no es válido.",
    "unsubscription.your_mail_address": "Tu dirección de correo electrónico:",
    "unsubscription.sad_message": "Ya no estás registrado en nuestro sitio.",
    "unsubscription.have_not_participated": "Si nunca te has registrado,",
    "unsubscription.sad_message_2": "Sentimos que te vayas, pero por supuesto siempre serás bienvenido.",
    "unsubscription.resubscribe_question": "¿Has cambiado de opinión?<span class='unsub-important-message'> Has clic a continuación para suscribirse de nuevo a nuestro boletín:</span>",
    "unsubscription.form_message": "¿Por qué abandonas la familia Outspot? Tu respuesta puede ayudarnos a mejorar nuestros servicios.",
    "unsubscription.report_not_participated": "le rogamos que denuncies este abuso.",
    "unsubscription.coreg_date_no_screenshot": "Participaste en una promoción (<a href=\"{coreg_site}\">{coreg_site}</a>) el {date} y de esta manera diste tu consentimiento para recibir nuestro boletín con las mejores ofertas a precios excepcionales.",
    "unsubscription.coreg_date_no_coreg_data": "Participaste en la promoción siguiente el {date} y diste tu consentimiento para recibir nuestro boletín con las mejores ofertas a precios excepcionales.",
    "unsubscription.coreg_date_screenshot": "Participaste en la promoción siguiente el {date} y diste tu consentimiento para recibir nuestro boletín con las mejores ofertas a precios excepcionales.",
    "unsubscription.coreg_no_date_no_screenshot": "Participaste en la promoción siguiente y diste tu consentimiento para recibir nuestro boletín con las mejores ofertas a precios excepcionales.",
    "unsubscription.coreg_no_date_screenshot": "Participaste en la promoción siguiente y diste tu consentimiento para recibir nuestro boletín con las mejores ofertas a precios excepcionales.",
    "unsubscription.main_title": "Darse de baja del boletín",
    "unsubscription.report_title": "Denunciar un abuso",
    "unsubscription.report_sent_message": "Gracias, recibimos tu notificación",
    "claim_coupon.welcome_message": "Especial para ti",
    "claim_coupon.info_line_message": "Ingresa tu código personal arriba al realizar el pago.",
    "claim_coupon.info_line_message_bis": "Este vale también te fue enviado por correo electrónico.",
    "claim_coupon.main_title": "Tu cupón",
    "about.title": "Sobre nosotros",
    "about.intro": "Bienvenido a Outspot, ¡tu destino número uno para ofertas irresistibles! Desde su fundación en 2008, Outspot ha crecido hasta convertirse en el principal sitio web europeo de ofertas flash. Outspot ofrece descuentos diarios en productos de calidad y viajes, lo que te permite, como cliente, conseguir grandes ahorros. Gracias a nuestro almacén en Europa, entregamos nuestros paquetes en tu casa lo antes posible.ido a Outspot, ¡tu destino número uno para ofertas irresistibles! Desde su fundación en 2008, Outspot ha crecido hasta convertirse en el principal sitio web europeo de ofertas flash.",
    "about.mission_title": "Mission",
    "about.mission_text": "En Outspot, creemos que las ofertas excepcionales deben estar al alcance de todos. Nuestro objetivo es proporcionar a nuestros clientes el mejor valor posible ofreciendo ofertas cuidadosamente seleccionadas a precios imbatibles.",
    "about.history_title": "Historia",
    "about.history_text": "La aventura de Outspot comenzó en Bélgica, donde la sede central sigue estando. Con un equipo dedicado de 25 empleados repartidos por toda Europa, trabajamos a diario para encontrar y ofrecer las mejores ofertas. Mientras tanto, estamos activos en 13 países y tenemos 100.000 visitantes satisfechos cada día",
    "about.quality_title": "Nuestra oferta está seleccionada en base a precio y calidad.",
    "about.quality_text": "En Outspot, creemos que te mereces lo mejor de ambos mundos: máxima calidad a un precio asequible. Por eso cada día seguimos buscando las ofertas más atractivas para ti. A través de una estrecha colaboración con nuestros proveedores, nos aseguramos de negociar los mejores precios, sin comprometer la calidad. <br /><br />  Cada producto y viaje que ofrecemos es cuidadosamente seleccionado gracias a una rigurosa evaluación de precio y calidad.  Así que puedes estar seguro de que con nosotros siempre obtendrás la mejor relación calidad-precio. ¡En Outspot, todo gira en torno a tu beneficio y satisfacción! ",
    "about.tested_title": "",
    "about.tested_text": "«En Outspot, creemos en ofrecer sólo lo mejor. Antes de que una oferta aparezca en nuestro sitio,es sometida a pruebas exhaustivas por nuestros propios colegas de Outspot. por nuestros propios compañeros de Outspot. No se conforman con pruebas superficiales: los productos se desmontan por completo y cada componente se evalúa cuidadosamente en cuanto a robustez, nivel de ruido, calidad del material y mucho más.<br /><br />  Nuestros colegas también visitan personalmente muchos hoteles para comprobar la calidad de los alojamientos.  De este modo, garantizamos que todo cumple nuestros elevados requisitos. Tanto si se trata de un producto o un viaje, puedes estar seguro de que en Outspot siempre haces tu compra con confianza.<br /><br />  Por ejemplo, en la imagen de abajo puedes ver cómo los compañeros inspeccionan y prueban los productos en detalle: por ejemplo, la comprobación de la calidad del tejido».",
    "about.guarantee_title": "",
    "about.guarantee_text": "",
    "about.returns_title": "Política de devolución de 30 días en la mayoría de los productos.",
    "about.returns_text": "Entendemos que a veces puede tomar tiempo decidir si quieres quedarte con un producto. Por eso, ofrecemos un derecho de devolución de 30 días en la mayoría de nuestros productos. Es importante que el producto permanezca sin usar y sin abrir para ser elegible para una devolución. Esto te da la oportunidad de reconsiderar tu compra a tu propio ritmo, mientras nosotros nos encargamos de un proceso de devolución sin problemas. Así puedes decidir sin preocupaciones, siempre que el producto se mantenga en su estado original.",
    "about.support_title": "El servicio de atención al cliente te ayudará en español.",
    "about.support_text": "En Outspot, la satisfacción del cliente es primordial, y hacemos todo lo posible para asegurarnos de que tengas una experiencia excelente. Si tienes alguna pregunta o necesitas ayuda con tu pedido, nuestro equipo de atención al cliente está siempre a tu disposición. Nuestro personal de habla española está disponible para proporcionarte una asistencia rápida, amable y eficaz. Nuestro objetivo es ayudarte de forma personal y comprensible, para que puedas disfrutar de tu compra sin preocupaciones. Tu satisfacción es nuestra prioridad, y estamos aquí para asegurarnos de que aproveches nuestro serviciode forma optimal,",
    "about.socials_title": "Mantente conectado",
    "about.socials_text": "Mantente al día de nuestras últimas ofertas exclusivas suscribiéndote a nuestro boletín. Síguenos en las redes sociales y comparte con nosotros tus experiencias con Outspot<br /><br />  ¿Tienes alguna pregunta o comentario? Nuestro servicio de asistencia está a tu disposición para ayudarte. Ponte en contacto con nosotros a través de <a href=\"{help}\">nuestra página de contacto</a>.<br /><br />  Gracias por tu confianza en Outspot. ¡Esperamos sorprenderte una y otra vez con nuestras fantásticas ofertas!",
    "vat.error_required": "El campo \"{field}\" no puede estar vacío en un pedido empresarial.",
    "vat.validation_message_format": "El campo \"{field}\" no tiene el formato correcto.",
    "vat.help_title": "Número de IVA",
    "vat.help_text": "El número de IVA en España debe consistir en 11 caracteres, por ejemplo ES123456789.",
    "label.download": "Descargar",
    "error.download_pdf": "Algo ha ido mal al descargar el PDF. Vuelve a intentarlo más tarde.",
    "manage_address.title": "Mis direcciones de entrega ",
    "error.select": "Seleccione una {field}",
    "manage_cards.title": "Mis formas de pago",
    "deal.quality_text_1": "Seleccionado según su relación calidad-precio",
    "deal.quality_text_2": "",
    "deal.delivery_text_days": "Plazo de entrega: {days} días laborables",
    "deal.delivery_text": "",
    "maintenance.going_offline": "En unos minutos, nuestro sitio web estará temporalmente fuera de servicio por mantenimiento programado. Completa tuu pedido ahora, ya que no será posible realizar pedidos durante un tiempo mientras dure el mantenimiento.",
    "maintenance.offline": "Nuestro sitio web está actualmente fuera de servicio por mantenimiento programado. Por favor, inténtelo de nuevo en unos minutos - ¡volveremos a estar en línea tan pronto como sea posible! Como un pequeño detalle, te damos GMJ2QPZ3  para un descuento de 5 € en tu próxima compra.",
    "sustainability.carpark_title": "Haciendo nuestra flota de vehículos más ecológica",
    "sustainability.carpark_text": "En nuestros esfuerzos continuos por reducir nuestra huella ecológica, estamos transformando nuestra flota de vehículos corporativos. Para 2026, aspiramos a tener una flota completamente eléctrica. Esta transición es un paso crucial para reducir emisiones y promover un transporte más limpio y sostenible.",
    "sustainability.energy_title": "Oficina alimentada por energía solar",
    "sustainability.energy_text": "La sostenibilidad empieza en casa, y para nosotros, eso significa nuestra oficina. Hemos instalado 30 paneles solares que generan casi la mitad de la electricidad que necesitamos. Al aprovechar la energía de sol, reducimos nuestra dependencia de fuentes de energía no renovables y disminuimos nuestras emisiones de CO₂.",
    "sustainability.eco_packaging_title": "Empaquetados ecológicos",
    "sustainability.eco_packaging_text": "Somos conscientes del impacto de los residuos de embalaje sobre el medio ambiente. Por eso, todos nuestros embalajes son de plástico 100% reciclable, fomentando un ciclo de uso y reutilización más sostenible.",
    "sustainability.combined_packaging_title": "Envíos consolidados",
    "sustainability.combined_packaging_text": "Para minimizar aún más nuestro impacto, incentivamos a los clientes a agrupar sus pedidos. Menos entregas significan menos emisiones y una huella ambiental reducida.",
    "sustainability.title": "Sostenibilidad en Outspot",
    "sustainability.vision_title": "Nuestra visión",
    "sustainability.vision_text": "Outspot integra la responsabilidad ecológica y social en cada aspecto de su actividad. Creemos que las acciones proactivas de hoy garantizan un planeta saludable para las futuras generaciones.",
    "sustainability.action_title": "Únete a nosotros",
    "sustainability.action_text": "",
    "sustainability.plant_text": "",
    "sustainability.treeplanters": "",
    "sustainability.action_text_believe": "",
    "sustainability.plant_title": "",
    "sustainability.trees": "",
    "sustainability.tree": "",
    "deal.advice_price": "",
    "sustainability.checkbox_title": "Planta junto con Outspot",
    "sustainability.checkbox_detail_text": "Te invitamos a plantar árboles junto con Outspot por  {price}."
  },
  "ie": {
    "route.home": "https://www.outspot.ie/ie/",
    "route.fallback_domain": "https://www2.outspot.ie",
    "language.code": "EN",
    "language.locale": "en-GB",
    "language.name": "Ireland",
    "language.flag": "ie",
    "language.date_input_format": "y,m,d",
    "language.currency": "EUR",
    "language.region": "eu",
    "countries.delivery": "IRL",
    "states.delivery": "",
    "tracking.aw_merchant_id": "TODO",
    "tracking.aw_feed_country": "IE",
    "countries.BEL": "Belgium",
    "countries.LUX": "Luxembourg",
    "countries.NLD": "Netherlands",
    "countries.FRA": "France",
    "countries.DEU": "Germany",
    "countries.AUT": "Austria",
    "countries.ITA": "Italy",
    "countries.ESP": "Spain",
    "countries.POL": "Poland",
    "countries.GBR": "United Kingdom",
    "countries.IRL": "Ireland",
    "countries.SWE": "Sweden",
    "countries.PRT": "Portugal",
    "countries.FIN": "Finland",
    "countries.USA": "",
    "states.AL": "",
    "states.AR": "",
    "states.AZ": "",
    "states.CA": "",
    "states.CO": "",
    "states.CT": "",
    "states.DE": "",
    "states.FL": "",
    "states.GA": "",
    "states.IA": "",
    "states.ID": "",
    "states.IL": "",
    "states.IN": "",
    "states.KS": "",
    "states.KY": "",
    "states.LA": "",
    "states.MA": "",
    "states.MD": "",
    "states.ME": "",
    "states.MI": "",
    "states.MN": "",
    "states.MO": "",
    "states.MS": "",
    "states.MT": "",
    "states.NC": "",
    "states.ND": "",
    "states.NE": "",
    "states.NH": "",
    "states.NJ": "",
    "states.NM": "",
    "states.NV": "",
    "states.NY": "",
    "states.OH": "",
    "states.OK": "",
    "states.OR": "",
    "states.PA": "",
    "states.RI": "",
    "states.SC": "",
    "states.SD": "",
    "states.TN": "",
    "states.TX": "",
    "states.UT": "",
    "states.VA": "",
    "states.VT": "",
    "states.WA": "",
    "states.WI": "",
    "states.WV": "",
    "states.WY": "",
    "trustpilot.businessunit_id": "604a2f9c68b4ed000165c8e6",
    "trustpilot.show": "FALSE",
    "mollie.profile_id": "pfl_kUCABMJyef",
    "mollie.locale": "en_GB",
    "facebook.url": "",
    "instagram.url": "",
    "linkedin.url": "https://www.linkedin.com/company/outspot/mycompany/",
    "header.hello": "Welcome",
    "header.hello_name": "Hi <b>{firstName}</b>",
    "subscribe.title": "Sign up for free and don't miss a single deal!",
    "subscribe.text": "Discover the best deals from Outspot. Sign up for free and enjoy exclusive and personalized discounts on unique products and experiences.",
    "subscribe.agree": "By subscribing to our newsletter, you agree to the <a href=\"{conditions}\" target=\"_blank\">general conditions</a> and <a href=\"{privacy}\" target=\"_blank\">privacy statement</a>.",
    "subscribe.sent": "Thank you! We have received your application correctly. You will receive an email shortly to confirm your registration.",
    "footer.follow": "Follow Us",
    "footer.payment_methods_logos": "visa,mastercard,amex,paypal,applepay",
    "footer.copyright": "Outspot — Sint-Martens-Latem, Belgium — Confinity NV",
    "home.new": "Latest deals",
    "home.ending": "These deals are about to expire",
    "home.other": "All deals",
    "countdown.prefix": "Only",
    "countdown.days": "day|days",
    "countdown.hours": "hours",
    "countdown.minutes": "min",
    "countdown.seconds": "sec left",
    "countdown.days_compact": "d",
    "date.years": "{count} years ago|{count} years ago",
    "date.months": "{count} month ago|{count} months ago",
    "date.weeks": "{count} week ago|{count} weeks ago",
    "date.days": "{count} day ago|{count} days ago",
    "date.hours": "{count} hours ago|{count} hours ago",
    "date.minutes": "{count} minute ago|{count} minutes ago",
    "date.seconds": "Less than 1 minute ago",
    "countdown.suffix": "",
    "onboard.language_title": "Enjoy personalised deals deals with high discounts",
    "onboard.language_text": "Choose the country and language you wish to shop in.",
    "onboard.notifications_title": "Stay informed:",
    "onboard.notifications_usp_1": "The best and latest deals",
    "onboard.notifications_usp_2": "Updates on your orders",
    "onboard.notifications_usp_3": "Personalised discount codes & vouchers",
    "order.shipping_title": "Shipping",
    "order.shipping_text": "Due to rising transportation costs and environmental impact, we are forced to charge shipping fees. However, Outspot still guarantees you great deals where you still benefit. Moreover, your shipping cost does not increase when you purchase more items. If you are an Outspot PLUS member or you buy within the hour of your previous purchase, these costs will be deducted in the next step.",
    "order.thank_you": "Thank you for your order!",
    "order.overview": "Overview of your order",
    "order.thank_you_bank": "Thank you very much for your purchase. You will receive the transfer details in your inbox or in spam. Please transfer the amount as soon as possible and always within 7 days. You will receive the voucher by e-mail as soon as the payment reaches us and has been processed.",
    "order.thank_you_with_shipping": "Your purchase is complete. We will send your order to the address below. You will get your purchase confirmation in your inbox or in spam. Enjoy your order.",
    "order.thank_you_without_shipping": "Your purchase is complete. You will get your purchase confirmation in your mailbox or in junk mail. Enjoy your order.",
    "order.code": "Order number: {code}",
    "order.shipping_address": "Delivery address",
    "order.save_payment_info_title": "Save bank details for my next purchases.",
    "order.save_payment_info_text": "If you choose this option, you won’t have to enter your details every time. Your information will be sorted securely.",
    "order.shipped_to_service_point": "",
    "my_outspot_plus.title": "My Outspot PLUS",
    "my_outspot_plus.subtitle": "This page allows you to manage your Outspot PLUS membership.",
    "my_outspot_plus.overview": "Overview of your Outspot PLUS membership",
    "my_outspot_plus.cancel_are_you_sure": "Are you sure you want to cancel your membership?",
    "my_outspot_plus.cancel_you_saved_prefix": "You already saved",
    "my_outspot_plus.cancel_you_saved_suffix": "shipping and filing fees with your Outspot PLUS membership.",
    "my_outspot_plus.no_membership": "You do not currently have an Outspot PLUS membership.",
    "plus.title": "Outspot PLUS",
    "plus.popup_text": "With Outspot PLUS, you benefit from free shipping on all items and pay no filing fees. After every order, you will receive an email giving you the opportunity to become an Outspot PLUS member. However, you have to be quick because this promotion is only valid for 7 days. Don't forget to check your next purchase confirmation email.",
    "plus.name": "Outspot PLUS",
    "plus.highlight_1": "Enjoy hassle-free ordering with no shipping or filing charges. ",
    "plus.highlight_2": "Your subscription will automatically renew for {renewPrice} every three months. ",
    "plus.highlight_3": "You may cancel at any time. ",
    "plus.question_1": "What are the benefits of Outspot PLUS? ",
    "plus.answer_1": "With Outspot PLUS, you get free shipping on all items and pay no filing fees.  ",
    "plus.question_2": "How do I become an Outspot PLUS member?  ",
    "plus.answer_2": "After each order you will receive an e-mail giving you the opportunity to become an Outspot PLUS member. Be quick because this promotion is only valid for 7 days. If you decide to activate Outspot PLUS, the benefits will start immediately. This means that on your next order, you can immediately enjoy free shipping on all items and you won't have to pay a filing fee.  ",
    "plus.question_3": "How much does Outspot PLUS membership cost?",
    "plus.answer_3": "Outspot PLUS costs just {price} for the first 3 months. This is a one-time payment. After this period, your membership will automatically renew every 3 months for {renewPrice}. This allows you to save significantly on shipping and filing costs.  ",
    "plus.question_4": "When and how will my Outspot PLUS be renewed?\r  ",
    "plus.answer_4": "Outspot PLUS is automatically renewed every three months. To manage this, you can always go to <a href=\"{myOutspotPlus}\">MY PURCHASES</a>. The amount will be automatically deducted from your account every 3 months.",
    "plus.question_5": "How do I cancel my Outspot PLUS? Will I receive a refund?\r  ",
    "plus.answer_5": "You can cancel your Outspot PLUS membership at any time via: <a href=\"{myOutspotPlus}\">MY PURCHASES</a>. Please note that no refund will be issued for the months when your membership was active. However, if you decide to cancel Outspot PLUS, the next scheduled bill will not be charged.  ",
    "plus.thank_you": "Your purchase is complete. From now on, you can enjoy hassle-free ordering without shipping and handling fees. You can view and manage your membership through <a href=\"{myOutspotPlus}\">My Outspot PLUS</a>.\r  ",
    "my_coupons.title": "My vouchers",
    "my_coupons.subtitle": "Your vouchers are on this page.",
    "my_coupons.no_coupons": "You currently have no vouchers.",
    "deal.sold": "Already bought {sold} times",
    "deal.retour_label": "30-day returns for just € 0.95",
    "deal.retour_title": "Revocation",
    "deal.retour_text": "Don't like your product after all? Contact our helpdesk within 30 days of receiving it and return it. And no need to give a reason! We will refund your purchase and you can order stress-free. Another great idea, from Outspot of course!",
    "deal.guarantee_label": "6 months refund guarantee",
    "deal.guarantee_title": "Unique : 100% refund guarantee",
    "deal.guarantee_text": "Unbelievable but true. You no longer have to hesitate when buying an experience for your friends/colleagues/family or yourself. If you change your mind afterwards, or if the recipient is not completely satisfied with the gift voucher, there is no problem.<br><br>With Outspot.ie you can cancel your purchase up to 6 months after ordering! You or the person who received the voucher can get a full refund within 6 months. This is applicable provided that the voucher has not been reserved, used or expired. This is exceptional as a guarantee and never seen. But that's just how Outspot works.<br><br> Please note that for all actions where the refund guarantee is not explicitly mentioned, the guarantee does not apply. This is because in some cases we cannot recover deliveries/tickets from our partner ourselves.",
    "deal.option_count": "{count} option|{count} options",
    "deal.campaign_number": "Campaign number",
    "deal.your_details": "Your details",
    "deal.payment_methods_title": "Payment method",
    "deal.shipping_methods_title": "",
    "deal.privacy": "By providing your details you consent to <a target='_blank' href='{privacy}'>our privacy policy</a>.",
    "deal.payment_methods_subtitle": "How would you like to pay for your order?",
    "deal.your_order": "Your order",
    "deal.from": "From",
    "help.title": "Need help?",
    "help.subtitle": "Let us know how we can help you.",
    "help.subject": "Your question via our website or app",
    "help.sent": "We have received your message correctly. You will get a reply from us within 4 business days at the latest.",
    "login.title": "Log in",
    "login.subtitle": "Welcome back! How would you like to log in?",
    "login.or": "or",
    "login.usps_title": "Why should I create an account?",
    "login.usps_title_cta": "Make it easier for yourself and log in now or create an account.",
    "login.usps_text": "Click on the \"Log in\" button at the top of the page to log in. If you don't have an account yet, you can also register on the login page. Having an Outspot account is 100% advantages:",
    "login.usp_manage_orders": "Viewing and tracking orders",
    "login.usp_remember_info": "Saving contact details for later",
    "login.usp_returns": "Reporting defects and requesting product returns",
    "login.usp_store_payments": "Save payment methods and pay faster",
    "login.short_cta": "<a href=\"{loginLink}\">Log in</a> or continue below as guest.",
    "forgot.title": "Request a new password",
    "forgot.subtitle": "Enter your email address below and we will send you a link to reset your password.",
    "forgot.sent": "We have sent you an email with a link to reset your password.",
    "password.title": "Change password",
    "password.subtitle": "Choose a new and secure password.",
    "password.updated": "Your password has been changed. You can now log in with your new password.",
    "register.title": "Register",
    "register.subtitle": "Create an account to make it even easier to order and track your orders.",
    "register.personal_info": "Personal details",
    "register.login_info": "Login details",
    "register.agree": "I agree to the <a href=\"/enl/help/general-conditions</a> and <a href=\"/enl/help/privacy\" target=\"_blank\">privacy statement</a>.",
    "register.sent": "You're almost there. We've sent you an email to activate your account.",
    "register.email_known": "Is your email address already registered with Outspot? <a href=\"{forgot}\">Click here</a> to request a new password. ",
    "my_orders.title": "My orders",
    "my_orders.subtitle": "This is an overview of all the orders you have placed at Outspot.",
    "my_orders.title_detail": "Details of your order",
    "my_travels.title": "My travels",
    "my_info.title": "My data",
    "my_info.subtitle": "Here you can edit your personal information",
    "my_info.sent": "Your information has been updated.",
    "notifications.title": "Newsletter and notifications",
    "notifications.subscription_mail": "How often would you like to receive our newsletter?",
    "notifications.subscription_notification": "How often would you like to receive notifications?",
    "notifications.frequency_0": "Never",
    "notifications.frequency_4": "Once a week",
    "notifications.frequency_56": "Twice a day",
    "notifications.sent": "Your preferences have been updated.",
    "combined_purchase_banner.text": "Pay no shipping fees for your subsequent orders during:",
    "plus_banner.text_welcome": "{firstName}, welcome to Outspot PLUS.",
    "plus_banner.text_welcome_anonimous": "Welcome to Outspot PLUS.",
    "plus_banner.text_savings_prefix": "{firstName}, you already saved",
    "plus_banner.text_savings_prefix_anonimous": "You already saved",
    "plus_banner.text_savings_suffix": "to shipping and filing fees through Outspot PLUS.",
    "cookie_banner.title": "Cookies",
    "cookie_banner.text": "This website uses cookies. Strictly necessary cookies ensure the website functions properly and cannot be refused. Functional, analytical & advertising cookies are used for functional, statistical, and marketing purposes, respectively. They are only downloaded if you consent to them. Read more in our <a href=\"{cookies}\" target=\"_blank\">cookie policy</a>.",
    "cookie_banner.decline": "Reject optional cookies",
    "cookie_banner.accept_all": "Accept all cookies",
    "cookie_banner.accept_selected": "Accept selected",
    "cookie_banner.settings": "Settings",
    "cookie_banner.functional_cookies_title": "Strictly necessary cookies",
    "cookie_banner.functional_cookies_text": "Cookies necessary for the basic functionality of the website.",
    "cookie_banner.analytical_cookies_title": "Analytical & functional cookies",
    "cookie_banner.analytical_cookies_text": "Cookies enable the website to provide additional features and personal settings as well as visits and traffic.",
    "cookie_banner.personal_cookies_title": "Advertising cookies",
    "cookie_banner.personal_cookies_text": "Cookies set by advertisers to build a profile of your interests without storing direct personal information.",
    "review.title": "Share it and win",
    "review.subtitle": "Post your review of your purchase below to be instantly entered into a draw to win a voucher (we draw one voucher every quarter).",
    "review.agree": "I agree that my review may be published on the website",
    "review.sent": "Thank you for your review. You now have a chance to win a voucher.",
    "review.title_thank_you": "Thank you. We appreciate your feedback.",
    "review.subtitle_thank_you": "Thanks to your feedback, we can make Outspot even better. Leave your review of your purchase below and have the chance to win a voucher (every quarter we draw a voucher).",
    "confirm.title": "Your registration has been confirmed",
    "confirm.subtitle": "Your subscription to the newsletter is hereby confirmed. Thank you! To serve you even better, we offer you the opportunity to share the following data with us without any obligation.",
    "popup_widget.title": "The best deals, tested for you.",
    "popup_widget.usp_1": "Receive our best offers daily.",
    "popup_widget.usp_2": "Selected and tested for best price and quality.",
    "popup_widget.usp_3": "Substantial discounts of up to -70%.",
    "popup_widget.footer_1": "Subscribe to our newsletter for free now and save immediately.",
    "popup_widget.footer_2": "You can unsubscribe again at any time.",
    "promo.title": "Our top promos",
    "promo.countdown": "2024-12-31 23:59:59",
    "promo.empty": "We currently have no top promos. Go to our home page for our latest deals.",
    "stars.info_title": "Product rating",
    "stars.info_text": "This rating is an average of our own collected reviews and those from other parties.",
    "stars.counter": "1 review | {count} reviews",
    "label.accept_selected": "Accept selected",
    "label.edit": "Change",
    "label.edit_address": "Change address",
    "label.address": "Address",
    "label.accept_all": "Accept all",
    "label.account": "Account",
    "label.help": "Help",
    "label.my_orders": "My orders",
    "label.my_travels": "My travels",
    "label.my_coupons": "My vouchers",
    "label.my_info": "My data",
    "label.change_password": "Change password",
    "label.notifications": "Newsletter and notifications",
    "label.logout": "Log out",
    "label.login": "Log in",
    "label.login_have_account": "Already have an account? Log in",
    "label.login_with_facebook": "Log in with Facebook",
    "label.login_with_google": "Log in with Google",
    "label.login_with_apple": "Log in with Apple",
    "label.password": "Password",
    "label.new_password": "New password",
    "label.repeat_password": "Repeat password",
    "label.repeat_new_password": "Repeat new password",
    "label.register_no_account": "No account yet? Register now",
    "label.register": "Register",
    "label.forgot_password": "Forgotten your password?",
    "label.general_conditions": "General conditions",
    "label.jobs": "Jobs at Outspot",
    "label.faq": "Frequently Asked Questions",
    "label.returns": "Return",
    "label.travel_conditions": "Specific travel conditions",
    "label.privacy_policy": "Privacy Statement",
    "label.cookie_policy": "Cookie Statement",
    "label.subscribe": "Sign me up",
    "label.all_deals": "All deals",
    "label.other_deals": "Other deals",
    "label.discount": "Off",
    "label.close": "Close",
    "label.order_now": "Buy now",
    "label.add": "Add",
    "label.book": "Book now",
    "label.upselling_next_step": "Also, don't forget this later",
    "label.upselling": "Also, don't forget this",
    "label.also_available": "Also available ",
    "label.reviews": "What our customers say",
    "label.automatically_translated": "Automatically translated",
    "label.show_all_reviews": "See all reviews",
    "label.back": "Back",
    "label.make_your_choice": "Make your choice",
    "label.sold_out": "Sold Out",
    "label.order_more": "Order more",
    "label.subtotal": "Subtotal",
    "label.total_shipping": "Shipping costs",
    "label.total_file": "File costs",
    "label.total_payable": "Total",
    "label.no_options_selected": "Your order is currently empty. Click \"Add\" below to continue.",
    "label.no_options_selected_extra": "You can only select this option when purchasing the product.",
    "label.total_saved_prefix": "(You save ",
    "label.total_saved_suffix": ")",
    "label.continue": "Continue",
    "label.usp_quality": "Selected on price and quality",
    "label.usp_discounts": "Hefty discounts of up to 70%",
    "label.usp_payment_methods": "Pay safely and easily with",
    "label.person": "Person",
    "label.free": "Free",
    "label.first_name": "First name",
    "label.last_name": "Surname",
    "label.birthdate": "Date of birth",
    "label.birthdate_why": "",
    "label.birthdate_why_text": "",
    "label.email": "Email address",
    "label.phone": "Phone number",
    "label.address_finder": "Address Finder",
    "label.address_finder_help": "Start typing your address",
    "label.select": "Select",
    "label.street": "Street",
    "label.building": "",
    "label.number": "No/bus",
    "label.zip": "Postal code",
    "label.city": "Municipality",
    "label.state": "",
    "label.country": "Country",
    "label.finish_order": "Order & pay",
    "label.finish_order_help": "Click the \"Order & pay\" button to complete your payment.",
    "label.enter_address": "Enter my address manually",
    "label.add_address": "Add an address",
    "label.add_payment_method": "Add a method of payment",
    "label.year": "YYYY",
    "label.month": "MM",
    "label.day": "DD",
    "label.business_order": "Business order",
    "label.company_name": "Company name",
    "label.tax_id": "VAT number",
    "label.invoice_reason": "Reason",
    "label.invoice_reasons": "Corporate gifts,Team building,Incentives,Seminar,Other",
    "label.delete": "Remove",
    "label.i_have_coupons": "I have vouchers",
    "label.email_outspot_plus": "Outspot PLUS member? Enter your email address.",
    "label.please_wait": "Please be patient...",
    "label.please_wait_2": "Please bear with us a little longer...",
    "label.please_wait_3": "Sorry, it's taking a little longer than expected....",
    "label.please_wait_4": "Just a few more minutes, we're almost there....",
    "label.please_wait_5": "Apologies for the inconvenience and thank you for your patience. We are almost there....",
    "label.continue_shopping": "Continue shopping",
    "label.my_code": "My code",
    "label.validate": "Validate",
    "label.total_coupons": "Total vouchers",
    "label.coupons": "Vouchers",
    "label.total_payable_with_coupons_prefix": "(Still to be paid ",
    "label.total_payable_with_coupons_suffix": ")",
    "label.rest_coupon_info": "The amount payable is less than the vouchers entered. You will receive a new voucher with the remaining value.",
    "label.contact_extra_info": "Order number, voucher number or structured communication",
    "label.contact": "Contact us",
    "label.message": "Message",
    "label.send": "Send",
    "label.save": "Save",
    "label.my_outspot_plus": "My Outspot PLUS",
    "label.order_date_prefix": "Ordered on",
    "label.order_date_suffix": "",
    "label.total_paid_with": "Total paid with {method}",
    "label.track_order": "Track your order with",
    "label.return_order": "Return and/or report defect",
    "label.download_invoice": "Download invoice",
    "label.ask_question": "Ask a question",
    "label.happy_hour_discount": "Happy Hour benefit",
    "label.outspot_plus_discount": "Outspot PLUS advantage",
    "label.cancel_membership": "Cancel membership",
    "label.membership_start_date": "Membership start date",
    "label.membership_price": "Price for 3 months",
    "label.membership_next_payment": "Next payment",
    "label.membership_end_date": "Membership end date",
    "label.total_savings": "Total shipping and filing costs saved",
    "label.confirm_cancel_membership": "Confirm cancellation",
    "label.continue_membership": "Do not cancel",
    "label.valid_till_prefix": "Valid until",
    "label.valid_till_suffix": "",
    "label.card_payment": "Card Payment",
    "label.place_review": "Place review",
    "label.name": "Name",
    "label.name_help": "we show these in the assessment",
    "label.review": "Review",
    "label.self_service": "",
    "label.update_cookie_preferences": "Change my cookie preferences",
    "label.more_info": "More info",
    "label.review_source": "Via {source}",
    "label.amount": "Amount",
    "label.iban": "IBAN",
    "label.bic": "BIC",
    "label.beneficiary": "Beneficiary",
    "label.structured_communication": "Structured communication",
    "label.later": "Later",
    "label.enable_notifications": "Yes, I want to receive notifications",
    "label.card_number": "Card number",
    "label.card_expiry": "Expiry",
    "label.card_cvc": "CVV",
    "label.cvc_info": "",
    "label.sales_tax": "",
    "label.calculating_sales_tax": "",
    "label.sales_tax_will_be_calculated": "",
    "label.ship_to_selected_address": "",
    "label.ship_to_service_point": "",
    "label.change_service_point": "",
    "label.choose_rooms": "",
    "label.room": "",
    "label.room_occupancy": "",
    "label.add_another_room": "",
    "label.number_of_adults": "",
    "label.number_of_adults_title": "",
    "label.number_of_children": "",
    "label.number_of_children_title": "",
    "label.choose_arrival_and_departure_date": "",
    "label.arrival_date": "",
    "label.select_arrival_date": "",
    "label.departure_date": "",
    "label.select_departure_date": "",
    "label.rate_type_pc": "",
    "label.rate_type_pcpd": "",
    "label.rate_type_pcpn": "",
    "label.rate_type_pp": "",
    "label.rate_type_pppd": "",
    "label.rate_type_pppn": "",
    "label.rate_type_pp_": "",
    "label.rate_type_pa": "",
    "label.rate_type_papd": "",
    "label.rate_type_papn": "",
    "label.rate_type_pa_": "",
    "label.choose_supplements": "",
    "label.opengds_invalid": "",
    "error.unknown": "Unknown error, please try again later.",
    "error.required": "{field} cannot be empty.",
    "error.required_email": "{field} cannot be empty and must be a valid e-mail address.",
    "error.required_password": "{field} must be at least 8 characters long and contain at least one uppercase, one lowercase, and one number.",
    "error.required_repeat_password": "{field} must be equal to the password specified in the {field2} field .",
    "error.payment": "Unfortunately, your payment has failed. This can happen if there is a problem with the payment details, the payment has been cancelled, your payment limit has been reached or for other reasons. Please contact your bank or try another card or payment method.",
    "error.payment_creditcard": "Sorry. Your payment has failed. Please check the details below and try again.",
    "error.incomplete_order": "We cannot validate this voucher until you have completed all the fields above.",
    "error.coupon_already_added": "You have already added this coupon.",
    "error.invalid_coupon": "This voucher is invalid.",
    "error.coupon_not_cumulative": "You cannot use this voucher together with other vouchers.",
    "error.other_coupon_not_cumulative": "You cannot use the coupon you have already added together with this coupon.",
    "error.invalid_username_or_password": "The email address and/or password provided is invalid.",
    "error.test_order": "This is a test order. Normally, the customer would now go to the payment page.",
    "error.required_agree": "You must agree to our terms and conditions and privacy policy.",
    "error.required_review_agree": "You must agree to your review being published on our website.",
    "error.register": "There is already an account registered to this email address. Click \"Continue\" to log in or use another email address.",
    "error.email_in_use": "There is already an account registered to this email address. Please use a different email address.",
    "error.already_reviewed": "You have already posted a review for this order.",
    "error.zip_blocked": "It is not possible to ship to the specified postcode.",
    "error.details": "It seems that one or more products you are trying to order are no longer in stock. Please return to the previous step, update your order and try again.",
    "error.incomplete_cvc": "CVV is too short.",
    "error.incomplete_date": "Expiration date cannot be empty.",
    "error.date_in_past": "Card has expired.",
    "error.invalid_expiration_year": "Card has expired.",
    "error.incomplete_card_number": "Card number is too short.",
    "error.incorrect_card_number": "Card number is invalid.",
    "unsubscription.your_mail_address": "Your email address: ",
    "unsubscription.sad_message": "You are now unsubscribed.",
    "unsubscription.have_not_participated": "Have you never signed up?",
    "unsubscription.sad_message_2": "We're sorry you've left us, but of course you're always welcome to come back.",
    "unsubscription.resubscribe_question": "Have you changed your mind? <span class='unsub-important-message'> Click below to subscribe to our newsletter again:</span>",
    "unsubscription.form_message": "Why are you leaving our Outspot family? Your answer can help us improve our services.",
    "unsubscription.report_not_participated": "Report abuse.",
    "unsubscription.coreg_date_no_screenshot": "You participated in a promotion on {date} (<a href=\"{coreg_site}\">{coreg_site}</a>) and thereby gave your consent to receive our newsletter with top offers at exceptional prices.",
    "unsubscription.coreg_date_no_coreg_data": "You participated in the promotion below on {date} and gave your consent to receive our newsletter with top offers at exceptional prices.",
    "unsubscription.coreg_date_screenshot": "You participated in the promotion below on {date} and gave your consent to receive our newsletter with top offers at exceptional prices.",
    "unsubscription.coreg_no_date_no_screenshot": "You participated in the promotion below and gave your consent to receive our newsletters with top offers at exceptional prices.",
    "unsubscription.coreg_no_date_screenshot": "You participated in the promotion below and gave your consent to receive our newsletters with top offers at exceptional prices.",
    "unsubscription.main_title": "Unsubscribe from the newsletter",
    "unsubscription.report_title": "Report abuse",
    "unsubscription.report_sent_message": "Thank you, we have received your notification.",
    "claim_coupon.welcome_message": "Just for you",
    "claim_coupon.info_line_message": "Enter this personal code at checkout.",
    "claim_coupon.info_line_message_bis": "We have also sent you this voucher by email.",
    "claim_coupon.main_title": "Your coupon",
    "about.title": "About us",
    "about.intro": "Welcome to Outspot, your number one destination for irresistible deals! Outspot was founded in 2008 and  has grown into the leading European flash deal website. Outspot offers daily discounts on quality products and travel, which allows customers to save a lot. Our warehouse in Europe helps us deliver our packages to you quickly.",
    "about.mission_title": "Mission",
    "about.mission_text": "At Outspot we believe in making  exceptional offers accessible to everyone. Our goal is to  offer customers the best possible value through carefully chosen deals at unbeatable prices.",
    "about.history_title": "History",
    "about.history_text": "The Outspot adventure started in Belgium, where our HQ is still located. We have a dedicated team of 25 employees throughout Europe, working each day to find and offer you the best deals. We are now present in 13 countries and we have 100,000 satisfied visitors every day.",
    "about.quality_title": "Our offer has been selected based on price and quality",
    "about.quality_text": "At Outspot we believe you deserve the best of both worlds: top quality at affordable prices. That's why  look for the most attractive deals for you every day. We make sure that we are working closely with our suppliers to negotiate the best prices without compromising on quality. <br /><br />  Every product and trip we offer is carefully selected using a rigorous price and quality evaluation criteria, sp you can be sure that you always get the best value for money with us. At Outspot, everything revolves around offering you benefits and satisfaction! Here is a photo of the smartbell sound being tested with a decibel meter.",
    "about.tested_title": "All deals have been tested extensively by our Outspot colleagues",
    "about.tested_text": "At Outspot we believe in delivering only the very best. Before a deal appears on our site, it is thoroughly tested by our Outspot colleagues. They don't make do with basic tests: all products are investigated and each component  (strength, noise level, material quality etc) is carefully assessed.<br /><br />  Our colleagues also personally visit many hotels to check the quality of  accommodation. This is how we guarantee that everything meets our high standards. Whether you are looking to purchase a product or a trip from Outspot, you can buy with confidence.<br /><br />  Here is a photo of our colleagues thoroughly assessing and testing products: e.g. fabric quality check.",
    "about.guarantee_title": "",
    "about.guarantee_text": "",
    "about.returns_title": "30 day return policy on most products",
    "about.returns_text": "We understand that it can sometimes take time to decide whether you want to keep a product. That’s why we offer a 30-day return policy on most of our products. It is important that the product remains unused and unopened to be eligible for a return. This gives you the chance to reconsider your purchase at your own pace, while we ensure a smooth return process. This way, you can decide with peace of mind, as long as the product stays in its original condition.",
    "about.support_title": "Customer service in English",
    "about.support_text": "At Outspot, customer satisfaction comes first, and we do everything we can to ensure you have an excellent experience. If you have any questions or need help with your order, please contact our customer service team. Our English-speaking agents are on-hand to help you and offer friendly and efficient support. We aim to please and offer you personalised help that you can understand, so that you you can enjoy your purchase stress-free. Your satisfaction is our top priority, and we are here to make sure you get the most out of our services.",
    "about.socials_title": "Stay connected",
    "about.socials_text": "Stay up to date with our latest deals and exclusive offers by signing up to our newsletter. Follow us on social media and share your Outspot experiences with us!<br /><br />  Do you have any questions or comments? Our helpdesk is on-hand to assist you. Contact us via <a href=\"{help}\">our contact page</a>.<br /><br />  Thank you for your trust in Outspot. We look forward to surprising you again and again with our fantastic offers!",
    "vat.error_required": "The field \"{field}\" must not be empty for a business order.",
    "vat.validation_message_format": "The field \"{field}\" is not in the correct format.",
    "vat.help_title": "VAT number",
    "vat.help_text": "The Irish VAT number must consist of 10 characters, for example IE1234567X.",
    "label.download": "Download",
    "error.download_pdf": "Something went wrong while downloading the PDF. Please try again later. ",
    "manage_address.title": "My delivery addresses",
    "error.select": "Select an {field}",
    "manage_cards.title": "My payment methods",
    "deal.quality_text_1": "Selected on price and quality",
    "deal.quality_text_2": "",
    "deal.delivery_text_days": "Delivery time: {days} working days",
    "deal.delivery_text": "",
    "maintenance.going_offline": "Our website will go temporarily offline for scheduled maintenance in a few minutes. Complete your order now, as you will not be able to place orders until the maintenance work is complete.",
    "maintenance.offline": "Our website is currently offline for scheduled maintenance. Please try again in a few minutes - we will be back online as soon as possible! As a gesture, we’re giving you GMJ2QPZ3  for €5 off your next purchase.",
    "sustainability.carpark_title": "",
    "sustainability.carpark_text": "",
    "sustainability.energy_title": "",
    "sustainability.energy_text": "",
    "sustainability.eco_packaging_title": "",
    "sustainability.eco_packaging_text": "",
    "sustainability.combined_packaging_title": "",
    "sustainability.combined_packaging_text": "",
    "sustainability.title": "",
    "sustainability.vision_title": "",
    "sustainability.vision_text": "",
    "sustainability.action_title": "",
    "sustainability.action_text": "",
    "sustainability.plant_text": "",
    "sustainability.treeplanters": "",
    "sustainability.action_text_believe": "",
    "sustainability.plant_title": "",
    "sustainability.trees": "",
    "sustainability.tree": "",
    "deal.advice_price": "",
    "sustainability.checkbox_title": "Plant together with Outspot",
    "sustainability.checkbox_detail_text": "We invite you to plant trees together with Outspot for {price}."
  },
  "fra": {
    "route.home": "https://www.outspot.fr/fra/",
    "route.fallback_domain": "https://www2.outspot.fr",
    "language.code": "FR",
    "language.locale": "fr-FR",
    "language.name": "France",
    "language.flag": "fr",
    "language.date_input_format": "d,m,y",
    "language.currency": "EUR",
    "language.region": "eu",
    "countries.delivery": "FRA",
    "states.delivery": "",
    "tracking.aw_merchant_id": "674571301",
    "tracking.aw_feed_country": "FR",
    "countries.BEL": "Belgique",
    "countries.LUX": "Luxembourg",
    "countries.NLD": "Pays-Bas",
    "countries.FRA": "France",
    "countries.DEU": "Allemagne",
    "countries.AUT": "Autriche",
    "countries.ITA": "Italie",
    "countries.ESP": "Espagne",
    "countries.POL": "Pologne",
    "countries.GBR": "Royaume-Uni",
    "countries.IRL": "Irlande",
    "countries.SWE": "Suède",
    "countries.PRT": "Portugal",
    "countries.FIN": "Finlande",
    "countries.USA": "",
    "states.AL": "",
    "states.AR": "",
    "states.AZ": "",
    "states.CA": "",
    "states.CO": "",
    "states.CT": "",
    "states.DE": "",
    "states.FL": "",
    "states.GA": "",
    "states.IA": "",
    "states.ID": "",
    "states.IL": "",
    "states.IN": "",
    "states.KS": "",
    "states.KY": "",
    "states.LA": "",
    "states.MA": "",
    "states.MD": "",
    "states.ME": "",
    "states.MI": "",
    "states.MN": "",
    "states.MO": "",
    "states.MS": "",
    "states.MT": "",
    "states.NC": "",
    "states.ND": "",
    "states.NE": "",
    "states.NH": "",
    "states.NJ": "",
    "states.NM": "",
    "states.NV": "",
    "states.NY": "",
    "states.OH": "",
    "states.OK": "",
    "states.OR": "",
    "states.PA": "",
    "states.RI": "",
    "states.SC": "",
    "states.SD": "",
    "states.TN": "",
    "states.TX": "",
    "states.UT": "",
    "states.VA": "",
    "states.VT": "",
    "states.WA": "",
    "states.WI": "",
    "states.WV": "",
    "states.WY": "",
    "trustpilot.businessunit_id": "53c7d4a90000640005791a38",
    "trustpilot.show": "TRUE",
    "mollie.profile_id": "pfl_pkMwmRrWdw",
    "mollie.locale": "fr_FR",
    "facebook.url": "https://www.facebook.com/OutspotFrance",
    "instagram.url": "",
    "linkedin.url": "",
    "header.hello": "Bienvenue",
    "header.hello_name": "Bonjour <b>{firstName}</b>,",
    "subscribe.title": "Inscrivez-vous gratuitement et ne manquez aucune de nos offres !",
    "subscribe.text": "Découvrez les meilleures offres Outspot en vous inscrivant gratuitement sur notre site. Vous profiterez de cette manière de réductions exclusives et personnalisées sur des produits et des expériences uniques.",
    "subscribe.agree": "En vous abonnant à notre newsletter, vous acceptez les <a href=\"{conditions}\" target=\"_blank\">conditions générales</a> et la <a href=\"{privacy}\" target=\"_blank\">déclaration de confidentialité</a>.",
    "subscribe.sent": "Merci beaucoup ! Nous avons bien reçu votre demande et vous envoyons immédiatement l'e-mail de confirmation de votre inscription.",
    "footer.follow": "Suivez-nous sur",
    "footer.payment_methods_logos": "cartebancaire,mastercard,visa,amex,paypal,applepay",
    "footer.copyright": "Outspot — Sint-Martens-Latem, Belgique — Confinity SA",
    "home.new": "Offres récentes",
    "home.ending": "Ces offres sont sur le point d'expirer",
    "home.other": "Toutes les offres",
    "countdown.prefix": "Encore",
    "countdown.days": "jour|jours",
    "countdown.hours": "heure|heures",
    "countdown.minutes": "min",
    "countdown.seconds": "sec",
    "countdown.days_compact": "j",
    "date.years": "Il y a {count} an|Il y a {count} ans",
    "date.months": "Il y a {count} mois",
    "date.weeks": "Il y a {count} semaine|Il y a {count} semaines",
    "date.days": "Il y a {count} jour|Il y a {count} jours",
    "date.hours": "Il y a {count} heure|Il y a {count} heures",
    "date.minutes": "Il y a {count} minute|Il y a {count} minutes",
    "date.seconds": "Il y a moins d'une minute",
    "countdown.suffix": "",
    "onboard.language_title": "Profitez d'offres personnalisées au meilleur prix",
    "onboard.language_text": "Choisissez le pays et la langue de votre choix pour faire vos achats :",
    "onboard.notifications_title": "Restez au courant de :",
    "onboard.notifications_usp_1": "Les meilleures offres les plus récentes.",
    "onboard.notifications_usp_2": "Statut de vos commandes.",
    "onboard.notifications_usp_3": "Codes de réduction personnels et bons d'achat.",
    "order.shipping_title": "Livraison",
    "order.shipping_text": "En raison de l'augmentation des coûts liés au transport et de l'impact sur l'environnement, nous sommes contraints de facturer des frais de port. Outspot garantit, néanmoins, toujours des prix réduits fantastiques grâce auxquels vous êtes de toutes façons toujours gagnant(e). En outre, les frais de ports n'augmenteront pas si vous achetez plusieurs fois le même article. Si vous êtes affilié(e) à Outspot PLUS ou si vous achetez dans l'heure qui suit votre précédent achat, les frais de livraison seront déduits à l'étape suivante.",
    "order.thank_you": "Merci pour votre commande!",
    "order.overview": "Aperçu de votre commande",
    "order.thank_you_bank": "Nous vous remercions pour votre achat. Vous recevrez les détails du virement par e-mail. Pensez également à consulter vos courriers indésirables. Veuillez effectuer le virement le plus rapidement possible et dans tous les cas dans un délai de 7 jours. Vous recevrez le bon que vous avez commandé par e-mail dès que le paiement nous sera parvenu et qu'il aura été traité.",
    "order.thank_you_with_shipping": "Votre achat a été effectué avec succès. Nous enverrons votre commande à l'adresse mentionnée ci-dessous. Vous recevrez votre confirmation d'achat par e-mail. Pensez également à consulter vos courriers indésirables. Profitez bien de votre achat !",
    "order.thank_you_without_shipping": "Votre achat a été effectué avec succès. Vous recevrez votre confirmation d'achat par e-mail. Pensez également à consulter vos courriers indésirables. Profitez bien de votre achat !",
    "order.code": "Numéro de la commande : {code}",
    "order.shipping_address": "Adresse de livraison",
    "order.save_payment_info_title": "Je sauvegarde mes coordonnées bancaires pour mes prochains achats.",
    "order.save_payment_info_text": "Si vous choisissez cette option, vous n'aurez pas à saisir vos données lors de chaque paiement. Vos données seront stockées en toute sécurité.",
    "order.shipped_to_service_point": "",
    "my_outspot_plus.title": "Mon abonnement Outspot PLUS",
    "my_outspot_plus.subtitle": "Vous pouvez gérer votre abonnement sur cette page.",
    "my_outspot_plus.overview": "Aperçu de votre abonnement",
    "my_outspot_plus.cancel_are_you_sure": "Êtes-vous certain(e) de vouloir annuler votre abonnement ?",
    "my_outspot_plus.cancel_you_saved_prefix": "Vous avez déjà économisé",
    "my_outspot_plus.cancel_you_saved_suffix": "en frais d'envoi et de dossier grâce à votre abonnement Outspot PLUS.",
    "my_outspot_plus.no_membership": "Vous n'êtes pas encore abonné(e) à Outspot PLUS.",
    "plus.title": "Outspot PLUS",
    "plus.popup_text": "Avec Outspot PLUS, vous bénéficiez de la livraison gratuite sur tous les produits et ne payez pas de frais de dossier sur les voyages. Après chaque commande, vous recevrez un e-mail vous permettant de vous abonner à Outspot PLUS. Ne tardez pas car la proposition n'est valable que durant 7 jours. N'oubliez pas de vérifier votre dernière confirmation d'achat.",
    "plus.name": "Outspot PLUS",
    "plus.highlight_1": "Commandez en toute tranquillité sans frais de port et de dossier.",
    "plus.highlight_2": "Votre abonnement sera automatiquement renouvelé tous les 3 mois pour seulement  {renewPrice}.",
    "plus.highlight_3": "Il est toujours possible de résilier l'abonnement.\r",
    "plus.question_1": "Quels sont les avantages d'Outspot PLUS ?",
    "plus.answer_1": "Avec Outspot PLUS, vous bénéficiez de la livraison gratuite sur tous les produits et ne payez aucuns frais de dossier sur les voyages.",
    "plus.question_2": "Comment devenir Outspot PLUS ?",
    "plus.answer_2": "Après chaque commande, vous recevrez un e-mail vous permettant de devenir Outspot PLUS. Ne tardez pas car la promotion n'est valable que durant 7 jours. Si vous décidez d'activer Outspot PLUS, les avantages commencent immédiatement. Cela signifie que lors de votre prochaine commande, vous bénéficierez immédiatement de la livraison gratuite sur tous les produits et que vous n'aurez plus à payer de frais de dossier sur les voyages.",
    "plus.question_3": "Combien coûte Outspot PLUS ?",
    "plus.answer_3": "Outspot PLUS ne coûte que {price} pour 3 mois. Il s'agit d'un paiement unique. Après cette période, votre adhésion sera automatiquement renouvelée tous les 3 mois pour {renewPrice}. Cela vous permet d'économiser considérablement sur les frais de port et de dossier.",
    "plus.question_4": "Quand et comment mon abonnement Outspot PLUS sera-t-il renouvelé ?",
    "plus.answer_4": "Outspot PLUS est automatiquement renouvelé tous les trois mois. Si vous souhaitez gérer votre abonnement, vous pouvez toujours vous rendre sur <a href=\"{myOutspotPlus}\">Mon Outspot PLUS</a>. Le montant sera automatiquement déduit tous les trois mois du compte bancaire que vous avez choisi.",
    "plus.question_5": "Comment puis-je résilier mon abonnement Outspot PLUS ? Serai-je remboursé(e) ?",
    "plus.answer_5": "Vous pouvez résilier votre adhésion à Outspot PLUS à tout moment via : <a href=\"{myOutspotPlus}\">Mon Outspot PLUS</a>. Il est important de noter qu'aucun remboursement ne sera effectué pour les mois au cours desquels votre adhésion a été active. Toutefois, si vous décidez de résilier Outspot PLUS, le prochain paiement prévu ne sera pas facturé.",
    "plus.thank_you": "Votre achat a été effectué avec succès. Les frais de port et de dossier ne seront, désormais, plus facturés. Vous pouvez consulter et gérer votre abonnement via <a href=\"{myOutspotPlus}\">Mon Outspot PLUS</a>.",
    "my_coupons.title": "Mes bons d'achat",
    "my_coupons.subtitle": "Vous trouverez vos bons d'achat sur cette page.",
    "my_coupons.no_coupons": "Vous n'avez actuellement aucun bon d'achat.",
    "deal.sold": "Déjà {sold} acheteurs",
    "deal.retour_label": "Retour sous 30 jours pour seulement 0,95 €",
    "deal.retour_title": "Droit de retour",
    "deal.retour_text": "Le produit que vous avez reçu ne vous convient pas ? Contactez notre service clientèle dans les 30 jours suivant la réception de votre commande afin de nous la renvoyer. Et ce, quelle que soit la raison ! Nous vous rembourserons votre achat afin que vous puissiez passer une nouvelle commande en toute confiance. Une autre bonne idée d'Outspot, évidemment !",
    "deal.guarantee_label": "Garantie de remboursement de 6 mois",
    "deal.guarantee_title": "Unique : garantie de remboursement intégral",
    "deal.guarantee_text": "Incroyable mais vrai. N’hésitez plus une seconde pour acheter une expérience pour vos amis / collègues / famille ou vous-même. Vous changez d’avis ou le bénéficiaire du cadeau n’est pas entièrement satisfait ? Aucun problème !<br><br>Outspot accepte en effet un droit de rétractation jusqu’à 6 mois après la commande ! Vous-même ou la personne qui a reçu le bon pouvez donc être intégralement remboursé(e) sans problème dans les 6 mois. Pour autant bien entendu que le bon n’ait pas encore été réservé, utilisé ou ne soit pas encore expiré. Cette garantie est exceptionnelle et inédite. C’est ça Outspot.<br><br>Veuillez noter que pour toutes les promotions où la garantie de remboursement n'est pas explicitement mentionnée, celle-ci n'est pas d'application. En effet, il ne nous est pas toujours possible de réclamer les livraisons ou tickets auprès de nos partenaires.",
    "deal.option_count": "{count} option|{count} options",
    "deal.campaign_number": "Numéro de la campagne",
    "deal.your_details": "Vos coordonnées",
    "deal.payment_methods_title": "Mode de paiement",
    "deal.shipping_methods_title": "",
    "deal.privacy": "En indiquant vos coordonnées, vous acceptez <a target='_blank' href='{privacy}'>notre déclaration de confidentialité</a>.",
    "deal.payment_methods_subtitle": "Comment souhaitez-vous payer votre commande ?",
    "deal.your_order": "Votre commande",
    "deal.from": "À partir de",
    "help.title": "Besoin d'aide ?",
    "help.subtitle": "Faites-nous savoir comment nous pouvons vous aider.",
    "help.subject": "Votre question via notre site web ou notre application",
    "help.sent": "Nous avons bien reçu votre message et vous répondrons dans les 4 jours ouvrables.",
    "login.title": "Me connecter",
    "login.subtitle": "Comment souhaitez-vous vous connecter ?",
    "login.or": "ou",
    "login.usps_title": "Créez un compte et vous pourrez :",
    "login.usps_title_cta": "Facilitez-vous la vie en vous connectant ou en créant un compte Outspot !",
    "login.usps_text": "Cliquez sur le bouton \"Je me connecte\" en haut de la page pour vous connecter. Si vous n'avez pas encore de compte, vous pouvez également vous inscrire sur la page de connexion. Avoir un compte Outspot n'a que des avantages :",
    "login.usp_manage_orders": "consulter et suivre vos commandes en ligne ;",
    "login.usp_remember_info": "sauvegarder vos adresses de livraison ;",
    "login.usp_returns": "signaler un défaut et/ou renvoyer un produit ;",
    "login.usp_store_payments": "enregistrer vos modes de paiement favoris et payer rapidement.",
    "login.short_cta": "<a href=\"{loginLink}\">Connectez-vous</a> ou continuez ci-dessous en tant qu'invité.",
    "forgot.title": "Demandez un nouveau mon mot de passe.",
    "forgot.subtitle": "Saisissez votre adresse e-mail ci-dessous afin que nous puissions vous envoyer un lien pour réinitialiser votre mot de passe.",
    "forgot.sent": "Nous vous avons envoyé un e-mail avec un lien pour réinitialiser votre mot de passe.",
    "password.title": "Modifiez votre mot de passe.",
    "password.subtitle": "Choisissez un nouveau mot de passe sécurisé.",
    "password.updated": "Vous avez modifié votre mot de passe. Vous pouvez désormais vous connecter avec le nouveau.",
    "register.title": "Je m'inscris",
    "register.subtitle": "Créez un compte pour passer commande facilement et suivre vos achats en ligne.",
    "register.personal_info": "Données personnelles",
    "register.login_info": "Données de connexion",
    "register.agree": "J'accepte les <a href=\"/fra/help/general-conditions\" target=\"_blank\">conditions générales</a> et la <a href=\"/fra/help/privacy\" target=\"_blank\">déclaration de confidentialité</a>.",
    "register.sent": "C'est bientôt terminé ! Nous vous avons envoyé un e-mail pour activer votre compte.",
    "register.email_known": "Votre adresse e-mail est déjà renseignée sur notre site ? <a href=\"{forgot}\">Cliquez alors ici</a> pour demander un mot de passe.",
    "my_orders.title": "Mes achats",
    "my_orders.subtitle": "Voici un aperçu de toutes les commandes que vous avez passées sur le site d'Outspot.",
    "my_orders.title_detail": "Détails de votre commande.",
    "my_travels.title": "Mes voyages",
    "my_info.title": "Mes coordonnées",
    "my_info.subtitle": "Vous pouvez modifier vos données personnelles sur cette page.",
    "my_info.sent": "Vos données ont été mises à jour.",
    "notifications.title": "Newsletter et notifications",
    "notifications.subscription_mail": "Combien de fois souhaitez-vous recevoir notre bulletin d'information ?",
    "notifications.subscription_notification": "Combien de fois souhaitez-vous recevoir des notifications ?",
    "notifications.frequency_0": "Jamais",
    "notifications.frequency_4": "Une fois par semaine",
    "notifications.frequency_56": "Deux fois par jour",
    "notifications.sent": "Vos préférences ont été mises à jour.",
    "combined_purchase_banner.text": "Ne payez aucuns frais de port ni de dossier sur vos prochaines commandes durant",
    "plus_banner.text_welcome": "{firstName}, bienvenue sur Outspot PLUS.",
    "plus_banner.text_welcome_anonimous": "Bienvenue sur Outspot PLUS.",
    "plus_banner.text_savings_prefix": "{firstName}, vous avez déjà enregistré",
    "plus_banner.text_savings_prefix_anonimous": "Vous avez déjà enregistré",
    "plus_banner.text_savings_suffix": "en frais d'envoi et de dossier grâce à votre abonnement Outspot PLUS.",
    "cookie_banner.title": "Cookies",
    "cookie_banner.text": "Ce site utilise des cookies. Les cookies strictement nécessaires garantissent le bon fonctionnement du site et ne peuvent pas être refusés. Les cookies fonctionnels, analytiques et publicitaires sont utilisés respectivement à des fins fonctionnelles, statistiques et de marketing. Ils ne sont téléchargés que si vous y consentez. Pour en savoir plus, nous vous invitons à consulter notre <a href=\"{cookies}\" target=\"_blank\">Déclaration des cookies</a>.",
    "cookie_banner.decline": "Refuser les cookies optionnels",
    "cookie_banner.accept_all": "Accepter tous les cookies",
    "cookie_banner.accept_selected": "Accepter les cookies sélectionnés",
    "cookie_banner.settings": "Paramètres",
    "cookie_banner.functional_cookies_title": "Cookies strictement nécessaires",
    "cookie_banner.functional_cookies_text": "Cookies nécessaires au bon fonctionnement du site.",
    "cookie_banner.analytical_cookies_title": "Cookies analytiques et fonctionnels",
    "cookie_banner.analytical_cookies_text": "Ces cookies permettent au site de fournir des fonctionnalités supplémentaires et des paramètres personnalisés, ainsi que des visites et du trafic.",
    "cookie_banner.personal_cookies_title": "Cookies publicitaires",
    "cookie_banner.personal_cookies_text": "Ces cookies sont définis par les annonceurs pour établir un profil de vos intérêts sans stocker d'informations personnelles directes.",
    "review.title": "Donnez votre avis et remportez un bon d'achat",
    "review.subtitle": "Donnez ci-dessous votre avis sur votre achat et tentez de remporter un bon d'achat de 250 € (nous tirons un client au sort chaque trimestre).",
    "review.agree": "J'accepte que mon avis soit publié sur le site.",
    "review.sent": "Merci pour votre commentaire. Vous avez maintenant une chance de remporter un bon d'achat de 250 €.",
    "review.title_thank_you": "Merci pour votre commentaire.",
    "review.subtitle_thank_you": "C'est grâce à vos commentaires que nous pouvons nous améliorer. Donnez ci-dessous votre avis sur votre achat et tentez de remporter un bon d'achat de 250 € (nous tirons chaque trimestre un client au sort).",
    "confirm.title": "Votre inscription est confirmée",
    "confirm.subtitle": "Votre inscription à la lettre d'information est confirmée. Nous vous remercions ! Afin de mieux vous servir, nous vous offrons la possibilité de nous communiquer les données suivantes sans aucune obligation.",
    "popup_widget.title": "Les meilleures offres, testées pour vous.",
    "popup_widget.usp_1": "Recevez chaque jour nos meilleures offres.",
    "popup_widget.usp_2": "Le choix du prix et de la qualité.",
    "popup_widget.usp_3": "Des réductions spectaculaires allant jusqu'à -70 %.",
    "popup_widget.footer_1": "Inscrivez-vous gratuitement dès maintenant à notre newsletter et commencez à économiser.",
    "popup_widget.footer_2": "Vous pouvez vous désinscrire à tout moment.",
    "promo.title": "Nos meilleures promotions",
    "promo.countdown": "2024-12-31 23:59:59",
    "promo.empty": "Actuellement, nous n'avons pas d'offres promotionnelles. Rendez-vous sur notre page d'accueil pour connaître nos dernières offres.",
    "stars.info_title": "Note du produit",
    "stars.info_text": "Cette note est une moyenne des avis que nous avons collectés nous-mêmes et de ceux provenant d'autres sources.",
    "stars.counter": "1 avis | {count} avis",
    "label.accept_selected": "Accepter la sélection",
    "label.edit": "Modifiez",
    "label.edit_address": "Modifiez l'adresse",
    "label.address": "Adresse",
    "label.accept_all": "Tout accepter",
    "label.account": "Mon compte",
    "label.help": "Aide",
    "label.my_orders": "Mes achats",
    "label.my_travels": "Mes voyages",
    "label.my_coupons": "Mes bons d'achat",
    "label.my_info": "Mes coordonnées",
    "label.change_password": "Modifier mon mot de passe",
    "label.notifications": "Newsletter et notifications",
    "label.logout": "Me déconnecter",
    "label.login": "Je me connecte",
    "label.login_have_account": "Vous avez déjà un compte ? Connectez-vous",
    "label.login_with_facebook": "Je me connecte avec Facebook",
    "label.login_with_google": "Je me connecte avec Google",
    "label.login_with_apple": "Je me connecte avec Apple",
    "label.password": "Mot de passe",
    "label.new_password": "Nouveau mot de passe",
    "label.repeat_password": "",
    "label.repeat_new_password": "Répétez votre nouveau mot de passe",
    "label.register_no_account": "Vous n'avez pas encore de compte ? Inscrivez-vous",
    "label.register": "Je m'inscris",
    "label.forgot_password": "Vous n'avez pas encore de mot de passe ou l'avez simplement oublié ?",
    "label.general_conditions": "Conditions générales",
    "label.jobs": "Offres d'emploi",
    "label.faq": "Questions fréquemment posées",
    "label.returns": "Retour",
    "label.travel_conditions": "Conditions spécifiques de voyage",
    "label.privacy_policy": "Déclaration de confidentialité",
    "label.cookie_policy": "Déclaration des cookies",
    "label.subscribe": "Je m'inscris",
    "label.all_deals": "Toutes les offres",
    "label.other_deals": "Autres offres",
    "label.discount": "de réduction",
    "label.close": "Fermer",
    "label.order_now": "J'achète",
    "label.add": "Ajouter",
    "label.book": "Réserver",
    "label.upselling_next_step": "N'oubliez pas ceci tout à l'heure",
    "label.upselling": "N'oubliez pas ceci",
    "label.also_available": "Également disponible",
    "label.reviews": "Ce que disent nos clients",
    "label.automatically_translated": "Traduction automatique",
    "label.show_all_reviews": "Voir tous les commentaires",
    "label.back": "Retour",
    "label.make_your_choice": "Faites votre choix",
    "label.sold_out": "En rupture de stock",
    "label.order_more": "Commander plus",
    "label.subtotal": "Sous-total",
    "label.total_shipping": "Livraison",
    "label.total_file": "Frais de dossier",
    "label.total_payable": "Total",
    "label.no_options_selected": "Vous n'avez pas encore fait votre choix. Cliquez ci-dessous sur « Ajouter » pour continuer.",
    "label.no_options_selected_extra": "Vous pouvez sélectionner cette option uniquement si vous achetez le produit.",
    "label.total_saved_prefix": "(Vous avez économisé ",
    "label.total_saved_suffix": ")",
    "label.continue": "Continuer",
    "label.usp_quality": "Le choix du prix et de la qualité",
    "label.usp_discounts": "Des réductions exceptionnelles pouvant aller jusqu'à -70 %.",
    "label.usp_payment_methods": "Paiement facile et sécurisé avec",
    "label.person": "Personne",
    "label.free": "Gratuit",
    "label.first_name": "Prénom",
    "label.last_name": "Nom de famille",
    "label.birthdate": "Date de naissance",
    "label.birthdate_why": "",
    "label.birthdate_why_text": "",
    "label.email": "Adresse e-mail",
    "label.phone": "Numéro de téléphone",
    "label.address_finder": "Recherche d'adresse",
    "label.address_finder_help": "Saisissez votre adresse de livraison complète",
    "label.select": "Sélectionner",
    "label.street": "Rue",
    "label.building": "",
    "label.number": "Numéro/boîte",
    "label.zip": "Code postal",
    "label.city": "Commune",
    "label.state": "",
    "label.country": "Pays",
    "label.finish_order": "Commander & payer",
    "label.finish_order_help": "Cliquez sur le \"Commander & payer\" pour confimer votre paiement.",
    "label.enter_address": "Saisir mon adresse manuellement",
    "label.add_address": "Ajoutez une autre adresse",
    "label.add_payment_method": "Ajoutez un mode de paiement",
    "label.year": "AAAA",
    "label.month": "MM",
    "label.day": "JJ",
    "label.business_order": "Commande professionnelle",
    "label.company_name": "Nom de la société",
    "label.tax_id": "Numéro de TVA",
    "label.invoice_reason": "Motif",
    "label.invoice_reasons": "Cadeau d'affaire, Teambuilding, Incentives, Séminaire, Autre",
    "label.delete": "Supprimer",
    "label.i_have_coupons": "J'ai des bons d'achat",
    "label.email_outspot_plus": "Vous êtes déjà abonné(e) à Outspot PLUS ? Saisissez alors votre adresse e-mail.",
    "label.please_wait": "Un peu de patience",
    "label.please_wait_2": "Encore un peu de patience...",
    "label.please_wait_3": "Désolé, cela prend un peu plus de temps que prévu...",
    "label.please_wait_4": "Encore un peu de patience, nous y sommes presque...",
    "label.please_wait_5": "Toutes nos excuses pour la gêne occasionnée et merci de votre patience, nous y sommes presque.",
    "label.continue_shopping": "Continuer mon shopping",
    "label.my_code": "Code du bon d'achat",
    "label.validate": "Valider",
    "label.total_coupons": "Total des bons d'achat",
    "label.coupons": "Bons d'achat",
    "label.total_payable_with_coupons_prefix": "(Reste à payer ",
    "label.total_payable_with_coupons_suffix": ")",
    "label.rest_coupon_info": "Le montant à payer est inférieur à la valeur des bons d'achat utilisés. Vous recevrez un nouveau bon d'achat avec la valeur restante.",
    "label.contact_extra_info": "Numéro de la commande ou communication structurée",
    "label.contact": "Contactez-nous",
    "label.message": "Message",
    "label.send": "Envoyer",
    "label.save": "Sauvegarder",
    "label.my_outspot_plus": "Mon abonnement Outspot PLUS",
    "label.order_date_prefix": "Commande passée le",
    "label.order_date_suffix": "",
    "label.total_paid_with": "Total payé avec {method}",
    "label.track_order": "Suivez votre commande avec",
    "label.return_order": "Renvoyer et/ou signaler un défaut",
    "label.download_invoice": "Télécharger la facture",
    "label.ask_question": "Poser une question",
    "label.happy_hour_discount": "Happy Hour",
    "label.outspot_plus_discount": "Outspot PLUS",
    "label.cancel_membership": "Annuler l'adhésion",
    "label.membership_start_date": "Date de début de l'adhésion",
    "label.membership_price": "Prix pour 3 mois",
    "label.membership_next_payment": "Paiement suivant",
    "label.membership_end_date": "Date de fin d'adhésion",
    "label.total_savings": "Total des frais d'expédition et de dossier économisés",
    "label.confirm_cancel_membership": "Confirmer l'annulation",
    "label.continue_membership": "Ne pas annuler",
    "label.valid_till_prefix": "Valable jusqu'au",
    "label.valid_till_suffix": "",
    "label.card_payment": "Carte de crédit",
    "label.place_review": "Publier mon avis",
    "label.name": "Nom",
    "label.name_help": "Nous l'indiquons dans votre avis",
    "label.review": "Avis",
    "label.self_service": "",
    "label.update_cookie_preferences": "Modifier mes préférences en matière de cookies",
    "label.more_info": "Plus d'informations",
    "label.review_source": "Via {source}",
    "label.amount": "Montant",
    "label.iban": "IBAN",
    "label.bic": "BIC",
    "label.beneficiary": "Bénéficiaire",
    "label.structured_communication": "Communication structurée",
    "label.later": "Plus tard",
    "label.enable_notifications": "Oui, je veux recevoir ces notifications",
    "label.card_number": "Numéro sur la carte",
    "label.card_expiry": "Date d'expiration",
    "label.card_cvc": "CVV",
    "label.cvc_info": "",
    "label.sales_tax": "",
    "label.calculating_sales_tax": "",
    "label.sales_tax_will_be_calculated": "",
    "label.ship_to_selected_address": "",
    "label.ship_to_service_point": "",
    "label.change_service_point": "",
    "label.choose_rooms": "",
    "label.room": "",
    "label.room_occupancy": "",
    "label.add_another_room": "",
    "label.number_of_adults": "",
    "label.number_of_adults_title": "",
    "label.number_of_children": "",
    "label.number_of_children_title": "",
    "label.choose_arrival_and_departure_date": "",
    "label.arrival_date": "",
    "label.select_arrival_date": "",
    "label.departure_date": "",
    "label.select_departure_date": "",
    "label.rate_type_pc": "",
    "label.rate_type_pcpd": "",
    "label.rate_type_pcpn": "",
    "label.rate_type_pp": "",
    "label.rate_type_pppd": "",
    "label.rate_type_pppn": "",
    "label.rate_type_pp_": "",
    "label.rate_type_pa": "",
    "label.rate_type_papd": "",
    "label.rate_type_papn": "",
    "label.rate_type_pa_": "",
    "label.choose_supplements": "",
    "label.opengds_invalid": "",
    "error.unknown": "Erreur inconnue, veuillez réessayer plus tard.",
    "error.required": "Le champ \"{field}\" ne peut pas être vide.",
    "error.required_email": "Le champ\"{field}\" ne peut pas être vide et l'adresse e-mail doit être valide.",
    "error.required_password": "Le champ \"{field}\" doit comporter au minimum 8 caractères et contenir au moins une majuscule, une minuscule et un chiffre.",
    "error.required_repeat_password": "Le mot de passe que vous répétez doit correspondre au mot de passe mentionné dans le champ \"{field2}\".",
    "error.payment": "Votre paiement a, malheureusement, échoué. Cela peut arriver si les coordonnées bancaires sont erronées, si vous avez annulé le paiement ou encore si votre plafond de paiement a été atteint. Contactez votre banque ou essayez avec une autre carte ou un autre mode de paiement.",
    "error.payment_creditcard": "Votre paiement a, malheureusement, échoué. Veuillez vérifier les données ci-dessous et réessayer.",
    "error.incomplete_order": "Nous validerons votre bon d'achat lorsque vous aurez rempli les champs ci-dessus.",
    "error.coupon_already_added": "Vous avez déjà ajouté ce bon d'achat.",
    "error.invalid_coupon": "Ce bon d'achat n'est pas valable.",
    "error.coupon_not_cumulative": "Ce bon d'achat ne peut pas être cumulé avec d'autres bons.",
    "error.other_coupon_not_cumulative": "Le bon d'achat que vous avez déjà ajouté ne peut pas être utilisé en même temps que ce bon.",
    "error.invalid_username_or_password": "L'adresse électronique et/ou le mot de passe fournis ne sont pas valides.",
    "error.test_order": "Il s'agit d'une commande test. Le client devrait normalement maintenant accéder à la page de paiement.",
    "error.required_agree": "Vous devez accepter nos conditions générales et la déclaration de confidentialité.",
    "error.required_review_agree": "Vous devez accepter que votre avis soit publié sur notre site web.",
    "error.register": "Il existe déjà un compte avec cette adresse e-mail. Cliquez sur \"Continuer\" pour vous connecter ou utiliser une autre adresse e-mail.",
    "error.email_in_use": "Il existe déjà un compte avec cette adresse e-mail. Veuillez utiliser une autre adresse e-mail.",
    "error.already_reviewed": "Vous avez déjà publié un avis sur cette commande.",
    "error.zip_blocked": "Il est impossible de faire un envoi au code postal introduit.",
    "error.details": "Il semble qu'un ou plusieurs produits que vous essayez de commander ne soient plus en stock. Veuillez revenir à l'étape précédente, mettre à jour votre commande et réessayer.",
    "error.incomplete_cvc": "CVV est trop court.",
    "error.incomplete_date": "La date d’expiration ne peut pas être vide.",
    "error.date_in_past": "La carte est expirée.",
    "error.invalid_expiration_year": "La carte est expirée.",
    "error.incomplete_card_number": "Le numéro de carte est trop court.",
    "error.incorrect_card_number": "Le numéro de carte est invalide.",
    "unsubscription.your_mail_address": "Votre adresse e-mail :",
    "unsubscription.sad_message": "Vous n'êtes désormais plus inscrit(e) sur notre site.",
    "unsubscription.have_not_participated": "Vous ne vous êtes jamais inscrit(e) ? ",
    "unsubscription.sad_message_2": "Nous regrettons de vous voir partir, mais vous restez bien sûr toujours le ou la bienvenu(e).",
    "unsubscription.resubscribe_question": "Vous avez changé d'avis ? <span class='unsub-important-message'>Cliquez ci-dessous pour vous réinscrire à notre newsletter :</span>",
    "unsubscription.form_message": "Pourquoi quittez-vous la famille Outspot ? Votre réponse peut nous aider à améliorer nos services.",
    "unsubscription.report_not_participated": "Signalez alors cet abus",
    "unsubscription.coreg_date_no_screenshot": "Le {date}, vous avez participé à une campagne (<a href=\"{coreg_site}\">{coreg_site}</a>) et donné votre accord pour recevoir nos newsletters dans lesquelles nous vous proposons des offres à un prix incroyable.",
    "unsubscription.coreg_date_no_coreg_data": "Le {date}, vous avez participé à la campagne ci-dessous et donné votre accord pour recevoir nos newsletters dans lesquelles nous vous proposons des offres à un prix incroyable.",
    "unsubscription.coreg_date_screenshot": "Le {date}, vous avez participé à la campagne ci-dessous et donné votre accord pour recevoir nos newsletters dans lesquelles nous vous proposons des offres à un prix incroyable.",
    "unsubscription.coreg_no_date_no_screenshot": "Vous avez participé à la campagne ci-dessous et donné votre accord pour recevoir nos newsletters dans lesquelles nous vous proposons des offres à un prix incroyable.",
    "unsubscription.coreg_no_date_screenshot": "Vous avez participé à la campagne ci-dessous et donné votre accord pour recevoir nos newsletters dans lesquelles nous vous proposons des offres à un prix incroyable.",
    "unsubscription.main_title": "Me désabonner de la newsletter",
    "unsubscription.report_title": "Signalez un abus",
    "unsubscription.report_sent_message": "Merci, nous avons bien reçu votre notification.",
    "claim_coupon.welcome_message": "Un cadeau rien que pour vous",
    "claim_coupon.info_line_message": "Indiquez le code personnel ci-dessus lors du paiement.",
    "claim_coupon.info_line_message_bis": "Le bon d'achat vous a également été envoyé par e-mail.",
    "claim_coupon.main_title": "Votre bon d'achat",
    "about.title": "Qui sommes nous",
    "about.intro": "Bienvenue chez Outspot, votre destination numéro un pour des offres irrésistibles ! Depuis sa création en 2008, Outspot est devenu le premier site de ventes flash en Europe où l'on vous propose chaque jour des réductions sur des produits et voyages de qualité, vous permettant, en tant que client, de faire de belles économies. Depuis notre entrepôt en Europe , nous livrons le plus rapidement possible à votre domicile.",
    "about.mission_title": "Notre mission",
    "about.mission_text": "Chez Outspot, nous estimons qu'il est essentiel de rendre des offres exceptionnelles accessibles à tous. Notre objectif est d'offrir à nos clients la meilleure qualité possible en proposant des offres soigneusement sélectionnées à des prix inégalés. ",
    "about.history_title": "Notre histoire",
    "about.history_text": "L'aventure Outspot a commencé en Belgique, où le siège social est toujours situé. Avec une équipe dévouée de 25 employés répartis à travers l'Europe, nous travaillons chaque jour pour dénicher et proposer les meilleures offres. Aujourd'hui, nous sommes actifs dans 13 pays et accueillons chaque jour 100 000 visiteurs satisfaits.",
    "about.quality_title": "Le choix du prix et de la qualité",
    "about.quality_text": "Chez Outspot, nous pensons que vous méritez le meilleur des deux mondes : une qualité supérieure à un prix abordable. C'est pourquoi, chaque jour, nous cherchons à vous proposer les offres les plus attractives. Grâce à des collaborations étroites avec nos fournisseurs, nous nous assurons de négocier les meilleurs prix, sans faire de compromis sur la qualité. <br /><br />  Les produits et voyages que nous proposons sont soigneusement sélectionnés selon une évaluation rigoureuse du rapport qualité-prix. Vous pouvez donc être sûr qu'avec nous, vous obtenez toujours le meilleur rapport qualité-prix. Chez Outspot, votre satisfaction et votre avantage sont nos priorités absolues ! Ici, vous voyez une photo où le son de la sonnette intelligente est testé avec un sonomètre.",
    "about.tested_title": "Toutes les offres sont minutieusement testées par les collègues d'Outspot",
    "about.tested_text": "Chez Outspot, nous estimons qu'il faut offrir le meilleur, rien de moins. Avant qu'une offre n'apparaisse sur notre site, elle est minutieusement testée par les collègues d'Outspot. Ils ne se contentent pas de tests superficiels : les produits sont complètement démontés, et chaque composant est soigneusement évalué pour sa solidité, son niveau sonore, la qualité des matériaux, et bien plus encore.<br /><br />  Nos collègues se rendent également dans de nombreux hôtels afin de vérifier la qualité des aménagements. Cela nous permet de garantir que tout répond à nos standards de qualité élevés. Qu'il s'agisse d'un produit ou d'un voyage, vous pouvez être certain qu'avec Outspot, vous achetez toujours en toute confiance.<br /><br />  Ici, vous voyez sur la photo comment nos collègues examinent et testent les produits en détail, par exemple en vérifiant la qualité des tissus.",
    "about.guarantee_title": "Garantie de remboursement de 6 mois sur la plupart des voyages",
    "about.guarantee_text": "Lorsque vous réservez un voyage, vous voulez être sûr que votre choix est le bon et que votre argent est bien dépensé. Nous offrons une garantie de remboursement de 6 mois sur la plupart de nos voyages. Grâce à cette garantie, vous pouvez planifier en toute tranquillité, en sachant que vous avez toujours droit à un remboursement, à condition qu'aucune réservation n'ait encore été effectuée. Cela vous offre la liberté de rester flexible dans vos décisions, sans risquer de frais supplémentaires tant qu'il n'y a pas de réservation définitive.",
    "about.returns_title": "Politique de retour de 30 jours sur la plupart des produits",
    "about.returns_text": "Nous comprenons qu'il peut parfois être nécessaire de prendre un peu de temps pour décider si vous souhaitez conserver un produit. C'est pourquoi nous offrons un droit de retour de 30 jours sur la plupart de nos produits. Il est important que le produit reste inutilisé et non ouvert pour pouvoir bénéficier d'un retour. Cela vous permet de réfléchir à votre achat à votre propre rythme, tout en assurant une procédure de retour fluide. Vous pouvez ainsi décider en toute tranquillité, tant que le produit reste dans son état d'origine.",
    "about.support_title": "Un service clientèle prêt à vous aider en français",
    "about.support_text": "Chez Outspot, la satisfaction du client est primordiale et nous faisons tout notre possible pour vous garantir une expérience exceptionnelle. Si vous avez des questions ou besoin d'aide concernant votre commande, notre service client est toujours à votre disposition. Nos collaborateurs francophones sont disponibles pour vous aider rapidement et efficacement. Nous nous efforçons de vous aider de manière personnalisée et claire, afin que vous puissiez profiter de votre achat en toute tranquillité. Votre satisfaction est notre priorité, et nous sommes là pour nous assurer que vous en tiriez le meilleur parti.",
    "about.socials_title": "Restez connecté",
    "about.socials_text": "Restez informé de nos dernières offres et promotions exclusives en vous inscrivant à notre newsletter. Suivez-nous sur les réseaux sociaux et partagez avec nous vos expériences Outspot !<br /><br />  Avez-vous des questions ou des commentaires ? Notre service clientèle est prêt à vous aider. Contactez-nous via <a href=\"{help}\">notre page de contact</a>.<br /><br />  Merci de votre confiance en Outspot. Nous avons hâte de continuer à vous surprendre avec nos offres fantastiques !",
    "vat.error_required": "Le champ \"{field}\" ne peut pas être vide pour une commande professionnelle.",
    "vat.validation_message_format": "Le champ \"{field}\" n'est pas au format correct.",
    "vat.help_title": "Numéro de TVA",
    "vat.help_text": "Le numéro de TVA français doit comporter 13 caractères, par exemple FR12345678901.",
    "label.download": "Télécharger",
    "error.download_pdf": "Un problème est survenu lors du téléchargement du fichier PDF. Veuillez réessayer plus tard.",
    "manage_address.title": "Mes adresses de livraison",
    "error.select": "Sélectionner une {field}",
    "manage_cards.title": "Mes moyens de paiement",
    "deal.quality_text_1": "Le choix du prix et de la qualité",
    "deal.quality_text_2": "",
    "deal.delivery_text_days": "Délai de livraison: {days} jours ouvrables",
    "deal.delivery_text": "",
    "maintenance.going_offline": "Dans quelques minutes, notre site web sera temporairement indisponible pour une maintenance programmée. Finalisez votre commande maintenant, car il ne sera pas possible de commander durant la maintenance.",
    "maintenance.offline": "Notre site web est actuellement indisponible pour une maintenance programmée. Veuillez réessayer dans quelques minutes - nous serons de nouveau en ligne dès que possible !À titre de compensation, nous vous offrons ce bon d'achat de 5 € valable sur votre prochain achat sur notre site : GMJ2QPZ3",
    "sustainability.carpark_title": "Transition vers une flotte de véhicules électriques",
    "sustainability.carpark_text": "Dans le cadre de nos efforts continus pour réduire notre empreinte écologique, nous transformons progressivement notre flotte de véhicules professionnels. D’ici 2026, notre objectif est de disposer d’une flotte entièrement électrique. Cette transition représente une étape clé vers une réduction significative de nos émissions et la promotion d’une mobilité plus propre et plus durable.",
    "sustainability.energy_title": "Des bureaux alimentés par l’énergie solaire",
    "sustainability.energy_text": "La durabilité commence chez nous, et cela signifie dans nos bureaux. Nous avons installé 30 panneaux solaires, qui produisent près de la moitié de l’électricité nécessaire à nos activités quotidiennes. En tirant parti de l’énergie solaire, nous réduisons considérablement notre dépendance aux sources d’énergie non renouvelables et diminuons notre empreinte carbone globale.",
    "sustainability.eco_packaging_title": "Des emballages respectueux de l'environnement",
    "sustainability.eco_packaging_text": "Conscients de l’impact des déchets d’emballage sur l’environnement, nous avons fait le choix d’utiliser uniquement du plastique 100 % recyclable pour tous nos emballages. Cette initiative contribue à créer un cycle d’utilisation et de réutilisation plus durable.",
    "sustainability.combined_packaging_title": "Expéditions groupées",
    "sustainability.combined_packaging_text": "Afin de réduire encore davantage notre impact environnemental, nous encourageons nos clients à regrouper leurs commandes. En combinant un maximum d’achats en un seul envoi, nous diminuons le nombre de livraisons nécessaires, ce qui réduit les émissions et l’empreinte écologique associée.",
    "sustainability.title": "La durabilité chez Outspot",
    "sustainability.vision_title": "Notre vision",
    "sustainability.vision_text": "Chez Outspot, nous sommes pleinement conscients de notre impact sur l’environnement. Chaque jour, nous faisons le bonheur de milliers de personnes grâce à nos offres, tout en intégrant la responsabilité écologique et sociale dans tous les aspects de notre activité. Nous croyons que des actions proactives aujourd’hui peuvent avoir un impact réel sur la santé de notre planète et le bien-être des générations futures.",
    "sustainability.action_title": "Rejoignez-nous",
    "sustainability.action_text": "Nous vous invitons à participer à notre démarche durable. Que ce soit en choisissant des produits éco-responsables ou en participant à nos programmes de recyclage, chaque petit geste compte.",
    "sustainability.plant_text": "Lors de votre achat, vous pouvez choisir de planter un arbre pour seulement 1 € de plus. Cela permet non seulement de compenser les émissions de CO₂, mais contribue également à la reforestation et à un avenir meilleur pour les communautés locales.",
    "sustainability.treeplanters": "Nous collaborons avec Tree-Planters, une organisation engagée dans la reforestation intelligente et durable. Les arbres sont plantés sur des sites soigneusement sélectionnés au Malawi, où ils contribuent non seulement à la restauration de la nature, mais aussi au soutien des populations locales grâce à des revenus et une sécurité alimentaire accrus.",
    "sustainability.action_text_believe": "Chez Outspot, nous croyons que la durabilité est une responsabilité partagée. Avec nos clients, nos collaborateurs et nos partenaires, nous œuvrons pour une relation plus consciente et respectueuse avec notre planète. Rejoignez-nous et faisons la différence ensemble !",
    "sustainability.plant_title": "Plantez un arbre – Faites la différence",
    "sustainability.trees": "arbres",
    "sustainability.tree": "arbre",
    "deal.advice_price": "",
    "sustainability.checkbox_title": "Plantez avec Outspot",
    "sustainability.checkbox_detail_text": "Nous vous invitons à planter des arbres avec Outspot pour {price} €."
  },
  "it": {
    "route.home": "https://www.outspot.it/it/",
    "route.fallback_domain": "https://www2.outspot.it",
    "language.code": "IT",
    "language.locale": "it-IT",
    "language.name": "Italia",
    "language.flag": "it",
    "language.date_input_format": "d,m,y",
    "language.currency": "EUR",
    "language.region": "eu",
    "countries.delivery": "ITA",
    "states.delivery": "",
    "tracking.aw_merchant_id": "5354933098",
    "tracking.aw_feed_country": "IT",
    "countries.BEL": "Belgio",
    "countries.LUX": "Lussemburgo",
    "countries.NLD": "Paesi Bassi",
    "countries.FRA": "Francia",
    "countries.DEU": "Germania",
    "countries.AUT": "Austria",
    "countries.ITA": "Italia",
    "countries.ESP": "Spagna",
    "countries.POL": "Polonia",
    "countries.GBR": "Regno Unito",
    "countries.IRL": "Irlanda",
    "countries.SWE": "Svezia",
    "countries.PRT": "Portogallo",
    "countries.FIN": "Finlandia",
    "countries.USA": "",
    "states.AL": "",
    "states.AR": "",
    "states.AZ": "",
    "states.CA": "",
    "states.CO": "",
    "states.CT": "",
    "states.DE": "",
    "states.FL": "",
    "states.GA": "",
    "states.IA": "",
    "states.ID": "",
    "states.IL": "",
    "states.IN": "",
    "states.KS": "",
    "states.KY": "",
    "states.LA": "",
    "states.MA": "",
    "states.MD": "",
    "states.ME": "",
    "states.MI": "",
    "states.MN": "",
    "states.MO": "",
    "states.MS": "",
    "states.MT": "",
    "states.NC": "",
    "states.ND": "",
    "states.NE": "",
    "states.NH": "",
    "states.NJ": "",
    "states.NM": "",
    "states.NV": "",
    "states.NY": "",
    "states.OH": "",
    "states.OK": "",
    "states.OR": "",
    "states.PA": "",
    "states.RI": "",
    "states.SC": "",
    "states.SD": "",
    "states.TN": "",
    "states.TX": "",
    "states.UT": "",
    "states.VA": "",
    "states.VT": "",
    "states.WA": "",
    "states.WI": "",
    "states.WV": "",
    "states.WY": "",
    "trustpilot.businessunit_id": "57036a9d0000ff00058b1211",
    "trustpilot.show": "TRUE",
    "mollie.profile_id": "pfl_VBdSeA3wDq",
    "mollie.locale": "it_IT",
    "facebook.url": "",
    "instagram.url": "",
    "linkedin.url": "https://www.linkedin.com/company/outspot/mycompany/",
    "header.hello": "Benvenuti",
    "header.hello_name": "Ciao <b>{firstName}</b>",
    "subscribe.title": "Iscriviti gratuitamente e per non perderti nemmeno un'offerta!",
    "subscribe.text": "Scopri le migliori offerte di Outspot. Iscriviti gratuitamente e approfitta di sconti esclusivi e personalizzati su prodotti ed esperienze uniche.",
    "subscribe.agree": "Iscrivendosi alla nostra newsletter, si accettano le <a href=\"{conditions}\" target=\"_blank\">condizioni generali</a> e <a href=\"{privacy}\" target=\"_blank\">dichiarazione sulla privacy</a>.",
    "subscribe.sent": "Grazie! Abbiamo ricevuto correttamente la tua domanda di iscrizione. Riceverai a breve un'e-mail per confermare la tua iscrizione.",
    "footer.follow": "Seguiteci",
    "footer.payment_methods_logos": "cartasi,visa,mastercard,amex,applepay,paypal",
    "footer.copyright": "Outspot — Sint-Martens-Latem, Belgio — Confinity NV",
    "home.new": "Ultime offerte",
    "home.ending": "Queste offerte stanno per scadere",
    "home.other": "Tutte le offerte",
    "countdown.prefix": "Ancora",
    "countdown.days": "giorno|giorni",
    "countdown.hours": "ora",
    "countdown.minutes": "min",
    "countdown.seconds": "sec",
    "countdown.days_compact": "d",
    "date.years": "{count} anni fa|{count} anni fa",
    "date.months": "{count} mese fa|{count} mesi fa",
    "date.weeks": "{count} settimana fa|{count} settimane fa",
    "date.days": "{count} giorno fa|{count} giorni fa",
    "date.hours": "{count} ore fa|{count} ore fa",
    "date.minutes": "{count} minuto fa|{count} minuti fa",
    "date.seconds": "Meno di 1 minuto fa",
    "countdown.suffix": "",
    "onboard.language_title": "Approfittate delle offerte personalizzate al miglior prezzo",
    "onboard.language_text": "Per prima cosa, configuriamo insieme il tuo account Outspot.",
    "onboard.notifications_title": "Tieniti aggiornato su:",
    "onboard.notifications_usp_1": "Le migliori e ultime offerte",
    "onboard.notifications_usp_2": "Aggiornamenti sugli ordini",
    "onboard.notifications_usp_3": "Codici sconto e buoni sconto personalizzati",
    "order.shipping_title": "Spedizione",
    "order.shipping_text": "A causa dell'aumento dei costi di trasporto e dell'impatto ambientale, siamo costretti ad addebitare le spese di trasporto. Tuttavia, Outspot ti garantisce comunque prezzi vantaggiosi e vincenti. Inoltre, le spese di trasporto non aumentano quando si acquistano più pezzi. Se sei membro di Outspot PLUS o se effettui altri acquisti entro un'ora dall'acquisto precedente, queste spese verranno detratte nella fase finale del pagamento.",
    "order.thank_you": "Grazie per l'ordine!",
    "order.overview": "Panoramica dell'ordine",
    "order.thank_you_bank": "Grazie per l'acquisto. Riceverete i dettagli del bonifico nella vostra casella di posta elettronica o nella posta indesiderata. Vi preghiamo di trasferire l'importo il prima possibile e comunque entro 7 giorni. Riceverete il buono ordinato via e-mail non appena il pagamento sarà arrivato ed elaborato.",
    "order.thank_you_with_shipping": "Il vostro acquisto è stato completato. Invieremo l'ordine all'indirizzo indicato di seguito. Riceverai la conferma dell'acquisto nella tua casella di posta elettronica o nella posta indesiderata. Goditi il tuo ordine.",
    "order.thank_you_without_shipping": "L'acquisto è completato. Riceverai la conferma dell'acquisto nella tua casella di posta elettronica o nella posta indesiderata. Goditi il tuo ordine.",
    "order.code": "Numero d'ordine: {code}",
    "order.shipping_address": "Indirizzo di consegna",
    "order.save_payment_info_title": "Conservare le coordinate bancarie per i miei prossimi acquisti.",
    "order.save_payment_info_text": "Se scegli questa opzione, non dovrai inserire i tuoi dati ogni volta. Le tue informazioni saranno conservate in modo sicuro.",
    "order.shipped_to_service_point": "",
    "my_outspot_plus.title": "Il mio Outspot PLUS",
    "my_outspot_plus.subtitle": "In questa pagina è possibile gestire la propria iscrizione a Outspot PLUS.",
    "my_outspot_plus.overview": "Panoramica dell'abbonamento Outspot PLUS",
    "my_outspot_plus.cancel_are_you_sure": "È sicuro di voler cancellare la tua iscrizione?",
    "my_outspot_plus.cancel_you_saved_prefix": "Hai già risparmiato",
    "my_outspot_plus.cancel_you_saved_suffix": "alle spese di spedizione e gestione con l'iscrizione a Outspot PLUS.",
    "my_outspot_plus.no_membership": "Al momento non hai un abbonamento Outspot PLUS.",
    "plus.title": "Outspot PLUS",
    "plus.popup_text": "Con Outspot PLUS benefici della spedizione gratuita su tutti gli articoli e non paghi nessun costo di gestione su tutti i viaggi. Dopo ogni ordine, riceverai un'e-mail che ti darà la possibilità di diventare Outspot PLUS. Tuttavia, è necessario essere veloci perché questa promozione è valida solo per 7 giorni. Non dimenticare di controllare la prossima e-mail di conferma dell'acquisto.",
    "plus.name": "Outspot PLUS",
    "plus.highlight_1": "Ordina senza preoccupazioni e senza spese di spedizione.",
    "plus.highlight_2": "L'abbonamento si rinnova automaticamente per {renewPrice} ogni tre mesi.",
    "plus.highlight_3": "È sempre possibile l'annullamento.",
    "plus.question_1": "Quali sono i vantaggi di Outspot PLUS?",
    "plus.answer_1": "Con Outspot PLUS, benefici della spedizione gratuita su tutti gli articoli e non paghi le spese di gestione per tutti i viaggi.",
    "plus.question_2": "Come faccio a diventare Outspot PLUS?",
    "plus.answer_2": "Dopo ogni ordine, riceverai un'e-mail che ti darà la possibilità di diventare Outspot PLUS. Tuttavia, è necessario essere rapidi perché questa promozione è valida solo per 7 giorni. Se si decide di attivare Outspot PLUS, i vantaggi entrano in vigore immediatamente. Ciò significa che al prossimo ordine potrai usufruire immediatamente della spedizione gratuita su tutti gli articoli e non dovrai pagare alcun costo di gestione per tutti i viaggi.",
    "plus.question_3": "Quanto costa Outspot PLUS?",
    "plus.answer_3": "Outspot PLUS costa solo {prezzo} per 3 mesi. Si tratta di un pagamento unico. Dopo questo periodo, l'iscrizione si rinnoverà automaticamente ogni 3 mesi al prezzo di {rinnovo}. Questo ti permette di risparmiare notevolmente sulle spese di spedizione e di gestione.",
    "plus.question_4": "Quando e come verrà rinnovato il mio Outspot PLUS?",
    "plus.answer_4": "Outspot PLUS si rinnova automaticamente ogni tre mesi. Per gestirlo puoi sempre andare su <a href=\"{myOutspotPlus}\">My Outspot PLUS</a>. L'importo verrà automaticamente detratto dal conto prescelto ogni 3 mesi.",
    "plus.question_5": "Come posso cancellare il mio abbonamento a Outspot PLUS? Riceverò un rimborso?",
    "plus.answer_5": "Puoi annullare la tua iscrizione a Outspot PLUS in qualsiasi momento tramite: <a href=\"{myOutspotPlus}\">My Outspot PLUS</a>. È importante notare che non sarà previsto alcun rimborso per i mesi in cui la tua iscrizione era attiva. Tuttavia, se decidi di annullare Outspot PLUS, non ti verrà addebitata la successiva fatturazione programmata.",
    "plus.thank_you": "Il tuo acquisto è completo. D'ora in poi potrai effettuare ordini senza preoccupazioni, senza costi di spedizione e gestione. Puoi visualizzare e gestire la tua iscrizione tramite <a href=\"{myOutspotPlus}\">My Outspot PLUS</a>.",
    "my_coupons.title": "I miei buoni acquisto",
    "my_coupons.subtitle": "Puoi trovare i tuoi buoni su questa pagina.",
    "my_coupons.no_coupons": "Al momento non ci sono voucher.",
    "deal.sold": "Già acquistato {sold} volte",
    "deal.retour_label": "Restituzione entro 30 giorni per soli 0,95 €",
    "deal.retour_title": "Diritto di recesso",
    "deal.retour_text": "Non ti piace il prodotto? Contatta il nostro helpdesk entro 30 giorni dal ricevimento e restituisci il prodotto. E per qualsiasi motivo! Ti rimborseremo l'acquisto e potrai ordinare senza problemi. Un'altra grande idea, naturalmente da Outspot!",
    "deal.guarantee_label": "Garanzia di rimborso di 6 mesi",
    "deal.guarantee_title": "Unico: garanzia di rimborso del 100%.",
    "deal.guarantee_text": "Incredibile ma vero. Non dovrai più esitare ad acquistare un'esperienza per i tuoi amici/colleghi/familiari o per te stesso. Se poi cambi idea o se il destinatario non è completamente soddisfatto del buono regalo, non c'è alcun problema.<br><br>Su Outspot.it potrai annullare l'acquisto fino a 6 mesi dopo l'ordine! Te o la persona che ha ricevuto il buono potrete ottenere un rimborso completo entro 6 mesi. Naturalmente, a condizione che il buono non sia ancora stato prenotato, utilizzato o scaduto. Come garanzia, questa è eccezionale e mai vista. Ma Outspot è fatto così.<br><br>Si noti che per tutte le azioni in cui la garanzia di rimborso non è esplicitamente menzionata, la garanzia non si applica. Questo perché in alcuni casi non possiamo recuperare noi stessi le consegne/biglietti dal nostro partner.",
    "deal.option_count": "{count} opzione|{count} opzioni",
    "deal.campaign_number": "Numero della campagna",
    "deal.your_details": "I vostri dati",
    "deal.payment_methods_title": "Metodo di pagamento",
    "deal.shipping_methods_title": "",
    "deal.privacy": "Inserendo i tuoi dati, accetti <a target='_blank' href='{privacy}'>la nostra dichiarazione sulla privacy</a>.",
    "deal.payment_methods_subtitle": "Come desideri pagare il tuo ordine?",
    "deal.your_order": "Il tuo ordine",
    "deal.from": "Da",
    "help.title": "Hai bisogno di aiuto?",
    "help.subtitle": "Facci sapere come possiamo aiutarti.",
    "help.subject": "La tua domanda tramite il nostro sito web o l'app",
    "help.sent": "Abbiamo ricevuto correttamente il tuo messaggio. Riceverai una risposta da parte nostra entro e non oltre 4 giorni lavorativi.",
    "login.title": "Accedi",
    "login.subtitle": "Bentornato/a! Come vuoi accedere?",
    "login.or": "oppure",
    "login.usps_title": "Perché accedere a Outspot?",
    "login.usps_title_cta": "Semplificati le cose e accedi o crea un account adesso.",
    "login.usps_text": "Fare clic sul pulsante \"Accedi\" in alto per effettuare il login. Se non hai ancora un account Outspot, puoi registrarti nella pagina di accesso. Di seguito sono elencati alcuni dei vantaggi di un account Outspot:",
    "login.usp_manage_orders": "Visualizzare e seguire gli ordini",
    "login.usp_remember_info": "Memorizzare i dettagli dell'indirizzo per i futuri acquisti",
    "login.usp_returns": "Segnalare un difetto o richiedere un reso",
    "login.usp_store_payments": "Salva i tuoi metodi di pagamento e paga più facilmente",
    "login.short_cta": "<a href=\"{loginLink}\">Accedi</a> o continua qui sotto come ospite.",
    "forgot.title": "Richiedi una nuova password",
    "forgot.subtitle": "Inserisci il tuo indirizzo e-mail qui sotto e ti invieremo un link per reimpostare la tua password.",
    "forgot.sent": "Le abbiamo inviato un'e-mail con un link per reimpostare la password.",
    "password.title": "Modifica della password",
    "password.subtitle": "Scegliere una nuova password sicura.",
    "password.updated": "La password è stata modificata. D'ora in poi è possibile accedere con la nuova password.",
    "register.title": "Registrati",
    "register.subtitle": "Crea un account per semplificare ulteriormente le operazioni dell'acquisto e la tracciabilità dei tuoi ordini.",
    "register.personal_info": "Dati personali",
    "register.login_info": "Dati di accesso",
    "register.agree": "Sono d'accordo con le <a href=\"/enl/help/general-conditions\" target=\"_blank\">general-conditions</a> e la <a href=\"/enl/help/privacy\" target=\"_blank\">privacy statement</a>.",
    "register.sent": "Ci sei quasi. Ti abbiamo inviato un'e-mail per attivare il tuo account.",
    "register.email_known": "Il tuo indirizzo email è già noto a Outspot? <a href=\"{forgot}\">Fai clic qui</a> per richiedere una password.",
    "my_orders.title": "I miei ordini",
    "my_orders.subtitle": "Questa è una panoramica di tutti gli ordini effettuati su Outspot.",
    "my_orders.title_detail": "Dettagli del tuo ordine",
    "my_travels.title": "I miei viaggi",
    "my_info.title": "I miei dati",
    "my_info.subtitle": "Qui è possibile modificare i propri dati personali.",
    "my_info.sent": "I suoi dati sono stati aggiornati.",
    "notifications.title": "Newsletter e notifiche",
    "notifications.subscription_mail": "Quante volte desideri ricevere la nostra newsletter?",
    "notifications.subscription_notification": "Quante volte desideri ricevere le notifiche?",
    "notifications.frequency_0": "Mai",
    "notifications.frequency_4": "Una volta alla settimana",
    "notifications.frequency_56": "Due volte al giorno",
    "notifications.sent": "Le tue preferenze sono state aggiornate.",
    "combined_purchase_banner.text": "Non paghi le spese di spedizione o di gestione per i tuoi ordini successivi durante:",
    "plus_banner.text_welcome": "{firstName}, benvenuto in Outspot PLUS.",
    "plus_banner.text_welcome_anonimous": "Benvenuto in Outspot PLUS.",
    "plus_banner.text_savings_prefix": "{firstName}, è già stato salvato",
    "plus_banner.text_savings_prefix_anonimous": "È già stato salvato",
    "plus_banner.text_savings_suffix": "alle spese di spedizione e gestione tramite Outspot PLUS.",
    "cookie_banner.title": "Cookies",
    "cookie_banner.text": "Questo sito web utilizza i cookie. I cookie strettamente necessari garantiscono il corretto funzionamento del sito web e non possono essere rifiutati. I cookie funzionali, analitici e pubblicitari vengono utilizzati rispettivamente per scopi funzionali, statistici e di marketing. Vengono scaricati solo se acconsenti. Leggi di più nella nostra <a href=\"{cookies}\" target=\"_blank\">politica sui cookie</a>.",
    "cookie_banner.decline": "Rifiuta i cookie opzionali",
    "cookie_banner.accept_all": "Accetta tutti i cookie",
    "cookie_banner.accept_selected": "Accetta selezionati",
    "cookie_banner.settings": "Impostazioni",
    "cookie_banner.functional_cookies_title": "Cookie strettamente necessari",
    "cookie_banner.functional_cookies_text": "Cookie necessari per il funzionamento di base del sito web.",
    "cookie_banner.analytical_cookies_title": "Cookie analitici e funzionali",
    "cookie_banner.analytical_cookies_text": "I cookie consentono al sito web di offrire funzionalità aggiuntive e impostazioni personali, nonché visite e traffico.",
    "cookie_banner.personal_cookies_title": "Cookie pubblicitari",
    "cookie_banner.personal_cookies_text": "Cookie impostati dagli inserzionisti per creare un profilo dei tuoi interessi senza memorizzare informazioni personali dirette.",
    "review.title": "Parlarne e vinci",
    "review.subtitle": "Pubblica qui di seguito la recensione del tuo acquisto per avere la possibilità di vincere subito un buono da 250 euro (ne mettiamo in palio uno ogni trimestre).",
    "review.agree": "Accetto che la mia recensione sia pubblicata sul sito web",
    "review.sent": "Grazie per il tuo commento. Ora ha la possibilità di vincere un buono da 250 euro.",
    "review.title_thank_you": "Grazie. Apprezziamo il tuo feedback.",
    "review.subtitle_thank_you": "Grazie al tuo feedback, possiamo rendere Outspot ancora migliore. Pubblica qui sotto la tua recensione del tuo acquisto e avrai la possibilità di vincere un buono da € 250 (mettiamo in palio questo buono ogni trimestre).",
    "confirm.title": "La vostra registrazione è confermata",
    "confirm.subtitle": "L'iscrizione alla newsletter è confermata. Grazie! Per servirvi ancora meglio, vi offriamo la possibilità di condividere con noi i seguenti dati senza alcun obbligo.",
    "popup_widget.title": "Le migliori offerte testate sulla nostra pelle.",
    "popup_widget.usp_1": "Ricevi ogni giorno le nostre migliori offerte.",
    "popup_widget.usp_2": "Tutte le offerte sono state testate e selezionate per miglior prezzo e qualità.",
    "popup_widget.usp_3": "Sconti fissi fino al -70%.",
    "popup_widget.footer_1": "Iscriviti gratuitamente alla nostra newsletter e risparmia fin da subito.",
    "popup_widget.footer_2": "Puoi cancellare la tua iscrizione in qualsiasi momento.",
    "promo.title": "Le nostre migliori promozioni",
    "promo.countdown": "2024-12-31 23:59:59",
    "promo.empty": "Attualmente non abbiamo promozioni top. Vai alla nostra home page per le ultime offerte.",
    "stars.info_title": "Valutazione del prodotto",
    "stars.info_text": "Questo punteggio è una media delle nostre recensioni raccolte e di quelle di altri utenti.",
    "stars.counter": "1 recensione | {count} recensioni",
    "label.accept_selected": "Accettare il selezionato",
    "label.edit": "Modifica",
    "label.edit_address": "Modifica dell'indirizzo",
    "label.address": "Indirizzo",
    "label.accept_all": "Accettare tutti",
    "label.account": "Account",
    "label.help": "Aiuto",
    "label.my_orders": "I miei ordini",
    "label.my_travels": "I miei viaggi",
    "label.my_coupons": "I miei buoni acquisto",
    "label.my_info": "I miei dati",
    "label.change_password": "Modifica della password",
    "label.notifications": "Newsletter e notifiche",
    "label.logout": "Disconnettersi",
    "label.login": "Accedi",
    "label.login_have_account": "Hai già un account? effettua l'accesso",
    "label.login_with_facebook": "Accedi con Facebook",
    "label.login_with_google": "Accedi con Google",
    "label.login_with_apple": "Accedi con Apple",
    "label.password": "Password",
    "label.new_password": "Nuova password",
    "label.repeat_password": "Ripeti la password",
    "label.repeat_new_password": "Ripetere la nuova password",
    "label.register_no_account": "Non hai ancora un account? Registrati ora",
    "label.register": "Registrati",
    "label.forgot_password": "Non hai ancora o hai dimenticato la password?",
    "label.general_conditions": "Termini e condizioni generali",
    "label.jobs": "Offerte di lavoro presso Outspot",
    "label.faq": "Domande frequenti",
    "label.returns": "Reso",
    "label.travel_conditions": "Condizioni di viaggio specifiche",
    "label.privacy_policy": "Informativa sulla privacy",
    "label.cookie_policy": "Dichiarazione sui cookie",
    "label.subscribe": "Iscrivimi",
    "label.all_deals": "Tutte le offerte",
    "label.other_deals": "Altre offerte",
    "label.discount": "Sconto",
    "label.close": "Chiudere",
    "label.order_now": "Ordina ora",
    "label.add": "Aggiungi",
    "label.book": "Prenota ora",
    "label.upselling_next_step": "Non dimenticarti anche questo punto",
    "label.upselling": "Non dimenticarti anche questo",
    "label.also_available": "",
    "label.reviews": "Cosa dicono i nostri clienti",
    "label.automatically_translated": "Tradotto automaticamente",
    "label.show_all_reviews": "Vedi tutte le recensioni",
    "label.back": "Indietro",
    "label.make_your_choice": "Fai la tua scelta",
    "label.sold_out": "Esaurito",
    "label.order_more": "Ordina di più",
    "label.subtotal": "Subtotale",
    "label.total_shipping": "Costi di spedizione",
    "label.total_file": "Costi di gestione",
    "label.total_payable": "Totale",
    "label.no_options_selected": "Il tuo ordine è attualmente vuoto. Fare clic su \"Aggiungi\" qui sotto per continuare.",
    "label.no_options_selected_extra": "È possibile selezionare questa opzione solo al momento dell'acquisto del prodotto.",
    "label.total_saved_prefix": "(Risparmi",
    "label.total_saved_suffix": ")",
    "label.continue": "Avanti",
    "label.usp_quality": "Selezionato per rapporto qualità-prezzo",
    "label.usp_discounts": "Sconti sostanziali fino al -70%",
    "label.usp_payment_methods": "Pagare in modo sicuro e semplice con",
    "label.person": "Persona",
    "label.free": "Gratuito",
    "label.first_name": "Nome",
    "label.last_name": "Cognome",
    "label.birthdate": "Data di nascita",
    "label.birthdate_why": "",
    "label.birthdate_why_text": "",
    "label.email": "Indirizzo e-mail",
    "label.phone": "Numero di telefono",
    "label.address_finder": "Trova l'indirizzo",
    "label.address_finder_help": "Inserisci il tuo indirizzo di consegna completo",
    "label.select": "Selezionare",
    "label.street": "Via",
    "label.building": "",
    "label.number": "Nr/interno",
    "label.zip": "Codice postale",
    "label.city": "Comune",
    "label.state": "",
    "label.country": "Paese",
    "label.finish_order": "Ordina & paga",
    "label.finish_order_help": "Fare clic sul pulsante \"Ordina & paga\" per completare il pagamento.",
    "label.enter_address": "Inserire manualmente il mio indirizzo",
    "label.add_address": "Aggiungere un indirizzo",
    "label.add_payment_method": "Aggiungere un metodo di pagamento",
    "label.year": "AAAA",
    "label.month": "MM",
    "label.day": "GG",
    "label.business_order": "Ordine commerciale",
    "label.company_name": "Nome della società",
    "label.tax_id": "Numero di partita IVA",
    "label.invoice_reason": "Motivo",
    "label.invoice_reasons": "Regali aziendali, team building, incentivi, seminario, altro",
    "label.delete": "Rimuovere",
    "label.i_have_coupons": "Ho dei voucher",
    "label.email_outspot_plus": "Siete già clienti di Outspot PLUS? Inserisci il tuo indirizzo e-mail.",
    "label.please_wait": "Prego, attendere...",
    "label.please_wait_2": "Solo un po' più di pazienza...",
    "label.please_wait_3": "ci dispiace, ci sta mettendo un po' più del previsto...",
    "label.please_wait_4": "Ancora un po', ci siamo quasi...",
    "label.please_wait_5": "Ci scusiamo per l'inconveniente e ti ringraziamo per la pazienza, ci siamo quasi.",
    "label.continue_shopping": "Ulteriori acquisti",
    "label.my_code": "Il mio codice",
    "label.validate": "Convalidare",
    "label.total_coupons": "Totale buoni",
    "label.coupons": "Buoni",
    "label.total_payable_with_coupons_prefix": "(Ancora da pagare ",
    "label.total_payable_with_coupons_suffix": ")",
    "label.rest_coupon_info": "L'importo da pagare è inferiore ai voucher inseriti. Riceverai un nuovo buono con il valore rimanente.",
    "label.contact_extra_info": "Numero d'ordine, numero di voucher o comunicazione strutturata",
    "label.contact": "Contattaci",
    "label.message": "Messaggio",
    "label.send": "Inviare",
    "label.save": "conferma",
    "label.my_outspot_plus": "Il mio Outspot PLUS",
    "label.order_date_prefix": "Ordinato il",
    "label.order_date_suffix": "",
    "label.total_paid_with": "Totale pagato con  {method}",
    "label.track_order": "Traccia il tuo ordine",
    "label.return_order": "Effettuare il reso e/o segnalazione un difetto",
    "label.download_invoice": "Scarica la fattura",
    "label.ask_question": "Fai una domanda",
    "label.happy_hour_discount": "Benefici dell' Happy Hour",
    "label.outspot_plus_discount": "Vantaggio Outspot PLUS",
    "label.cancel_membership": "Annullamento dell'iscrizione",
    "label.membership_start_date": "Data di inizio dell'iscrizione",
    "label.membership_price": "Prezzo per 3 mesi",
    "label.membership_next_payment": "Prossimo pagamento",
    "label.membership_end_date": "Data di scadenza dell'iscrizione",
    "label.total_savings": "Totale spese di spedizione e gestione risparmiate",
    "label.confirm_cancel_membership": "Confermare la cancellazione",
    "label.continue_membership": "Non annullare",
    "label.valid_till_prefix": "Valido fino a",
    "label.valid_till_suffix": "",
    "label.card_payment": "Pagamento con carta",
    "label.place_review": "Invio commento",
    "label.name": "Nome",
    "label.name_help": "li mostriamo nella valutazione",
    "label.review": "Recensione",
    "label.self_service": "",
    "label.update_cookie_preferences": "Modificare le mie preferenze sui cookie",
    "label.more_info": "Più informazioni",
    "label.review_source": "Tramite {source}",
    "label.amount": "Importo",
    "label.iban": "IBAN",
    "label.bic": "BIC",
    "label.beneficiary": "Beneficiario",
    "label.structured_communication": "Messaggio strutturato",
    "label.later": "Dopo",
    "label.enable_notifications": "Attiva le notifiche",
    "label.card_number": "Numero della carta",
    "label.card_expiry": "Data di scadenza",
    "label.card_cvc": "CVV",
    "label.cvc_info": "",
    "label.sales_tax": "",
    "label.calculating_sales_tax": "",
    "label.sales_tax_will_be_calculated": "",
    "label.ship_to_selected_address": "",
    "label.ship_to_service_point": "",
    "label.change_service_point": "",
    "label.choose_rooms": "",
    "label.room": "",
    "label.room_occupancy": "",
    "label.add_another_room": "",
    "label.number_of_adults": "",
    "label.number_of_adults_title": "",
    "label.number_of_children": "",
    "label.number_of_children_title": "",
    "label.choose_arrival_and_departure_date": "",
    "label.arrival_date": "",
    "label.select_arrival_date": "",
    "label.departure_date": "",
    "label.select_departure_date": "",
    "label.rate_type_pc": "",
    "label.rate_type_pcpd": "",
    "label.rate_type_pcpn": "",
    "label.rate_type_pp": "",
    "label.rate_type_pppd": "",
    "label.rate_type_pppn": "",
    "label.rate_type_pp_": "",
    "label.rate_type_pa": "",
    "label.rate_type_papd": "",
    "label.rate_type_papn": "",
    "label.rate_type_pa_": "",
    "label.choose_supplements": "",
    "label.opengds_invalid": "",
    "error.unknown": "Errore sconosciuto, riprovare più tardi.",
    "error.required": "{field} non può essere vuoto.",
    "error.required_email": "{field} non può essere vuoto e deve essere un indirizzo e-mail valido.",
    "error.required_password": "La {campo} deve essere lunga almeno 8 caratteri e contenere almeno una lettera maiuscola, una lettera minuscola e un numero.",
    "error.required_repeat_password": "{field} deve essere uguale alla password specificata nel campo {field2}.",
    "error.payment": "Purtroppo il pagamento non è andato a buon fine. Questo può accadere se c'è un problema con i dettagli del pagamento, se il pagamento è stato annullato, se il limite di pagamento è stato raggiunto o per problemi con l'autenticazione per il pagamento . Considera la possibilità di contattare la tua banca o di provare un'altra carta o un altro metodo di pagamento.",
    "error.payment_creditcard": "Peccato. Il pagamento non è andato a buon fine. Controlla i dati sottostanti e riprova.",
    "error.incomplete_order": "Possiamo convalidare questo voucher solo dopo che hai compilato tutti i campi qui sopra.",
    "error.coupon_already_added": "Hai già aggiunto questo buono.",
    "error.invalid_coupon": "Questo buono non è valido.",
    "error.coupon_not_cumulative": "Non è possibile utilizzare questo buono insieme ad altri buoni.",
    "error.other_coupon_not_cumulative": "Il buono già aggiunto non può essere utilizzato insieme a questo buono.",
    "error.invalid_username_or_password": "L'indirizzo e-mail e/o la password forniti non sono validi.",
    "error.test_order": "Si tratta di un ordine di prova. Normalmente, il cliente passa alla pagina di pagamento.",
    "error.required_agree": "È necessario accettare i nostri termini e condizioni e l'informativa sulla privacy.",
    "error.required_review_agree": "L'utente deve accettare che la sua recensione venga pubblicata sul nostro sito web.",
    "error.register": "Esiste già un account con questo indirizzo e-mail. Fare clic su \"Continua\" per accedere o utilizzare un altro indirizzo e-mail.",
    "error.email_in_use": "Esiste già un account con questo indirizzo e-mail. Si prega di utilizzare un altro indirizzo e-mail.",
    "error.already_reviewed": "Hai già inviato una recensione per questo ordine.",
    "error.zip_blocked": "Non è possibile fare una spedizione al CAP inserito.",
    "error.details": "Sembra che uno o più prodotti che si sta cercando di ordinare non siano più disponibili. Tornare al punto precedente, aggiornare l'ordine e riprovare.",
    "error.incomplete_cvc": "CVV è troppo corto.",
    "error.incomplete_date": "La data di scadenza non può essere vuota.",
    "error.date_in_past": "La carta è scaduta.",
    "error.invalid_expiration_year": "La carta è scaduta.",
    "error.incomplete_card_number": "Il numero di carta è troppo corto.",
    "error.incorrect_card_number": "Il numero di carta non è valido.",
    "unsubscription.your_mail_address": "Il tuo indirizzo e-mail: ",
    "unsubscription.sad_message": "Con la presente si annulla l'iscrizione.",
    "unsubscription.have_not_participated": "Non ti sei mai iscritto? ",
    "unsubscription.sad_message_2": "Ci dispiace che tu ci abbia lasciato, ma ovviamente sarai sempre il benvenuto.",
    "unsubscription.resubscribe_question": "Hai cambiato idea? <span class='unsub-important-message'>Clicca qui sotto per iscriverti di nuovo alla nostra newsletter:</span>",
    "unsubscription.form_message": "Perché lasci la famiglia Outspot? La tua risposta può aiutarci a migliorare i nostri servizi.",
    "unsubscription.report_not_participated": "Segnala un abuso",
    "unsubscription.coreg_date_no_screenshot": "Il {date} hai partecipato a una campagna (<a href=\"{coreg_site}\">{coreg_site}</a>) e in quell’occasione hai espresso il tuo consenso a ricevere la nostra newsletter con le offerte speciali.",
    "unsubscription.coreg_date_no_coreg_data": "Il {date} hai partecipato a una campagna e in quell’occasione hai espresso il tuo consenso a ricevere la nostra newsletter con le offerte speciali.",
    "unsubscription.coreg_date_screenshot": "Il {date} hai partecipato a una campagna e in quell’occasione hai espresso il tuo consenso a ricevere la nostra newsletter con le offerte speciali.",
    "unsubscription.coreg_no_date_no_screenshot": "Hai partecipato alla seguente campagna (in evidenza qui sotto) e in quell’occasione hai espresso il tuo consenso a ricevere la nostra newsletter con le offerte speciali.",
    "unsubscription.coreg_no_date_screenshot": "Hai partecipato alla seguente campagna (in evidenza qui sotto) e in quell’occasione hai espresso il tuo consenso a ricevere la nostra newsletter con le offerte speciali.",
    "unsubscription.main_title": "Disiscriviti dalla newsletter",
    "unsubscription.report_title": "Segnala un abuso",
    "unsubscription.report_sent_message": "Grazie, la notifica è stata ricevuta.",
    "claim_coupon.welcome_message": "Solo per te",
    "claim_coupon.info_line_message": "Inserisci il codice personale sopra al momento del pagamento.",
    "claim_coupon.info_line_message_bis": "Questo buono ti è stato anche inviato via email.",
    "claim_coupon.main_title": "Il tuo coupon",
    "about.title": "Su di noi",
    "about.intro": "Benvenuto in Outspot, la tua meta numero uno per offerte irresistibili! Dalla sua fondazione nel 2008, Outspot è diventato il sito web leader in Europa per le offerte lampo. Outspot offre sconti giornalieri su prodotti di qualità e viaggi, che ti permette, come cliente, di risparmiare enormemente. Grazie ai nostri magazzini situati in Europa, possiamo consegnare i tuoi ordini il prima possibile a casa tua.\"",
    "about.mission_title": "Missione",
    "about.mission_text": "Noi di Outspot crediamo nel rendere accessibile offerte eccezionali per tutti. Il nostro obiettivo è offrire ai nostri clienti il miglior valore possibile, mettendo a disposizione offerte selezionate a prezzi imbattibili.",
    "about.history_title": "Storia",
    "about.history_text": "L'avventura di Outspot è iniziata in Belgio, dove si trova tuttora la sede centrale . Con un team dedicato di 25 dipendenti sparsi in tutta Europa, lavoriamo ogni giorno per trovare e offrire le migliori offerte. Ora siamo attivi in 13 paesi e abbiamo 100.000 visitatori soddisfatti ogni giorno.",
    "about.quality_title": "La nostra offerta è stata selezionata in base al prezzo e alla qualità",
    "about.quality_text": "In Outspot crediamo che tu meriti il ​​meglio di entrambi i mondi: massima qualità ad un prezzo accessibile. Ecco perché andiamo ogni giorno ancora una volta alla ricerca delle offerte più interessanti per te. Per mezzo di Ci assicuriamo di lavorare a stretto contatto con i nostri fornitori poter negoziare i migliori prezzi senza fare concessioni alla qualità. <br /><br />  Ogni prodotto e viaggio che offriamo è realizzato con cura selezionati sulla base di una rigorosa valutazione del prezzo e qualità. In questo modo puoi essere sicuro di ottenere sempre il miglior valore con noi prendi per i tuoi soldi. In Outspot tutto ruota attorno ai tuoi vantaggi e vantaggi soddisfazione! Di seguito puoi vedere una foto del suono della smartbell testata con un misuratore di decibel.",
    "about.tested_title": "Tutte le offerte sono state ampiamente testate dai nostri colleghi di Outspot",
    "about.tested_text": "Noi di Outspot crediamo nel fornire solo il meglio. Prima che un accordo appaia sul nostro sito, viene dettagliato testato dai nostri colleghi di Outspot. Non si accontentano con test superficiali: i prodotti vengono completamente smontati raggiunto e ogni componente viene attentamente valutato resistenza, livello di rumore, qualità dei materiali e altro ancora.<br /><br />  I nostri colleghi visitano personalmente anche molti hotel per... per verificare la qualità degli alloggi. Ecco come garantiamo che tutto soddisfi i nostri elevati standard. Che si tratti di a prodotto o un viaggio, puoi star certo che ci sarai Outspot effettua sempre i tuoi acquisti con fiducia.<br /><br />  Nella foto sotto puoi vedere come i colleghi producono prodotti visionare e testare in dettaglio: ad es. controllo qualità del tessuto.",
    "about.guarantee_title": "Garanzia di rimborso di 6 mesi sulla maggior parte dei viaggi",
    "about.guarantee_text": "Quando prenoti un viaggio vuoi essere sicuro di fare la scelta giusta e che il tuo investimento sia ben speso. Ecco perché offriamo una garanzia di rimborso di 6 mesi sulla maggior parte dei nostri viaggi. Con questa garanzia potrai stare tranquillo, sapendo che in caso di cancellazioni o modifiche, potresti avere il diritto ad un rimborso. Questo periodo ti offre la libertà di organizzare e gestire il tuo viaggio senza preoccupazioni ",
    "about.returns_title": "Politica di restituzione di 30 giorni sulla maggior parte dei prodotti",
    "about.returns_text": "Quando prenoti un viaggio, vuoi essere sicuro che la tua scelta sia quella giusta e che il tuo investimento sia ben speso. Per questo motivo offriamo una garanzia di rimborso di 6 mesi sulla maggior parte dei nostri viaggi. Con questa garanzia, puoi pianificare con tranquillità, sapendo che hai sempre diritto a un rimborso, a condizione che non sia stata ancora effettuata una prenotazione. Questo ti dà la libertà di rimanere flessibile nelle tue decisioni, senza il rischio di costi aggiuntivi finché non viene effettuata una prenotazione definitiva.",
    "about.support_title": "Servizio clienti in italiano",
    "about.support_text": "In Outspot, la soddisfazione del cliente viene prima di tutto e facciamo tutto il possibile per assicurarti un'esperienza eccellente. Se hai domande o hai bisogno di aiuto con il tuo ordine, il nostro servizio clienti è sempre lì per te. I nostri  dipendenti di lingua italiana sono disponibili per aiutarti rapidamente, in modo amichevole ed efficiente. Ci sforziamo di accontentarti per aiutarti in modo personale e comprensibile, in modo che tu possa goderti il ​​tuo acquisto senza preoccupazioni. La tua soddisfazione è la nostra priorità e siamo qui per assicurarci che tu possa trarne i migliori benefici dal nostro servizio.",
    "about.socials_title": "Rimani connesso",
    "about.socials_text": "Rimani aggiornato sulle nostre ultime ed esclusive offerte iscrivendoti alla nostra newsletter. Seguici sui social e condividi con noi le tue esperienze Outspot!<br /><br />  Hai domande o commenti? Il nostro helpdesk è pronto ad assisterti per aiutare. Contattaci a <a href=\"{help}\">la nostra pagina dei contatti</a>.<br /><br />  Grazie per la fiducia riposta in Outspot. Non vediamo l'ora per sorprenderti ancora e ancora con le nostre fantastiche offerte!",
    "vat.error_required": "Il campo \"{field}\" non può essere vuoto per un ordine aziendale.",
    "vat.validation_message_format": "Il campo \"{field}\" non è nel formato corretto.",
    "vat.help_title": "Partita IVA",
    "vat.help_text": "Il numero di partita IVA in Italia deve essere composto da 13 caratteri, ad esempio IT12345678901.",
    "label.download": "Scaricare",
    "error.download_pdf": "Qualcosa è andato storto durante il download del PDF. Riprovare più tardi.\r ",
    "manage_address.title": "I miei indirizzi di consegna",
    "error.select": "Selezionare un {field}",
    "manage_cards.title": "I miei metodi di pagamento",
    "deal.quality_text_1": "Selezionato per rapporto qualità-prezzo",
    "deal.quality_text_2": "",
    "deal.delivery_text_days": "Tempo di consegna: {days} giorni lavorativi",
    "deal.delivery_text": "",
    "maintenance.going_offline": "Tra pochi minuti il nostro sito web sarà temporaneamente offline per una manutenzione programmata. Completa subito il tuo ordine, perché durante la manutenzione non sarà possibile ordinare per un po' di tempo.",
    "maintenance.offline": "Il nostro sito web è attualmente offline per manutenzione programmata. Ti preghiamo di riprovare tra qualche minuto: saremo di nuovo online il prima possibile! Come gesto di cortesia, ti offriamo GMJ2QPZ3  per uno sconto di 5 € sul tuo prossimo acquisto.",
    "sustainability.carpark_title": "Rendere la nostra flotta di veicoli più ecologica",
    "sustainability.carpark_text": "Nel nostro impegno continuo a ridurre l’impronta ecologica, stiamo trasformando la nostra flotta aziendale. Entro il 2026 puntiamo ad avere una flotta completamente elettrica. Questa transizione è un passo cruciale per ridurre le emissioni e promuovere un trasporto più pulito e sostenibile.",
    "sustainability.energy_title": "Ufficio alimentato a energia solare",
    "sustainability.energy_text": "La sostenibilità inizia a casa, e per noi significa il nostro ufficio. Abbiamo installato 30 pannelli solari, che producono quasi la metà dell'elettricità necessaria per le nostre attività. Sfruttando l'energia solare, riduciamo significativamente la nostra dipendenza da fonti energetiche non rinnovabili e abbassiamo le nostre emissioni totali di CO₂.",
    "sustainability.eco_packaging_title": "Imballaggi ecologici",
    "sustainability.eco_packaging_text": "Siamo consapevoli dell'impatto dei rifiuti di imballaggio sull'ambiente. Per questo tutti i nostri imballaggi sono realizzati in plastica 100% riciclabile. Questa iniziativa aiuta a creare un ciclo più sostenibile di utilizzo e riutilizzo.",
    "sustainability.combined_packaging_title": "Spedizioni consolidate",
    "sustainability.combined_packaging_text": "Per ridurre ulteriormente il nostro impatto ambientale, incoraggiamo i clienti a consolidare gli ordini. Raggruppando più acquisti in una sola spedizione, riduciamo il numero di consegne, diminuendo così le emissioni e l'impronta ecologica.",
    "sustainability.title": "Sostenibilità in Outspot",
    "sustainability.vision_title": "La nostra visione",
    "sustainability.vision_text": "Outspot è consapevole del suo impatto sull'ambiente. Ogni giorno rendiamo felici migliaia di persone con la nostra offerta, integrando la responsabilità ecologica e sociale in ogni aspetto della nostra attività. Crediamo che, agendo in modo proattivo oggi, possiamo influire positivamente sulla salute del nostro pianeta e sul benessere delle generazioni future.",
    "sustainability.action_title": "Unisciti a noi",
    "sustainability.action_text": "Ti invitiamo a partecipare al nostro viaggio verso la sostenibilità. Dalla scelta di prodotti ecologici alla partecipazione ai nostri programmi di riciclo, ogni piccolo gesto fa la differenza.",
    "sustainability.plant_text": "Al momento dell'acquisto, puoi scegliere di piantare un albero per soli 1€ in più. Questo non solo aiuta a compensare le emissioni di CO₂, ma contribuisce anche alla riforestazione e a un futuro migliore per le comunità locali.",
    "sustainability.treeplanters": "Collaboriamo con Tree-Planters, un'organizzazione impegnata nella riforestazione intelligente e sostenibile. Piantano alberi in aree accuratamente selezionate in Malawi, ripristinando la natura e sostenendo le popolazioni locali con redditi aggiuntivi e risorse alimentari.",
    "sustainability.action_text_believe": "In Outspot crediamo che la sostenibilità sia una responsabilità condivisa. Insieme a clienti, dipendenti e partner, ci impegniamo per un rapporto più consapevole con il nostro pianeta. Unisciti a noi e facciamo la differenza insieme!",
    "sustainability.plant_title": "Piantare un albero – Fare la differenz",
    "sustainability.trees": "albero ",
    "sustainability.tree": "alberi",
    "deal.advice_price": "",
    "sustainability.checkbox_title": "Pianta insieme a Outspot",
    "sustainability.checkbox_detail_text": "Ti invitiamo a piantare alberi insieme a Outspot per  {price}."
  },
  "at": {
    "route.home": "https://www.outspot.at/at/",
    "route.fallback_domain": "https://www2.outspot.at",
    "language.code": "DE",
    "language.locale": "de-DE",
    "language.name": "Österreich",
    "language.flag": "at",
    "language.date_input_format": "d,m,y",
    "language.currency": "EUR",
    "language.region": "eu",
    "countries.delivery": "AUT",
    "states.delivery": "",
    "tracking.aw_merchant_id": "731101145",
    "tracking.aw_feed_country": "AT",
    "countries.BEL": "Belgien",
    "countries.LUX": "Luxemburg",
    "countries.NLD": "Niederlande",
    "countries.FRA": "Frankreich",
    "countries.DEU": "Deutschland",
    "countries.AUT": "Österreich",
    "countries.ITA": "Italien",
    "countries.ESP": "Spanien",
    "countries.POL": "Polen",
    "countries.GBR": "Vereinigtes Königreich",
    "countries.IRL": "Irland",
    "countries.SWE": "Schweden",
    "countries.PRT": "Portugal",
    "countries.FIN": "Finnland",
    "countries.USA": "",
    "states.AL": "",
    "states.AR": "",
    "states.AZ": "",
    "states.CA": "",
    "states.CO": "",
    "states.CT": "",
    "states.DE": "",
    "states.FL": "",
    "states.GA": "",
    "states.IA": "",
    "states.ID": "",
    "states.IL": "",
    "states.IN": "",
    "states.KS": "",
    "states.KY": "",
    "states.LA": "",
    "states.MA": "",
    "states.MD": "",
    "states.ME": "",
    "states.MI": "",
    "states.MN": "",
    "states.MO": "",
    "states.MS": "",
    "states.MT": "",
    "states.NC": "",
    "states.ND": "",
    "states.NE": "",
    "states.NH": "",
    "states.NJ": "",
    "states.NM": "",
    "states.NV": "",
    "states.NY": "",
    "states.OH": "",
    "states.OK": "",
    "states.OR": "",
    "states.PA": "",
    "states.RI": "",
    "states.SC": "",
    "states.SD": "",
    "states.TN": "",
    "states.TX": "",
    "states.UT": "",
    "states.VA": "",
    "states.VT": "",
    "states.WA": "",
    "states.WI": "",
    "states.WV": "",
    "states.WY": "",
    "trustpilot.businessunit_id": "53c7d4c40000640005791a3b",
    "trustpilot.show": "TRUE",
    "mollie.profile_id": "pfl_QeqWmTzQxW",
    "mollie.locale": "de_DE",
    "facebook.url": "",
    "instagram.url": "",
    "linkedin.url": "https://www.linkedin.com/company/outspot/mycompany/",
    "header.hello": "Willkommen",
    "header.hello_name": "Guten Tag <b>{firstName}</b>",
    "subscribe.title": "Melden Sie sich kostenlos an und verpassen Sie kein einziges Angebot mehr!",
    "subscribe.text": "Entdecken Sie die besten Angebote von Outspot. Melden Sie sich kostenlos an und genießen Sie exklusive und personalisierte Rabatte auf einzigartige Produkte und Erlebnisse.",
    "subscribe.agree": "Indem Sie unseren Newsletter abonnieren, erklären Sie sich mit den <a href=\"{conditions}\" target=\"_blank\">Allgemeinen Bedingungen</a> und der <a href=\"{privacy}\" target=\"_blank\">Datenschutzerklärung</a> einverstanden.",
    "subscribe.sent": "Vielen Dank! Wir haben Ihre Anmeldung erfolgreich erhalten. Sie werden in Kürze eine Anmeldebestätigung per E-Mail erhalten.",
    "footer.follow": "Folgen Sie uns",
    "footer.payment_methods_logos": "eps,visa,mastercard,amex,applepay,paypal",
    "footer.copyright": "Outspot — Sint-Martens-Latem, Belgien — Confinity NV",
    "home.new": "Neueste Angebote",
    "home.ending": "Diese Angebote laufen demnächst aus",
    "home.other": "Alle Angebote",
    "countdown.prefix": "Noch",
    "countdown.days": "Tag|Tage",
    "countdown.hours": "Stunde",
    "countdown.minutes": "min",
    "countdown.seconds": "sec",
    "countdown.days_compact": "d",
    "date.years": "vor {count} Jahr| vor {count} Jahren",
    "date.months": "vor {count} Monat| vor {count} Monaten",
    "date.weeks": "vor {count} Woche| vor {count} Wochen",
    "date.days": "vor {count} Tag| vor {count} Tagen",
    "date.hours": "vor {count} Stunde| vor {count} Stunden",
    "date.minutes": "vor {count} Minute|vor {count} Minuten",
    "date.seconds": "Vor weniger als 1 Minute",
    "countdown.suffix": "",
    "onboard.language_title": "Genießen Sie personalisierte Angebote mit starken Rabatten",
    "onboard.language_text": "Lassen Sie uns zunächst gemeinsam Ihr Outspot-Konto einrichten.",
    "onboard.notifications_title": "Bleiben Sie informiert:",
    "onboard.notifications_usp_1": "Die besten und neuesten Angebote",
    "onboard.notifications_usp_2": "Aktualisierungen zu Ihren Bestellungen",
    "onboard.notifications_usp_3": "Personalisierte Rabattcodes & Gutscheine",
    "order.shipping_title": "Versand",
    "order.shipping_text": "Aufgrund der steigenden Transportkosten und der Umweltbelastung sind wir gezwungen, Transportkosten zu berechnen. Outspot garantiert Ihnen aber immer noch tolle Preise, bei denen Sie trotzdem gewinnen. Außerdem erhöhen sich Ihre Transportkosten nicht, wenn Sie mehr Artikel kaufen. Wenn Sie ein Outspot PLUS-Mitglied sind oder innerhalb einer Stunde nach Ihrem letzten Kauf bestellen, werden diese Kosten im nächsten Schritt abgezogen.",
    "order.thank_you": "Vielen Dank für Ihre Bestellung!",
    "order.overview": "Überblick über Ihre Bestellung",
    "order.thank_you_bank": "Vielen Dank für Ihren Kauf. Sie erhalten die Überweisungsdaten in Ihrem E-Mail Posteingang oder in Ihren Spam-Mails. Bitte überweisen Sie den Betrag so schnell wie möglich, auf jeden Fall aber innerhalb von 7 Tagen. Sie erhalten den von Ihnen bestellten Gutschein per E-Mail, sobald die Zahlung eingegangen ist und bearbeitet wurde.",
    "order.thank_you_with_shipping": "Ihr Einkauf ist abgeschlossen. Wir werden Ihre Bestellung an die unten angegebene Adresse senden. Sie erhalten Ihre Kaufbestätigung in Ihrem E-Mail Posteingang oder in den Spam-Mails. Viel Spaß mit Ihrer Bestellung.",
    "order.thank_you_without_shipping": "Ihr Kauf ist abgeschlossen. Sie erhalten Ihre Kaufbestätigung in Ihrem E-Mail Posteingang oder in den Spam-Mails. Viel Spaß mit Ihrer Bestellung.",
    "order.code": "Bestellnummer: {code}",
    "order.shipping_address": "Lieferadresse",
    "order.save_payment_info_title": "Speichern Sie die Bankdaten für meine nächsten Einkäufe.",
    "order.save_payment_info_text": "Wenn Sie diese Option wählen, müssen Sie Ihre Daten nicht jedes Mal eingeben. Ihre Daten werden sicher gespeichert.",
    "order.shipped_to_service_point": "",
    "my_outspot_plus.title": "Mein Outspot PLUS",
    "my_outspot_plus.subtitle": "Auf dieser Seite können Sie Ihre Outspot PLUS-Mitgliedschaft verwalten.",
    "my_outspot_plus.overview": "Überblick über Ihre Outspot PLUS-Mitgliedschaft",
    "my_outspot_plus.cancel_are_you_sure": "Sind Sie sicher, dass Sie Ihre Mitgliedschaft kündigen möchten?",
    "my_outspot_plus.cancel_you_saved_prefix": "Sie sparen bereits",
    "my_outspot_plus.cancel_you_saved_suffix": " Versand- und Bearbeitungsbeführen drch Ihre Outspot PLUS-Mitgliedschaft.",
    "my_outspot_plus.no_membership": "Sie haben derzeit keine Outspot PLUS-Mitgliedschaft.",
    "plus.title": "Outspot PLUS",
    "plus.popup_text": "Mit Outspot PLUS profitieren Sie von einem kostenlosen Versand für alle Artikel und zahlen keine Bearbeitungsgebühren auf alle Reisen. Nach jeder Bestellung erhalten Sie eine E-Mail, die Ihnen die Möglichkeit gibt, Outspot PLUS-Mitglied zu werden. Sie müssen jedoch schnell sein, da diese Aktion nur 7 Tage lang gültig ist. Vergessen Sie also nicht, Ihre nächste Bestellbestätigungs-E-Mail zu überprüfen.",
    "plus.name": "Outspot PLUS-Mitgliedschaft",
    "plus.highlight_1": "Genießen Sie eine einfache Bestellung ohne Versand- und Bearbeitungsgebühren.",
    "plus.highlight_2": "Ihr Abonnement wird automatisch alle drei Monate für {renewPrice} verlängert.",
    "plus.highlight_3": "Stornierung jederzeit möglich.",
    "plus.question_1": "Was sind die Vorteile von Outspot PLUS?",
    "plus.answer_1": "Mit Outspot PLUS profitieren Sie von einem kostenlosen Versand auf alle Artikel und zahlen keine Bearbeitungsgebühren für alle Reisen.",
    "plus.question_2": "Wie werde ich Outspot PLUS-Mitglied?",
    "plus.answer_2": "Nach jeder Bestellung erhalten Sie eine E-Mail, die Ihnen die Möglichkeit gibt, Outspot PLUS Mitglied zu werden. Sie müssen jedoch schnell sein, da diese Aktion nur 7 Tage lang gültig ist. Wenn Sie sich für die Aktivierung von Outspot PLUS entscheiden, treten die Vorteile sofort in Kraft. Das bedeutet, dass Sie bei Ihrer nächsten Bestellung sofort in den Genuss eines kostenlosen Versands für alle Artikel kommen und keine Bearbeitungsgebühren für alle Ihre Reisen zahlen müssen.",
    "plus.question_3": "Wie viel kostet Outspot PLUS?",
    "plus.answer_3": "Outspot PLUS kostet nur {Preis} für 3 Monate. Dies ist eine einmalige Zahlung. Danach verlängert sich Ihre Mitgliedschaft automatisch alle 3 Monate zum Preis von {renewPreis}. Dadurch sparen Sie erheblich bei den Versand- und Bearbeitungskosten.",
    "plus.question_4": "Wann und wie wird mein Outspot PLUS verlängert?",
    "plus.answer_4": "Outspot PLUS wird alle drei Monate automatisch erneuert. Um dies zu verwalten, können Sie jederzeit auf <a href=\"{myOutspotPlus}\">Mein Outspot PLUS</a> gehen. Der Betrag wird automatisch alle 3 Monate von dem von Ihnen gewählten Konto abgebucht.",
    "plus.question_5": "Wie kann ich mein Outspot PLUS kündigen? Bekomme ich eine Rückerstattung?",
    "plus.answer_5": "Sie können Ihre Outspot PLUS-Mitgliedschaft jederzeit kündigen über: <a href=\"{myOutspotPlus}\">Mijn Outspot PLUS</a>. Bitte beachten Sie, dass für die Monate, in denen Ihre Mitgliedschaft aktiv war, keine Erstattung erfolgt. Wenn Sie sich jedoch entschließen, Outspot PLUS zu kündigen, wird die nächste geplante Abrechnung nicht berechnet.",
    "plus.thank_you": "Ihr Kauf ist abgeschlossen. Ab jetzt können Sie ganz einfach und ohne Versandkosten bestellen. Sie können Ihre Mitgliedschaft über <a href=\"{myOutspotPlus}\">My Outspot PLUS</a> einsehen und verwalten.",
    "my_coupons.title": "Meine Wertgutscheine",
    "my_coupons.subtitle": "Sie können Ihre Wertgutscheine auf dieser Seite finden.",
    "my_coupons.no_coupons": "Sie haben derzeit keine Wertgutscheine.",
    "deal.sold": "Bereits {sold} mal gekauft ",
    "deal.retour_label": "Rücksendung innerhalb von 30 Tagen für nur 0,95 €",
    "deal.retour_title": "Rückgaberecht",
    "deal.retour_text": "Gefällt Ihnen Ihr Produkt doch nicht? Wenden Sie sich innerhalb von 30 Tagen nach Erhalt an unseren Helpdesk und senden Sie Ihr Produkt zurück. Und das aus jedem beliebigen Grund! Wir erstatten Ihnen den Kaufpreis und Sie können sorglos neu bestellen. Eine weitere tolle Idee, natürlich von Outspot!",
    "deal.guarantee_label": "6-monatige Rückzahlungsgarantie",
    "deal.guarantee_title": "Einzigartig: 100% Erstattungsgarantie",
    "deal.guarantee_text": "Unglaublich, aber wahr. Sie müssen nicht länger zögern, ein Erlebnis für Ihre Freunde/Kollegen/Familie oder sich selbst zu kaufen. Wenn Sie es sich im Nachhinein anders überlegen oder der Beschenkte mit dem Gutschein nicht ganz zufrieden ist, ist das kein Problem.<br><br>Bei Outspot.de können Sie Ihren Kauf bis zu 6 Monate nach der Bestellung widerrufen! Sie oder die Person, die den Gutschein erhalten hat, können innerhalb von 6 Monaten eine vollständige Rückerstattung erhalten. Dies natürlich nur, solange der Gutschein noch nicht reserviert, verbraucht oder abgelaufen ist. Als Garantie ist dies außergewöhnlich und nie gesehen. Aber so ist Outspot nun einmal.<br><br> Bitte beachten Sie, dass für alle Aktionen, bei denen die Rückerstattungsgarantie nicht ausdrücklich erwähnt wird, die Garantie nicht gilt. Das liegt daran, dass wir in manchen Fällen Lieferungen/Gutscheine von unserem Partner nicht selbst rückerstatten können.",
    "deal.option_count": "{count} Option|{count} Optionen",
    "deal.campaign_number": "Kampagnennummer",
    "deal.your_details": "Ihre Angaben",
    "deal.payment_methods_title": "Zahlungsmethode",
    "deal.shipping_methods_title": "",
    "deal.privacy": "Mit der Eingabe Ihrer Daten erklären Sie sich mit <a target='_blank' href='{privacy}'>unserer Datenschutzerklärung</a> einverstanden.",
    "deal.payment_methods_subtitle": "Wie möchten Sie Ihre Bestellung bezahlen?",
    "deal.your_order": "Ihre Bestellung",
    "deal.from": "Ab",
    "help.title": "Brauchen Sie Hilfe?",
    "help.subtitle": "Lassen Sie uns wissen, wie wir Ihnen helfen können.",
    "help.subject": "Ihre Frage über unsere Website oder App",
    "help.sent": "Wir haben Ihre Nachricht korrekt erhalten. Sie werden spätestens innerhalb von 4 Werktagen eine Antwort von uns erhalten.",
    "login.title": "Einloggen",
    "login.subtitle": "Willkommen zurück! Wie möchten Sie sich anmelden?",
    "login.or": "oder",
    "login.usps_title": "Warum bei Outspot anmelden?",
    "login.usps_title_cta": "Machen Sie es sich leichter und melden Sie sich an oder erstellen Sie jetzt ein Konto.",
    "login.usps_text": "Klicken Sie oben auf \"Anmelden\", um sich einzuloggen. Wenn Sie noch kein Konto bei Outspot haben, können Sie sich auch auf der Anmeldeseite registrieren. Nachfolgend finden Sie einige der Vorteile eines Outspot-Kontos:",
    "login.usp_manage_orders": "Ihre Bestellungen einsehen und verfolgen",
    "login.usp_remember_info": "Speichern Ihrer Adressdaten",
    "login.usp_returns": "Einen Defekt melden oder eine Rücksendung beantragen",
    "login.usp_store_payments": "Speichern Sie Ihre Bezahlmethoden und zahlen Sie schneller",
    "login.short_cta": "<a href=\"{loginLink}\">Melden Sie sich an</a> oder fahren Sie unten als Gast fort.",
    "forgot.title": "Ein neues Passwort anfordern",
    "forgot.subtitle": "Geben Sie unten Ihre E-Mail-Adresse ein und wir senden Ihnen einen Link, mit dem Sie Ihr Passwort zurücksetzen können.",
    "forgot.sent": "Wir haben Ihnen eine E-Mail mit einem Link zum Zurücksetzen Ihres Passworts geschickt.",
    "password.title": "Passwort ändern",
    "password.subtitle": "Wählen Sie ein neues und sicheres Passwort.",
    "password.updated": "Ihr Passwort wurde erfolgreich geändert. Sie können sich von nun an mit Ihrem neuen Passwort anmelden.",
    "register.title": "Registrieren",
    "register.subtitle": "Erstellen Sie ein Konto, um die Bestellung und die Verfolgung Ihrer Bestellungen noch einfacher zu machen.",
    "register.personal_info": "Persönliche Daten",
    "register.login_info": "Login-Daten",
    "register.agree": "Ich stimme zu <a href=\"/enl/help/general-conditions\" target=\"_blank\">general-conditions</a> und <a href=\"/enl/help/privacy\" target=\"_blank\">privacy statement</a> zu.",
    "register.sent": "Sie sind fast am Ziel. Wir haben Ihnen eine E-Mail zur Aktivierung Ihres Kontos geschickt.",
    "register.email_known": "Wenn Ihre E-Mail-Adresse Outspot bereits bekannt ist, <a href=\"{Vergessen}\">klicken Sie hier</a>, um ein Passwort anzufordern.",
    "my_orders.title": "Meine Bestellungen",
    "my_orders.subtitle": "Dies ist eine Übersicht über alle Bestellungen, die Sie bei Outspot getätigt haben.",
    "my_orders.title_detail": "Details Ihrer Bestellung",
    "my_travels.title": "Meine Reisen",
    "my_info.title": "Meine Angaben",
    "my_info.subtitle": "Hier können Sie Ihre persönlichen Daten bearbeiten.",
    "my_info.sent": "Ihre Angaben wurden aktualisiert.",
    "notifications.title": "Newsletter und Benachrichtigung",
    "notifications.subscription_mail": "Wie oft möchtest du unseren Newsletter erhalten?",
    "notifications.subscription_notification": "Wie oft möchtest du Benachrichtigungen erhalten?",
    "notifications.frequency_0": "Nie",
    "notifications.frequency_4": "Einmal pro Woche",
    "notifications.frequency_56": "Zweimal pro Tag",
    "notifications.sent": "Deine Einstellungen wurden aktualisiert.",
    "combined_purchase_banner.text": "Bezahlen Sie keine Versand- oder Bearbeitungsgebühren für Ihre nachfolgenden Bestellungen für:",
    "plus_banner.text_welcome": "{firstName}, willkommen bei Outspot PLUS.",
    "plus_banner.text_welcome_anonimous": "Willkommen bei Outspot PLUS.",
    "plus_banner.text_savings_prefix": "{firstName}, Sie sparen bereits",
    "plus_banner.text_savings_prefix_anonimous": "Sie sparen bereits",
    "plus_banner.text_savings_suffix": "Versand- und Bearbeitungsgebühren mit Outspot PLUS.",
    "cookie_banner.title": "Cookies",
    "cookie_banner.text": "Diese Website verwendet Cookies. Unbedingt erforderliche Cookies sorgen dafür, dass die Website ordnungsgemäß funktioniert und können nicht abgelehnt werden. Funktionale, analytische und Werbe-Cookies werden jeweils für funktionale, statistische und Marketingzwecke verwendet. Sie werden nur heruntergeladen, wenn Sie zustimmen. Lesen Sie mehr in unserer <a href=\"{cookies}\" target=\"_blank\">Cookie-Richtlinie</a>.",
    "cookie_banner.decline": "Optionale Cookies ablehnen",
    "cookie_banner.accept_all": "Alle Cookies akzeptieren",
    "cookie_banner.accept_selected": "Ausgewählte akzeptieren",
    "cookie_banner.settings": "Einstellungen",
    "cookie_banner.functional_cookies_title": "Unbedingt erforderliche Cookies",
    "cookie_banner.functional_cookies_text": "Cookies, die für die grundlegende Funktionalität der Website erforderlich sind.",
    "cookie_banner.analytical_cookies_title": "Analytische & funktionale Cookies",
    "cookie_banner.analytical_cookies_text": "Cookies ermöglichen es der Website, zusätzliche Funktionen und persönliche Einstellungen sowie Besuche und Traffic bereitzustellen.",
    "cookie_banner.personal_cookies_title": "Werbe-Cookies",
    "cookie_banner.personal_cookies_text": "Cookies, die von Werbetreibenden gesetzt werden, um ein Profil Ihrer Interessen zu erstellen, ohne direkte persönliche Informationen zu speichern.",
    "review.title": "Teilen und gewinnen",
    "review.subtitle": "Bewerten Sie Ihren Einkauf und erhalten Sie die Chance, einen Gutschein im Wert von 250 € zu gewinnen (wir verlosen jeden Quartal einen Gutschein).",
    "review.agree": "Ich bin damit einverstanden, dass meine Bewertung auf der Website veröffentlicht wird.",
    "review.sent": "Vielen Dank für Ihre Bewertung. Sie haben jetzt die Chance, einen 250 € Gutschein zu gewinnen.",
    "review.title_thank_you": "Vielen Dank! Wir schätzen Ihre Meinung.",
    "review.subtitle_thank_you": "Dank Ihres Feedbacks können wir Outspot noch besser machen. Schreiben Sie unten eine Bewertung Ihres Einkaufs und Sie haben die Chance, einen Gutschein im Wert von 250 € zu gewinnen (wir verlosen jeden Quartal einen solchen Gutschein).",
    "confirm.title": "Ihre Anmeldung ist bestätigt",
    "confirm.subtitle": "Ihr Abonnement des Newsletters wird hiermit bestätigt. Wir danken Ihnen! Um Sie noch besser bedienen zu können, bieten wir Ihnen die Möglichkeit, uns die folgenden Daten unverbindlich mitzuteilen.",
    "popup_widget.title": "Angebote vom Feinsten, für Sie getestet.",
    "popup_widget.usp_1": "Erhalten Sie jeden Tag unsere besten Angebote.",
    "popup_widget.usp_2": "Ausgewählt und getestet für besten Preis und Qualität.",
    "popup_widget.usp_3": "Riesenrabatte von bis zu -70%.",
    "popup_widget.footer_1": "Melden Sie sich jetzt kostenlos für unseren Newsletter an und sparen Sie sofort.",
    "popup_widget.footer_2": "Sie können sich jederzeit wieder abmelden.",
    "promo.title": "Unsere Top-Promos",
    "promo.countdown": "2024-12-31 23:59:59",
    "promo.empty": "Derzeit haben wir keine Top-Promos. Gehen Sie auf unsere Homepage für unsere neuesten Angebote.",
    "stars.info_title": "Produktbewertung",
    "stars.info_text": "Diese Bewertung ist ein Durchschnitt unserer eigenen gesammelten Bewertungen und der von anderen Beteiligten.",
    "stars.counter": "1 Bewertung | {count} Bewertungen",
    "label.accept_selected": "Auswahl akzeptieren",
    "label.edit": "Ändern Sie",
    "label.edit_address": "Adresse ändern",
    "label.address": "Adresse",
    "label.accept_all": "Alle akzeptieren",
    "label.account": "Konto",
    "label.help": "Hilfe",
    "label.my_orders": "Meine Bestellungen",
    "label.my_travels": "Meine Reisen",
    "label.my_coupons": "Meine Gutscheine",
    "label.my_info": "Meine Angaben",
    "label.change_password": "Passwort ändern",
    "label.notifications": "Newsletter und Benachrichtigung",
    "label.logout": "Abmelden",
    "label.login": "Einloggen",
    "label.login_have_account": "Sie haben bereits ein Konto? Anmelden",
    "label.login_with_facebook": "Mit Facebook anmelden",
    "label.login_with_google": "Mit Google anmelden",
    "label.login_with_apple": "Mit Apple anmelden",
    "label.password": "Passwort",
    "label.new_password": "Neues Passwort",
    "label.repeat_password": "Passwort wiederholen",
    "label.repeat_new_password": "Wiederholung des neuen Passworts",
    "label.register_no_account": "Noch kein Konto? Jetzt registrieren",
    "label.register": "Registrieren",
    "label.forgot_password": "Haben Sie Ihr Passwort vergessen?",
    "label.general_conditions": "AGB´s und Konditionen",
    "label.jobs": "Jobs bei Outspot",
    "label.faq": "Häufig gestellte Fragen",
    "label.returns": "Rücksendungen",
    "label.travel_conditions": "Besondere Reisebedingungen",
    "label.privacy_policy": "Erklärung zum Datenschutz",
    "label.cookie_policy": "Cookie-Anweisung",
    "label.subscribe": "Anmelden",
    "label.all_deals": "Alle Angebote",
    "label.other_deals": "Andere Angebote",
    "label.discount": "Rabatt",
    "label.close": "Schließen",
    "label.order_now": "Jetzt bestellen",
    "label.add": "hinzufügen",
    "label.book": "Jetzt buchen",
    "label.upselling_next_step": "Vergessen Sie später auch das nicht",
    "label.upselling": "Vergessen Sie auch das nicht",
    "label.also_available": "Ebenfalls verfügbar",
    "label.reviews": "Was unsere Kunden sagen",
    "label.automatically_translated": "Automatisch übersetzt",
    "label.show_all_reviews": "Alle Bewertungen sehen",
    "label.back": "Zurück",
    "label.make_your_choice": "Treffen Sie Ihre Wahl",
    "label.sold_out": "Ausverkauft",
    "label.order_more": "Mehr bestellen",
    "label.subtotal": "Zwischensumme",
    "label.total_shipping": "Versandkosten ",
    "label.total_file": "Bearbeitungsgebühren",
    "label.total_payable": "Insgesamt",
    "label.no_options_selected": "Ihre Bestellung ist derzeit leer. Klicken Sie unten auf \"Hinzufügen\", um fortzufahren.",
    "label.no_options_selected_extra": "Sie können diese Option nur beim Kauf des Produkts auswählen.",
    "label.total_saved_prefix": "(Sie sparen ",
    "label.total_saved_suffix": ")",
    "label.continue": "Weiter",
    "label.usp_quality": "Ausgewählt nach Preis und Qualität",
    "label.usp_discounts": "Erhebliche Rabatte von bis zu -70%",
    "label.usp_payment_methods": "Sicher und einfach bezahlen mit",
    "label.person": "Person",
    "label.free": "Kostenlos",
    "label.first_name": "Vornamen",
    "label.last_name": "Nachname",
    "label.birthdate": "Geburtsdatum",
    "label.birthdate_why": "",
    "label.birthdate_why_text": "",
    "label.email": "E-Mail Adresse",
    "label.phone": "Telefonnummer",
    "label.address_finder": "Adressfinder",
    "label.address_finder_help": "Geben Sie Ihre vollständige Lieferadresse ein.",
    "label.select": "Auswählen ",
    "label.street": "Straße",
    "label.building": "",
    "label.number": "Hausnummer",
    "label.zip": "Postleitzahl",
    "label.city": "Ort",
    "label.state": "",
    "label.country": "Land",
    "label.finish_order": "Bestellen & bezahlen",
    "label.finish_order_help": "Klicken Sie auf die Schaltfläche \"Bestellen & bezahlen\", um Ihre Zahlung abzuschließen.",
    "label.enter_address": "Meine Adresse manuell eingeben",
    "label.add_address": "Adresse hinzufügen",
    "label.add_payment_method": "Zahlungsmethode hinzufügen",
    "label.year": "JJJJ",
    "label.month": "MM",
    "label.day": "DD",
    "label.business_order": "geschäftliche Bestellung",
    "label.company_name": "Name des Unternehmens",
    "label.tax_id": "Umsatzsteueridentifikationsnummer",
    "label.invoice_reason": "Grund",
    "label.invoice_reasons": "Firmengeschenke,Teambuilding,Incentives,Seminar,Sonstiges",
    "label.delete": "entfernen",
    "label.i_have_coupons": "Ich habe Gutscheine",
    "label.email_outspot_plus": "Sie sind bereits Outspot PLUS-Kunde? Dann geben Sie Ihre E-Mail-Adresse ein.",
    "label.please_wait": "Bitte haben Sie Geduld...",
    "label.please_wait_2": "Nur noch wenig Geduld...",
    "label.please_wait_3": "Entschuldigung, es dauert etwas länger als erwartet...",
    "label.please_wait_4": "Nur noch ein bisschen, wir sind fast da...",
    "label.please_wait_5": "Wir entschuldigen uns für die Unannehmlichkeiten und danken Ihnen für Ihre Geduld, wir sind fast fertig.",
    "label.continue_shopping": "Weiter Einkaufen",
    "label.my_code": "Mein Code",
    "label.validate": "Validieren",
    "label.total_coupons": "Total Gutscheine",
    "label.coupons": "Gutscheine",
    "label.total_payable_with_coupons_prefix": "(Noch zu zahlen ",
    "label.total_payable_with_coupons_suffix": ")",
    "label.rest_coupon_info": "Der zu zahlende Betrag ist geringer als die eingegebenen Gutscheine. Sie erhalten einen neuen Gutschein mit dem Restwert.",
    "label.contact_extra_info": "Bestellnummer, Rechnungsnummer oder Verwendungszweck",
    "label.contact": "Kontaktieren Sie uns ",
    "label.message": "Nachricht",
    "label.send": "Senden",
    "label.save": "Speichern",
    "label.my_outspot_plus": "Mein Outspot PLUS",
    "label.order_date_prefix": "Bestellt am",
    "label.order_date_suffix": "",
    "label.total_paid_with": "Insgesamt bezahlt mit {method}",
    "label.track_order": "Verfolgen Sie Ihre Bestellung mit",
    "label.return_order": "Rücksendung und/oder Meldung eines Defekts",
    "label.download_invoice": "Rechnung herunterladen",
    "label.ask_question": "Eine Frage stellen",
    "label.happy_hour_discount": "Happy-Hour-Vorteil",
    "label.outspot_plus_discount": "Outspot PLUS Vorteil",
    "label.cancel_membership": "Mitgliedschaft kündigen",
    "label.membership_start_date": "Datum des Beginns der Mitgliedschaft",
    "label.membership_price": "Preis für 3 Monate",
    "label.membership_next_payment": "Nächste Zahlung",
    "label.membership_end_date": "Enddatum der Mitgliedschaft",
    "label.total_savings": "Insgesamt eingesparte Versand- und Bearbeitungskosten",
    "label.confirm_cancel_membership": "Stornierung bestätigen",
    "label.continue_membership": "Nicht abbrechen",
    "label.valid_till_prefix": "Gültig bis",
    "label.valid_till_suffix": "",
    "label.card_payment": "Kreditkarte",
    "label.place_review": "Bewertung schreiben ",
    "label.name": "Name",
    "label.name_help": "wir zeigen diese in der Bewertung",
    "label.review": "Beurteilung ",
    "label.self_service": "",
    "label.update_cookie_preferences": "Meine Cookie-Einstellungen ändern",
    "label.more_info": "Mehr Informationen",
    "label.review_source": "Über {source}",
    "label.amount": "Betrag",
    "label.iban": "IBAN",
    "label.bic": "BIC",
    "label.beneficiary": "Begünstigter",
    "label.structured_communication": "Strukturierte Mitteilung",
    "label.later": "Später",
    "label.enable_notifications": "Ja, ich möchte diese Benachrichtigung erhalten",
    "label.card_number": "Kreditkartenummer",
    "label.card_expiry": "Gültig bis",
    "label.card_cvc": "CVV",
    "label.cvc_info": "",
    "label.sales_tax": "",
    "label.calculating_sales_tax": "",
    "label.sales_tax_will_be_calculated": "",
    "label.ship_to_selected_address": "",
    "label.ship_to_service_point": "",
    "label.change_service_point": "",
    "label.choose_rooms": "",
    "label.room": "",
    "label.room_occupancy": "",
    "label.add_another_room": "",
    "label.number_of_adults": "",
    "label.number_of_adults_title": "",
    "label.number_of_children": "",
    "label.number_of_children_title": "",
    "label.choose_arrival_and_departure_date": "",
    "label.arrival_date": "",
    "label.select_arrival_date": "",
    "label.departure_date": "",
    "label.select_departure_date": "",
    "label.rate_type_pc": "",
    "label.rate_type_pcpd": "",
    "label.rate_type_pcpn": "",
    "label.rate_type_pp": "",
    "label.rate_type_pppd": "",
    "label.rate_type_pppn": "",
    "label.rate_type_pp_": "",
    "label.rate_type_pa": "",
    "label.rate_type_papd": "",
    "label.rate_type_papn": "",
    "label.rate_type_pa_": "",
    "label.choose_supplements": "",
    "label.opengds_invalid": "",
    "error.unknown": "Unbekannter Fehler, bitte versuchen Sie es später noch einmal.",
    "error.required": "{field} darf nicht leer sein.",
    "error.required_email": "{field} darf nicht leer sein und muss eine gültige E-Mail-Adresse sein.",
    "error.required_password": "{field} muss mindestens 8 Zeichen lang sein und mindestens einen Großbuchstaben, einen Kleinbuchstaben und eine Zahl enthalten.",
    "error.required_repeat_password": "{field} muss mit dem im Feld {field2} angegebenen Passwort übereinstimmen.",
    "error.payment": "Leider ist Ihre Zahlung fehlgeschlagen. Dies kann passieren, wenn es ein Problem mit den Zahlungsdaten gibt, die Zahlung storniert wurde, Ihr Zahlungslimit erreicht wurde oder aus anderen Gründen. Wenden Sie sich an Ihre Bank oder versuchen Sie eine andere Karte oder Zahlungsmethode.",
    "error.payment_creditcard": "Schade.Ihre Zahlung ist fehlgeschlagen. Bitte überprüfen Sie die untenstehenden Angaben und versuchen Sie es erneut.",
    "error.incomplete_order": "Wir können diesen Gutschein erst validieren, wenn Sie alle oben genannten Felder ausgefüllt haben.",
    "error.coupon_already_added": "Sie haben diesen Gutschein bereits hinzugefügt.",
    "error.invalid_coupon": "Dieser Gutschein ist ungültig.",
    "error.coupon_not_cumulative": "Dieser Gutschein kann nicht mit anderen Gutscheinen kombiniert werden.",
    "error.other_coupon_not_cumulative": "Der Gutschein, den Sie bereits hinzugefügt haben, kann nicht zusammen mit diesem Gutschein verwendet werden.",
    "error.invalid_username_or_password": "Die angegebene E-Mail-Adresse und/oder das Passwort sind ungültig.",
    "error.test_order": "Dies ist eine Testbestellung. Normalerweise würde der Kunde jetzt auf die Zahlungsseite gehen.",
    "error.required_agree": "Sie müssen unseren Allgemeinen Geschäftsbedingungen und unserer Datenschutzerklärung zustimmen.",
    "error.required_review_agree": "Sie müssen zustimmen, dass Ihre Bewertung auf unserer Website veröffentlicht werden darf.",
    "error.register": "Es gibt bereits ein Konto mit dieser E-Mail-Adresse. Klicken Sie auf \"Weiter\", um sich anzumelden oder verwenden Sie eine andere E-Mail-Adresse.",
    "error.email_in_use": "Es gibt bereits ein Konto mit dieser E-Mail Adresse. Bitte verwenden Sie eine andere E-Mail-Adresse.",
    "error.already_reviewed": "Sie haben bereits eine Bewertung für diese Bestellung abgegeben.",
    "error.zip_blocked": "Es ist unmöglich, zur ausgefüllten Postleitzahl zu senden.",
    "error.details": "Es scheint, dass ein oder mehrere Produkte, die Sie zu bestellen versuchen, nicht mehr auf Lager sind. Bitte kehren Sie zum vorherigen Schritt zurück, aktualisieren Sie Ihre Bestellung und versuchen Sie es erneut.",
    "error.incomplete_cvc": "CVV ist zu kurz.",
    "error.incomplete_date": "Das Ablaufdatum darf nicht leer sein.",
    "error.date_in_past": "Die Karte ist abgelaufen.",
    "error.invalid_expiration_year": "Die Karte ist abgelaufen.",
    "error.incomplete_card_number": "Die Kartennummer ist zu kurz.",
    "error.incorrect_card_number": "Die Kartennummer ist ungültig.",
    "unsubscription.your_mail_address": "Ihre E-Mail Adresse:",
    "unsubscription.sad_message": "Sie sind hiermit abgemeldet",
    "unsubscription.have_not_participated": "Haben Sie sich nie angemeldet? ",
    "unsubscription.sad_message_2": "Es tut uns leid, dass Sie uns verlassen haben, aber Sie sind natürlich immer willkommen.",
    "unsubscription.resubscribe_question": "Haben Sie Ihre Meinung geändert?<span class='unsub-important-message'> Klicken Sie unten, um sich erneut für unseren Newsletter anzumelden:</span>",
    "unsubscription.form_message": "Warum verlassen Sie unsere Outspot-Familie? Ihre Antwort kann uns helfen, unsere Dienste zu verbessern.",
    "unsubscription.report_not_participated": "Missbrauch melden",
    "unsubscription.coreg_date_no_screenshot": "Sie haben am {date} an einer Aktion (<a href=\"{coreg_site}\">{coreg_site}</a>) teilgenommen und so Genehmigung erteilt zum Erhalt unseres Newsletters mit Toppangeboten zu Sonderpreisen.",
    "unsubscription.coreg_date_no_coreg_data": "Sie haben am {date} an untengenannter Aktion teilgenommen und so Genehmigung erteilt zum Erhalt unseres Newsletters mit Toppangeboten zu Sonderpreisen.",
    "unsubscription.coreg_date_screenshot": "Sie haben am {date} an untengenannter Aktion teilgenommen und so Genehmigung erteilt zum Erhalt unseres Newsletters mit Toppangeboten zu Sonderpreisen.",
    "unsubscription.coreg_no_date_no_screenshot": "Sie haben an untengenannter Aktion teilgenommen und so Genehmigung erteilt zum Erhalt unseres Newsletters mit Toppangeboten zu Sonderpreisen.",
    "unsubscription.coreg_no_date_screenshot": "Sie haben an untengenannter Aktion teilgenommen und so Genehmigung erteilt zum Erhalt unseres Newsletters mit Toppangeboten zu Sonderpreisen.",
    "unsubscription.main_title": "Vom Newsletter abmelden",
    "unsubscription.report_title": "Einen Missbrauch melden",
    "unsubscription.report_sent_message": "Vielen Dank, wir haben Ihre Mitteilung erhalten.",
    "claim_coupon.welcome_message": "Speziell für dich",
    "claim_coupon.info_line_message": "Geben Sie den oben stehenden persönlichen Code an der Kasse ein.",
    "claim_coupon.info_line_message_bis": "Dieser Gutschein wurde Ihnen auch per E-Mail zugesandt.",
    "claim_coupon.main_title": "Ihr Gutschein",
    "about.title": "Über uns",
    "about.intro": "Willkommen bei Outspot, Ihrem Reiseziel Nummer eins für unwiderstehliche Angebote! Seit seiner Gründung im Jahr 2008 ist Outspot hat sich zur führenden europäischen Flash-Deal-Website entwickelt. Outspot bietet tägliche Rabatte auf Qualitätsprodukte und Reisen, wodurch Sie als Kunde enorm sparen können. Dank unseres Lagers in Europa liefern wir unsere Pakete so schnell wie möglich bei Ihnen zu Hause.",
    "about.mission_title": "Mission",
    "about.mission_text": "Bei Outspot glauben wir daran, es zugänglich zu machen außergewöhnliche Angebote für jedermann. Unser Ziel ist es, unser zu machen Wir bieten unseren Kunden durch Sorgfalt den bestmöglichen Wert Wir bieten ausgewählte Angebote zu konkurrenzlosen Preisen an.",
    "about.history_title": "Geschichte",
    "about.history_text": "Das Outspot-Abenteuer begann in Belgien, wo sich auch heute noch der Hauptsitz befindet. Mit einem engagierten Team von 25 Mitarbeitern, sind wir über ganz Europa verteilt und arbeiten jeden Tag daran die besten Angebote anzubieten. Mittlerweile sind wir in 13 Ländern aktiv und haben täglich 100.000 zufriedene Besucher.",
    "about.quality_title": "Unser Angebot wurde nach Preis und Qualität ausgewählt",
    "about.quality_text": "Wir bei Outspot glauben, dass Sie das Beste aus beiden Welten verdienen: Top-Qualität zu einem erschwinglichen Preis. Deshalb gehen wir jeden Tag wieder auf die Suche nach den attraktivsten Angeboten für Sie.  Wir stellen sicher, dass wir eng mit unseren Lieferanten zusammenarbeiten die besten Preise aushandeln zu können, ohne Zugeständnisse zu machen zur Qualität. <br /><br />  Jedes Produkt und jede Reise, die wir anbieten, ist sorgfältig gewählt. Die Auswahl erfolgt auf der Grundlage einer strengen Preis- und Leistungsbewertung. So können Sie sicher sein, dass Sie bei uns immer das beste Preis-Qualität-Verhältnis  für Ihr Geld erhalten. Bei Outspot dreht sich alles um Ihre Vorteile und Zufriedenheit! Unten sehen Sie ein Foto des Klangs der Smartbell, die mit einem Dezibelmessgerät getestet wird.",
    "about.tested_title": "Alle Angebote wurden von unseren Outspot-Kollegen ausgiebig getestet",
    "about.tested_text": "Bei Outspot glauben wir daran, nur das Allerbeste zu liefern. Bevor ein Angebot auf unserer Website erscheint, wird es detailliert beschrieben und von unseren eigenen Outspot-Kollegen getestet . Sie lassen sich nicht nieder bei oberflächlichen Tests: Produkte werden komplett zerlegt und jede Komponente wird sorgfältig bewertet. Festigkeit, Geräuschpegel, Materialqualität und mehr.<br /><br />  Unsere Kollegen besuchen viele Hotels auch persönlich, um die Qualität der Unterkünfte zu rprüfen. So garantieren wir, dass alles unseren hohen Standards entspricht. Ob es ein ist Produkt oder eine Reise ist, Sie können sicher sein, dass Sie bei Outspot Ihren Einkauf immer mit Vertrauen tätigen können. <br /><br />  Auf dem Foto unten können Sie sehen, wie Kollegen Produkte herstellen, im Detail betrachten und testen: z.B. Stoffqualitätsprüfung.",
    "about.guarantee_title": "6 Monate Rückerstattungsgarantie auf die meisten Reisen",
    "about.guarantee_text": "Wenn Sie eine Reise buchen, möchten Sie sicher sein, dass Ihre Wahl die richtige ist und dass Ihre Investition gut angelegt ist. Aus diesem Grund\r bieten wir eine 6-monatige Erstattungsgarantie für die meisten unserer\r Reisen. Mit dieser Garantie können Sie beruhigt planen, denn Sie wissen, dass Sie jederzeit Anspruch auf eine Rückerstattung haben, sofern noch keine Buchung erfolgt ist. Dies gibt Ihnen die Freiheit, Ihre Entscheidungen flexibel zu treffen, ohne das Risiko zusätzlicher Kosten, bis eine endgültige Buchung erfolgt ist.",
    "about.returns_title": "30-tägiges Rückgaberecht für die meisten Produkte",
    "about.returns_text": "Wir verstehen, dass es manchmal Zeit braucht, um zu entscheiden, ob Sie ein Produkt behalten möchten. Deshalb bieten wir bei den meisten unserer Produkte ein 30-tägiges Rückgaberecht an. Es ist wichtig, dass das Produkt unbenutzt und ungeöffnet bleibt, um für eine Rückgabe in Frage zu kommen. Dies gibt Ihnen die Möglichkeit, Ihren Kauf in Ihrem eigenen Tempo zu überdenken, während wir für einen reibungslosen Rückgabeprozess sorgen. So können Sie ohne Sorgen entscheiden, solange das Produkt im Originalzustand bleibt.",
    "about.support_title": "Kundenservice auf Deustch",
    "about.support_text": "Bei Outspot steht die Kundenzufriedenheit an erster Stelle, und wir tun alles um sicherzustellen, dass Sie eine ausgezeichnete Erfahrung machen. Sollten Sie Fragen haben oder Hilfe bei Ihrer Bestellung benötigen, ist unser Kundenservice-Team immer für Sie da. Unsere deutschsprachigen Mitarbeiter stehen Ihnen zur Verfügung, um Sie schnell, freundlich und effizient zu unterstützen. Unser Ziel ist es, Ihnen auf persönliche und verständliche Art und Weise zu helfen, so dass Sie damit Sie Ihren Kauf unbesorgt genießen können. Ihre Zufriedenheit ist unsere Priorität, und wir sind hier, um sicherzustellen, dass Sie den vollen Nutzen aus unserem Service ziehen. ",
    "about.socials_title": "Bleiben Sie in Verbindung",
    "about.socials_text": " Bleiben Sie auf dem Laufenden über unsere neuesten Angebote und exklusiven Angebote, indem Sie sich für unseren Newsletter anmelden. Folgen Sie uns auf den sozialen Medien und teilen Sie Ihre Outspot-Erfahrungen mit uns!<br /><br />  Haben Sie Fragen oder Anmerkungen? Unser Helpdesk ist bereit, Ihnen Ihnen zu helfen. Bitte kontaktieren Sie uns über <a href=\"{help}“>unsere Kontaktseite</a>.<br /><br />  Vielen Dank für Ihr Vertrauen in Outspot. Wir freuen uns darauf Sie immer wieder mit unseren fantastischen Angeboten zu überraschen! ",
    "vat.error_required": "Das Feld \"{field}\" darf bei einer geschäftlichen Bestellung nicht leer sein.",
    "vat.validation_message_format": "Das Feld \"{field}\" hat nicht das richtige Format.",
    "vat.help_title": "UID-Nummer",
    "vat.help_text": "Die österreichische Umsatzsteuernummer muss aus 11 Zeichen bestehen, zum Beispiel ATU12345678.",
    "label.download": "Herunterladen",
    "error.download_pdf": "Beim Herunterladen der PDF-Datei ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.",
    "manage_address.title": "Meine Lieferadressen",
    "error.select": "Wählen Sie eine {field}",
    "manage_cards.title": "Meine Zahlungsmittel",
    "deal.quality_text_1": "Nach Preis und Qualität ausgewählt",
    "deal.quality_text_2": "",
    "deal.delivery_text_days": "Lieferfrist: {days} Werktage",
    "deal.delivery_text": "",
    "maintenance.going_offline": "In wenigen Minuten wird unsere Website wegen geplanter Wartungsarbeiten vorübergehend offline gehen. Schließen Sie Ihre Bestellung jetzt ab, da die Bestellung während der Wartungsarbeiten eine Zeit lang nicht möglich sein wird. ",
    "maintenance.offline": "Unsere Website ist derzeit wegen geplanter Wartungsarbeiten offline. Bitte versuchen Sie es in ein paar Minuten erneut - wir werden so schnell wie möglich wieder online sein! Als kleine Aufmerksamkeit schenken wir Ihnen GMJ2QPZ3 für 5 € Rabatt auf Ihren nächsten Einkauf.",
    "sustainability.carpark_title": "Unseren Fuhrpark umweltfreundlicher gestalten",
    "sustainability.carpark_text": "In unseren kontinuierlichen Bemühungen, unseren ökologischen Fußabdruck zu verringern, modernisieren wir unsere Firmenfahrzeuge. Bis 2026 streben wir eine vollständig elektrische Flotte an. Dieser Übergang ist ein entscheidender Schritt, um unsere Emissionen zu reduzieren und sauberere, grünere Transportlösungen zu fördern.",
    "sustainability.energy_title": "Büro mit Solarstrom",
    "sustainability.energy_text": "Nachhaltigkeit beginnt zu Hause – und für uns bedeutet das unser Büro. Wir haben 30 Solarpaneele installiert, die fast die Hälfte unseres Strombedarfs decken. Durch die Nutzung der Sonnenenergie verringern wir unsere Abhängigkeit von nicht-erneuerbaren Energien und reduzieren unseren CO₂-Ausstoß.",
    "sustainability.eco_packaging_title": "Umweltfreundliche Verpackungen",
    "sustainability.eco_packaging_text": "Wir sind uns der Auswirkungen von Verpackungsmüll auf die Umwelt bewusst. Daher bestehen all unsere Verpackungen aus 100 % recyclebarem Kunststoff. Diese Initiative hilft, einen nachhaltigeren Nutzungs- und Wiederverwertungskreislauf zu schaffen.",
    "sustainability.combined_packaging_title": "Gebündelter Versand",
    "sustainability.combined_packaging_text": "Um unsere Umweltbelastung weiter zu reduzieren, ermutigen wir unsere Kunden, Bestellungen zu bündeln. Durch die Zusammenfassung mehrerer Einkäufe in einer Lieferung verringern wir die Anzahl der Fahrten und damit die Emissionen und den ökologischen Fußabdruck.",
    "sustainability.title": "Nachhaltigkeit bei Outspot",
    "sustainability.vision_title": "Unsere Vision",
    "sustainability.vision_text": "Outspot ist sich seiner Verantwortung für die Umwelt bewusst. Während wir täglich Tausende von Menschen mit unserem Angebot erfreuen, ist ökologische und soziale Verantwortung in jeden Aspekt unserer Tätigkeit integriert. Wir glauben, dass wir durch proaktives Handeln heute die Gesundheit unseres Planeten und das Wohl zukünftiger Generationen positiv beeinflussen können.",
    "sustainability.action_title": "Machen Sie mit",
    "sustainability.action_text": "Wir laden Sie ein, sich unserer Nachhaltigkeits-Reise anzuschließen. Ob durch die Wahl umweltfreundlicher Produkte oder die Teilnahme an unseren Recyclingprogrammen – jeder kleine Beitrag zählt.",
    "sustainability.plant_text": "Bei Ihrem Einkauf können Sie für nur 1 € zusätzlich einen Baum pflanzen lassen. Dies hilft nicht nur, CO₂-Emissionen auszugleichen, sondern unterstützt auch die Aufforstung und eine bessere Zukunft für lokale Gemeinschaften.",
    "sustainability.treeplanters": "Wir arbeiten mit Tree-Planters zusammen, einer Organisation, die sich für intelligente und nachhaltige Aufforstung einsetzt. Sie pflanzen Bäume in sorgfältig ausgewählten Gebieten in Malawi, wo sie nicht nur die Natur wiederherstellen, sondern auch die lokale Bevölkerung durch zusätzliche Einkommen und Nahrungsressourcen unterstützen.",
    "sustainability.action_text_believe": "Bei Outspot glauben wir, dass Nachhaltigkeit eine gemeinsame Verantwortung ist. Gemeinsam mit unseren Kunden, Mitarbeitern und Partnern streben wir einen bewussten Umgang mit unserem Planeten an. Machen Sie mit und lassen Sie uns gemeinsam einen Unterschied bewirken!",
    "sustainability.plant_title": "Pflanzen Sie einen Baum – machen Sie den Unterschied",
    "sustainability.trees": "Bäume",
    "sustainability.tree": "Baum ",
    "deal.advice_price": "",
    "sustainability.checkbox_title": "Pflanze gemeinsam mit Outspot",
    "sustainability.checkbox_detail_text": "Wir laden dich ein, gemeinsam mit Outspot Bäume für {price} zu pflanzen."
  },
  "pl": {
    "route.home": "https://www.outspot.pl/pl/",
    "route.fallback_domain": "https://www2.outspot.pl",
    "language.code": "PL",
    "language.locale": "pl-PL",
    "language.name": "Polska",
    "language.flag": "pl",
    "language.date_input_format": "d,m,y",
    "language.currency": "PLN",
    "language.region": "eu",
    "countries.delivery": "POL",
    "states.delivery": "",
    "tracking.aw_merchant_id": "5301339926",
    "tracking.aw_feed_country": "PL",
    "countries.BEL": "Belgia",
    "countries.LUX": "Luksemburg",
    "countries.NLD": "Holandia",
    "countries.FRA": "Francja",
    "countries.DEU": "Niemcy",
    "countries.AUT": "Austria",
    "countries.ITA": "Włochy",
    "countries.ESP": "Hiszpania",
    "countries.POL": "Polska",
    "countries.GBR": "Wielka Brytania",
    "countries.IRL": "Irlandia",
    "countries.SWE": "Szwecja",
    "countries.PRT": "Portugalia",
    "countries.FIN": "Finlandia",
    "countries.USA": "",
    "states.AL": "",
    "states.AR": "",
    "states.AZ": "",
    "states.CA": "",
    "states.CO": "",
    "states.CT": "",
    "states.DE": "",
    "states.FL": "",
    "states.GA": "",
    "states.IA": "",
    "states.ID": "",
    "states.IL": "",
    "states.IN": "",
    "states.KS": "",
    "states.KY": "",
    "states.LA": "",
    "states.MA": "",
    "states.MD": "",
    "states.ME": "",
    "states.MI": "",
    "states.MN": "",
    "states.MO": "",
    "states.MS": "",
    "states.MT": "",
    "states.NC": "",
    "states.ND": "",
    "states.NE": "",
    "states.NH": "",
    "states.NJ": "",
    "states.NM": "",
    "states.NV": "",
    "states.NY": "",
    "states.OH": "",
    "states.OK": "",
    "states.OR": "",
    "states.PA": "",
    "states.RI": "",
    "states.SC": "",
    "states.SD": "",
    "states.TN": "",
    "states.TX": "",
    "states.UT": "",
    "states.VA": "",
    "states.VT": "",
    "states.WA": "",
    "states.WI": "",
    "states.WV": "",
    "states.WY": "",
    "trustpilot.businessunit_id": "5ecf71a744ff0b0001ea9832",
    "trustpilot.show": "TRUE",
    "mollie.profile_id": "pfl_HaJc4eE95U",
    "mollie.locale": "pl_PL",
    "facebook.url": "",
    "instagram.url": "",
    "linkedin.url": "https://www.linkedin.com/company/outspot/mycompany/",
    "header.hello": "Witamy",
    "header.hello_name": "Witaj <b>{firstName}</b>",
    "subscribe.title": "Zarejestruj się za darmo i nie przegap żadnej okazji!",
    "subscribe.text": "Odkrywaj najlepsze oferty od Outspot. Zarejestruj się za darmo i korzystaj z ekskluzywnych i spersonalizowanych rabatów na wyjątkowe produkty i doświadczenia.",
    "subscribe.agree": "Subskrybując nasz biuletyn, akceptujesz <a href=\"{conditions}\" target=\"_blank\">warunki ogólne</a> i <a href=\"{privacy}\" target=\"_blank\">oświadczenie o ochronie prywatności</a>.",
    "subscribe.sent": "Dziękujemy! Otrzymaliśmy Twoje zgłoszenie. Wkrótce prześlemy Ci wiadomość e-mail z potwierdzeniem rejestracji.",
    "footer.follow": "Obserwuj nas",
    "footer.payment_methods_logos": "przelewy24,visa,mastercard,amex,applepay,paypal",
    "footer.copyright": "Outspot — Sint-Martens-Latem, Belgia — Confinity NV",
    "home.new": "Najnowsze oferty",
    "home.ending": "Te oferty wkrótce wygasną",
    "home.other": "Wszystkie oferty",
    "countdown.prefix": "Jeszcze",
    "countdown.days": "dzień|dni",
    "countdown.hours": "godz.",
    "countdown.minutes": "min",
    "countdown.seconds": "s",
    "countdown.days_compact": "d",
    "date.years": "{count} rok temu|{count} lat(a) temu",
    "date.months": "{count} miesiąc temu|{count} miesiące (miesięcy) temu",
    "date.weeks": "{count} tydzień temu|{count} tygodni(e) temu",
    "date.days": "{count} dzień temu|{count} dni temu",
    "date.hours": "{count} godzinę temu|{count} godzin(y) temu",
    "date.minutes": "{count} minut temu|{count} minut(y) temu",
    "date.seconds": "Mniej niż 1 minutę temu",
    "countdown.suffix": "",
    "onboard.language_title": "Ciesz się indywidualnym ofertami z atrakcyjnymi promocjami",
    "onboard.language_text": "Wybierz, w którym kraju i w jakim języku chcesz robić zakupy:",
    "onboard.notifications_title": "Bądź na bieżąco:",
    "onboard.notifications_usp_1": "Najlepsze i najnowsze oferty",
    "onboard.notifications_usp_2": "Aktualizacje na temat Twoich zamówień",
    "onboard.notifications_usp_3": "Indywidualne kody promocyjne i bony wartościowe",
    "order.shipping_title": "Wysyłka",
    "order.shipping_text": "Ze względu na rosnące koszty transportu i wpływ na środowisko jesteśmy zmuszeni do naliczania kosztów transportu. Mimo tego Outspot wciąż gwarantuje doskonałe ceny, dzięki którym nadal zyskujesz. Oprócz tego koszt transportu nie wzrasta przy zakupie większej liczby sztuk. Jeśli jesteś członkiem Outspot PLUS lub kupujesz w ciągu godziny od poprzedniego zakupu, koszty te zostaną odliczone w następnym kroku.",
    "order.thank_you": "Dziękujemy za zamówienie!",
    "order.overview": "Przegląd Twojego zamówienia",
    "order.thank_you_bank": "Dziękujemy za zakup. Szczegóły dotyczące przelewu otrzymasz drogą mailową (wiadomość od nas może również trafić do folderu spam). Prosimy o jak najszybsze przelanie kwoty – najpóźniej w ciągu 7 dni. Zamówiony voucher otrzymasz drogą mailową, kiedy tylko płatność dotrze do nas i zostanie zaksięgowana.",
    "order.thank_you_with_shipping": "Zakup został zakończony. Zamówienie zostanie wysłane na poniższy adres. Potwierdzenie zakupu otrzymasz drogą mailową (wiadomość od nas może również trafić do folderu spam). Życzymy wiele przyjemności z Twojego zamówienia!",
    "order.thank_you_without_shipping": "Zakup został zakończony. Potwierdzenie zakupu otrzymasz drogą mailową (wiadomość od nas może również trafić do folderu spam). Życzymy wiele przyjemności z Twojego zamówienia!",
    "order.code": "Numer zamówienia: {code}",
    "order.shipping_address": "Adres dostawy",
    "order.save_payment_info_title": "Zapisz dane bankowe do następnych zakupów.",
    "order.save_payment_info_text": "Jeśli wybierzesz tę opcję, nie będziesz musiał za każdym razem wprowadzać swoich danych. Twoje dane zostaną bezpiecznie zapisane.",
    "order.shipped_to_service_point": "",
    "my_outspot_plus.title": "Mój Outspot PLUS",
    "my_outspot_plus.subtitle": "Na tej stronie możesz zarządzać swoim członkostwem Outspot PLUS.",
    "my_outspot_plus.overview": "Przegląd Twojego członkostwa Outspot PLUS",
    "my_outspot_plus.cancel_are_you_sure": "Czy na pewno chcesz anulować swoje członkostwo?",
    "my_outspot_plus.cancel_you_saved_prefix": "Do tej pory udało Ci się oszczędzić",
    "my_outspot_plus.cancel_you_saved_suffix": "na opłatach za wysyłkę i kosztach administracyjnych w ramach członkostwa Outspot PLUS.",
    "my_outspot_plus.no_membership": "Nie posiadasz obecnie członkostwa Outspot PLUS.",
    "plus.title": "Outspot PLUS",
    "plus.popup_text": "Z Outspot PLUS korzystasz z bezpłatnej wysyłki wszystkich produktów i nie płacisz żadnych opłat administracyjnych związanych z podróżami. Po każdym zamówieniu otrzymasz wiadomość e-mail z możliwością uzyskania statusu Outspot PLUS. Musisz jednak działać szybko, ponieważ promocja jest ważna tylko przez 7 dni. Nie zapomnij sprawdzić następnej wiadomości e-mail z potwierdzeniem zakupu.",
    "plus.name": "Członkostwo Outspot PLUS",
    "plus.highlight_1": "Ciesz się bezproblemowym zamawianiem bez opłat za wysyłkę i kosztów administracyjnych. ",
    "plus.highlight_2": "Subskrypcja będzie automatycznie odnawiana w cenie {renewPrice} co trzy miesiące. ",
    "plus.highlight_3": "Zawsze możesz ją anulować. ",
    "plus.question_1": "Jakie korzyści daje Outspot PLUS?\r \r",
    "plus.answer_1": "Dzięki Outspot PLUS korzystasz z bezpłatnej dostawy wszystkich artykułów i nie płacisz żadnych opłat administracyjnych w przypadku wszystkich podróży.  ",
    "plus.question_2": "Jak uzyskać członkostwo Outspot PLUS?  ",
    "plus.answer_2": "Po każdym zamówieniu otrzymasz wiadomość e-mail informujący o możliwości zostania członkiem Outspot PLUS. Musisz się jednak spieszyć, ponieważ ta promocja jest ważna tylko przez 7 dni. Jeśli zdecydujesz się aktywować Outspot PLUS, z korzyści możesz cieszyć się natychmiast. Oznacza to, że przy następnym zamówieniu możesz od razu korzystać z darmowej dostawy wszystkich produktów i nie musisz ponisić kosztów administracyjnych w przypadku wszystkich swoich podróży.  ",
    "plus.question_3": "Ile kosztuje Outspot PLUS?\r \r",
    "plus.answer_3": "Outspot PLUS kosztuje tylko {price} przez pierwsze 3 miesiące. Jest to płatność jednorazowa. Po tym okresie członkostwo będzie automatycznie odnawiane co 3 miesiące w cenie {renewPrice}. Pozwala to znacznie zaoszczędzić na kosztach dostaw i opłatach administracyjnych.  ",
    "plus.question_4": "Kiedy i w jaki sposób mój Outspot PLUS zostanie odnowiony?\r \r ",
    "plus.answer_4": "Outspot PLUS odnawia się automatycznie co trzy miesiące. Aby zarządzać związanymi z tym ustawieniami, zawsze możesz wejść na stronę <a href=\"{myOutspotPlus}\">Mój Outspot PLUS</a>. Kwota będzie automatycznie pobierana z wybranego konta co 3 miesiące.  ",
    "plus.question_5": "Jak mogę anulować subskrypcję Outspot PLUS? Czy otrzymam zwrot pieniędzy?\r \r ",
    "plus.answer_5": "Możesz anulować członkostwo Outspot PLUS w dowolnym momencie na stronie: <a href=\"{myOutspotPlus}\">Mój Outspot PLUS</a>. Należy przy tym pamiętać, że zwrot pieniędzy nie przysługuje w odniesieniu do miesięcy, w których członkostwo było aktywne. Jeśli jednak zdecydujesz się anulować Outspot PLUS, następne zaplanowane rozliczenie nie zostanie naliczone.  ",
    "plus.thank_you": "Twoje zakupy zostały zrealizowane. Od teraz możesz korzystać z bezproblemowego zamawiania bez opłat za wysyłkę i bez kosztów administracyjnych. Możesz przeglądać i zarządzać swoim członkostwem na stronie <a href=\"{myOutspotPlus}\">Mój Outspot PLUS</a>.  ",
    "my_coupons.title": "Moje bony wartościowe",
    "my_coupons.subtitle": "Bony wartościowe można znaleźć na tej stronie.",
    "my_coupons.no_coupons": "Obecnie nie posiadasz żadnych bonów wartościowych.",
    "deal.sold": "Kupiono już {sold} razy",
    "deal.retour_label": "Możliwość zwrotu w ciągu 30 dni za jedyne 3,95 zł",
    "deal.retour_title": "Odstąpienie od umowy",
    "deal.retour_text": "Nie podoba Ci się jednak zamówiony produkt? Skontaktuj się z naszym działem pomocy technicznej w ciągu 30 dni od otrzymania produktu i zwróć go. Niezależnie od powodu! Zwrócimy Ci pieniądze za zakup, abyś mógł (mogła) przeznaczyć je na kolejne zamówienia. Kolejny świetny pomysł – oczywiście od Outspot!",
    "deal.guarantee_label": "6-miesięczna gwarancja zwrotu pieniędzy",
    "deal.guarantee_title": "Wyjątkowo: 100% gwarancja zwrotu pieniędzy",
    "deal.guarantee_text": "Niewiarygodne, ale prawdziwe. Nie musisz już wahać się, czy kupić przeżycie dla swoich przyjaciół, znajomych, rodziny lub dla siebie. Jeśli później zmienisz zdanie lub osoba obdarowana nie będzie w pełni zadowolona z vouchera prezentowego, nie ma problemu.<br><br>Na stronie Outspot.pl możesz anulować zakup do 6 miesięcy po złożeniu zamówienia! Ty lub osoba, która otrzymała voucher, możecie otrzymać pełny zwrot pieniędzy w ciągu 6 miesięcy. Oczywiście pod warunkiem, że voucher nie został jeszcze zarezerwowany, wykorzystany lub nie wygasł. Jest to wyjątkowe i nigdy niestosowane zabezpieczenie gwarancyjne. Taki jednak jest Outspot.<br><br> Należy pamiętać, że w przypadku wszystkich akcji, w których gwarancja zwrotu pieniędzy nie jest wyraźnie wymieniona, gwarancja nie ma zastosowania. Wynika to z faktu, że w niektórych przypadkach nie możemy samodzielnie odzyskać kosztów związanych z dostawami lub biletami od naszego partnera.",
    "deal.option_count": "{count} opcja|{count} opcje",
    "deal.campaign_number": "Numer kampanii",
    "deal.your_details": "Twoje dane",
    "deal.payment_methods_title": "Metoda płatności",
    "deal.shipping_methods_title": "",
    "deal.privacy": "Podając swoje dane, akceptujesz <a target='_blank' href='{privacy}'>naszą politykę prywatności</a>.",
    "deal.payment_methods_subtitle": "Jak chcesz zapłacić za zamówienie?",
    "deal.your_order": "Twoje zamówienie",
    "deal.from": "Od",
    "help.title": "Potrzebujesz pomocy?",
    "help.subtitle": "Napisz do nas, w czym możemy Ci pomóc.",
    "help.subject": "Pytanie za pośrednictwem naszej strony internetowej lub aplikacji",
    "help.sent": "Otrzymaliśmy Twoją wiadomość. Odpowiedź otrzymasz najpóźniej w ciągu 4 dni roboczych.",
    "login.title": "Zaloguj się",
    "login.subtitle": "Jak chcesz się zalogować?",
    "login.or": "lub",
    "login.usps_title": "Dlaczego warto zalogować się w Outspot?",
    "login.usps_title_cta": "Ułatw to sobie i zaloguj się lub utwórz konto już teraz.",
    "login.usps_text": "Kliknij przycisk „Zaloguj się” na górze.",
    "login.usp_manage_orders": "Przeglądanie i śledzenie zamówień",
    "login.usp_remember_info": "Zapisywanie danych kontaktowych na przyszłość",
    "login.usp_returns": "Zgłaszanie reklamacji i wnioskowanie o zwrot",
    "login.usp_store_payments": "Zapisywanie metod płatności i szybsze płacenie",
    "login.short_cta": "<a href=\"{loginLink}\">Zaloguj się</a> lub kontynuuj poniżej jako gość.",
    "forgot.title": "Uzyskaj nowe hasło",
    "forgot.subtitle": "Wprowadź swój adres e-mail poniżej, a my wyślemy Ci link do zresetowania hasła.",
    "forgot.sent": "Wysłaliśmy Ci wiadomość e-mail z linkiem umożliwiającym zresetowanie hasła.",
    "password.title": "Zmiana hasła",
    "password.subtitle": "Wybierz nowe, bezpieczne hasło.",
    "password.updated": "Twoje hasło zostało pomyślnie zmienione.",
    "register.title": "Zarejestruj się",
    "register.subtitle": "Utwórz konto, aby jeszcze łatwiej zamawiać i śledzić swoje zamówienia.",
    "register.personal_info": "Dane osobowe",
    "register.login_info": "Dane do logowania",
    "register.agree": "Akceptuję <a href=\"/enl/help/general-conditions</a>warunki ogólne</a> i <a href=\"/enl/help/privacy\" target=\"_blank\">oświadczenie o ochronie prywatności</a>.",
    "register.sent": "To już prawie wszystko. Wysłaliśmy do Ciebie wiadomość e-mail dotyczącą aktywacji konta.",
    "register.email_known": "Twój adres e-mail jest już znany w Outspot? <a href=\"{forgot}\">Kliknij tutaj</a>, aby uzyskać hasło.",
    "my_orders.title": "Moje zamówienia",
    "my_orders.subtitle": "Oto przegląd wszystkich zamówień złożonych w Outspot.",
    "my_orders.title_detail": "Szczegóły Twojego zamówienia",
    "my_travels.title": "Moje podróże",
    "my_info.title": "Moje dane",
    "my_info.subtitle": "Tutaj możesz edytować swoje dane osobowe.",
    "my_info.sent": "Twoje dane zostały zaktualizowane.",
    "notifications.title": "Newsletter i powiadomienia",
    "notifications.subscription_mail": "Jak często chciałbyś otrzymywać nasz biuletyn?",
    "notifications.subscription_notification": "Jak często chciałbyś otrzymywać powiadomienia?",
    "notifications.frequency_0": "Nigdy",
    "notifications.frequency_4": "Raz w tygodniu",
    "notifications.frequency_56": "Dwa razy dziennie",
    "notifications.sent": "Twoje preferencje zostały zaktualizowane.",
    "combined_purchase_banner.text": "Nie ponosisz żadnych opłat za wysyłkę lub kosztów administracyjnych w przypadku kolejnych zamówień:",
    "plus_banner.text_welcome": "{firstName}, witamy w Outspot PLUS.",
    "plus_banner.text_welcome_anonimous": "Witamy w Outspot PLUS.",
    "plus_banner.text_savings_prefix": "{firstName}, do tej pory udało Ci się oszczędzić",
    "plus_banner.text_savings_prefix_anonimous": "Do tej pory udało Ci się oszczędzić",
    "plus_banner.text_savings_suffix": "na opłatach za wysyłkę i kosztach administracyjnych w ramach członkostwa Outspot PLUS.",
    "cookie_banner.title": "Pliki cookie",
    "cookie_banner.text": "Ta strona internetowa używa plików cookie. Ściśle niezbędne pliki cookie zapewniają prawidłowe funkcjonowanie strony i nie mogą być odrzucone. Funkcjonalne, analityczne i reklamowe pliki cookie są używane odpowiednio do celów funkcjonalnych, statystycznych i marketingowych. Są one pobierane tylko za Twoją zgodą. Możesz przeczytać o tym więcej w naszej <a href=\"{cookies}\" target=\"_blank\">polityce plików cookie</a>.",
    "cookie_banner.decline": "Odrzuć opcjonalne pliki cookie",
    "cookie_banner.accept_all": "Zaakceptuj wszystkie pliki cookie",
    "cookie_banner.accept_selected": "Zaakceptuj wybrane",
    "cookie_banner.settings": "Ustawienia",
    "cookie_banner.functional_cookies_title": "Ściśle niezbędne pliki cookie",
    "cookie_banner.functional_cookies_text": "Pliki cookie niezbędne do podstawowego działania strony internetowej.",
    "cookie_banner.analytical_cookies_title": "Analityczne i funkcjonalne pliki cookie",
    "cookie_banner.analytical_cookies_text": "Pliki cookie umożliwiają stronie internetowej zapewnianie dodatkowych funkcji oraz personalizację ustawień, a także monitorowanie wizyt i ruchu.",
    "cookie_banner.personal_cookies_title": "Reklamowe pliki cookie",
    "cookie_banner.personal_cookies_text": "Pliki cookie ustawiane przez reklamodawców w celu tworzenia profilu Twoich zainteresowań (nie obejmuje to przechowywania bezpośrednich informacji osobowych).",
    "review.title": "Podziel się opinią i wygrywaj",
    "review.subtitle": "Opublikuj poniżej swoją opinię dotyczącą zakupu, aby od razu zyskać szansę na wygranie bonu wartościowego (taki bon losujemy co kwartał).",
    "review.agree": "Wyrażam zgodę na publikację mojej opinii na stronie internetowej",
    "review.sent": "Dziękujemy za Twoją opinię. Masz teraz szansę na wygranie bonu wartościowego.",
    "review.title_thank_you": "Dziękujemy! Doceniamy Twoją opinię.",
    "review.subtitle_thank_you": "Dzięki Twojej opinii możemy nieustannie ulepszać Outspot. Wpisz swoją ocenę zakupu poniżej i skorzystaj z szansy na wygranie bonu (taki bon losujemy co kwartał).",
    "confirm.title": "Rejestracja została potwierdzona",
    "confirm.subtitle": "Subskrypcja biuletynu zostaje niniejszym potwierdzona. Dziękujemy! Aby służyć Ci jeszcze lepiej, oferujemy Ci możliwość udostępnienia nam następujących danych bez żadnych zobowiązań.",
    "popup_widget.title": "Najlepsze oferty, sprawdzone dla Ciebie.",
    "popup_widget.usp_1": "Codziennie otrzymuj nasze najlepsze oferty.",
    "popup_widget.usp_2": "Wyselekcjonowane i sprawdzone pod kątem najlepszej ceny i jakości.",
    "popup_widget.usp_3": "Ogromne rabaty do -70%.",
    "popup_widget.footer_1": "Już teraz subskrybuj za darmo nasz newsletter i oszczędzaj od razu.",
    "popup_widget.footer_2": "Możesz zrezygnować z subskrypcji w dowolnym momencie.",
    "promo.title": "Nasze najlepsze promocje",
    "promo.countdown": "2024-12-31 23:59:59",
    "promo.empty": "Obecnie nie mamy najlepszych promocji. Przejdź do strony głównej, aby zapoznać się z najnowszymi ofertami.",
    "stars.info_title": "Ocena produktu",
    "stars.info_text": "Ta ocena to średnia opinii zebranych przez nas oraz opinii z zewnątrz.",
    "stars.counter": "1 opinia | {count} opinii",
    "label.accept_selected": "Zaakceptuj wybrane",
    "label.edit": "Edytuj",
    "label.edit_address": "Zmień adres",
    "label.address": "Adres",
    "label.accept_all": "Zaakceptuj wszystko",
    "label.account": "Konto",
    "label.help": "Pomoc",
    "label.my_orders": "Moje zamówienia",
    "label.my_travels": "Moje podróże",
    "label.my_coupons": "Moje bony wartościowe",
    "label.my_info": "Moje dane",
    "label.change_password": "Zmień hasło",
    "label.notifications": "Newsletter i powiadomienia",
    "label.logout": "Wyloguj się",
    "label.login": "Zaloguj się",
    "label.login_have_account": "Masz już konto? Zaloguj się",
    "label.login_with_facebook": "Zaloguj się za pomocą Facebooka",
    "label.login_with_google": "Zaloguj się za pomocą Google",
    "label.login_with_apple": "Zaloguj się za pomocą Apple",
    "label.password": "Hasło",
    "label.new_password": "Nowe hasło",
    "label.repeat_password": "Powtórz hasło",
    "label.repeat_new_password": "Powtórz nowe hasło",
    "label.register_no_account": "Nie masz jeszcze konta? Zarejestruj się teraz",
    "label.register": "Zarejestruj się",
    "label.forgot_password": "Nie masz jeszcze hasła albo go nie pamiętasz?",
    "label.general_conditions": "Warunki ogólne",
    "label.jobs": "Praca w Outspot",
    "label.faq": "Często zadawane pytania",
    "label.returns": "Zwroty",
    "label.travel_conditions": "Szczególne warunki podróży",
    "label.privacy_policy": "Oświadczenie o ochronie prywatności",
    "label.cookie_policy": "Oświadczenie dotyczące plików cookie",
    "label.subscribe": "Chcę się zapisać",
    "label.all_deals": "Wszystkie oferty",
    "label.other_deals": "Inne oferty",
    "label.discount": "Zniżka",
    "label.close": "Zamknij",
    "label.order_now": "Zamów teraz",
    "label.add": "Dodaj",
    "label.book": "Zarezerwuj teraz",
    "label.upselling_next_step": "Nie zapomnij później też o tym",
    "label.upselling": "Nie zapomnij też o tym",
    "label.also_available": "Również dostępne ",
    "label.reviews": "Co mówią nasi klienci",
    "label.automatically_translated": "Tłumaczenie automatyczne",
    "label.show_all_reviews": "Zobacz wszystkie opinie",
    "label.back": "Powrót",
    "label.make_your_choice": "Dokonaj wyboru",
    "label.sold_out": "Wyprzedane",
    "label.order_more": "Zamów więcej",
    "label.subtotal": "Suma częściowa",
    "label.total_shipping": "Koszty wysyłki",
    "label.total_file": "Koszty administracyjne",
    "label.total_payable": "Łącznie",
    "label.no_options_selected": "Twoje zamówienie jest obecnie puste. Kliknij poniżej przycisk „Dodaj”, aby kontynuować.",
    "label.no_options_selected_extra": "Tę opcję można wybrać tylko podczas zakupu produktu.",
    "label.total_saved_prefix": "(Oszczędzasz ",
    "label.total_saved_suffix": ")",
    "label.continue": "Dalej",
    "label.usp_quality": "Wybrane na podstawie ceny i jakości",
    "label.usp_discounts": "Duże zniżki do -70%",
    "label.usp_payment_methods": "Płać bezpiecznie i łatwo za pomocą",
    "label.person": "Osoba",
    "label.free": "Gratis",
    "label.first_name": "Imię",
    "label.last_name": "Nazwisko",
    "label.birthdate": "Data urodzenia",
    "label.birthdate_why": "",
    "label.birthdate_why_text": "",
    "label.email": "Adres e-mail",
    "label.phone": "Numer telefonu",
    "label.address_finder": "Wyszukiwarka adresów",
    "label.address_finder_help": "Wpisz swój pełny adres dostawy",
    "label.select": "Wybierz",
    "label.street": "Ulica",
    "label.building": "",
    "label.number": "Nr domu/ nr lokalu",
    "label.zip": "Kod pocztowy",
    "label.city": "Miejscowość",
    "label.state": "",
    "label.country": "Kraj",
    "label.finish_order": "Zamów & zapłać",
    "label.finish_order_help": "Kliknij przycisk „Zamów & zapłać”, aby dokończyć płatność.",
    "label.enter_address": "Wprowadź mój adres ręcznie",
    "label.add_address": "Dodaj adres",
    "label.add_payment_method": "Dodaj metodę płatności",
    "label.year": "RRRR",
    "label.month": "MM",
    "label.day": "DD",
    "label.business_order": "Zamówienie firmowe",
    "label.company_name": "Nazwa firmy",
    "label.tax_id": "Numer VAT",
    "label.invoice_reason": "Powód",
    "label.invoice_reasons": "Prezenty firmowe, Budowanie zespołu, Zachęty, Szkolenie, Inne",
    "label.delete": "Usuń",
    "label.i_have_coupons": "Mam bony wartościowe",
    "label.email_outspot_plus": "Jesteś już klientem Outspot PLUS? Wprowadź swój adres e-mail.",
    "label.please_wait": "Prosimy o cierpliwość...",
    "label.please_wait_2": "Jeszcze chwileczkę...",
    "label.please_wait_3": "Przepraszamy! Trwa to nieco dłużej niż przewidywaliśmy...",
    "label.please_wait_4": "Jeszcze chwileczkę, już prawie gotowe...",
    "label.please_wait_5": "Przepraszamy za niedogodności i dziękujemy za cierpliwość! Wszystko już prawie gotowe...",
    "label.continue_shopping": "Kontynuuj zakupy",
    "label.my_code": "Mój kod",
    "label.validate": "Zatwierdź",
    "label.total_coupons": "Bony wartościowe łącznie",
    "label.coupons": "Bony wartościowe",
    "label.total_payable_with_coupons_prefix": "(Wciąż do zapłaty ",
    "label.total_payable_with_coupons_suffix": ")",
    "label.rest_coupon_info": "Kwota do zapłaty jest niższa niż wartość wprowadzonych bonów wartościowych. Otrzymasz nowy bon o pozostałej wartości.",
    "label.contact_extra_info": "Numer zamówienia, numer vouchera lub komunikat strukturalny",
    "label.contact": "Skontaktuj się z nami",
    "label.message": "Wiadomość",
    "label.send": "Wyślij",
    "label.save": "Zapisz",
    "label.my_outspot_plus": "Mój Outspot PLUS",
    "label.order_date_prefix": "Zamówiono dnia",
    "label.order_date_suffix": "",
    "label.total_paid_with": "Całkowita kwota zapłacona przy użyciu {method}",
    "label.track_order": "Śledź swoje zamówienie za pomocą",
    "label.return_order": "Zwróć i/lub zgłoś wadę",
    "label.download_invoice": "Pobierz fakturę",
    "label.ask_question": "Zadaj pytanie",
    "label.happy_hour_discount": "Korzyści Happy Hour",
    "label.outspot_plus_discount": "Korzyści Outspot PLUS",
    "label.cancel_membership": "Anuluj członkostwo",
    "label.membership_start_date": "Data rozpoczęcia członkostwa",
    "label.membership_price": "Cena za 3 miesiące",
    "label.membership_next_payment": "Następna płatność",
    "label.membership_end_date": "Data zakończenia członkostwa",
    "label.total_savings": "Łączne zaoszczędzone opłaty za wysyłkę i koszty administracyjne",
    "label.confirm_cancel_membership": "Potwierdź anulowanie",
    "label.continue_membership": "Nie anuluj",
    "label.valid_till_prefix": "Ważne do",
    "label.valid_till_suffix": "",
    "label.card_payment": "Płatność kartą",
    "label.place_review": "Zamieść ocenę",
    "label.name": "Imię",
    "label.name_help": "pokazujemy je w ocenie",
    "label.review": "Ocena",
    "label.self_service": "",
    "label.update_cookie_preferences": "Zmiana preferencji dotyczących plików cookie",
    "label.more_info": "Więcej informacji",
    "label.review_source": "Via {source}",
    "label.amount": "Kwota",
    "label.iban": "IBAN",
    "label.bic": "BIC",
    "label.beneficiary": "Odbiorca",
    "label.structured_communication": "Strukturalna wiadomość",
    "label.later": "Później",
    "label.enable_notifications": "Tak, chcę otrzymywać te powiadomienia",
    "label.card_number": "Numer karty",
    "label.card_expiry": "Data wygaśnięcia",
    "label.card_cvc": "CVV",
    "label.cvc_info": "",
    "label.sales_tax": "",
    "label.calculating_sales_tax": "",
    "label.sales_tax_will_be_calculated": "",
    "label.ship_to_selected_address": "",
    "label.ship_to_service_point": "",
    "label.change_service_point": "",
    "label.choose_rooms": "",
    "label.room": "",
    "label.room_occupancy": "",
    "label.add_another_room": "",
    "label.number_of_adults": "",
    "label.number_of_adults_title": "",
    "label.number_of_children": "",
    "label.number_of_children_title": "",
    "label.choose_arrival_and_departure_date": "",
    "label.arrival_date": "",
    "label.select_arrival_date": "",
    "label.departure_date": "",
    "label.select_departure_date": "",
    "label.rate_type_pc": "",
    "label.rate_type_pcpd": "",
    "label.rate_type_pcpn": "",
    "label.rate_type_pp": "",
    "label.rate_type_pppd": "",
    "label.rate_type_pppn": "",
    "label.rate_type_pp_": "",
    "label.rate_type_pa": "",
    "label.rate_type_papd": "",
    "label.rate_type_papn": "",
    "label.rate_type_pa_": "",
    "label.choose_supplements": "",
    "label.opengds_invalid": "",
    "error.unknown": "Nieznany błąd, spróbuj ponownie później.",
    "error.required": "{field} nie może być puste.",
    "error.required_email": "{field} nie może być puste i musi być prawidłowym adresem e-mail.",
    "error.required_password": "{field} musi liczyć co najmniej 8 znaków i zawierać co najmniej jedną wielką literę, jedną małą literę i jedną cyfrę.",
    "error.required_repeat_password": "{field} musi być takie samo jak hasło podane w polu {field2}.",
    "error.payment": "Niestety, płatność nie powiodła się. Może się tak zdarzyć, jeśli wystąpił problem ze szczegółami płatności, płatność została anulowana, osiągnięto limit płatności lub z innych powodów. Rozważ skontaktowanie się ze swoim bankiem lub wypróbuj inną kartę lub metodę płatności.",
    "error.payment_creditcard": "Niestety! Płatność nie powiodła się. Sprawdź poniższe dane i spróbuj ponownie.",
    "error.incomplete_order": "Ten bon wartościowy możemy zatwierdzić dopiero po wypełnieniu wszystkich powyższych pól.",
    "error.coupon_already_added": "Ten bon wartościowy został już dodany.",
    "error.invalid_coupon": "Ten bon wartościowy jest nieważny.",
    "error.coupon_not_cumulative": "Tego bonu wartościowego nie można wykorzystać razem z innymi bonami.",
    "error.other_coupon_not_cumulative": "Dodany wcześniej bon wartościowy nie może zostać użyty razem z tym bonem.",
    "error.invalid_username_or_password": "Podany adres e-mail i/lub hasło są nieprawidłowe.",
    "error.test_order": "To jest zamówienie testowe. Normalnie klient przeszedłby teraz do strony płatności.",
    "error.required_agree": "Musisz zaakceptować nasze warunki ogólne i oświadczenie o ochronie prywatności.",
    "error.required_review_agree": "Musisz wyrazić zgodę na ewentualną publikację swojej opinii na naszej stronie internetowej.",
    "error.register": "Istnieje już konto z tym adresem e-mail. Kliknij „Kontynuuj”, aby się zalogować, lub użyj innego adresu e-mail.",
    "error.email_in_use": "Istnieje już konto z tym adresem e-mail. Użyj innego adresu e-mail.",
    "error.already_reviewed": "Opinia na temat tego zamówienia została już przez Ciebie zamieszczona.",
    "error.zip_blocked": "Wysyłka na podany kod pocztowy jest niemożliwa.",
    "error.details": "Wygląda na to, że jeden lub więcej produktów, które próbujesz zamówić, nie jest już dostępny w magazynie. Wróć do poprzedniego kroku, zaktualizuj zamówienie i spróbuj ponownie.",
    "error.incomplete_cvc": "CVV jest za krótki.",
    "error.incomplete_date": "Data ważności nie może być pusta.",
    "error.date_in_past": "Karta jest przeterminowana.",
    "error.invalid_expiration_year": "Karta jest przeterminowana.",
    "error.incomplete_card_number": "Numer karty jest za krótki.",
    "error.incorrect_card_number": "Numer karty jest nieprawidłowy.",
    "unsubscription.your_mail_address": "Twój adres e-mail:",
    "unsubscription.sad_message": "Niniejszym rezygnujesz z subskrypcji.",
    "unsubscription.have_not_participated": "Nigdy się nie rejestrowałeś(-aś)? ",
    "unsubscription.sad_message_2": "Przykro nam, że nas opuszczasz, ale oczywiście zawsze jesteś mile widziany(-a).",
    "unsubscription.resubscribe_question": "Zmieniasz zdanie? <span class='unsub-important-message'>Kliknij poniżej, aby ponownie zapisać się do naszego newslettera:</span>",
    "unsubscription.form_message": "Dlaczego opuszczasz rodzinę Outspot? Twoja odpowiedź może pomóc nam w ulepszeniu naszych usług.",
    "unsubscription.report_not_participated": "Zgłoś nadużycie",
    "unsubscription.coreg_date_no_screenshot": "{date} wziąłeś(-aś) udział w akcji promocyjnej (<a href=\"{coreg_site}\">{coreg_site}</a>) i w ten sposób udzieliłeś(-aś) zgody na otrzymywanie newslettera z najlepszymi promocjami w wyjątkowych cenach.",
    "unsubscription.coreg_date_no_coreg_data": "{date} wziąłeś(-aś) udział w poniższej akcji promocyjnej i w ten sposób udzieliłeś(-aś) zgody na otrzymywanie newslettera z najlepszymi promocjami w wyjątkowych cenach.",
    "unsubscription.coreg_date_screenshot": "{date} wziąłeś(-aś) udział w poniższej akcji promocyjnej i w ten sposób udzieliłeś(-aś) zgody na otrzymywanie newslettera z najlepszymi promocjami w wyjątkowych cenach.",
    "unsubscription.coreg_no_date_no_screenshot": "Wziąłeś(-aś) udział w poniższej akcji promocyjnej i w ten sposób udzieliłeś(-aś) zgody na otrzymywanie newslettera z najlepszymi promocjami w wyjątkowych cenach.",
    "unsubscription.coreg_no_date_screenshot": "Wziąłeś(-aś) udział w poniższej akcji promocyjnej i w ten sposób udzieliłeś(-aś) zgody na otrzymywanie newslettera z najlepszymi promocjami w wyjątkowych cenach.",
    "unsubscription.main_title": "Wypisz się z newslettera",
    "unsubscription.report_title": "Zgłoś nadużycie",
    "unsubscription.report_sent_message": "Dziękujemy! Powiadomienie zostało odebrane.",
    "claim_coupon.welcome_message": "Specjalnie dla Ciebie",
    "claim_coupon.info_line_message": "Wprowadź powyższy kod osobisty przy kasie.",
    "claim_coupon.info_line_message_bis": "Ten bon wartościowy został wysłany również na Twój adres e-mail.",
    "claim_coupon.main_title": "Twój kupon",
    "about.title": "O nas",
    "about.intro": "Witamy w Outspot – Twoim najlepszym miejscu z ofertami, którym nie można się oprzeć! Od momentu powstania w 2008 roku Outspot stała się wiodącą europejską platformą internetową z ofertami błyskawicznymi. Outspot oferuje codzienne zniżki na wysokiej jakości produkty oraz podróże, co pozwala Tobie, jako klientowi, na znaczne oszczędności. Dzięki naszemu magazynowi w Europie dostarczamy nasze przesyłki możliwie najszybciej pod Twoje drzwi.",
    "about.mission_title": "Misja",
    "about.mission_text": "W Outspot wierzymy w to, że wyjątkowe oferty mogą być dostępne dla każdego. Naszym celem jest zapewnianie klientom najlepszej możliwej jakości dzięki starannie wyselekcjonowanym ofertom w bezkonkurencyjnych cenach.",
    "about.history_title": "Historia",
    "about.history_text": "Przygoda Outspot rozpoczęła się w Belgii, gdzie do dziś mieści się siedziba główna firmy. W pełni zaangażowany zespół 25 pracowników rozsianych po całej Europie każdego dnia pracuje nad tym, aby znaleźć i zaproponować Ci najlepsze oferty. Działamy obecnie w 13 krajach, a każdego dnia odwiedza nas 100 tysięcy zadowolonych gości.",
    "about.quality_title": "Naszą ofertę dobieramy, kierując się ceną i jakością",
    "about.quality_text": "W Outspot wierzymy, że zasługujesz na to, co najlepsze z obu światów: najwyższą jakość w przystępnej cenie. Dlatego każdego dnia szukamy dla Ciebie najciekawszych ofert. Dzięki ścisłej współpracy z naszymi dostawcami dbamy o to, aby ustalać jak najlepsze ceny bez ustępstw na rzecz jakości. <br /><br />  Każda oferta produktowa i turystyczna, którą publikujemy, są starannie wyselekcjonowane na podstawie rygorystycznej analizy ceny i jakość. Dzięki temu możesz mieć pewność, że u nas zawsze otrzymasz najlepszą jakość za wydane pieniądze. W Outspot wszystko kręci się wokół Twoich korzyści i satysfakcji! Poniżej możesz zobaczyć zdjęcie, na którym dźwięk inteligentnego dzwonka jest testowany za pomocą decybeliomierza.",
    "about.tested_title": "Wszystkie oferty są szczegółowo testowane przez pracowników Outspot",
    "about.tested_text": "W Outspot wierzymy w dostarczanie tylko tego, co najlepsze. Zanim oferta pojawi się na naszej stronie, zostaje szczegółowo przetestowane przez pracowników Outspot. Nie wystarczą tutaj jednak powierzchowne testy: produkty są kompletnie demontowane, a każdy element jest dokładnie oceniany pod kątem wytrzymałości, poziomu hałasu, jakości materiału i nie tylko.<br /><br />  Nasi pracownicy również osobiście odwiedzają wiele hoteli, aby sprawdzić jakość zakwaterowania. W ten sposób możemy zagwarantować, że wszystko spełnia nasze wysokie standardy. Niezależnie więc od tego, czy chodzi o ofertę produktową czy turystyczną, możesz mieć pewność, że w Outspot zawsze dokonujesz zakupów bez obaw.<br /><br />  Na zdjęciu poniżej możesz zobaczyć, jak pracownicy szczegółowo analizują i testują produkty, np. przeprowadzają kontrolę jakości materiału.",
    "about.guarantee_title": "",
    "about.guarantee_text": "",
    "about.returns_title": "Prawo do zwrotu w okresie 30 dni w przypadku większości produktów",
    "about.returns_text": "Rozumiemy, że czasem potrzeba trochę czasu, aby zdecydować, czy chcesz zachować produkt. Dlatego oferujemy 30-dniowe prawo do zwrotu większości naszych produktów. Ważne jest, aby produkt pozostał nieużywany i nierozpakowany, aby kwalifikował się do zwrotu. Daje to możliwość spokojnego zastanowienia się nad zakupem, a my zapewniamy sprawny proces zwrotu. Dzięki temu możesz podjąć decyzję bez obaw, pod warunkiem, że produkt pozostanie w oryginalnym stanie.",
    "about.support_title": "Obsługa klienta w języku polskim",
    "about.support_text": "Dla Outspot zadowolenie klienta jest najważniejsze, dlatego robimy wszystko, co w naszej mocy, aby zapewnić Ci doskonałe doświadczenia. Jeśli masz jakieś pytania lub potrzebujesz pomocy przy zamówieniu, możesz zawsze skontaktować się z naszą obsługą klienta. Nasi polskojęzyczni pracownicy są do Twojej dyspozycji, aby zapewnić Ci przyjazne i skuteczne wsparcie. Zawsze dążymy do tego, aby pomóc Ci w osobisty i zrozumiały sposób, aby pozwolić Ci cieszyć się zakupem bez żadnych zmartwień. Twoja satysfakcja jest naszym priorytetem i jesteśmy tutaj, aby gwarantować Ci optymalne korzystanie z naszych usług.",
    "about.socials_title": "Bądźmy w kontakcie",
    "about.socials_text": "Bądź na bieżąco z naszymi najnowszymi ofertami i ekskluzywnymi ofertami, subskrybując nasz newsletter. Śledź nas w mediach społecznościowych i podziel się z nami swoimi doświadczeniami związanymi z Outspot!<br /><br />  Masz pytania lub uwagi? Nasz dział pomocy jest gotowy, aby Ci pomóc. Skontaktuj się z nami za pośrednictem <a href=\"{help}\">naszej strony kontaktowej</a>.<br /><br />  Dziękujemy za zaufanie okazane Outspot. Nie możemy się doczekać, aby po raz kolejny zaskoczyć Cię naszymi fantastycznymi ofertami!",
    "vat.error_required": "Pole \"{field}\" nie może być puste przy zamówieniu biznesowym.",
    "vat.validation_message_format": "Pole \"{field}\" nie ma poprawnego formatu.",
    "vat.help_title": "Numer VAT",
    "vat.help_text": "Polski numer VAT musi składać się z 12 znaków, na przykład PL1234567890.",
    "label.download": "Pobierz",
    "error.download_pdf": "Coś poszło nie tak podczas pobierania pliku PDF. Spróbuj ponownie później.",
    "manage_address.title": "Moje adresy dostawy",
    "error.select": "Wybierz {field}",
    "manage_cards.title": "Moje metody płatności",
    "deal.quality_text_1": "Wybrane na podstawie ceny i jakości",
    "deal.quality_text_2": "",
    "deal.delivery_text_days": "Przewidywany czas dostawy: {days} dni roboczych",
    "deal.delivery_text": "",
    "maintenance.going_offline": "Za kilka minut nasza strona internetowa zostanie tymczasowo wyłączona z powodu zaplanowanych prac konserwacyjnych. Dokończ swoje zamówienie teraz, ponieważ nie będzie to możliwe przez pewien czas podczas konserwacji.",
    "maintenance.offline": "Nasza strona internetowa jest obecnie wyłączona z powodu zaplanowanych prac konserwacyjnych. Spróbuj ponownie za kilka minut – strona będzie dostępna najszybciej jak będzie to możliwe! W ramach małego upominku otrzymujesz od nas GMJ2QPZ5  na 20 zl rabatu na następny zakup.",
    "sustainability.carpark_title": "Bardziej ekologiczna flota pojazdów",
    "sustainability.carpark_text": "W ramach naszych ciągłych starań zmierzających do zmniejszenia śladu węglowego modernizujemy naszą flotę pojazdów firmowych. Naszym celem jest to, aby do 2026 roku nasza flota była w pełni elektryczna. Ta transformacja jest kluczowym krokiem w redukcji emisji i promowaniu czystszego, bardziej ekologicznego transportu.",
    "sustainability.energy_title": "Biuro zasilane energią słoneczną",
    "sustainability.energy_text": "Zrównoważony rozwój należy zacząć od domu, a dla nas jest to nasze biuro. Zainstalowaliśmy 30 paneli słonecznych, które produkują niemal połowę potrzebnej nam energii elektrycznej. Wykorzystując moc słońca, znacznie zmniejszamy naszą zależność od nieodnawialnych źródeł energii i obniżamy całkowitą emisję CO₂.",
    "sustainability.eco_packaging_title": "Opakowania przyjazne dla środowiska",
    "sustainability.eco_packaging_text": "Zdajemy sobie sprawę z wpływu odpadów opakowaniowych na środowisko. Dlatego wszystkie nasze opakowania wykonane są w 100% z plastiku, który nadaje się do recyklingu. Ta inicjatywa pomaga stworzyć bardziej zrównoważony cykl użytkowania i ponownego wykorzystania.",
    "sustainability.combined_packaging_title": "Konsolidacja wysyłek",
    "sustainability.combined_packaging_text": "Aby jeszcze bardziej zmniejszyć nasz wpływ na środowisko, zachęcamy klientów do łączenia zamówień. Umieszczając jak najwięcej zakupów w jednej przesyłce, możemy zmniejszyć liczbę dostaw, a to z kolei ogranicza emisje i ślad węglowy.",
    "sustainability.title": "Zrównoważony rozwój w Outspot",
    "sustainability.vision_title": "Nasza wizja",
    "sustainability.vision_text": "Outspot jest firmą świadomą swojego wpływu na środowisko. Codziennie sprawiamy radość tysiącom ludzi naszymi ofertami, jednocześnie wplatając odpowiedzialność ekologiczną i społeczną w każdy aspekt naszej działalności. Wierzymy, że podejmując dziś proaktywne kroki, możemy realnie wpłynąć na zdrowie naszej planety i dobrobyt przyszłych pokoleń.",
    "sustainability.action_title": "Dołącz do nas",
    "sustainability.action_text": "Zapraszamy Cię do przyłączenia się do naszej podróży w kierunku zrównoważonego rozwoju. Od wyboru produktów przyjaznych dla środowiska po udział w naszych programach recyklingowych – każde małe działanie ma znaczenie.",
    "sustainability.plant_text": "Podczas zakupów możesz wybrać zasadzenie drzewa, inwestując w to jedyne 4 zloti. Pomaga to nie tylko zrekompensować emisję CO₂, ale także przyczynia się do zalesiania i lepszej przyszłości lokalnych społeczności.",
    "sustainability.treeplanters": "Współpracujemy z Tree-Planters, organizacją zaangażowaną w inteligentne i zrównoważone zalesianie. Sadzi ona drzewa w starannie wybranych lokalizacjach w Malawi, przywracając rozwój przyrody i wspierając lokalną ludność, zapewniając jej dodatkowe dochody i zasoby żywności.",
    "sustainability.action_text_believe": "W Outspot wierzymy, że zrównoważony rozwój jest naszą wspólną odpowiedzialnością. Razem z klientami, pracownikami i partnerami dążymy do bardziej świadomej relacji z naszą planetą. Dołącz do nas i razem zmieniajmy świat na lepsze!",
    "sustainability.plant_title": "Posadź drzewo – Zrób różnicę",
    "sustainability.trees": "drzewo",
    "sustainability.tree": "drzewa",
    "deal.advice_price": "",
    "sustainability.checkbox_title": "Zasadź drzewo razem z Outspot",
    "sustainability.checkbox_detail_text": "Zapraszamy Cię do wspólnego sadzenia drzew z Outspot za  {price}"
  },
  "pt": {
    "route.home": "https://www.outspot.pt/pt/",
    "route.fallback_domain": "https://www2.outspot.pt",
    "language.code": "PT",
    "language.locale": "pt-PT",
    "language.name": "Portugal",
    "language.flag": "pt",
    "language.date_input_format": "d,m,y",
    "language.currency": "EUR",
    "language.region": "eu",
    "countries.delivery": "PRT",
    "states.delivery": "",
    "tracking.aw_merchant_id": "764659841",
    "tracking.aw_feed_country": "PT",
    "countries.BEL": "Bélgica",
    "countries.LUX": "Luxemburgo",
    "countries.NLD": "Países Baixos",
    "countries.FRA": "França",
    "countries.DEU": "Alemanha",
    "countries.AUT": "Áustria",
    "countries.ITA": "Itália",
    "countries.ESP": "Espanha",
    "countries.POL": "Polónia",
    "countries.GBR": "Reino Unido",
    "countries.IRL": "Irlanda",
    "countries.SWE": "Suécia",
    "countries.PRT": "Portugal",
    "countries.FIN": "Finlândia",
    "countries.USA": "",
    "states.AL": "",
    "states.AR": "",
    "states.AZ": "",
    "states.CA": "",
    "states.CO": "",
    "states.CT": "",
    "states.DE": "",
    "states.FL": "",
    "states.GA": "",
    "states.IA": "",
    "states.ID": "",
    "states.IL": "",
    "states.IN": "",
    "states.KS": "",
    "states.KY": "",
    "states.LA": "",
    "states.MA": "",
    "states.MD": "",
    "states.ME": "",
    "states.MI": "",
    "states.MN": "",
    "states.MO": "",
    "states.MS": "",
    "states.MT": "",
    "states.NC": "",
    "states.ND": "",
    "states.NE": "",
    "states.NH": "",
    "states.NJ": "",
    "states.NM": "",
    "states.NV": "",
    "states.NY": "",
    "states.OH": "",
    "states.OK": "",
    "states.OR": "",
    "states.PA": "",
    "states.RI": "",
    "states.SC": "",
    "states.SD": "",
    "states.TN": "",
    "states.TX": "",
    "states.UT": "",
    "states.VA": "",
    "states.VT": "",
    "states.WA": "",
    "states.WI": "",
    "states.WV": "",
    "states.WY": "",
    "trustpilot.businessunit_id": "641344aea9b77a66a8aa949c",
    "trustpilot.show": "TRUE",
    "mollie.profile_id": "pfl_Bm7N3odPWo",
    "mollie.locale": "pt_PT",
    "facebook.url": "",
    "instagram.url": "",
    "linkedin.url": "https://www.linkedin.com/company/outspot/mycompany/",
    "header.hello": "Bem-vindo",
    "header.hello_name": "Bom dia, <b>{firstName}</b>",
    "subscribe.title": "Registe-se gratuitamente e não perca uma única oferta!",
    "subscribe.text": "Descubra as melhores ofertas do Outspot. Registe-se gratuitamente e desfrute de descontos exclusivos e personalizados em produtos e experiências únicas.",
    "subscribe.agree": "Ao subscrever a nossa newsletter, o utilizador concorda com as <a href=\"{conditions}\" target=\"_blank\">condições gerais</a> e <a href=\"{privacy}\" target=\"_blank\">declaração de privacidade</a>.",
    "subscribe.sent": "Obrigado! Recebemos corretamente a sua inscrição. Receberá em breve uma mensagem eletrónica para confirmar a sua inscrição.",
    "footer.follow": "Siga-nos",
    "footer.payment_methods_logos": "mastercard,visa,amex,paypal,applepay,multibanco,mbway",
    "footer.copyright": "Outspot — Sint-Martens-Latem, Bélgica — Confinity NV",
    "home.new": "Últimas ofertas",
    "home.ending": "Estas ofertas estão prestes a expirar",
    "home.other": "Todas as ofertas",
    "countdown.prefix": "Ainda assim",
    "countdown.days": "dia|dias",
    "countdown.hours": "hora",
    "countdown.minutes": "min",
    "countdown.seconds": "sec",
    "countdown.days_compact": "d",
    "date.years": "Há {count} anos|Há {count} anos",
    "date.months": "Há {count} mês|Há {count} meses",
    "date.weeks": "{count} há uma semana|{count} há uma semana",
    "date.days": "Há {count} dia|Há {count} dias",
    "date.hours": "Há {count} horas|Há {count} horas",
    "date.minutes": "Há {count} minuto|Há {count} minutos",
    "date.seconds": "Há menos de 1 minuto",
    "countdown.suffix": "",
    "onboard.language_title": "Aproveite ofertas personalizadas com promoções atraentes",
    "onboard.language_text": "Escolha em qual país e idioma deseja fazer compras:",
    "onboard.notifications_title": "Fique por dentro de:",
    "onboard.notifications_usp_1": "As melhores e mais recentes ofertas",
    "onboard.notifications_usp_2": "Atualizações sobre seus pedidos",
    "onboard.notifications_usp_3": "Códigos de desconto e vales personalizados",
    "order.shipping_title": "Expedição",
    "order.shipping_text": "Devido ao aumento dos custos de transporte e ao impacto ambiental, somos obrigados a cobrar custos de transporte. No entanto, a Outspot continua a garantir-lhe preços muito vantajosos em que fica a ganhar na mesma. Para além disso, o custo de transporte não aumenta quando compra mais unidades. Se pertencer ao Outspot PLUS ou se comprar dentro de uma hora após a sua compra anterior, estes custos serão deduzidos no passo seguinte.",
    "order.thank_you": "Obrigado pela sua encomenda!",
    "order.overview": "Visão geral da sua encomenda",
    "order.thank_you_bank": "Obrigado pela sua compra. Receberá os detalhes da transferência na sua caixa de correio ou na caixa de spam. Transfira o montante o mais rapidamente possível e, em qualquer caso, no prazo de 7 dias. Receberá o voucher que encomendou por correio eletrónico assim que o pagamento for recebido e processado.",
    "order.thank_you_with_shipping": "A sua compra foi concluída. Enviaremos a sua encomenda para a morada indicada abaixo. Receberá a confirmação da sua compra na sua caixa de correio ou na caixa de spam. Desfrute da sua encomenda.",
    "order.thank_you_without_shipping": "A sua compra está concluída. Receberá a confirmação da sua compra na sua caixa de correio ou na caixa de spam. Desfrute da sua encomenda.",
    "order.code": "Número de encomenda: {code}",
    "order.shipping_address": "Endereço de entrega",
    "order.save_payment_info_title": "Guardar os dados bancários para as minhas próximas compras.",
    "order.save_payment_info_text": "Se escolher esta opção, não precisará de inserir sempre os seus dados. As suas informações serão armazenadas com segurança.",
    "order.shipped_to_service_point": "",
    "my_outspot_plus.title": "A minha Outspot PLUS",
    "my_outspot_plus.subtitle": "Nesta página, pode gerir a sua subscrição Outspot PLUS.",
    "my_outspot_plus.overview": "Visão geral da sua subscrição Outspot PLUS",
    "my_outspot_plus.cancel_are_you_sure": "Tem a certeza de que pretende cancelar a sua subscrição?",
    "my_outspot_plus.cancel_you_saved_prefix": "Já guardou",
    "my_outspot_plus.cancel_you_saved_suffix": "as taxas de envio e registo com a sua subscrição à Outspot PLUS.",
    "my_outspot_plus.no_membership": "Atualmente, não tem uma subscrição na Outspot PLUS.",
    "plus.title": "Outspot PLUS",
    "plus.popup_text": "Com a Outspot PLUS, beneficia de portes grátis em todos os artigos e não paga taxas de registo em todas as viagens. Após cada encomenda, receberá um e-mail que lhe dará a oportunidade de se tornar Outspot PLUS. No entanto, tem de ser rápido, pois esta promoção só é válida durante 7 dias. Não se esqueça de verificar o seu próximo e-mail de confirmação de compra.",
    "plus.name": "Outspot PLUS ",
    "plus.highlight_1": "Desfrute de uma encomenda sem complicações, sem despesas de envio e de registo. ",
    "plus.highlight_2": "A sua subscrição será renovada automaticamente por {renewPrice} de três em três meses. ",
    "plus.highlight_3": "É sempre possível cancelar.\r",
    "plus.question_1": "Quais são os benefícios da Outspot PLUS? ",
    "plus.answer_1": "Com a Outspot PLUS, beneficia de portes grátos em todos os artigos e não paga taxas de registo em todas as viagens.  ",
    "plus.question_2": "Como é que me torno Outspot PLUS?\r \r ",
    "plus.answer_2": "Após cada encomenda, receberá um e-mail que lhe dará a oportunidade de se tornar Outspot PLUS. No entanto, tem de ser rápido porque esta promoção só é válida durante 7 dias. Se decidir ativar a Outspot PLUS, os benefícios começarão imediatamente. Isto significa que, na sua próxima encomenda, pode usufruir imediatamente de portes grátis em todos os artigos e não terá de pagar uma taxa de registo para todas as suas viagens.  ",
    "plus.question_3": "Quanto custa a Outspot PLUS?  ",
    "plus.answer_3": "A Outspot PLUS custa apenas {price} para os primeiros 3 meses. Este é um pagamento único. Após este período, a sua subscrição será renovada automaticamente a cada 3 meses por {renewPrice}. Isto permite-lhe poupar significativamente nos custos de envio e de registo.  ",
    "plus.question_4": "Quando e como a minha subscrição à Outspot PLUS será renovada?  ",
    "plus.answer_4": "A Outspot PLUS é renovado automaticamente de três em três meses. Para o gerir, pode sempre ir a <a href=\"{myOutspotPlus}\">AS MINHAS COMPRAS </a>. O montante será automaticamente deduzido da conta escolhida a cada 3 meses.  ",
    "plus.question_5": "Como cancelo a minha Outspot PLUS? Receberei um reembolso?  ",
    "plus.answer_5": "Pode cancelar a sua subscrição Outspot PLUS em qualquer altura através de: <a href=\"{myOutspotPlus}\">AS MINHAS COMPRAS </a>. É importante notar que não será emitido qualquer reembolso para os meses em que a sua subscrição esteve ativa. No entanto, se decidir cancelar o Outspot PLUS, a próxima faturação programada não será cobrada.  ",
    "plus.thank_you": "A sua compra está concluída. A partir de agora, pode desfrutar de encomendas sem preocupações, sem custos de envio e manuseamento. Pode visualizar e gerir a sua subscrição através de <a href=\"{myOutspotPlus}\">Meu Outspot PLUS</a>. ",
    "my_coupons.title": "Os meus cupões",
    "my_coupons.subtitle": "Pode encontrar os seus cupões nesta página.",
    "my_coupons.no_coupons": "Atualmente, não tem vales.",
    "deal.sold": "Já comprei {sold} vezes",
    "deal.retour_label": "Devoluções em 30 dias por apenas 0,95 €",
    "deal.retour_title": "Revogação",
    "deal.retour_text": "Afinal não gosta do seu produto? Contacte o nosso serviço de assistência no prazo de 30 dias a partir da data de receção e devolva o seu produto, por qualquer motivo! Reembolsamos a sua compra e pode encomendar sem preocupações. Outra grande ideia, da Outspot, claro!",
    "deal.guarantee_label": "Garantia de reembolso de 6 meses",
    "deal.guarantee_title": "Único: garantia de reembolso de 100%.",
    "deal.guarantee_text": "Inacreditável, mas verdadeiro. Já não tem de hesitar em comprar uma experiência para os seus amigos/colegas/família ou para si próprio. Se depois mudar de ideias ou se o destinatário não ficar completamente satisfeito com o vale de oferta, não há problema.<br><br>No Outspot.pt, pode cancelar a sua compra até 6 meses após a encomenda! Você ou a pessoa que recebeu o voucher podem obter um reembolso total no prazo de 6 meses. Isto, claro, desde que o vale ainda não tenha sido reservado, utilizado ou expirado. Como garantia, isto é excecional e nunca antes visto. Mas é assim que o Outspot é.<br><br> Tenha em atenção que, para todas as ações em que a garantia de reembolso não é explicitamente mencionada, a garantia não se aplica. Isto deve-se ao facto de, em alguns casos, não podermos recuperar as entregas/tickets do nosso parceiro.",
    "deal.option_count": "{count} option|{count} options",
    "deal.campaign_number": "Número da campanha",
    "deal.your_details": "Os seus dados",
    "deal.payment_methods_title": "Método de pagamento",
    "deal.shipping_methods_title": "",
    "deal.privacy": "Ao indicar os seus detalhes, concorda com <a target=\"_blank\" href=\"{privacy}\">a nossa política de privacidade</a>.",
    "deal.payment_methods_subtitle": "Como gostaria de pagar a sua encomenda?",
    "deal.your_order": "A sua encomenda",
    "deal.from": "A partir de",
    "help.title": "Precisa de ajuda?",
    "help.subtitle": "Diga-nos como o podemos ajudar.",
    "help.subject": "A sua pergunta através do nosso site ou da nossa aplicação",
    "help.sent": "Recebemos a sua mensagem corretamente. Receberá uma resposta da nossa parte no prazo máximo de 4 dias úteis.",
    "login.title": "Iniciar sessão",
    "login.subtitle": "Bem-vindo de volta! Como pretende iniciar sessão?",
    "login.or": "ou",
    "login.usps_title": "Porque é que devo criar uma conta?",
    "login.usps_title_cta": "Facilite a sua vida e inicie sessão agora ou crie uma conta.  ",
    "login.usps_text": "Clique no botão \"Iniciar sessão\" no topo da página para iniciar sessão. Se ainda não tem uma conta, também pode registar-se na página de início de sessão. Ter uma conta Outspot só tem vantagens:",
    "login.usp_manage_orders": "Ver e seguir encomendas",
    "login.usp_remember_info": "Guardar os dados de contacto para mais tarde",
    "login.usp_returns": "Comunicar defeitos e pedir devoluções de produtos",
    "login.usp_store_payments": "Guardar métodos de pagamento e pagar mais rapidamente",
    "login.short_cta": "<a href=\"{loginLink}\">Faça login</a> ou continue abaixo como convidado.",
    "forgot.title": "Solicite uma nova palavra-passe.",
    "forgot.subtitle": "Introduza o seu endereço de correio eletrónico abaixo e enviar-lhe-emos uma ligação para redefinir a sua palavra-passe.",
    "forgot.sent": "Enviámos-lhe uma mensagem de correio eletrónico com uma ligação para redefinir a sua palavra-passe.",
    "password.title": "Alterar a palavra-passe",
    "password.subtitle": "Escolha uma palavra-passe nova e segura.",
    "password.updated": "A sua palavra-passe foi alterada. A partir de agora, pode iniciar sessão com a sua nova palavra-passe.",
    "register.title": "Registar-se",
    "register.subtitle": "Crie uma conta para facilitar ainda mais a encomenda e o acompanhamento das suas encomendas.",
    "register.personal_info": "Dados pessoais",
    "register.login_info": "Dados de acesso",
    "register.agree": "Concordo com as <a href=\"/enl/help/general-conditions</a> e <a href=\"/enl/help/privacy\" target=\"_blank\">declaração de privacidade</a>.",
    "register.sent": "Está quase a chegar lá. Enviámos-lhe um e-mail para ativar a sua conta.",
    "register.email_known": "O seu endereço de e-mail já é conhecido pela Outspot? <a href=\"{forgot}\">Clique aqui</a> para solicitar uma palavra-passe. ",
    "my_orders.title": "As minhas encomendas",
    "my_orders.subtitle": "Esta é uma visão geral de todas as encomendas que efetuou no Outspot.",
    "my_orders.title_detail": "Detalhes da sua encomenda.",
    "my_travels.title": "As minhas viagens",
    "my_info.title": "Os meus dados",
    "my_info.subtitle": "Pode editar aqui os seus dados pessoais.",
    "my_info.sent": "Os seus dados foram atualizados.",
    "notifications.title": "Newsletter e notificações",
    "notifications.subscription_mail": "Com que frequência gostaria de receber nossa newsletter?",
    "notifications.subscription_notification": "Com que frequência gostaria de receber notificações?",
    "notifications.frequency_0": "Nunca",
    "notifications.frequency_4": "Uma vez por semana",
    "notifications.frequency_56": "Duas vezes por dia",
    "notifications.sent": "Suas preferências foram atualizadas.",
    "combined_purchase_banner.text": "Não pague taxas de envio ou de registo para as suas encomendas subsequentes durante:",
    "plus_banner.text_welcome": "{firstName}, bem-vindo à Outspot PLUS.",
    "plus_banner.text_welcome_anonimous": "Bem-vindo à Outspot PLUS.",
    "plus_banner.text_savings_prefix": "{firstName}, já guardou",
    "plus_banner.text_savings_prefix_anonimous": "Já guardou",
    "plus_banner.text_savings_suffix": "às taxas de envio e de registo através da Outspot PLUS.",
    "cookie_banner.title": "Cookies",
    "cookie_banner.text": "Este site utiliza cookies. Cookies estritamente necessárias garantem que o site funcione corretamente e não podem ser recusadas. Cookies funcionais, analíticos e de publicidade são usados, respectivamente, para fins funcionais, estatísticos e de marketing. Eles são baixados apenas se você consentir. Leia mais na nossa <a href=\"{cookies}\" target=\"_blank\">política de cookies</a>.",
    "cookie_banner.decline": "Rejeitar cookies opcionais",
    "cookie_banner.accept_all": "Aceitar todos os cookies",
    "cookie_banner.accept_selected": "Aceitar selecionados",
    "cookie_banner.settings": "Configurações",
    "cookie_banner.functional_cookies_title": "Cookies estritamente necessários",
    "cookie_banner.functional_cookies_text": "Cookies necessárias para a funcionalidade básica do site.",
    "cookie_banner.analytical_cookies_title": "Cookies analíticos e funcionais",
    "cookie_banner.analytical_cookies_text": "Os cookies permitem que o site forneça recursos adicionais e configurações pessoais, além de visitas e tráfego.",
    "cookie_banner.personal_cookies_title": "Cookies de publicidade",
    "cookie_banner.personal_cookies_text": "Cookies definidos por anunciantes para construir um perfil de seus interesses sem armazenar informações pessoais diretas.",
    "review.title": "Falar sobre o assunto e ganhar",
    "review.subtitle": "Publique a sua opinião sobre a sua compra abaixo para ter uma oportunidade imediata de ganhar um vale de 250 euros (sorteamos esse vale a cada trimestre).",
    "review.agree": "Aceito que a minha opinião possa ser publicada no site",
    "review.sent": "Obrigado pelo seu comentário. Tem agora a oportunidade de ganhar um vale de 250 euros.",
    "review.title_thank_you": "Obrigado. Agradecemos o seu feedback.",
    "review.subtitle_thank_you": "Graças ao seu feedback, podemos tornar a Outspot ainda melhor. Deixe abaixo a sua avaliação da sua compra e tenha a oportunidade de ganhar um vale de 250 € (todos os trimestres sorteamos um vale destes).",
    "confirm.title": "A sua inscrição está confirmada",
    "confirm.subtitle": "A sua subscrição do boletim informativo fica assim confirmada. Obrigado! Para o servir ainda melhor, oferecemos-lhe a oportunidade de partilhar connosco os seguintes dados sem qualquer obrigação.",
    "popup_widget.title": "As melhores oportunidades, testadas para si.",
    "popup_widget.usp_1": "Recebe as nossas melhores ofertas diariamente.",
    "popup_widget.usp_2": "Selecionado e testado para o melhor preço e qualidade.",
    "popup_widget.usp_3": "Descontos substanciais de até -70%.",
    "popup_widget.footer_1": "Subscreva a nossa newsletter gratuitamente agora e poupe de imediato.",
    "popup_widget.footer_2": "Pode anular a subscrição novamente em qualquer altura.",
    "promo.title": "As nossas principais promoções",
    "promo.countdown": "2024-12-31 23:59:59",
    "promo.empty": "Atualmente, não temos promoções de topo. Aceda à nossa página inicial para ver as nossas últimas ofertas.",
    "stars.info_title": "Avaliação do produto",
    "stars.info_text": "Esta pontuação é uma média das nossas próprias avaliações recolhidas e das de outras partes.",
    "stars.counter": "1 avaliação | {count} avaliações",
    "label.accept_selected": "Aceitar opções selecionadas",
    "label.edit": "Alterar",
    "label.edit_address": "Alterar o endereço",
    "label.address": "Endereço",
    "label.accept_all": "Aceitar tudo",
    "label.account": "Conta",
    "label.help": "Ajuda",
    "label.my_orders": "As minhas encomendas",
    "label.my_travels": "As minhas viagens",
    "label.my_coupons": "Os meus cupões",
    "label.my_info": "Os meus dados",
    "label.change_password": "Alterar a palavra-passe",
    "label.notifications": "Newsletter e notificações",
    "label.logout": "Terminar sessão",
    "label.login": "Iniciar sessão",
    "label.login_have_account": "Já tem uma conta? Iniciar sessão",
    "label.login_with_facebook": "Iniciar sessão com o Facebook",
    "label.login_with_google": "Iniciar sessão com o Google",
    "label.login_with_apple": "Iniciar sessão com a Apple",
    "label.password": "Palavra-passe",
    "label.new_password": "Nova palavra-passe",
    "label.repeat_password": "Repetir a palavra-passe",
    "label.repeat_new_password": "Repetir a nova palavra-passe",
    "label.register_no_account": "Ainda não tem conta? Registe-se agora",
    "label.register": "Registar",
    "label.forgot_password": "Esqueceu-se da sua palavra-passe?",
    "label.general_conditions": "Termos e condições gerais",
    "label.jobs": "Emprego no Outspot",
    "label.faq": "Perguntas mais frequentes",
    "label.returns": "Regresso",
    "label.travel_conditions": "Condições específicas de viagem",
    "label.privacy_policy": "Declaração de privacidade",
    "label.cookie_policy": "Declaração de cookies",
    "label.subscribe": "Inscrever-me",
    "label.all_deals": "Todas as ofertas",
    "label.other_deals": "Outras ofertas",
    "label.discount": "Desconto",
    "label.close": "Fechar",
    "label.order_now": "Encomendar agora",
    "label.add": "Adicionar",
    "label.book": "Reservar agora",
    "label.upselling_next_step": "Não se esqueça também do seguinte",
    "label.upselling": "Não se esqueça também do seguinte",
    "label.also_available": "Também disponível.",
    "label.reviews": "O que dizem os nossos clientes",
    "label.automatically_translated": "Traduzido automaticamente",
    "label.show_all_reviews": "Ver todas as avaliações",
    "label.back": "Voltar",
    "label.make_your_choice": "Faça a sua escolha",
    "label.sold_out": "Esgotado",
    "label.order_more": "Encomendar mais",
    "label.subtotal": "Subtotal",
    "label.total_shipping": "Custos de envio",
    "label.total_file": "Custos do ficheiro",
    "label.total_payable": "Total",
    "label.no_options_selected": "A sua encomenda está atualmente vazia. Clique em «Adicionar» para continuar.",
    "label.no_options_selected_extra": "Só pode selecionar esta opção ao comprar o produto.",
    "label.total_saved_prefix": "(Salva-se ",
    "label.total_saved_suffix": ")",
    "label.continue": "Mais",
    "label.usp_quality": "Seleção por preço e qualidade",
    "label.usp_discounts": "Descontos substanciais até -70%",
    "label.usp_payment_methods": "Pagar de forma segura e fácil com",
    "label.person": "Pessoa",
    "label.free": "Grátis",
    "label.first_name": "Nome próprio",
    "label.last_name": "Apelido",
    "label.birthdate": "Data de nascimento",
    "label.birthdate_why": "",
    "label.birthdate_why_text": "",
    "label.email": "Endereço de correio eletrónico",
    "label.phone": "Número de telefone",
    "label.address_finder": "Localizador de endereços",
    "label.address_finder_help": "Digite o seu endereço de entrega completo",
    "label.select": "Selecionar",
    "label.street": "Rua",
    "label.building": "",
    "label.number": "Não/autocarro",
    "label.zip": "Código-postal",
    "label.city": "Município",
    "label.state": "",
    "label.country": "País",
    "label.finish_order": "Fazer pedido & pagar",
    "label.finish_order_help": "Clique no botão «Fazer pedido & pagar» para concluir o seu pagamento.",
    "label.enter_address": "Introduzir o meu endereço manualmente",
    "label.add_address": "Adicionar uma morada",
    "label.add_payment_method": "Adicionar um método de pagamento",
    "label.year": "YYYY",
    "label.month": "MM",
    "label.day": "DD",
    "label.business_order": "Ordem de fabrico",
    "label.company_name": "Nome da empresa",
    "label.tax_id": "Número de IVA",
    "label.invoice_reason": "Motivo",
    "label.invoice_reasons": "Presentes de empresa, Formação de equipas, Incentivos, Seminário, Outros",
    "label.delete": "Remover",
    "label.i_have_coupons": "Tenho cupões",
    "label.email_outspot_plus": "Já é um cliente Outspot PLUS? Então, introduza o seu endereço de correio eletrónico.",
    "label.please_wait": "Seja paciente...",
    "label.please_wait_2": "Só um pouco mais de paciência...",
    "label.please_wait_3": "Lamentamos por estar a demorar um pouco mais do que o previsto...",
    "label.please_wait_4": "Só mais um pouco, estamos quase lá...",
    "label.please_wait_5": "Pedimos desculpa pelo incómodo e agradecemos a sua paciência. Estamos quase lá",
    "label.continue_shopping": "Compras adicionais",
    "label.my_code": "O meu código",
    "label.validate": "Validar",
    "label.total_coupons": "Total de cupões",
    "label.coupons": "Cupões",
    "label.total_payable_with_coupons_prefix": "(Ainda por pagar ",
    "label.total_payable_with_coupons_suffix": ")",
    "label.rest_coupon_info": "O montante a pagar é inferior aos cupões introduzidos. Receberá um novo vale com o valor restante.",
    "label.contact_extra_info": "Número da encomenda, número do vale ou comunicação estruturada",
    "label.contact": "Contactar-nos",
    "label.message": "Mensagem",
    "label.send": "Enviar",
    "label.save": "Guardar",
    "label.my_outspot_plus": "O meu Outspot PLUS",
    "label.order_date_prefix": "Encomendado em",
    "label.order_date_suffix": "",
    "label.total_paid_with": "Total pago com {método}",
    "label.track_order": "Acompanhe a sua encomenda com",
    "label.return_order": "Devolver e/ou reportar defeito",
    "label.download_invoice": "Transferir fatura",
    "label.ask_question": "Fazer uma pergunta",
    "label.happy_hour_discount": "Happy Hour de beneficência",
    "label.outspot_plus_discount": "Vantagem do Outspot PLUS",
    "label.cancel_membership": "Cancelar subscrição",
    "label.membership_start_date": "Data de início da subscrição",
    "label.membership_price": "Preço para 3 meses",
    "label.membership_next_payment": "Próximo pagamento",
    "label.membership_end_date": "Data de fim da filiação",
    "label.total_savings": "Total de custos de envio e manuseamento poupados",
    "label.confirm_cancel_membership": "Confirmar a anulação",
    "label.continue_membership": "Não cancelar",
    "label.valid_till_prefix": "Válido até",
    "label.valid_till_suffix": "",
    "label.card_payment": "Pagamento com cartão",
    "label.place_review": "Classificação do local",
    "label.name": "Nome",
    "label.name_help": "apresentamo-las na avaliação",
    "label.review": "Revisão",
    "label.self_service": "",
    "label.update_cookie_preferences": "Alterar as minhas preferências de cookies",
    "label.more_info": "Mais informações",
    "label.review_source": "Via {source}",
    "label.amount": "Montante",
    "label.iban": "IBAN",
    "label.bic": "BIC",
    "label.beneficiary": "Beneficiário",
    "label.structured_communication": "Mensagem estruturada",
    "label.later": "Mais tarde",
    "label.enable_notifications": "Sim, quero receber essas notificações",
    "label.card_number": "Número do cartão",
    "label.card_expiry": "Prazo de validade",
    "label.card_cvc": "CVV",
    "label.cvc_info": "",
    "label.sales_tax": "",
    "label.calculating_sales_tax": "",
    "label.sales_tax_will_be_calculated": "",
    "label.ship_to_selected_address": "",
    "label.ship_to_service_point": "",
    "label.change_service_point": "",
    "label.choose_rooms": "",
    "label.room": "",
    "label.room_occupancy": "",
    "label.add_another_room": "",
    "label.number_of_adults": "",
    "label.number_of_adults_title": "",
    "label.number_of_children": "",
    "label.number_of_children_title": "",
    "label.choose_arrival_and_departure_date": "",
    "label.arrival_date": "",
    "label.select_arrival_date": "",
    "label.departure_date": "",
    "label.select_departure_date": "",
    "label.rate_type_pc": "",
    "label.rate_type_pcpd": "",
    "label.rate_type_pcpn": "",
    "label.rate_type_pp": "",
    "label.rate_type_pppd": "",
    "label.rate_type_pppn": "",
    "label.rate_type_pp_": "",
    "label.rate_type_pa": "",
    "label.rate_type_papd": "",
    "label.rate_type_papn": "",
    "label.rate_type_pa_": "",
    "label.choose_supplements": "",
    "label.opengds_invalid": "",
    "error.unknown": "Erro desconhecido, tente novamente mais tarde.",
    "error.required": "{field} não pode estar vazio.",
    "error.required_email": "{field} não pode estar vazio e deve ser um endereço de correio eletrónico válido.",
    "error.required_password": "\r {field} deve ter pelo menos 8 caracteres e conter pelo menos uma letra maiúscula, uma minúscula e um número.",
    "error.required_repeat_password": "{field} tem de ser igual à palavra-passe especificada no campo {field2}.",
    "error.payment": "Infelizmente, o seu pagamento falhou. Isto pode acontecer se houver um problema com os dados de pagamento, se o pagamento tiver sido cancelado, se o limite de pagamento tiver sido atingido ou por outros motivos. Pense em contactar o seu banco ou tente outro cartão ou método de pagamento.",
    "error.payment_creditcard": "Lamentamos mas não foi possível realizar o seu pagamento. Verifique os dados abaixo e tente novamente.",
    "error.incomplete_order": "Só podemos validar este vale depois de ter preenchido todos os campos acima.",
    "error.coupon_already_added": "Já adicionou este voucher.",
    "error.invalid_coupon": "Este vale não é válido.",
    "error.coupon_not_cumulative": "Não é possível utilizar este vale em conjunto com outros vales.",
    "error.other_coupon_not_cumulative": "O voucher que já adicionou não pode ser utilizado juntamente com este voucher.",
    "error.invalid_username_or_password": "O endereço de correio eletrónico e/ou a palavra-passe fornecidos são inválidos.",
    "error.test_order": "Esta é uma encomenda de teste. Normalmente, o cliente iria agora para a página de pagamento.",
    "error.required_agree": "Tem de concordar com os nossos termos e condições e política de privacidade.",
    "error.required_review_agree": "O utilizador deve aceitar que a sua opinião seja publicada no nosso site.",
    "error.register": "Já existe uma conta com este endereço de correio eletrónico. Clique em \"Continuar\" para iniciar sessão ou utilizar outro endereço de correio eletrónico.",
    "error.email_in_use": "Já existe uma conta com este endereço de correio eletrónico. Utilize um endereço de correio eletrónico diferente.",
    "error.already_reviewed": "Já efetuou uma avaliação para esta encomenda.",
    "error.zip_blocked": "Não é possível enviar para o código postal especificado.",
    "error.details": "Parece que um ou mais produtos que está a tentar encomendar já não estão em stock. Volte ao passo anterior, actualize a sua encomenda e tente novamente.",
    "error.incomplete_cvc": "CVV é muito curto.",
    "error.incomplete_date": "A data de validade não pode estar vazia.",
    "error.date_in_past": "O cartão expirou.",
    "error.invalid_expiration_year": "O cartão expirou.",
    "error.incomplete_card_number": "O número do cartão é muito curto.",
    "error.incorrect_card_number": "O número do cartão é inválido.",
    "unsubscription.your_mail_address": "O seu endereço de correio eletrónico: ",
    "unsubscription.sad_message": "A sua inscrição foi anulada.",
    "unsubscription.have_not_participated": "Nunca te inscreveste?",
    "unsubscription.sad_message_2": "Lamentamos que nos tenha deixado, mas é claro que é sempre bem-vindo.",
    "unsubscription.resubscribe_question": "Mudou de ideias? <span class='unsub-important-message'>Clique em baixo para voltar a subscrever a nossa newsletter:</span>",
    "unsubscription.form_message": "Porque está a deixar a família Outspot? A sua resposta pode ajudar-nos a melhorar os nossos serviços.",
    "unsubscription.report_not_participated": " Denunciar abuso",
    "unsubscription.coreg_date_no_screenshot": "Participou numa promoção a {date} (<a href=\"{coreg_site}\">{coreg_site}</a>) e, portanto, deu o seu consentimento para receber a nossa newsletter com as melhores ofertas a preços excecionais.",
    "unsubscription.coreg_date_no_coreg_data": "Participou na promoção abaixo a {date}  e deu o seu consentimento para receber a nossa newsletter com as melhores ofertas a preços excecionais.",
    "unsubscription.coreg_date_screenshot": "Participou na promoção abaixo a {date}  e deu o seu consentimento para receber a nossa newsletter com as melhores ofertas a preços excecionais.",
    "unsubscription.coreg_no_date_no_screenshot": "Participou na promoção abaixo e deu o seu consentimento para receber a nossa newsletter com as melhores ofertas a preços excecionais.",
    "unsubscription.coreg_no_date_screenshot": "Participou na promoção abaixo e deu o seu consentimento para receber a nossa newsletter com as melhores ofertas a preços excecionais.",
    "unsubscription.main_title": "Cancelar subscrição da newsletter",
    "unsubscription.report_title": "Comunicar um abuso",
    "unsubscription.report_sent_message": "Obrigado, a sua notificação foi recebida.",
    "claim_coupon.welcome_message": "Só para si",
    "claim_coupon.info_line_message": "Insira o código pessoal acima no momento do pagamento.",
    "claim_coupon.info_line_message_bis": "Este voucher também foi enviado para si por e-mail.",
    "claim_coupon.main_title": "O seu cupão",
    "about.title": "Sobre nós",
    "about.intro": "Bem-vindo ao Outspot, o seu destino número um para ofertas irresistíveis! Desde a sua fundação em 2008, o Outspot tornou-se o principal site europeu de negócios flash. O Outspot oferece descontos diários em produtos de qualidade e viagens, o que permite que você, como cliente, economize enormemente. Graças ao nosso armazém na Europa, entregamos os nossos pacotes muito rapidamente possivelmente em sua casa.\"",
    "about.mission_title": "Missão",
    "about.mission_text": "No Outspot acreditamos em torná-lo acessível ofertas excepcionais para todos. O nosso objetivo é oferecer aos nossos clientes o melhor valor possível através de cuidadosas ofertas selecionadas a preços incomparáveis.",
    "about.history_title": "História",
    "about.history_text": "A aventura Outspot começou na Bélgica, onde a sede ainda está localizada. Com uma equipa dedicada de 25 funcionários espalhados por toda a Europa, trabalhamos todos os dias para encontrar e... oferecer as melhores ofertas. Estamos agora ativos em 13 países e temos 100 000 visitantes satisfeitos todos os dias.",
    "about.quality_title": "A nossa oferta foi selecionada com base no preço e na qualidade",
    "about.quality_text": "No Outspot, acreditamos que merece o melhor dos dois mundos: qualidade superior a um preço acessível. É por isso que vamos todos os dias vez após vez procurar as ofertas mais atraentes para si. Garantimos que trabalhamos em estreita colaboração com os nossos fornecedores, negociando os melhores preços sem fazer concessões à qualidade. <br /><br />  Cada produto e viagem que oferecemos são cuidadosamente elaborados, selecionados com base numa avaliação rigorosa de preço e qualidade. Desta forma, pode ter a certeza de que sempre obterá o melhor valor connosco receba pelo seu dinheiro. No Outspot, tudo gira em torno dos seus benefícios e benefícios satisfação! Abaixo encontra uma foto do som da smartbell sendo testada com um decibelímetro.",
    "about.tested_title": "Todas as ofertas foram extensivamente testadas pelos nossos colegas Outspot",
    "about.tested_text": "No Outspot, acreditamos em entregar apenas o melhor. Antes de uma oferta aparecer no nosso site, ela é testada ao detalhe pelos nossos próprios colegas Outspot. Eles não se acomodam com testes superficiais: os produtos são completamente desmontados e cada componente é cuidadosamente avaliado ao nível de resistência, nível de ruído, qualidade do material e muito mais.<br /><br />  Os nossos colegas também visitam pessoalmente muitos hotéis para... para verificar a qualidade das acomodações. É assim que garantimos que tudo cumpre os nossos altos padrões. Quer seja um produto, quer uma viagem, pode ter a certeza de que no Outspot sempre faz a sua compra com confiança.<br /><br />  Na foto abaixo, pode ver como os colegas produzem produtos visualizam e testar ao detalhe: por exemplo, verificação da qualidade do tecido.",
    "about.guarantee_title": "",
    "about.guarantee_text": "",
    "about.returns_title": "Política de devolução de 30 dias para a maioria dos produtos",
    "about.returns_text": "Entendemos que às vezes pode demorar um pouco para decidir se deseja manter um produto. Por isso, oferecemos uma política de devolução de 30 dias na maioria dos nossos produtos. É importante que o produto permaneça sem uso e não aberto para ser elegível para devolução. Isso lhe dá a oportunidade de reconsiderar sua compra no seu próprio ritmo, enquanto garantimos um processo de devolução tranquilo. Assim, você pode decidir com tranquilidade, desde que o produto permaneça em seu estado original.",
    "about.support_title": "O atendimento ao cliente irá ajudá-lo no seu idioma",
    "about.support_text": "No Outspot, a satisfação do cliente está em primeiro lugar e fazemos tudo o que podemos para garantir que tem uma excelente experiência. Se tiver alguma dúvida ou precisar de ajuda com o seu pedido, o nosso atendimento ao cliente está sempre disponível para si. Os nossos funcionários que falam holandês estão disponíveis para ajudá-lo rapidamente, e dar-lhe suporte amigável e eficiente. Esforçamo-nos para ajudá-lo de uma forma pessoal e compreensível, para que possa desfrutar da sua compra sem preocupações. A sua satisfação é a nossa prioridade, e estamos aqui para garantir que aproveita ao máximo e pode beneficiar do nosso serviço.",
    "about.socials_title": "Fique conectado",
    "about.socials_text": "Mantenha-se atualizado com as nossas últimas ofertas e exclusividades ao subscrever a nossa newsletter. Siga-nos nas redes sociais e partilhe as suas experiências com o Outspot connosco!<br /><br />  Tem perguntas ou comentários? O nosso helpdesk está pronto para ajudar. Contacte-nos em <a href=\"{help}\">nossa página de contato</a>.<br /><br />  Obrigado pela sua confiança no Outspot. Estamos ansiosos para surpreendê-lo continuamente com as nossas fantásticas ofertas!",
    "vat.error_required": "O campo \"{field}\" não pode estar vazio em um pedido comercial.",
    "vat.validation_message_format": "O campo \"{field}\" não está no formato correto.",
    "vat.help_title": "Número de IVA",
    "vat.help_text": "O número de IVA em Portugal deve ter 11 caracteres, por exemplo PT123456789.",
    "label.download": "Descarregar",
    "error.download_pdf": "Algo correu mal durante a transferência do PDF. Tente novamente mais tarde. ",
    "manage_address.title": "As minhas moradas de entrega ",
    "error.select": "Selecionar um {field}",
    "manage_cards.title": "Os meus métodos de pagamento",
    "deal.quality_text_1": "Selecionado no preço e na qualidade",
    "deal.quality_text_2": "",
    "deal.delivery_text_days": "Tempo de entrega: {days} dias úteis",
    "deal.delivery_text": "",
    "maintenance.going_offline": "Dentro de alguns minutos, a nossa página web ficará temporariamente offline para uma manutenção programada. Conclua já a sua encomenda, uma vez que não será possível efetuar pedidos por algum tempo durante a manutenção.",
    "maintenance.offline": "A nossa página web está atualmente offline para manutenção programada. Tente novamente dentro de alguns minutos - ficaremos novamente online o mais rapidamente possível! Como um pequeno gesto, oferecemos-lhe GMJ2QPZ3  para um desconto de 5 € na sua próxima compra.",
    "sustainability.carpark_title": "Tornar a nossa frota de veículos mais ecológica",
    "sustainability.carpark_text": "Nos nossos esforços contínuos para reduzir a pegada ecológica, estamos a transformar a nossa frota de veículos empresariais. Até 2026, ambicionamos ter uma frota totalmente elétrica. Esta transição é um passo crucial para reduzir as emissões e promover transportes mais limpos e ecológicos.",
    "sustainability.energy_title": "Escritório alimentado a energia solar",
    "sustainability.energy_text": "A sustentabilidade começa em casa, e para nós isso significa o nosso escritório. Instalámos 30 painéis solares, que produzem quase metade da eletricidade necessária para as nossas atividades. Ao aproveitar a energia solar, reduzimos significativamente a nossa dependência de fontes de energia não renováveis e diminuímos as nossas emissões totais de CO₂.",
    "sustainability.eco_packaging_title": "Embalagens ecológicas",
    "sustainability.eco_packaging_text": "Estamos conscientes do impacto dos resíduos de embalagens no ambiente. Por isso, todas as nossas embalagens são feitas de plástico 100% reciclável. Esta iniciativa ajuda a criar um ciclo mais sustentável de uso e reutilização.",
    "sustainability.combined_packaging_title": "Expedição consolidada",
    "sustainability.combined_packaging_text": "Para reduzir ainda mais o nosso impacto ambiental, incentivamos os clientes a consolidar as suas encomendas. Ao combinar o máximo de compras possível num único envio, podemos reduzir o número de entregas, o que por sua vez reduz as emissões e a pegada ecológica.",
    "sustainability.title": "Sustentabilidade no Outspot",
    "sustainability.vision_title": "A nossa visão",
    "sustainability.vision_text": "O Outspot está consciente do seu impacto no meio ambiente. Diariamente, fazemos milhares de pessoas felizes com a nossa oferta, enquanto a responsabilidade ecológica e social está entrelaçada em cada aspeto da nossa atividade. Acreditamos que, ao tomar medidas proativas hoje, podemos ter um impacto efetivo na saúde do nosso planeta e no bem-estar das gerações futuras.",
    "sustainability.action_title": "Junte-se a nós",
    "sustainability.action_text": "Convidamo-lo a embarcar na nossa jornada de sustentabilidade. Desde a escolha de produtos ecológicos até à participação nos nossos programas de reciclagem, cada pequena ação faz a diferença.",
    "sustainability.plant_text": "Na sua compra, pode optar por plantar uma árvore por apenas mais 1€. Isto não só ajuda a compensar as emissões de CO₂, como também contribui para a reflorestação e um futuro melhor para as comunidades locais.",
    "sustainability.treeplanters": "Colaboramos com a Tree-Planters, uma organização empenhada numa reflorestação inteligente e sustentável. Plantam árvores em locais cuidadosamente selecionados no Malawi, restaurando a natureza e apoiando as populações locais com rendimentos adicionais e recursos alimentares.",
    "sustainability.action_text_believe": "No Outspot, acreditamos que a sustentabilidade é uma responsabilidade partilhada. Juntamente com os nossos clientes, colaboradores e parceiros, almejamos uma relação mais consciente com o nosso planeta. Junte-se a nós e faça a diferença connosco!",
    "sustainability.plant_title": "Plante uma árvore – Faça a diferença",
    "sustainability.trees": "árvore ",
    "sustainability.tree": "árvores",
    "deal.advice_price": "",
    "sustainability.checkbox_title": "Plante junto com a Outspot",
    "sustainability.checkbox_detail_text": "Convidamos você a plantar árvores junto com a Outspot por {price}."
  },
  "sv": {
    "route.home": "https://www.outspot.se/sv/",
    "route.fallback_domain": "https://www2.outspot.se",
    "language.code": "SV",
    "language.locale": "sv-SE",
    "language.name": "Sverige",
    "language.flag": "se",
    "language.date_input_format": "d,m,y",
    "language.currency": "SEK",
    "language.region": "eu",
    "countries.delivery": "SWE",
    "states.delivery": "",
    "tracking.aw_merchant_id": "756646525",
    "tracking.aw_feed_country": "SE",
    "countries.BEL": "Belgien",
    "countries.LUX": "Luxemburg",
    "countries.NLD": "Nederländerna",
    "countries.FRA": "Frankrike",
    "countries.DEU": "Tyskland",
    "countries.AUT": "Österrike",
    "countries.ITA": "Italien",
    "countries.ESP": "Spanien",
    "countries.POL": "Polen",
    "countries.GBR": "Förenade kungariket",
    "countries.IRL": "Irland",
    "countries.SWE": "Sverige",
    "countries.PRT": "Portugal",
    "countries.FIN": "Finland",
    "countries.USA": "",
    "states.AL": "",
    "states.AR": "",
    "states.AZ": "",
    "states.CA": "",
    "states.CO": "",
    "states.CT": "",
    "states.DE": "",
    "states.FL": "",
    "states.GA": "",
    "states.IA": "",
    "states.ID": "",
    "states.IL": "",
    "states.IN": "",
    "states.KS": "",
    "states.KY": "",
    "states.LA": "",
    "states.MA": "",
    "states.MD": "",
    "states.ME": "",
    "states.MI": "",
    "states.MN": "",
    "states.MO": "",
    "states.MS": "",
    "states.MT": "",
    "states.NC": "",
    "states.ND": "",
    "states.NE": "",
    "states.NH": "",
    "states.NJ": "",
    "states.NM": "",
    "states.NV": "",
    "states.NY": "",
    "states.OH": "",
    "states.OK": "",
    "states.OR": "",
    "states.PA": "",
    "states.RI": "",
    "states.SC": "",
    "states.SD": "",
    "states.TN": "",
    "states.TX": "",
    "states.UT": "",
    "states.VA": "",
    "states.VT": "",
    "states.WA": "",
    "states.WI": "",
    "states.WV": "",
    "states.WY": "",
    "trustpilot.businessunit_id": "641344b15a153ec4fec12aab",
    "trustpilot.show": "FALSE",
    "mollie.profile_id": "pfl_CvDdWVtu7R",
    "mollie.locale": "sv_SE",
    "facebook.url": "",
    "instagram.url": "",
    "linkedin.url": "https://www.linkedin.com/company/outspot/mycompany/",
    "header.hello": "Välkommen",
    "header.hello_name": "Hej <b>{firstName}</b>",
    "subscribe.title": "Registrera dig kostnadsfritt och missa inte ett enda erbjudande!",
    "subscribe.text": "Upptäck de bästa erbjudandena från Outspot. Registrera dig kostnadsfritt och få exklusiva och personliga rabatter på unika produkter och upplevelser.",
    "subscribe.agree": "Genom att prenumerera på vårt nyhetsbrev godkänner du <a href=\"{conditions}\" target=\"_blank\">de allmänna villkoren</a> och <a href=\"{privacy}\" target=\"_blank\">integritetspolicyn</a>.",
    "subscribe.sent": "Tack för din registrering. Du kommer inom kort att få ett e-postmeddelande så att du kan bekräfta din registrering.",
    "footer.follow": "Följ oss",
    "footer.payment_methods_logos": "mastercard,visa,amex,trustly,paypal,applepay,swish",
    "footer.copyright": "Outspot — Sint-Martens-Latem, Belgien — Confinity NV",
    "home.new": "De senaste erbjudandena",
    "home.ending": "Dessa erbjudanden är på väg att löpa ut",
    "home.other": "Alla erbjudanden",
    "countdown.prefix": "Fortfarande",
    "countdown.days": "dag|dagar",
    "countdown.hours": "timmar",
    "countdown.minutes": "min",
    "countdown.seconds": "sek",
    "countdown.days_compact": "d",
    "date.years": "{count} år sedan|{count} år sedan",
    "date.months": "{count} månad sedan|{count} månader sedan",
    "date.weeks": "{count} vecka sedan|{count} veckor sedan",
    "date.days": "{count} dag sedan|{count} dagar sedan",
    "date.hours": "{count} timme sedan|{count} timmar sedan",
    "date.minutes": "{count} minut sedan|{count} minuter sedan",
    "date.seconds": "Mindre än 1 minut sedan",
    "countdown.suffix": "",
    "onboard.language_title": "Njut av personliga erbjudanden med attraktiva kampanjer",
    "onboard.language_text": "Välj i vilket land och på vilket språk du vill handla: ",
    "onboard.notifications_title": "Håll dig uppdaterad om:",
    "onboard.notifications_usp_1": "De bästa och senaste erbjudandena",
    "onboard.notifications_usp_2": "Uppdateringar om dina beställningar",
    "onboard.notifications_usp_3": "Personliga rabattkoder och kuponger",
    "order.shipping_title": "Frakt",
    "order.shipping_text": "På grund av stigande transportkostnader och miljöpåverkan är vi tvungna att ta ut transportkostnader. Outspot garanterar dig dock fortfarande bra dealpriser som du vinner på ändå. Dessutom ökar inte din transportkostnad när du köper mer av någonting. Om du är Outspot PLUS-medlem eller om du köper inom en timme efter ditt tidigare köp, dras dessa kostnader av i nästa steg.",
    "order.thank_you": "Tack för din beställning!",
    "order.overview": "Översikt över din beställning",
    "order.thank_you_bank": "Tack för ditt köp. Du kommer att få betalningsuppgifterna i din brevlåda eller i din skräppost. Överför beloppet så snart som möjligt inom 7 dagar. Du kommer att få den voucher du beställt via e-post så snart vi har fått din betalning och behandlat den.",
    "order.thank_you_with_shipping": "Ditt köp har slutförts. Vi kommer att skicka din beställning till adressen nedan. Du kommer att få din köpbekräftelse i din brevlåda eller i skräppost. Mycket nöje med din beställning.",
    "order.thank_you_without_shipping": "Ditt köp har slutförts. Du kommer att få din köpbekräftelse i din brevlåda eller i skräppost. Tack för din beställning!",
    "order.code": "Beställningsnummer: {code}",
    "order.shipping_address": "Leveransadress",
    "order.save_payment_info_title": "Spara bankuppgifter för mina nästa inköp.",
    "order.save_payment_info_text": "Om du väljer detta alternativ behöver du inte ange dina uppgifter varje gång. Dina uppgifter kommer att lagras säkert.",
    "order.shipped_to_service_point": "",
    "my_outspot_plus.title": "Mitt Outspot PLUS",
    "my_outspot_plus.subtitle": "På den här sidan kan du hantera ditt Outspot PLUS-medlemskap.",
    "my_outspot_plus.overview": "Översikt över ditt Outspot PLUS-medlemskap",
    "my_outspot_plus.cancel_are_you_sure": "Är du säker på att du vill avsluta ditt medlemskap?",
    "my_outspot_plus.cancel_you_saved_prefix": "Du har redan sparat",
    "my_outspot_plus.cancel_you_saved_suffix": "på frakt- och administrationskostnader med ditt Outspot PLUS-medlemskap.",
    "my_outspot_plus.no_membership": "Du har för närvarande inget Outspot PLUS-medlemskap.",
    "plus.title": "Outspot PLUS",
    "plus.popup_text": "Med Outspot PLUS får du fri frakt på alla varor och betalar inga administrationskostnader på några resor. Efter varje beställning får du ett e-postmeddelande som ger dig möjlighet att bli Outspot PLUS-medlem. Du måste dock vara snabb eftersom denna kampanj endast är giltig i 7 dagar. Glöm inte att kontrollera nästa e-postmeddelande om köpbekräftelse.",
    "plus.name": "Outspot PLUS-medlemskap\r ",
    "plus.highlight_1": "Beställ utan krångel och utan frakt- och administrationskostnader. ",
    "plus.highlight_2": "Din prenumeration förnyas automatiskt för {renewPrice} var tredje månad.. ",
    "plus.highlight_3": "Alltid möjligt att avbryta.\r",
    "plus.question_1": "Vilka är fördelarna med Outspot PLUS?\r \r ",
    "plus.answer_1": "Med Outspot PLUS får du fri frakt på alla varor och behöver inte betala några administrationsavgifter på någon resa.\r \r ",
    "plus.question_2": "Hur blir jag en Outspot PLUS-medlem?\r \r ",
    "plus.answer_2": "Efter varje beställning får du ett e-postmeddelande som ger dig möjlighet att bli Outspot PLUS-medlem. Du måste dock vara snabb eftersom kampanjen endast är giltig i 7 dagar. Om du väljer att aktivera Outspot PLUS får du tillgång till medlemsförmånerna direkt. Det innebär att du direkt vid din nästa beställning får fri frakt på alla varor och att du inte behöver betala några administrationskostnader för dina resor.  ",
    "plus.question_3": "Vad kostar det att vara Outspot PLUS-medlem?  ",
    "plus.answer_3": "Outspot PLUS kostar bara {price} de första 3 månaderna och du betalar bara en gång. Efter denna period förnyas ditt medlemskap automatiskt var 3:e månad till en kostnad av {renewPrice}. Detta gör att du kan spara mycket pengar på frakt- och administrationskostnader.  ",
    "plus.question_4": "När och hur förnyas mitt Outspot PLUS-medlemskap?\r \r",
    "plus.answer_4": "Outspot PLUS förnyas automatiskt var tredje månad. Ditt medlemskap kan du hantera genom att gå till <a href=\"{myOutspotPlus}\">Mina köp</a>. Beloppet dras automatiskt var tredje månad från det konto du har angett.  ",
    "plus.question_5": "Hur avbryter jag mitt Outspot PLUS-medlemskap? Kan jag få pengar tillbaka?  ",
    "plus.answer_5": "Du kan när som helst säga upp ditt Outspot PLUS-medlemskap via: <a href=\"{myOutspotPlus}\">Mina köp</a>. Observera att du inte får några pengar tillbaka för de månader som ditt medlemskap har varit aktivt. Om du väljer att säga upp Outspot PLUS kommer dock nästa månadskostnad inte att debiteras.  ",
    "plus.thank_you": "Ditt köp är slutfört. Från och med nu kan du beställa varor utan frakt- och administrationskostnader. Du kan se och hantera ditt medlemskap genom att gå till <a href=\"{myOutspotPlus}\">Mitt Outspot PLUS</a>. ",
    "my_coupons.title": "Mina kuponger",
    "my_coupons.subtitle": "Du hittar dina kuponger på denna sida.",
    "my_coupons.no_coupons": "Du har för närvarande inga kuponger.",
    "deal.sold": "Redan köpt {sold} gånger",
    "deal.retour_label": "30 dagars returrätt för bara 9,95 kr",
    "deal.retour_title": "Återkallelse",
    "deal.retour_text": "Gillar du inte din produkt trots allt? Kontakta vår helpdesk inom 30 dagar från mottagandet och returnera din produkt. Oavsett anledning! Vi återbetalar ditt köp och du kan beställa utan bekymmer. Ännu en bra idé, från Outspot förstås!",
    "deal.guarantee_label": "6 månaders återbetalningsgaranti",
    "deal.guarantee_title": "Unikt: 100 % återbetalningsgaranti",
    "deal.guarantee_text": "Otroligt men sant. Du behöver inte längre tveka om du ska köpa en upplevelse till dina vänner/kollegor/familj eller dig själv. Om du ändrar dig efteråt, eller om mottagaren inte är helt nöjd med presentkortet, är det inga problem.<br><br>På Outspot.be kan du avbryta ditt köp upp till 6 månader efter beställningen! Du eller den person som fick presentkortet kan få full återbetalning inom 6 månader. Detta, naturligtvis, så länge kupongen ännu inte har reserverats, använts eller löpt ut. Som garanti är detta exceptionellt och unikt. Men det är precis så Outspot är.<br><br> Observera att för alla åtgärder där återbetalningsgarantin inte uttryckligen nämns, gäller inte garantin. Detta beror på att vi i vissa fall inte själva kan återfå leveranser/biljetter från vår partner.",
    "deal.option_count": "{count} alternativ|{count} alternativ",
    "deal.campaign_number": "Kampanjnummer",
    "deal.your_details": "Dina uppgifter",
    "deal.payment_methods_title": "Betalningsmetod",
    "deal.shipping_methods_title": "",
    "deal.privacy": "Genom att lämna dina uppgifter godkänner du <a target=\"_blank\" href=\"{privacy}\">vår integritetspolicy</a>.",
    "deal.payment_methods_subtitle": "Hur vill du betala för din beställning?",
    "deal.your_order": "Din beställning",
    "deal.from": "Från",
    "help.title": "Behöver du hjälp?",
    "help.subtitle": "Låt oss veta hur vi kan hjälpa dig.",
    "help.subject": "Din fråga via vår webbplats eller app",
    "help.sent": "Vi har tagit emot ditt meddelande. Du kommer att få ett svar från oss senast inom 4 arbetsdagar.",
    "login.title": "Logga in",
    "login.subtitle": "Välkommen tillbaka! Hur vill du logga in?",
    "login.or": "eller",
    "login.usps_title": "Varför ska jag skapa ett konto?",
    "login.usps_title_cta": "Gör det enkelt för dig själv och logga in nu eller skapa ett konto.  ",
    "login.usps_text": "Klicka på knappen \"Logga in\" högst upp på sidan för att logga in. Om du inte har ett konto ännu kan du också registrera dig på inloggningssidan. Att ha ett Outspot-konto har bara fördelar:",
    "login.usp_manage_orders": "Visa och spåra beställningar",
    "login.usp_remember_info": "Spara kontaktuppgifter för senare bruk",
    "login.usp_returns": "Rapportera defekter och begära produktreturer",
    "login.usp_store_payments": "Spara betalningsmetoder och betala snabbare",
    "login.short_cta": "<a href=\"{loginLink}\">Logga in</a> eller fortsätt nedan som gäst.",
    "forgot.title": "Begär ett nytt lösenord.",
    "forgot.subtitle": "Ange din e-postadress nedan så skickar vi dig en länk för att återställa ditt lösenord.",
    "forgot.sent": "Vi har skickat ett e-postmeddelande till dig med en länk för att återställa ditt lösenord.",
    "password.title": "Ändra lösenord",
    "password.subtitle": "Välj ett nytt och säkert lösenord.",
    "password.updated": "Ditt lösenord har ändrats. Du kan logga in med ditt nya lösenord nu.",
    "register.title": "Registrera dig",
    "register.subtitle": "Skapa ett konto för att göra det ännu enklare att beställa och spåra dina beställningar.",
    "register.personal_info": "Personuppgifter",
    "register.login_info": "Inloggningsuppgifter",
    "register.agree": "Jag godkänner <a href=\"/enl/help/de allmänna villkoren</a> och <a href=\"/enl/help/privacy\" target=\"_blank\">integritetspolicyn</a>.",
    "register.sent": "Nu är du nästan där. Vi har skickat ett e-postmeddelande till dig för att aktivera ditt konto.",
    "register.email_known": "Är din e-postadress redan känd av Outspot? <a href=\"{forgot}\">Klicka här</a> för att begära ett lösenord.\r",
    "my_orders.title": "Mina beställningar",
    "my_orders.subtitle": "Detta är en översikt över alla beställningar du har gjort hos Outspot.",
    "my_orders.title_detail": "Detaljer om din beställning.",
    "my_travels.title": "Mina resor",
    "my_info.title": "Mina uppgifter",
    "my_info.subtitle": "Här kan du redigera dina personuppgifter.",
    "my_info.sent": "Dina uppgifter har uppdaterats.",
    "notifications.title": "Nyhetsbrev och meddelanden",
    "notifications.subscription_mail": "Hur ofta vill du få vårt nyhetsbrev?",
    "notifications.subscription_notification": "Hur ofta vill du få aviseringar?",
    "notifications.frequency_0": "Aldrig",
    "notifications.frequency_4": "En gång i veckan",
    "notifications.frequency_56": "Två gånger om dagen",
    "notifications.sent": "Dina preferenser har uppdaterats.",
    "combined_purchase_banner.text": "Vi bjuder på frakt- eller administrationskostnader på nya beställningar under denna begränsade tid:",
    "plus_banner.text_welcome": "{firstName}, välkommen till Outspot PLUS.",
    "plus_banner.text_welcome_anonimous": "Välkommen till Outspot PLUS.",
    "plus_banner.text_savings_prefix": "{firstName}, du har redan sparat",
    "plus_banner.text_savings_prefix_anonimous": "Du har redan sparat",
    "plus_banner.text_savings_suffix": "på frakt- och administrationskostnader via Outspot PLUS.",
    "cookie_banner.title": "Cookies",
    "cookie_banner.text": "Denna webbplats använder cookies. Strikt nödvändiga cookies säkerställer att webbplatsen fungerar korrekt och kan inte avvisas. Funktionella, analytiska och reklamcookies används för funktionella, statistiska och marknadsföringsändamål. De laddas endast ned om du samtycker till dem. Läs mer i vår <a href=\"{cookies}\" target=\"_blank\">cookiepolicy</a>",
    "cookie_banner.decline": "Avvisa valfria cookies",
    "cookie_banner.accept_all": "Acceptera alla cookies",
    "cookie_banner.accept_selected": "Acceptera valda",
    "cookie_banner.settings": "Inställningar",
    "cookie_banner.functional_cookies_title": "Strikt nödvändiga cookies",
    "cookie_banner.functional_cookies_text": "Cookies som är nödvändiga för webbplatsens grundläggande funktionalitet.",
    "cookie_banner.analytical_cookies_title": "Analytiska och funktionella cookies",
    "cookie_banner.analytical_cookies_text": "Cookies gör det möjligt för webbplatsen att erbjuda extra funktioner och personliga inställningar samt besök och trafik.",
    "cookie_banner.personal_cookies_title": "Annonscookies",
    "cookie_banner.personal_cookies_text": "Cookies som sätts av annonsörer för att skapa en profil av dina intressen utan att lagra direkt personlig information.",
    "review.title": "Prata om det och vinn",
    "review.subtitle": "Skriv en recension om ditt köp nedan så har du chansen att vinna en kupong (vi lottar ut en sådan kupong varje kvartal).",
    "review.agree": "Jag godkänner att min recension publiceras på webbplatsen",
    "review.sent": "Tack för din recension. Du har nu chansen att vinna en kupong.",
    "review.title_thank_you": "Tack. Vi uppskattar din feedback.\r ",
    "review.subtitle_thank_you": "Tack vare din feedback kan vi göra Outspot ännu bättre. Lämna din bedömning av ditt köp nedan och få chansen att vinna en presentkort (varje kvartal lottar vi ut ett sådant presentkort).",
    "confirm.title": "Din registrering är bekräftad",
    "confirm.subtitle": "Din prenumeration på nyhetsbrevet är härmed bekräftad. Vi tackar för informationen! För att kunna hjälpa dig ännu bättre erbjuder vi dig möjligheten att dela följande uppgifter med oss utan några förpliktelser.",
    "popup_widget.title": "Erbjudanden när de är som bäst, testade för dig.",
    "popup_widget.usp_1": "Ta del av våra bästa erbjudanden dagligen.",
    "popup_widget.usp_2": "Utvalda och testade för bästa pris och kvalitet.",
    "popup_widget.usp_3": "Rejäla rabatter på upp till -70%.",
    "popup_widget.footer_1": "Prenumerera på vårt nyhetsbrev gratis nu och spara omedelbart.",
    "popup_widget.footer_2": "Du kan när som helst avregistrera dig igen.",
    "promo.title": "Våra bästa kampanjer",
    "promo.countdown": "2024-12-31 23:59:59",
    "promo.empty": "För närvarande har vi inga toppkampanjer. Gå till vår startsida för våra senaste erbjudanden.",
    "stars.info_title": "Produktbetyg",
    "stars.info_text": "Detta betyg är ett genomsnitt av våra egna insamlade recensioner samt från andra parter.",
    "stars.counter": "1 recension | {count} recensioner",
    "label.accept_selected": "Godkänn valda",
    "label.edit": "Ändra",
    "label.edit_address": "Ändra adress",
    "label.address": "Adress",
    "label.accept_all": "Godkänn alla",
    "label.account": "Konto",
    "label.help": "Hjälp",
    "label.my_orders": "Mina beställningar",
    "label.my_travels": "Mina resor",
    "label.my_coupons": "Mina kuponger",
    "label.my_info": "Mina uppgifter",
    "label.change_password": "Ändra lösenord",
    "label.notifications": "Nyhetsbrev och meddelanden",
    "label.logout": "Logga ut",
    "label.login": "Logga in",
    "label.login_have_account": "Har du redan ett konto? Logga in",
    "label.login_with_facebook": "Logga in med Facebook",
    "label.login_with_google": "Logga in med Google",
    "label.login_with_apple": "Logga in med Apple",
    "label.password": "Lösenord",
    "label.new_password": "Nytt lösenord",
    "label.repeat_password": "Upprepa lösenord",
    "label.repeat_new_password": "Upprepa nytt lösenord",
    "label.register_no_account": "Har du inget konto än? Registrera dig nu",
    "label.register": "Registrera dig",
    "label.forgot_password": "Har du glömt ditt lösenord?",
    "label.general_conditions": "Allmänna bestämmelser och villkor",
    "label.jobs": "Jobb på Outspot",
    "label.faq": "Vanliga frågor och svar",
    "label.returns": "Returer",
    "label.travel_conditions": "Särskilda resevillkor",
    "label.privacy_policy": "Integritetspolicy",
    "label.cookie_policy": "Policy för cookies",
    "label.subscribe": "Prenumerera",
    "label.all_deals": "Alla erbjudanden",
    "label.other_deals": "Andra erbjudanden",
    "label.discount": "Rabatt",
    "label.close": "Nära",
    "label.order_now": "Beställ nu",
    "label.add": "Lägg till",
    "label.book": "Boka nu",
    "label.upselling_next_step": "Glöm inte heller detta senare",
    "label.upselling": "Glöm inte heller detta",
    "label.also_available": "Också tillgänglig.\r ",
    "label.reviews": "Vad våra kunder säger",
    "label.automatically_translated": "Automatiskt översatt",
    "label.show_all_reviews": "Se alla recensioner",
    "label.back": "Tillbaka",
    "label.make_your_choice": "Gör ditt val",
    "label.sold_out": "Slutsåld",
    "label.order_more": "Beställ mer",
    "label.subtotal": "Delsumma",
    "label.total_shipping": "Kostnader för frakt",
    "label.total_file": "Kostnader för filer",
    "label.total_payable": "Totalt",
    "label.no_options_selected": "Din order är för närvarande tom. Klicka på \"Lägg till\" nedan för att fortsätta.",
    "label.no_options_selected_extra": "Du kan endast välja detta alternativ när du köper produkten.",
    "label.total_saved_prefix": "(Du sparar ",
    "label.total_saved_suffix": ")",
    "label.continue": "Vidare",
    "label.usp_quality": "Valt på pris och kvalitet",
    "label.usp_discounts": "Betydande rabatter på upp till -70 %.",
    "label.usp_payment_methods": "Betala säkert och enkelt med",
    "label.person": "Person",
    "label.free": "Gratis",
    "label.first_name": "Förnamn",
    "label.last_name": "Efternamn",
    "label.birthdate": "Födelsedatum",
    "label.birthdate_why": "",
    "label.birthdate_why_text": "",
    "label.email": "E-postadress",
    "label.phone": "Telefonnummer",
    "label.address_finder": "Sök efter adress",
    "label.address_finder_help": "Skriv in din fullständiga leveransadress",
    "label.select": "Välj",
    "label.street": "Gata",
    "label.building": "",
    "label.number": "Nr",
    "label.zip": "Postnummer",
    "label.city": "Kommun",
    "label.state": "",
    "label.country": "Land",
    "label.finish_order": "Beställ & betala",
    "label.finish_order_help": "Klicka på knappen \"Beställ & betala\" för att slutföra din betalning.",
    "label.enter_address": "Ange min adress manuellt",
    "label.add_address": "Lägg till en adress",
    "label.add_payment_method": "Lägg till en betalningsmetod",
    "label.year": "ÅÅÅÅ",
    "label.month": "MM",
    "label.day": "DD",
    "label.business_order": "Företagsbeställning",
    "label.company_name": "Företagets namn",
    "label.tax_id": "Momsregistreringsnummer",
    "label.invoice_reason": "Anledning",
    "label.invoice_reasons": "Företagspresenter, teambuilding, incitament, seminarium, övrigt",
    "label.delete": "Ta bort",
    "label.i_have_coupons": "Jag har kuponger",
    "label.email_outspot_plus": "Är du redan Outspot PLUS-kund? Ange då din e-postadress.",
    "label.please_wait": "Vänta ...",
    "label.please_wait_2": "Lite tålamod till...",
    "label.please_wait_3": "Ursäkta, det tar lite längre tid än väntat...",
    "label.please_wait_4": "Bara lite till, vi är nästan där...",
    "label.please_wait_5": "Vi ber om ursäkt för besväret och tackar för ditt tålamod, vi är nästan klara",
    "label.continue_shopping": "Fortsätt shoppa",
    "label.my_code": "Min kod",
    "label.validate": "Validera",
    "label.total_coupons": "Totalt antal kuponger",
    "label.coupons": "Kuponger",
    "label.total_payable_with_coupons_prefix": "(Återstår att betala ",
    "label.total_payable_with_coupons_suffix": ")",
    "label.rest_coupon_info": "Det belopp som ska betalas är lägre än de kuponger som angetts. Du kommer att få en ny kupong med det återstående värdet.",
    "label.contact_extra_info": "Ordernummer, kupongnummer eller strukturerad kommunikation",
    "label.contact": "Kontakta oss",
    "label.message": "Meddelande",
    "label.send": "Skicka",
    "label.save": "Spara",
    "label.my_outspot_plus": "Mitt Outspot PLUS",
    "label.order_date_prefix": "Beställd den",
    "label.order_date_suffix": "",
    "label.total_paid_with": "Totalt betalt med {metod}",
    "label.track_order": "Spåra din beställning med",
    "label.return_order": "Returnera och/eller rapportera en defekt",
    "label.download_invoice": "Ladda ner faktura",
    "label.ask_question": "Ställ en fråga",
    "label.happy_hour_discount": "Happy Hour-förmån",
    "label.outspot_plus_discount": "Outspot PLUS-förmån",
    "label.cancel_membership": "Avbryt medlemskap",
    "label.membership_start_date": "Medlemskapets startdatum",
    "label.membership_price": "Pris för 3 månader",
    "label.membership_next_payment": "Nästa betalning",
    "label.membership_end_date": "Slutdatum för medlemskap",
    "label.total_savings": "Totalt sparade frakt- och administrationskostnader",
    "label.confirm_cancel_membership": "Bekräfta uppsägning",
    "label.continue_membership": "Avbryt inte",
    "label.valid_till_prefix": "Giltig till",
    "label.valid_till_suffix": "",
    "label.card_payment": "Betalning med kort",
    "label.place_review": "Skriv recension",
    "label.name": "Namn",
    "label.name_help": "vi visar dessa i recensionen",
    "label.review": "Granskning",
    "label.self_service": "",
    "label.update_cookie_preferences": "Ändra mina inställningar för cookies",
    "label.more_info": "Mer information",
    "label.review_source": "Via {source}",
    "label.amount": "Belopp",
    "label.iban": "IBAN",
    "label.bic": "BIC",
    "label.beneficiary": "Mottagare",
    "label.structured_communication": "Strukturerat meddelande",
    "label.later": "Senare",
    "label.enable_notifications": "Ja, jag vill få dessa meddelanden",
    "label.card_number": "Kortsnummer",
    "label.card_expiry": "Utgångsdatum",
    "label.card_cvc": "CVV",
    "label.cvc_info": "",
    "label.sales_tax": "",
    "label.calculating_sales_tax": "",
    "label.sales_tax_will_be_calculated": "",
    "label.ship_to_selected_address": "",
    "label.ship_to_service_point": "",
    "label.change_service_point": "",
    "label.choose_rooms": "",
    "label.room": "",
    "label.room_occupancy": "",
    "label.add_another_room": "",
    "label.number_of_adults": "",
    "label.number_of_adults_title": "",
    "label.number_of_children": "",
    "label.number_of_children_title": "",
    "label.choose_arrival_and_departure_date": "",
    "label.arrival_date": "",
    "label.select_arrival_date": "",
    "label.departure_date": "",
    "label.select_departure_date": "",
    "label.rate_type_pc": "",
    "label.rate_type_pcpd": "",
    "label.rate_type_pcpn": "",
    "label.rate_type_pp": "",
    "label.rate_type_pppd": "",
    "label.rate_type_pppn": "",
    "label.rate_type_pp_": "",
    "label.rate_type_pa": "",
    "label.rate_type_papd": "",
    "label.rate_type_papn": "",
    "label.rate_type_pa_": "",
    "label.choose_supplements": "",
    "label.opengds_invalid": "",
    "error.unknown": "Okänt fel, försök igen senare.",
    "error.required": "{field} får inte vara tomt.",
    "error.required_email": "{field} får inte vara tomt och måste vara en giltig e-postadress.",
    "error.required_password": "{field} måste vara minst 8 tecken långt och innehålla minst en stor bokstav, en liten bokstav och en siffra.",
    "error.required_repeat_password": "{field} måste vara lika med det lösenord som anges i fältet {field2}.",
    "error.payment": "Tyvärr har din betalning inte gått igenom. Detta kan hända om det finns ett problem med betalningsuppgifterna, betalningen har avbrutits, din betalningsgräns har nåtts eller av andra skäl. Överväg att kontakta din bank eller prova ett annat kort eller betalningsmetod.",
    "error.payment_creditcard": "Tyvärr gick din betalning inte igenom. Vänligen kontrollera uppgifterna nedan och försök igen.",
    "error.incomplete_order": "Vi kan endast validera denna kupong när du har fyllt i alla fält ovan.",
    "error.coupon_already_added": "Du har redan lagt till denna kupong.",
    "error.invalid_coupon": "Denna kupong är ogiltig.",
    "error.coupon_not_cumulative": "Du kan inte använda denna kupong tillsammans med andra kuponger.",
    "error.other_coupon_not_cumulative": "",
    "error.invalid_username_or_password": "Den angivna e-postadressen och/eller lösenordet är ogiltigt.",
    "error.test_order": "Detta är en testbeställning. Normalt skulle kunden nu gå till betalningssidan.",
    "error.required_agree": "Du måste godkänna våra allmänna villkor och vår integritetspolicy.",
    "error.required_review_agree": "Du måste godkänna att din recension får publiceras på vår webbplats.",
    "error.register": "Det finns redan ett konto med den här e-postadressen. Klicka på \"Fortsätt\" för att logga in eller använda en annan e-postadress.",
    "error.email_in_use": "Det finns redan ett konto med den här e-postadressen. Använd en annan e-postadress.",
    "error.already_reviewed": "Du har redan skrivit en recension för denna beställning.",
    "error.zip_blocked": "Det går inte att leverera till det specificerade postnumret.",
    "error.details": "Det verkar som om en eller flera produkter som du försöker beställa inte längre finns i lager. Gå tillbaka till föregående steg, uppdatera din beställning och försök igen.",
    "error.incomplete_cvc": "CVV är för kort.",
    "error.incomplete_date": "Utgångsdatumet kan inte vara tomt.",
    "error.date_in_past": "Kortet har gått ut.",
    "error.invalid_expiration_year": "Kortet har gått ut.",
    "error.incomplete_card_number": "Kortnumret är för kort.",
    "error.incorrect_card_number": "Kortnumret är ogiltigt.",
    "unsubscription.your_mail_address": "Din e-postadress:",
    "unsubscription.sad_message": "Du har avregistrerat dig.",
    "unsubscription.have_not_participated": "Har du aldrig registrerat dig?",
    "unsubscription.sad_message_2": "Vi beklagar att du har lämnat oss, men du är naturligtvis alltid välkommen igen.",
    "unsubscription.resubscribe_question": "Har du ångrat dig? <span class='unsub-important-message'>Klicka nedan för att prenumerera på vårt nyhetsbrev igen:</span>",
    "unsubscription.form_message": "Varför lämnar du vår Outspot-familj? Ditt svar kan hjälpa oss att förbättra våra tjänster.",
    "unsubscription.report_not_participated": "Rapportera bedrägeri.",
    "unsubscription.coreg_date_no_screenshot": "Du deltog i en kampanj den {date} (<a href=\"{coreg_site}\">{coreg_site}</a>) och gav därmed ditt samtycke till att få vårt nyhetsbrev med topperbjudanden till exceptionella priser.",
    "unsubscription.coreg_date_no_coreg_data": "Du deltog i nedanstående kampanj den {date} och gav ditt samtycke till att få vårt nyhetsbrev med topperbjudanden till exceptionella priser.",
    "unsubscription.coreg_date_screenshot": "Du deltog i nedanstående kampanj den {date} och gav ditt samtycke till att få vårt nyhetsbrev med topperbjudanden till exceptionella priser.",
    "unsubscription.coreg_no_date_no_screenshot": "Du deltog i nedanstående kampanj och gav ditt samtycke till att få vårt nyhetsbrev med topperbjudanden till exceptionella priser.",
    "unsubscription.coreg_no_date_screenshot": "Du deltog i nedanstående kampanj och gav ditt samtycke till att få vårt nyhetsbrev med topperbjudanden till exceptionella priser.",
    "unsubscription.main_title": "Avsluta prenumerationen på nyhetsbrevet",
    "unsubscription.report_title": "Rapportera missbruk ",
    "unsubscription.report_sent_message": "Tack, din anmälan har mottagits.",
    "claim_coupon.welcome_message": "Bara för dig",
    "claim_coupon.info_line_message": "Ange den personliga koden ovan vid utcheckningen.",
    "claim_coupon.info_line_message_bis": "Denna kupong har även skickats till dig via e-post.",
    "claim_coupon.main_title": "Din kupong",
    "about.title": "Om oss",
    "about.intro": "Välkommen till Outspot, det bästa stället när det gäller oemotståndliga erbjudanden! Sedan grundandet 2008 har Outspot vuxit till att bli den ledande webbplatsen i Europa för flash deals. Outspot erbjuder rabatter på kvalitetsprodukter och resor varje dag, vilket gör att du som kund kan spara enormt mycket. Genom vårt lager i Europa levererar vi våra paket till dig så snabbt det bara går.",
    "about.mission_title": "Uppdrag",
    "about.mission_text": "På Outspot tror vi på att göra  exceptionella erbjudanden tillgängliga för alla. Vårt mål är att ge kunderna bästa möjliga värde genom att erbjuda noggrant utvalda erbjudanden till de absolut bästa priserna.",
    "about.history_title": "Historia",
    "about.history_text": "Outspots äventyr startade i Belgien, där huvudkontoret fortfarande ligger  kvar. Med ett engagerat team på 25 anställda utspridda över hela Europa arbetar vi varje dag för att hitta de bästa erbjudandena. Vi finns nu i 13 länder och har 100 000 nöjda besökare varje dag.",
    "about.quality_title": "Vårt erbjudande har valts ut utifrån pris och kvalitet",
    "about.quality_text": "På Outspot tror vi att du förtjänar det bästa av två världar: Högsta kvalitet och till ett överkomligt pris. Det är därför vi varje dag letar efter de mest attraktiva erbjudandena för dig. Vi säkerställer att vi har ett nära samarbete med våra leverantörer för att kunna förhandla fram de bästa priserna utan att göra eftergifter på kvaliteten. <br /><br />  Alla produkter och resor vi erbjuder är noggrant utvalda baserat på en rigorös utvärdering av pris och kvalitet. På så sätt kan du vara säker på att du alltid får det bästa värdet hos oss för pengarna. På Outspot kretsar allt kring dina fördelar och din tillfredsställelse! Nedan kan du se ett foto en smartklocka som ljudtestas med en decibelmätare.",
    "about.tested_title": "Alla erbjudanden har testats utförligt av våra Outspot-kollegor",
    "about.tested_text": "På Outspot tror vi på att bara leverera det allra bästa. Innan ett erbjudande dyker upp på vår sida har det noggrant  testats av våra egna Outspot-kollegor. De nöjer sig inte med enbart enkla tester: Produkterna tas isär helt och varje komponent bedöms noggrant efter robusthet, ljudnivå, materialkvalitet med mera.<br /><br />  Våra kollegor besöker också personligen många hotell för att kontrollera kvaliteten på boendet. Så här garanterar vi att allt uppfyller våra höga krav. Oavsett om det handlar om en produkt eller en resa kan du vara säker på att Outspot alltid ser till att du kan tryggt genomföra ditt köp och vara säker på att det är ett bra val.<br /><br />  På bilden nedan kan du se hur kollegor undersöker och testar produkter i detalj: t.ex materialkvalitetstest.",
    "about.guarantee_title": "",
    "about.guarantee_text": "",
    "about.returns_title": "30 dagars returrätt på de flesta produkter",
    "about.returns_text": "Vi förstår att det ibland kan ta tid att bestämma om du vill behålla en produkt. Därför erbjuder vi en 30-dagars returrätt på de flesta av våra produkter. Det är viktigt att produkten förblir oanvänd och oöppnad för att vara berättigad till retur. Detta ger dig möjlighet att överväga ditt köp i din egen takt, medan vi säkerställer en smidig returprocess. På så sätt kan du besluta utan bekymmer, så länge produkten förblir i originalskick.",
    "about.support_title": "Kundtjänst på svenska",
    "about.support_text": "På Outspot kommer kundnöjdheten först och vi gör allt vi kan för att säkerställa att du får en utmärkt upplevelse. Om du har några frågor eller behöver hjälp med din beställning finns vår kundtjänst alltid där för att hjälpa dig. Våra svensktalande personal är alltid tillgängliga och kan ge dig snabb och effektig support. Vi strävar efter att göra dig nöjd och ge dig hjälp på ett personligt och lättförståeligt sätt så att du kan njuta av ditt köp utan några bekymmer. Din tillfredsställelse är vår prioritet och vi är här för att se till att du får ut det bästa av vår servide och dra nytta av den.",
    "about.socials_title": "Håll kontakten",
    "about.socials_text": "Håll dig uppdaterad med våra senaste erbjudanden och exklusiva erbjudanden erbjudanden genom att registrera dig för vårt nyhetsbrev. Följ oss på sociala medier och dela dina Outspot-upplevelser med oss!<br /><br />  Har du frågor eller kommentarer? Vår helpdesk är alltid redo att hjälpa dig. Kontakta oss på <a href=\"{help}\">vår kontaktsida</a>.<br /><br />  Tack för ditt förtroende för Outspot. Vi ser fram emot för att överraska dig gång på gång med våra fantastiska erbjudanden!",
    "vat.error_required": "Fältet \"{field}\" får inte vara tomt vid en företagsbeställning.",
    "vat.validation_message_format": "Fältet \"{field}\" har inte rätt format.",
    "vat.help_title": "Momsregistreringsnummer",
    "vat.help_text": "Det svenska momsnumret måste bestå av 12-4 tecken, till exempel SE123456789012.",
    "label.download": "Nedladdningar",
    "error.download_pdf": "Något gick fel när du laddade ner PDF-filen. Vänligen försök igen senare.",
    "manage_address.title": "Mina leveransadresser",
    "error.select": "Välj en {field}",
    "manage_cards.title": "Mina betalningsmetoder",
    "deal.quality_text_1": "Det självklara valet när det gäller pris och kvalitet",
    "deal.quality_text_2": "",
    "deal.delivery_text_days": "Leveranstid: {days} arbetsdagar",
    "deal.delivery_text": "",
    "maintenance.going_offline": "Om några minuter kommer vår webbplats att vara tillfälligt offline för planerat underhåll. Vänligen slutför din beställning nu då det inte kommer att vara möjligt att genomföra några beställningar under underhållet.",
    "maintenance.offline": "Vår webbplats är för närvarande offline för planerat underhåll. Vänligen försök igen om några minuter – vi kommer att vara tillbaka online snart igen!  Som en liten gest får du GMJ2QPZ6  för 55 SEK rabatt på ditt nästa köp.",
    "sustainability.carpark_title": "Vi gör vår fordonsflotta grönare",
    "sustainability.carpark_text": "Vi strävar ständigt efter att minska vårt ekologiska fotavtryck och arbetar därför med att modernisera vår fordonsflotta. Målet är att alla våra fordon ska vara eldrivna år 2026. Denna övergång är ett avgörande steg för att minska utsläppen och främja renare, grönare transporter.",
    "sustainability.energy_title": "Kontor med solenergi",
    "sustainability.energy_text": "För oss börjar hållbarhet på hemmaplan, det vill säga vårt kontor. Med 30 solpaneler producerar nästan hälften av den el vi behöver. Genom att använda solkraft minskar vi vårt beroende av fossila bränslen och reducerar våra CO₂-utsläpp.",
    "sustainability.eco_packaging_title": "Miljövänliga förpackningar",
    "sustainability.eco_packaging_text": "Vi är medvetna om förpackningsavfallets miljöpåverkan och använder därför enbart 100 % återvinningsbar plast i våra förpackningar. Detta initiativ bidrar till en mer hållbar cykel för användning och återanvändning.",
    "sustainability.combined_packaging_title": "Hopslagna leveranser",
    "sustainability.combined_packaging_text": "För att ytterligare minska vår miljöpåverkan uppmuntrar vi våra kunder att slå ihop sina beställningar. Genom att kombinera flera inköp i en och samma beställning kan vi reducera antalet transporter och därmed både utsläpp och vårt ekologiska fotavtryck.",
    "sustainability.title": "Hållbarhet hos Outspot",
    "sustainability.vision_title": "Vår vision",
    "sustainability.vision_text": "Vi på Outspot är medvetna om vår miljöpåverkan. Varje dag gläder vi tusentals människor med vårt utbud, samtidigt som vi integrerar ekologiskt och socialt ansvar i alla delar av vår verksamhet. Vi tror att genom att agera proaktivt idag kan vi bidra till en friskare planet och en bättre framtid för kommande generationer.",
    "sustainability.action_title": "Häng med!",
    "sustainability.action_text": "Häng med på vår hållbarhetsresa, allt från att välja miljövänliga produkter till att delta i våra återvinningsprogram – varje liten handling gör skillnad.",
    "sustainability.plant_text": "När du handlar kan du välja att plantera ett träd för bara 20 SEK extra. Detta bidrar inte bara till att kompensera för CO₂-utsläpp utan även till återbeskogning och en bättre framtid för lokala samhällen.",
    "sustainability.treeplanters": "Vi samarbetar med Tree-Planters, en organisation som arbetar för smart och hållbar återbeskogning. De planterar träd på noggrant utvalda platser i Malawi, återställer naturen och stödjer lokalbefolkningen med extra inkomster och livsmedelsresurser.",
    "sustainability.action_text_believe": "För oss på Outspot är hållbarhet en delad ansvarsfråga. Tillsammans med våra kunder, anställda och partners strävar vi efter ett mer medvetet förhållande till vår planet. Var med och gör skillnad tillsammans med oss!",
    "sustainability.plant_title": "Plantera ett träd – Gör skillnad",
    "sustainability.trees": "träd ",
    "sustainability.tree": "träd ",
    "deal.advice_price": "",
    "sustainability.checkbox_title": "Plantera tillsammans med Outspot",
    "sustainability.checkbox_detail_text": "Vi inbjuder dig att plantera träd tillsammans med Outspot för {price}."
  },
  "fi": {
    "route.home": "https://www.outspot.fi/fi/",
    "route.fallback_domain": "https://www2.outspot.fi",
    "language.code": "FI",
    "language.locale": "fi-FI",
    "language.name": "Suomi",
    "language.flag": "fi",
    "language.date_input_format": "d,m,y",
    "language.currency": "EUR",
    "language.region": "eu",
    "countries.delivery": "FIN",
    "states.delivery": "",
    "tracking.aw_merchant_id": "5086420230",
    "tracking.aw_feed_country": "FI",
    "countries.BEL": "Belgia",
    "countries.LUX": "Luxemburg",
    "countries.NLD": "Alankomaat",
    "countries.FRA": "Ranska",
    "countries.DEU": "Saksa",
    "countries.AUT": "Itävalta",
    "countries.ITA": "Italia",
    "countries.ESP": "Espanja",
    "countries.POL": "Puola",
    "countries.GBR": "Yhdistynyt kuningaskunta",
    "countries.IRL": "Irlanti",
    "countries.SWE": "Ruotsi",
    "countries.PRT": "Portugali",
    "countries.FIN": "Suomi",
    "countries.USA": "",
    "states.AL": "",
    "states.AR": "",
    "states.AZ": "",
    "states.CA": "",
    "states.CO": "",
    "states.CT": "",
    "states.DE": "",
    "states.FL": "",
    "states.GA": "",
    "states.IA": "",
    "states.ID": "",
    "states.IL": "",
    "states.IN": "",
    "states.KS": "",
    "states.KY": "",
    "states.LA": "",
    "states.MA": "",
    "states.MD": "",
    "states.ME": "",
    "states.MI": "",
    "states.MN": "",
    "states.MO": "",
    "states.MS": "",
    "states.MT": "",
    "states.NC": "",
    "states.ND": "",
    "states.NE": "",
    "states.NH": "",
    "states.NJ": "",
    "states.NM": "",
    "states.NV": "",
    "states.NY": "",
    "states.OH": "",
    "states.OK": "",
    "states.OR": "",
    "states.PA": "",
    "states.RI": "",
    "states.SC": "",
    "states.SD": "",
    "states.TN": "",
    "states.TX": "",
    "states.UT": "",
    "states.VA": "",
    "states.VT": "",
    "states.WA": "",
    "states.WI": "",
    "states.WV": "",
    "states.WY": "",
    "trustpilot.businessunit_id": "641344aa8b5fd75faaf53ecc",
    "trustpilot.show": "FALSE",
    "mollie.profile_id": "pfl_AX4WCNciZK",
    "mollie.locale": "fi_FI",
    "facebook.url": "",
    "instagram.url": "",
    "linkedin.url": "https://www.linkedin.com/company/outspot/mycompany/",
    "header.hello": "Tervetuloa",
    "header.hello_name": "Hei <b>{firstName}</b>",
    "subscribe.title": "Rekisteröidy ilmaiseksi, jotta et missaa yhtään tarjousta!",
    "subscribe.text": "Löydä parhaat tarjoukset Outspotilta. Rekisteröidy ilmaiseksi ja nauti eksklusiivisista ja henkilökohtaisista alennuksista ainutlaatuisista tuotteista ja elämyksistä.",
    "subscribe.agree": "Tilaamalla uutiskirjeemme hyväksyt <a href=\"{conditions}\" target=\"_blank\">yleiset ehdot</a> ja <a href=\"{privacy}\" target=\"_blank\">tietosuojaselosteen</a>.",
    "subscribe.sent": "Kiitos! Olemme vastaanottaneet hakemuksesi. Saat pian sähköpostiviestin, jossa vahvistamme rekisteröitymisesi.",
    "footer.follow": "Seuraa meitä",
    "footer.payment_methods_logos": "mastercard,visa,amex,trustly,paypal,applepay",
    "footer.copyright": "Outspot — Sint-Martens-Latem, Belgia — Confinity NV",
    "home.new": "Viimeisimmät tarjoukset",
    "home.ending": "Nämä tarjoukset ovat päättymässä",
    "home.other": "Kaikki tarjoukset",
    "countdown.prefix": "Jäljellä",
    "countdown.days": "päivä|päivää",
    "countdown.hours": "tuntia",
    "countdown.minutes": "minuuttia",
    "countdown.seconds": "sekuntia",
    "countdown.days_compact": "pv",
    "date.years": "{count} vuosi sitten|{count} vuotta sitten",
    "date.months": "{count} kuukausi sitten|{count} kuukautta sitten",
    "date.weeks": "{count} viikko sitten|{count} viikkoa sitten",
    "date.days": "{count} päivä sitten|{count} päivää sitten",
    "date.hours": "{count} tunti sitten|{count} tuntia sitten",
    "date.minutes": "{count} minuutti sitten|{count} minuuttia sitten",
    "date.seconds": "Alle 1 minuutti sitten",
    "countdown.suffix": "",
    "onboard.language_title": "Nauti yksilöllisistä tarjouksista houkuttelevilla kampanjoilla",
    "onboard.language_text": "Valitse, missä maassa ja kielellä haluat tehdä ostoksia:",
    "onboard.notifications_title": "Pysy ajan tasalla:",
    "onboard.notifications_usp_1": "Parhaat ja uusimmat tarjoukset",
    "onboard.notifications_usp_2": "Päivitykset tilauksistasi",
    "onboard.notifications_usp_3": "Henkilökohtaiset alennuskoodit ja kupongit",
    "order.shipping_title": "Toimitus",
    "order.shipping_text": "Kasvavien kuljetuskustannusten ja ympäristövaikutusten vuoksi meidän on pakko veloittaa kuljetuskustannuksia. Outspot takaa sinulle kuitenkin edelleen edulliset hinnat, joiden avulla voitat joka tapauksessa. Lisäksi kuljetuskustannuksesi eivät kasva, kun ostat useampia kappaleita tuotetta. Jos olet Outspot PLUS -jäsen tai ostat tunnin sisällä edellisestä ostoksestasi, nämä kustannukset vähennetään seuraavassa vaiheessa.",
    "order.thank_you": "Kiitos tilauksestasi!",
    "order.overview": "Tilauksesi yleiskatsaus",
    "order.thank_you_bank": "Kiitos ostoksestasi. Saat siirtotiedot postilaatikkoosi tai roskapostiin. Siirrä summa mahdollisimman pian ja joka tapauksessa 7 päivän kuluessa. Saat tilaamasi kupongin sähköpostitse heti, kun maksu on saapunut ja käsitelty.",
    "order.thank_you_with_shipping": "Ostoksesi on suoritettu. Lähetämme tilauksesi alla olevaan osoitteeseen. Saat ostovahvistuksen postilaatikkoosi tai roskapostiin. Nauti tilauksestasi.",
    "order.thank_you_without_shipping": "Ostoksesi on valmis. Saat ostovahvistuksen postilaatikkoosi tai roskapostiin. Nauti tilauksestasi.",
    "order.code": "Tilausnumero: {code}",
    "order.shipping_address": "Toimitusosoite",
    "order.save_payment_info_title": "Tallenna pankkitiedot seuraavia ostoksia varten.",
    "order.save_payment_info_text": "Jos valitset tämän vaihtoehdon, sinun ei tarvitse syöttää tietojasi joka kerta. Tietosi tallennetaan turvallisesti.",
    "order.shipped_to_service_point": "",
    "my_outspot_plus.title": "Oma Outspot PLUS",
    "my_outspot_plus.subtitle": "Tällä sivulla voit hallita Outspot PLUS -jäsenyyttäsi.",
    "my_outspot_plus.overview": "Outspot PLUS -jäsenyytesi yleiskatsaus",
    "my_outspot_plus.cancel_are_you_sure": "Haluatko varmasti peruuttaa jäsenyytesi?",
    "my_outspot_plus.cancel_you_saved_prefix": "Olet jo säästänyt",
    "my_outspot_plus.cancel_you_saved_suffix": "toimitus- ja käsittelymaksuista Outspot PLUS -jäsenyytesi aikana.",
    "my_outspot_plus.no_membership": "Sinulla ei ole tällä hetkellä Outspot PLUS -jäsenyyttä.",
    "plus.title": "Outspot PLUS",
    "plus.popup_text": "Outspot PLUS -palvelun avulla saat maksuttoman toimituksen kaikille tuotteille, etkä maksa käsittelymaksuja mistään matkoista. Jokaisen tilauksen jälkeen saat sähköpostin, jossa annetaan sinulle mahdollisuus liittyä Outspot PLUS -jäseneksi. Sinun on kuitenkin oltava nopea, sillä tämä kampanja on voimassa vain 7 päivää. Älä unohda tarkistaa seuraavaa ostovahvistussähköpostiasi.",
    "plus.name": "Outspot PLUS -jäsenyys ",
    "plus.highlight_1": "Nauti vaivattomasta tilaamisesta ilman toimitus- ja käsittelymaksuja. ",
    "plus.highlight_2": "Tilauksesi uusitaan automaattisesti {renewPrice} kolmen kuukauden välein. ",
    "plus.highlight_3": "Peruuttaminen on aina mahdollista.\r",
    "plus.question_1": "Mitkä ovat Outspot PLUS -palvelun edut?  ",
    "plus.answer_1": "Outspot PLUS -palvelun avulla saat ilmaisen toimituksen kaikista tuotteista, etkä maksa käsittelymaksuja matkoista.  ",
    "plus.question_2": "Miten saan Outspot PLUS -palvelun?\r \r",
    "plus.answer_2": "Jokaisen tilauksen jälkeen saat sähköpostiviestin, jossa annetaan sinulle mahdollisuus liittyä Outspot PLUS -jäseneksi. Sinun on kuitenkin oltava nopea, sillä tämä kampanja on voimassa vain 7 päivää. Jos päätät aktivoida Outspot PLUS -palvelun, edut alkavat heti. Tämä tarkoittaa sitä, että seuraavassa tilauksessasi voit heti nauttia ilmaisesta toimituksesta kaikille tuotteille, eikä sinun tarvitse maksaa arkistointimaksua matkoistasi.  ",
    "plus.question_3": "Paljonko Outspot PLUS maksaa?\r \r ",
    "plus.answer_3": "Outspot PLUS maksaa vain {price} euroa ensimmäiseltä 3 kuukaudelta. Tämä on kertamaksu. Tämän jälkeen jäsenyytesi uusitaan automaattisesti 3 kuukauden välein {renewPrice} euron hintaan. Näin säästät merkittävästi toimitus- ja käsittelykuluissa.  ",
    "plus.question_4": "Milloin ja miten Outspot PLUS -jäsenyyteni uusitaan?\r \r ",
    "plus.answer_4": "Outspot PLUS -jäsenyys uusitaan automaattisesti kolmen kuukauden välein. Voit hallita tätä aina kohdassa <a href=\"{myOutspotPlus}\">OMAT OSTOKSET </a>. Summa vähennetään automaattisesti valitsemaltasi tililtä kolmen kuukauden välein.  ",
    "plus.question_5": "Miten voin peruuttaa Outspot PLUS -tilaukseni? Saanko hyvityksen?\r \r",
    "plus.answer_5": "Voit peruuttaa Outspot PLUS -jäsenyytesi milloin tahansa kohdassa <a href=\"{myOutspotPlus}\">OMAT OSTOKSET</a>. On tärkeää huomata, että hyvitystä ei makseta niiltä kuukausilta, joina jäsenyytesi oli aktiivinen. Jos kuitenkin päätät peruuttaa Outspot PLUS -jäsenyyden, seuraavaa suunniteltua laskutusta ei veloiteta.  ",
    "plus.thank_you": "Ostoksesi on valmis. Tästä lähtien voit nauttia huolettomasta tilaamisesta ilman toimitus- ja käsittelykuluja. Voit tarkastella ja hallita jäsenyyttäsi kohdan <a href=\"{myOutspotPlus}\">Oma Outspot PLUS</a> kautta.  ",
    "my_coupons.title": "Omat kupongit",
    "my_coupons.subtitle": "Löydät kupongit tältä sivulta.",
    "my_coupons.no_coupons": "Sinulla ei ole tällä hetkellä kuponkeja.",
    "deal.sold": "Ostettu jo {sold} kertaa",
    "deal.retour_label": "30 päivän palautusoikeus vain 0,95 €",
    "deal.retour_title": "Peruuttaminen",
    "deal.retour_text": "Etkö sittenkään pidä tuotteestasi? Ota yhteyttä asiakaspalveluumme 30 päivän kuluessa tuotteen vastaanottamisesta ja palauta tuote. Ja mistä tahansa syystä! Palautamme ostoksesi ja voit tilata huoletta. Toinen loistava idea, tietenkin Outspotilta!",
    "deal.guarantee_label": "6 kuukauden palautustakuu",
    "deal.guarantee_title": "Ainutlaatuista: 100 %:n palautustakuu",
    "deal.guarantee_text": "Uskomatonta mutta totta. Sinun ei tarvitse enää empiä, kun ostat ystävillesi/kollegoillesi/perheellesi tai itsellesi. Jos muutat mielesi jälkikäteen tai jos lahjansaaja ei ole täysin tyytyväinen lahjakorttiin, se ei haittaa.<br><br>Outspot.fi:ssä voit peruuttaa ostoksesi jopa 6 kuukauden kuluttua tilauksesta! Sinä tai lahjakortin saanut henkilö voi saada täyden hyvityksen 6 kuukauden kuluessa. Tämä pätee tietysti vain, jos arvoseteliä ei ole vielä varattu tai käytetty, eikä se ole vanhentunut. Takuuna tämä on poikkeuksellinen eikä sitä ole koskaan nähty ennen. Mutta sellainen Outspot on.<br><br> Huomioithan, että takuuta ei sovelleta kampanjoihin, joissa palautustakuuta ei ole erikseen mainittu. Tämä johtuu siitä, että joissakin tapauksissa emme voi itse periä toimituksia/lippuja takaisin yhteistyökumppaniltamme.",
    "deal.option_count": "{count} vaihtoehto|{count} vaihtoehtoa",
    "deal.campaign_number": "Kampanjan numero",
    "deal.your_details": "Tietosi",
    "deal.payment_methods_title": "Maksutapa",
    "deal.shipping_methods_title": "",
    "deal.privacy": "Antamalla tietosi hyväksyt <a target=\"_blank\" href=\"{privacy}\">tietosuojakäytäntömme</a>.",
    "deal.payment_methods_subtitle": "Miten haluat maksaa tilauksesi?",
    "deal.your_order": "Tilauksesi",
    "deal.from": "Alkaen",
    "help.title": "Tarvitsetko apua?",
    "help.subtitle": "Kerro meille, miten voimme auttaa sinua.",
    "help.subject": "Kysymyksesi verkkosivujemme tai sovelluksen kautta",
    "help.sent": "Olemme vastaanottaneet viestisi. Saat meiltä vastauksen viimeistään 4 työpäivän kuluessa.",
    "login.title": "Kirjaudu sisään",
    "login.subtitle": "Tervetuloa takaisin! Miten haluat kirjautua sisään?",
    "login.or": "tai",
    "login.usps_title": "Miksi minun pitäisi luoda tili?",
    "login.usps_title_cta": "Helpota prosessia ja kirjaudu nyt sisään tai luo tili.  ",
    "login.usps_text": "Klikkaa sivun yläreunassa olevaa \"Kirjaudu sisään\" -painiketta kirjautuaksesi sisään. Jos sinulla ei ole vielä tiliä, voit myös rekisteröityä kirjautumissivulla. Outspot-tilillä on vain etuja:",
    "login.usp_manage_orders": "Tilausten tarkastelu ja seuranta",
    "login.usp_remember_info": "Yhteystietojen tallentaminen myöhempää käyttöä varten",
    "login.usp_returns": "Vioista ilmoittaminen ja tuotepalautusten pyytäminen",
    "login.usp_store_payments": "Maksutapojen tallentaminen ja nopeampi maksaminen",
    "login.short_cta": "<a href=\"{loginLink}\">Kirjaudu sisään</a> tai jatka alla vieraana.",
    "forgot.title": "Pyydä uutta salasanaa.",
    "forgot.subtitle": "Kirjoita sähköpostiosoitteesi alle, niin lähetämme sinulle linkin salasanasi palauttamista varten.",
    "forgot.sent": "Olemme lähettäneet sinulle sähköpostiviestin, jossa on linkki salasanasi palauttamiseen.",
    "password.title": "Vaihda salasana",
    "password.subtitle": "Valitse uusi ja turvallinen salasana.",
    "password.updated": "Salasanasi on vaihdettu. Voit kirjautua sisään uudella salasanallasi tästä lähtien.",
    "register.title": "Rekisteröi",
    "register.subtitle": "Luo tili, jotta tilaaminen ja tilausten seuranta on entistäkin helpompaa.",
    "register.personal_info": "Henkilökohtaiset tiedot",
    "register.login_info": "Kirjautumistiedot",
    "register.agree": "Hyväksyn <a href=\"/fi/help/general-conditions\" target=\"_blank\">yleiset käyttöehdot</a> ja <a href=\"/fi/help/privacy\" target=\"_blank\">tietosuojailmoituksen</a>.",
    "register.sent": "Olet melkein valmis. Olemme lähettäneet sinulle sähköpostin tilisi aktivoimiseksi.",
    "register.email_known": "Onko sähköpostiosoitteesi jo tiedossa Outspotissa? <a href=\"{forgot}\">Klikkaa tästä</a> pyytääksesi salasanaa.\r",
    "my_orders.title": "Tilaukseni",
    "my_orders.subtitle": "Tämä on yleiskatsaus kaikista tilauksistasi, jotka olet tehnyt Outspotissa.",
    "my_orders.title_detail": "Tilauksesi tiedot.",
    "my_travels.title": "Matkani",
    "my_info.title": "Omat tiedot",
    "my_info.subtitle": "Täällä voit muokata henkilötietojasi.",
    "my_info.sent": "Tietosi on päivitetty.",
    "notifications.title": "Uutiskirje ja ilmoitukset",
    "notifications.subscription_mail": "Kuinka usein haluat vastaanottaa uutiskirjeemme?",
    "notifications.subscription_notification": "Kuinka usein haluat vastaanottaa ilmoitukset?",
    "notifications.frequency_0": "Ei koskaan",
    "notifications.frequency_4": "Kerran viikossa",
    "notifications.frequency_56": "Kaksi kertaa päivässä",
    "notifications.sent": "Asetuksesi on päivitetty.",
    "combined_purchase_banner.text": "Tilaa ilman lähetys- tai käsittelymaksuja seuraavana aikana:",
    "plus_banner.text_welcome": "{firstName}, tervetuloa Outspot PLUS -palveluun.",
    "plus_banner.text_welcome_anonimous": "Tervetuloa Outspot PLUS -palveluun.",
    "plus_banner.text_savings_prefix": "{firstName}, olet jo säästänyt",
    "plus_banner.text_savings_prefix_anonimous": "Olet jo säästänyt",
    "plus_banner.text_savings_suffix": "toimitus- ja käsittelymaksuista Outspot PLUS -jäsenyytesi aikana.",
    "cookie_banner.title": "Evästeet",
    "cookie_banner.text": "Tämä verkkosivusto käyttää evästeitä. Välttämättömät evästeet varmistavat verkkosivuston toimivuuden eikä niitä voi kieltäytyä. Toiminnallisia, analytiikka- ja mainosevästeitä käytetään vastaavasti toiminnallisiin, tilastollisiin ja markkinointitarkoituksiin. Ne ladataan vain, jos suostut niihin. Lue lisää <a href=\"{cookies}\" target=\"_blank\">evästekäytännöstämme</a>.",
    "cookie_banner.decline": "Hylkää valinnaiset evästeet",
    "cookie_banner.accept_all": "Hyväksy kaikki evästeet",
    "cookie_banner.accept_selected": "Hyväksy valitut",
    "cookie_banner.settings": "Asetukset",
    "cookie_banner.functional_cookies_title": "Välttämättömät evästeet",
    "cookie_banner.functional_cookies_text": "Evästeet, jotka ovat välttämättömiä verkkosivuston perustoiminnalle.",
    "cookie_banner.analytical_cookies_title": "Analytiikka- ja toiminnalliset evästeet",
    "cookie_banner.analytical_cookies_text": "Evästeet mahdollistavat verkkosivuston tarjoavan lisäominaisuuksia ja henkilökohtaisia asetuksia sekä vierailuja ja liikennettä.",
    "cookie_banner.personal_cookies_title": "Mainosevästeet",
    "cookie_banner.personal_cookies_text": "Mainostajien asettamat evästeet kiinnostustesi profiilin muodostamiseksi ilman suoranaisia henkilötietoja.",
    "review.title": "Puhu siitä ja voita",
    "review.subtitle": "Kirjoita arvostelusi ostoksestasi alla, niin sinulla on mahdollisuus voittaa 250 euron arvoinen kuponki (arvomme tällaisen kupongin kolmen kuukauden välein).",
    "review.agree": "Suostun siihen, että arvosteluni voidaan julkaista verkkosivustolla.",
    "review.sent": "Kiitos arvostelusta. Sinulla on nyt mahdollisuus voittaa 250 euron arvoinen arvoseteli.",
    "review.title_thank_you": "Kiitos. Arvostamme palautettasi.",
    "review.subtitle_thank_you": "Palautteesi avulla voimme tehdä Outspotista entistä paremman. Jätä arviosi ostoksestasi alla olevaan kohtaan ja voit samalla voittaa 250 euron lahjakortin (joka neljännes arvomme tällaisen lahjakortin).",
    "confirm.title": "Rekisteröitymisesi on vahvistettu",
    "confirm.subtitle": "Uutiskirjeen tilaaminen vahvistetaan täten. Kiitos! Jotta voisimme palvella sinua entistä paremmin, tarjoamme sinulle mahdollisuuden jakaa kanssamme seuraavat tiedot ilman mitään velvoitteita.",
    "popup_widget.title": "Tarjoamme sinua varten kokeiltuja parhaita tarjouksia.",
    "popup_widget.usp_1": "Saat parhaat tarjouksemme päivittäin.",
    "popup_widget.usp_2": "Ne on valittu ja kokeiltu parhaan hinnan ja laadun varmistamiseksi.",
    "popup_widget.usp_3": "Saatavilla on merkittäviä alennuksia, jopa 70 %.",
    "popup_widget.footer_1": "Tilaa tiedotteemme nyt maksutta ja alat säästämään välittömästi.",
    "popup_widget.footer_2": "Voit peruuttaa tilauksen milloin tahansa.",
    "promo.title": "Huipputarjouksemme",
    "promo.countdown": "2024-12-31 23:59:59",
    "promo.empty": "Tällä hetkellä meillä ei ole huipputarjouksia. Siirry kotisivuillemme viimeisimpien tarjoustemme löytämiseksi.",
    "stars.info_title": "Tuotearvio",
    "stars.info_text": "Tämä arvosana on keskiarvo omista kerätyistä arvioistamme ja muiden osapuolten arvioista.",
    "stars.counter": "1 arvio | {count} arviota",
    "label.accept_selected": "Hyväksy valitut",
    "label.edit": "Muuta",
    "label.edit_address": "Muuta osoitetta",
    "label.address": "Osoite",
    "label.accept_all": "Hyväksy kaikki",
    "label.account": "Tili",
    "label.help": "Apua",
    "label.my_orders": "Tilaukseni",
    "label.my_travels": "Matkani",
    "label.my_coupons": "Kuponkini",
    "label.my_info": "Tietoni",
    "label.change_password": "Vaihda salasana",
    "label.notifications": "Uutiskirje ja ilmoitukset",
    "label.logout": "Kirjaudu ulos",
    "label.login": "Kirjaudu sisään",
    "label.login_have_account": "Onko sinulla jo tili? Kirjaudu sisään",
    "label.login_with_facebook": "Kirjaudu sisään Facebookilla",
    "label.login_with_google": "Kirjaudu sisään Googlella",
    "label.login_with_apple": "Kirjaudu sisään Applella",
    "label.password": "Salasana",
    "label.new_password": "Uusi salasana",
    "label.repeat_password": "Toista salasana",
    "label.repeat_new_password": "Toista uusi salasana",
    "label.register_no_account": "Eikö sinulla ole vielä tiliä? Rekisteröidy nyt",
    "label.register": "Rekisteröi",
    "label.forgot_password": "Unohditko salasanasi?",
    "label.general_conditions": "Yleiset ehdot",
    "label.jobs": "Työpaikat Outspotissa",
    "label.faq": "Usein kysytyt kysymykset",
    "label.returns": "Palautus",
    "label.travel_conditions": "Erityiset toimitusolosuhteet",
    "label.privacy_policy": "Tietosuojailmoitus",
    "label.cookie_policy": "Evästeilmoitus",
    "label.subscribe": "Tilaa",
    "label.all_deals": "Kaikki tarjoukset",
    "label.other_deals": "Muut tarjoukset",
    "label.discount": "Alennus",
    "label.close": "Sulje",
    "label.order_now": "Tilaa nyt",
    "label.add": "Lisää",
    "label.book": "Varaa nyt",
    "label.upselling_next_step": "Älä myöskään unohda tätä myöhemmin",
    "label.upselling": "Älä myöskään unohda tätä",
    "label.also_available": "Myös saatavilla.\r ",
    "label.reviews": "Mitä asiakkaamme sanovat",
    "label.automatically_translated": "Automaattisesti käännetty",
    "label.show_all_reviews": "Katso kaikki arvostelut",
    "label.back": "Takaisin",
    "label.make_your_choice": "Tee valintasi",
    "label.sold_out": "Myyty loppuun",
    "label.order_more": "Tilaa lisää",
    "label.subtotal": "Yhteensä",
    "label.total_shipping": "Toimituskulut",
    "label.total_file": "Tiedoston kustannukset",
    "label.total_payable": "Yhteensä",
    "label.no_options_selected": "Tilauksesi on tällä hetkellä tyhjä. Klikkaa \"Lisää\" alla jatkaaksesi.",
    "label.no_options_selected_extra": "Voit valita tämän vaihtoehdon vain tuotetta ostaessasi.",
    "label.total_saved_prefix": "(Säästät ",
    "label.total_saved_suffix": ")",
    "label.continue": "Jatka",
    "label.usp_quality": "Valittu hinnan ja laadun perusteella",
    "label.usp_discounts": "Huomattavia alennuksia, jopa –70 %",
    "label.usp_payment_methods": "Maksa turvallisesti ja helposti",
    "label.person": "Henkilö",
    "label.free": "Ilmainen",
    "label.first_name": "Etunimi",
    "label.last_name": "Sukunimi",
    "label.birthdate": "Syntymäaika",
    "label.birthdate_why": "",
    "label.birthdate_why_text": "",
    "label.email": "Sähköpostiosoite",
    "label.phone": "Puhelinnumero",
    "label.address_finder": "Osoitteen etsijä",
    "label.address_finder_help": "Kirjoita täydellinen toimitusosoitteesi",
    "label.select": "Valitse",
    "label.street": "Katu",
    "label.building": "",
    "label.number": "Numero",
    "label.zip": "Postinumero",
    "label.city": "Paikkakunta",
    "label.state": "",
    "label.country": "Maa",
    "label.finish_order": "Tilaa & maksa",
    "label.finish_order_help": "Klikkaa Tilaa & maksa tilaus -painiketta suorittaaksesi maksun loppuun.",
    "label.enter_address": "Kirjoita osoitteeni manuaalisesti",
    "label.add_address": "Lisää osoite",
    "label.add_payment_method": "Lisää maksutapa",
    "label.year": "VVVV",
    "label.month": "KK",
    "label.day": "PP",
    "label.business_order": "Yritystilaus",
    "label.company_name": "Yrityksen nimi",
    "label.tax_id": "ALV-numero",
    "label.invoice_reason": "Syy",
    "label.invoice_reasons": "Yrityslahjat,Tiimin rakentaminen,Kannustimet,Seminaari,Muut",
    "label.delete": "Poista",
    "label.i_have_coupons": "Minulla on kuponkeja",
    "label.email_outspot_plus": "Oletko jo Outspot PLUS -asiakas? Anna sitten sähköpostiosoitteesi.",
    "label.please_wait": "Odota hetki...",
    "label.please_wait_2": "Ei enää pitkään...",
    "label.please_wait_3": "Anteeksi, tässä kestää hieman odotettua kauemmin...",
    "label.please_wait_4": "Vielä vähän, olemme melkein valmiita...",
    "label.please_wait_5": "Pahoittelut häiriöstä ja kiitos kärsivällisyydestä, olemme melkein valmiita.",
    "label.continue_shopping": "Lisää ostoksia",
    "label.my_code": "Minun koodini",
    "label.validate": "Validoi",
    "label.total_coupons": "Kupongit yhteensä",
    "label.coupons": "Kupongit",
    "label.total_payable_with_coupons_prefix": "(Vielä maksamatta ",
    "label.total_payable_with_coupons_suffix": ")",
    "label.rest_coupon_info": "Maksettava määrä on pienempi kuin syötetyt kupongit. Saat uuden kupongin, jossa on jäljellä oleva arvo.",
    "label.contact_extra_info": "Tilausnumero, kupongin numero tai strukturoitu viestintä",
    "label.contact": "Ota yhteyttä",
    "label.message": "Viesti",
    "label.send": "Lähetä",
    "label.save": "Tallenna",
    "label.my_outspot_plus": "Oma Outspot PLUS",
    "label.order_date_prefix": "Tilattu",
    "label.order_date_suffix": "",
    "label.total_paid_with": "Kokonaissumma maksettu seuraavalla: {method}",
    "label.track_order": "Seuraa tilaustasi seuraavalla:",
    "label.return_order": "Palauta ja/tai ilmoita vika",
    "label.download_invoice": "Lataa lasku",
    "label.ask_question": "Kysy kysymys",
    "label.happy_hour_discount": "Happy Hour -hyöty",
    "label.outspot_plus_discount": "Outspot PLUS -etu",
    "label.cancel_membership": "Peruuta jäsenyys",
    "label.membership_start_date": "Jäsenyyden alkamispäivä",
    "label.membership_price": "Hinta 3 kuukaudeksi",
    "label.membership_next_payment": "Seuraava maksu",
    "label.membership_end_date": "Jäsenyyden päättymispäivä",
    "label.total_savings": "Säästetyt toimitus- ja käsittelykulut yhteensä",
    "label.confirm_cancel_membership": "Vahvista peruutus",
    "label.continue_membership": "Älä peruuta",
    "label.valid_till_prefix": "Voimassaolo päättyy",
    "label.valid_till_suffix": "",
    "label.card_payment": "Korttimaksu",
    "label.place_review": "Lisää arvostelu",
    "label.name": "Nimi",
    "label.name_help": "näytämme sen arvioinnissa",
    "label.review": "Arvostelu",
    "label.self_service": "",
    "label.update_cookie_preferences": "Muuta evästeasetuksiani",
    "label.more_info": "Lisätietoja",
    "label.review_source": "Via {source}",
    "label.amount": "Määrä",
    "label.iban": "IBAN",
    "label.bic": "BIC",
    "label.beneficiary": "Saaja",
    "label.structured_communication": "Strukturoitu viesti",
    "label.later": "Myöhemmin",
    "label.enable_notifications": "Kyllä, haluan vastaanottaa nämä ilmoitukset",
    "label.card_number": "Kortin numero",
    "label.card_expiry": "Päättymispäivä",
    "label.card_cvc": "CVV",
    "label.cvc_info": "",
    "label.sales_tax": "",
    "label.calculating_sales_tax": "",
    "label.sales_tax_will_be_calculated": "",
    "label.ship_to_selected_address": "",
    "label.ship_to_service_point": "",
    "label.change_service_point": "",
    "label.choose_rooms": "",
    "label.room": "",
    "label.room_occupancy": "",
    "label.add_another_room": "",
    "label.number_of_adults": "",
    "label.number_of_adults_title": "",
    "label.number_of_children": "",
    "label.number_of_children_title": "",
    "label.choose_arrival_and_departure_date": "",
    "label.arrival_date": "",
    "label.select_arrival_date": "",
    "label.departure_date": "",
    "label.select_departure_date": "",
    "label.rate_type_pc": "",
    "label.rate_type_pcpd": "",
    "label.rate_type_pcpn": "",
    "label.rate_type_pp": "",
    "label.rate_type_pppd": "",
    "label.rate_type_pppn": "",
    "label.rate_type_pp_": "",
    "label.rate_type_pa": "",
    "label.rate_type_papd": "",
    "label.rate_type_papn": "",
    "label.rate_type_pa_": "",
    "label.choose_supplements": "",
    "label.opengds_invalid": "",
    "error.unknown": "Tuntematon virhe, yritä myöhemmin uudelleen.",
    "error.required": "{field} ei voi olla tyhjä.",
    "error.required_email": "{field} ei voi olla tyhjä, ja sen on oltava voimassa oleva sähköpostiosoite.",
    "error.required_password": "Kentän {field} on oltava vähintään 8 merkkiä pitkä ja sisältää vähintään yhden ison ja pienen kirjaimen sekä numeron.",
    "error.required_repeat_password": "Kentän {field} arvon on oltava sama kuin kentässä {field2} määritetty salasana.",
    "error.payment": "Valitettavasti maksusi on epäonnistunut. Näin voi tapahtua, jos maksutiedoissa on ongelma, maksu on peruutettu, maksurajasi on saavutettu tai muista syistä. Ota yhteyttä pankkiisi tai kokeile toista korttia tai maksutapaa.",
    "error.payment_creditcard": "Valitettavasti maksusi epäonnistui.Tarkista alla olevat tiedot ja yritä uudelleen.",
    "error.incomplete_order": "Voimme vahvistaa tämän kupongin vasta, kun olet täyttänyt kaikki yllä olevat kentät.",
    "error.coupon_already_added": "Olet jo lisännyt tämän kupongin.",
    "error.invalid_coupon": "Tämä kuponki on mitätön.",
    "error.coupon_not_cumulative": "Et voi käyttää tätä kuponkia yhdessä muiden kuponkien kanssa.",
    "error.other_coupon_not_cumulative": "Jo lisäämääsi kuponkia ei voi käyttää yhdessä tämän kupongin kanssa.",
    "error.invalid_username_or_password": "Annettu sähköpostiosoite ja/tai salasana on virheellinen.",
    "error.test_order": "Tämä on testitilaus. Normaalisti asiakas siirtyisi nyt maksusivulle.",
    "error.required_agree": "Sinun on hyväksyttävä käyttöehtomme ja tietosuojakäytäntömme.",
    "error.required_review_agree": "Sinun on hyväksyttävä, että arvostelusi voidaan julkaista verkkosivustollamme.",
    "error.register": "Tällä sähköpostiosoitteella on jo tili. Klikkaa \"Jatka\" kirjautuaksesi sisään tai käytä toista sähköpostiosoitetta.",
    "error.email_in_use": "Tällä sähköpostiosoitteella on jo tili. Käytä toista sähköpostiosoitetta.",
    "error.already_reviewed": "Olet jo kirjoittanut arvostelun tästä tilauksesta.",
    "error.zip_blocked": "Annettuun postinumeroon ei ole mahdollista lähettää.",
    "error.details": "Näyttää siltä, että yhtä tai useampaa tuotetta, jota yrität tilata, ei ole enää varastossa. Palaa edelliseen vaiheeseen, päivitä tilauksesi ja yritä uudelleen.",
    "error.incomplete_cvc": "CVV on liian lyhyt.",
    "error.incomplete_date": "Vanhenemispäivä ei voi olla tyhjä.",
    "error.date_in_past": "Kortti on vanhentunut.",
    "error.invalid_expiration_year": "Kortti on vanhentunut.",
    "error.incomplete_card_number": "Korttinumero on liian lyhyt.",
    "error.incorrect_card_number": "Korttinumero on virheellinen.",
    "unsubscription.your_mail_address": "Sähköpostiosoitteesi",
    "unsubscription.sad_message": "Tilauksesi on nyt peruttu.",
    "unsubscription.have_not_participated": "Etkö ole koskaan rekisteröitynyt? ",
    "unsubscription.sad_message_2": "Harmi, että jouduit jättämään meidät, mutta olet tietysti aina tervetullut takaisin.",
    "unsubscription.resubscribe_question": "Oletko muuttanut mielesi? <span class='unsub-important-message'>Klikkaa alla olevaa linkkiä tilataksesi uutiskirjeemme uudelleen:</span>",
    "unsubscription.form_message": "Miksi olet jättämässä Outspot-perheemme? Vastauksesi voi auttaa meitä parantamaan palveluitamme.",
    "unsubscription.report_not_participated": "Ilmoita väärinkäytöstä.",
    "unsubscription.coreg_date_no_screenshot": "Osallistuit kampanjaan {date} (<a href=\"{coreg_site}\">{coreg_site}</a>) ja annoit silloin lupasi lähettää sinulle tiedotteemme, jossa on huipputarjouksia erinomaisen edullisesti.",
    "unsubscription.coreg_date_no_coreg_data": "Osallistuit seuraavaan kampanjaan {date} ja annoit silloin lupasi lähettää sinulle tiedotteemme, jossa on huipputarjouksia erinomaisen edullisesti.",
    "unsubscription.coreg_date_screenshot": "Osallistuit seuraavaan kampanjaan {date} ja annoit silloin lupasi lähettää sinulle tiedotteemme, jossa on huipputarjouksia erinomaisen edullisesti.",
    "unsubscription.coreg_no_date_no_screenshot": "Osallistuit seuraavaan kampanjaan ja annoit silloin lupasi lähettää sinulle tiedotteemme, jossa on huipputarjouksia erinomaisen edullisesti.",
    "unsubscription.coreg_no_date_screenshot": "Osallistuit seuraavaan kampanjaan ja annoit silloin lupasi lähettää sinulle tiedotteemme, jossa on huipputarjouksia erinomaisen edullisesti.",
    "unsubscription.main_title": "Peru uutiskirjeen tilaus",
    "unsubscription.report_title": "Ilmoita väärinkäytöstä",
    "unsubscription.report_sent_message": "Kiitos, ilmoituksesi on vastaanotettu.",
    "claim_coupon.welcome_message": "Vain sinulle",
    "claim_coupon.info_line_message": "Anna yllä oleva henkilökohtainen koodi kassalla.",
    "claim_coupon.info_line_message_bis": "Tämä lahjakortti on lähetetty sinulle myös sähköpostitse.",
    "claim_coupon.main_title": "Sinun kuponkisi",
    "about.title": "Tietoa meistä",
    "about.intro": "Tervetuloa Outspotiin, josta löydät ainutlaatuisia tarjouksia! Outspot perustettiin vuonna 2008, ja se on kasvanut Euroopan johtavaksi pikatarjoussivustoksi. Outspot tarjoaa päivittäisiä alennuksia laadukkaista tuotteista ja matkoista, ja sen asiakkaana voit säästää valtavasti. Euroopassa sijaitsevan varastomme ansiosta toimitamme pakettimme niin nopeasti kuin mahdollista kotiisi.",
    "about.mission_title": "Missio",
    "about.mission_text": "Me Outspotilla uskomme, että poikkeuksellisten tarjousten on oltava kaikkien saatavilla. Tavoitteemme on tehdä osamme tarjotaksemme asiakkaille parasta mahdollista arvoa huolella valituilla tarjouksilla vertaansa vailla olevaan hintaan.",
    "about.history_title": "Historia",
    "about.history_text": "Outspotin seikkailu alkoi Belgiasta, jossa pääkonttori sijaitsee edelleen. 25 työntekijän omistautunut tiimi ajaa kasvua ympäri Eurooppaa. Työskentelemme joka päivä löytääksemme ja tarjotaksemme parhaat tarjoukset. Toimimme nyt 13 maassa, ja meillä on 100 000 tyytyväistä kävijää päivittäin.",
    "about.quality_title": "Tarjouksemme on valittu hinnan ja laadun perusteella",
    "about.quality_text": "Me Outspotilla uskomme, että ansaitset molempien maailmojen parhaat puolet: huippulaadun ja edullisen hinnann. Siksi etsimme joka päivä yhä houkuttelevimpia tarjouksia sinulle. Varmistamme, että teemme tiivistä yhteistyötä toimittajiemme kanssa neuvotellaksemme parhaat hinnat uhraamatta laatua. <br /><br />  Jokainen tarjoamamme tuote ja matka on valittu huolellisesti tiukan hinta- ja hinta-arvioinnin perusteella. Näin voit olla varma, että saat meiltä aina parhaan vastineen rahoillesi. Outspotilla kaikki pyörii sinun etujesi ja tyytyväisyytesi ympärillä! Alla näet kuvan desibelimittarilla testattavasta älykellon äänestä.",
    "about.tested_title": "Outspot-kollegamme ovat testaaneet kaikki tarjoukset tarkasti",
    "about.tested_text": "Me Outspotilla uskomme tarjoavamme vain parasta. Ennen kuin tarjous ilmestyy sivustollemme, Outspot-kollegamme testaavat sen yksityiskohtaisesti. Emme tyydy vain pinnallisiin testeihin: tuotteet puretaan kokonaan osiin ja jokainen komponentti arvioidaan huolellisesti mm. lujuuden, melutason ja materiaalin laadun perusteella.<br /><br />  Kollegamme vierailevat myös henkilökohtaisesti monissa hotelleissa tarkistaakseen majoituksen laadun. Näin me takaamme, että kaikki täyttää korkeat vaatimukset. Olipa kyse tuotteesta tai matkasta, voit olla varma, että Outspotilta voit tehdä ostoksesi aina luottavaisin mielin.<br /><br />  Alla olevassa kuvassa näet, kuinka kollegat testaavat tuotteita yksityiskohtaisesti: esim. kankaan laatutarkistus.",
    "about.guarantee_title": "",
    "about.guarantee_text": "",
    "about.returns_title": "30 päivän palautusoikeus useimmille tuotteille",
    "about.returns_text": "Ymmärrämme, että joskus voi kestää jonkin aikaa päättää, haluatko säilyttää tuotteen. Siksi tarjoamme 30 päivän palautusoikeuden useimmille tuotteillemme. On tärkeää, että tuote pysyy käyttämättömänä ja avaamattomana, jotta se täyttää palautuksen ehdot. Tämä antaa sinulle mahdollisuuden harkita ostostasi omaan tahtiisi, samalla kun varmistamme sujuvan palautusprosessin. Näin voit tehdä päätöksen huoletta, kunhan tuote pysyy alkuperäisessä kunnossa.",
    "about.support_title": "Asiakaspalvelu auttaa sinua omalla kielelläsi",
    "about.support_text": "Outspotilla asiakastyytyväisyys on etusijalla, ja teemme kaikkemme varmistaaksesi, että sinulla on erinomainen kokemus. Jos sinulla on kysyttävää tai tarvitset apua tilauksesi kanssa, asiakaspalvelumme on aina saatavilla sinua varten. Meidän suomea puhuvat työntekijät ovat valmiina auttamaan sinua nopeasti, ystävällisesti ja tehokkaasti. Pyrimme auttamaan henkilökohtaisella ja ymmärrettävällä tavalla, jotta sinä voit nauttia ostoksestasi huoletta. Tyytyväisyytesi on prioriteettimme, ja olemme täällä varmistaaksemme, että saat siitä parhaan hyödyn.",
    "about.socials_title": "Pysy yhteydessä",
    "about.socials_text": "Pysy ajan tasalla uusimmista tarjouksistamme ja eksklusiivisista alennuksistamme tilaamalla uutiskirjeemme. Seuraa meitä sosiaalisessa mediassa ja jaa Outspot-kokemuksesi kanssamme!<br /><br />  Onko sinulla kysyttävää tai kommentteja? Tukemme on valmis auttamaan. Ota yhteyttä <a href=\"{help}\">yhteydenottosivullamme</a>.<br /><br />  Kiitos luottamuksestasi Outspotia kohtaan. Odotamme innolla, että pääsemme yllättämään sinut yhä uudelleen upeilla tarjouksillamme!",
    "vat.error_required": "Kenttä \"{field}\" ei saa olla tyhjä liiketilauksessa.",
    "vat.validation_message_format": "Kenttä \"{field}\" ei ole oikeassa muodossa.",
    "vat.help_title": "ALV-numero",
    "vat.help_text": "Suomalaisen ALV-numeron tulee koostua 12 merkistä, esimerkiksi FI07654321.",
    "label.download": "Lataa",
    "error.download_pdf": "Jotain meni pieleen PDF-tiedoston lataamisen aikana. Yritä myöhemmin uudelleen. ",
    "manage_address.title": "Omat toimitusosoitteet ",
    "error.select": "Valitse {field}",
    "manage_cards.title": "Minun maksutapani",
    "deal.quality_text_1": "Valittu hinnalla ja laadulla",
    "deal.quality_text_2": "",
    "deal.delivery_text_days": "Toimitusaika: {days} työpäivää",
    "deal.delivery_text": "",
    "maintenance.going_offline": "Muutaman minuutin kuluttua verkkosivustomme poistuu tilapäisesti käytöstä suunniteltujen huoltotöiden ajaksi. Tee tilauksesi nyt, sillä tilaaminen ei ole mahdollista hetkeen huoltotöiden aikana.",
    "maintenance.offline": "Verkkosivustomme on tällä hetkellä poissa käytöstä  suunniteltujen huoltotöiden ajan. Yritä uudelleen muutaman minuutin kuluttua – palaamme verkkoon mahdollisimman pian! Pienenä huomionosoituksena saat meiltä GMJ2QPZ3 , jolla saat 5 € alennusta seuraavasta ostoksestasi.        ",
    "sustainability.carpark_title": "Teemme ajoneuvokannastamme vihreämmän",
    "sustainability.carpark_text": "Pyrimme jatkuvasti pienentämään ekologista jalanjälkeämme, joten uudistamme yritysajoneuvokantaamme. Vuoteen 2026 mennessä tavoitteenamme on, että autokanta on kokonaan sähkökäyttöinen. Tämä siirtymä on ratkaiseva askel päästöjen vähentämisessä ja puhtaamman, vihreämmän liikenteen edistämisessä.",
    "sustainability.energy_title": "Toimisto aurinkoenergialla",
    "sustainability.energy_text": "Kestävyys alkaa kotona, ja meille se tarkoittaa toimistoamme. Olemme asentaneet 30 aurinkopaneelia, jotka tuottavat lähes puolet toimistollamme tarvitsemasta sähköstä. Hyödyntämällä aurinkoenergiaa vähennämme riippuvuuttamme uusiutumattomista energialähteistä ja alennamme kokonais-CO₂-päästöjämme.",
    "sustainability.eco_packaging_title": "Ympäristöystävälliset pakkaukset",
    "sustainability.eco_packaging_text": "Olemme tietoisia pakkausjätteen vaikutuksesta ympäristöön. Siksi kaikki pakkauksemme on valmistettu 100-prosenttisesti uusiutuvasta muovista. Tämä aloite auttaa luomaan kestävän käyttö- ja kierrätyskierron.",
    "sustainability.combined_packaging_title": "Yhdistetyt toimitukset",
    "sustainability.combined_packaging_text": "Vähentääksemme ympäristövaikutustamme edelleen kannustamme asiakkaita yhdistämään tilauksiaan. Yhdistämällä mahdollisimman monta ostoa yhteen lähetykseen vähennämme toimitusmääriä, mikä puolestaan vähentää päästöjä ja ekologista jalanjälkeä.",
    "sustainability.title": "Kestävyys Outspotilla",
    "sustainability.vision_title": "Visioimme",
    "sustainability.vision_text": "Outspot on tietoinen vaikutuksestaan ympäristöön. Teemme tuhansia ihmisiä onnellisiksi päivittäin tarjonnallamme, mutta kestävyys ja sosiaalinen vastuu on kudottu toimintamme jokaiseen osa-alueeseen. Uskomme, että proaktiivisilla toimilla tänään voimme vaikuttaa planeettamme terveyteen ja tulevien sukupolvien hyvinvointiin.",
    "sustainability.action_title": "Liity joukkoomme",
    "sustainability.action_text": "Kutsumme sinut mukaan kestävyysmatkallemme valitsemalla ympäristöystävällisiä tuotteita ja osallistumalla kierrätysohjelmiimme. Jokainen pieni teko on tärkeä.",
    "sustainability.plant_text": "Ostosta tehdessä voit istuttaa puun vain 1 €:n lisähinnalla. Tämä kompensoi CO₂-päästöjä sekä edistää myös metsitystä ja parempaa tulevaisuutta paikallisille yhteisöille.",
    "sustainability.treeplanters": "Teemme yhteistyötä Tree-Plantersin kanssa, joka on sitoutunut älykkääseen ja kestävään metsitykseen. He istuttavat puita huolellisesti valituilla alueilla Malawissa, mikä palauttaa luontoa ja tukee paikallisia yhteisöjä lisätuloilla ja ruokaresursseilla.",
    "sustainability.action_text_believe": "Me Outspotilla uskomme, että kestävyys on jaettu vastuu. Yhdessä asiakkaiden, työntekijöiden ja kumppaneidemme kanssa pyrimme tietoisempaan suhteesen planeettamme kanssa. Liity joukkoomme ja tee ero yhdessä kanssamme!",
    "sustainability.plant_title": "Istuta puu – Tee ero",
    "sustainability.trees": "puu",
    "sustainability.tree": "puut",
    "deal.advice_price": "",
    "sustainability.checkbox_title": "Istuta yhdessä Outspotin kanssa",
    "sustainability.checkbox_detail_text": "Kutsumme sinut istuttamaan puita yhdessä Outspotin kanssa {price}."
  },
  "us": {
    "route.home": "https://www.outspot.us/us",
    "route.fallback_domain": "https://www2.outspot.us",
    "language.code": "US",
    "language.locale": "en-US",
    "language.name": "United States",
    "language.flag": "us",
    "language.date_input_format": "m,d,y",
    "language.currency": "USD",
    "language.region": "us",
    "countries.delivery": "USA",
    "states.delivery": "AL,AR,AZ,CA,CO,CT,DE,FL,GA,IA,ID,IL,IN,KS,KY,LA,MA,MD,ME,MI,MN,MO,MS,MT,NC,ND,NE,NH,NJ,NM,NV,NY,OH,OK,OR,PA,RI,SC,SD,TN,TX,UT,VA,VT,WA,WI,WV,WY",
    "tracking.aw_merchant_id": "5446896377",
    "tracking.aw_feed_country": "US",
    "countries.BEL": "",
    "countries.LUX": "",
    "countries.NLD": "",
    "countries.FRA": "",
    "countries.DEU": "",
    "countries.AUT": "",
    "countries.ITA": "",
    "countries.ESP": "",
    "countries.POL": "",
    "countries.GBR": "",
    "countries.IRL": "",
    "countries.SWE": "",
    "countries.PRT": "",
    "countries.FIN": "",
    "countries.USA": "United States",
    "states.AL": "Alabama",
    "states.AR": "Arkansas",
    "states.AZ": "Arizona",
    "states.CA": "California",
    "states.CO": "Colorado",
    "states.CT": "Connecticut",
    "states.DE": "Delaware",
    "states.FL": "Florida",
    "states.GA": "Georgia",
    "states.IA": "Iowa",
    "states.ID": "Idaho",
    "states.IL": "Illinois",
    "states.IN": "Indiana",
    "states.KS": "Kansas",
    "states.KY": "Kentucky",
    "states.LA": "Louisiana",
    "states.MA": "Massachusetts",
    "states.MD": "Maryland",
    "states.ME": "Maine",
    "states.MI": "Michigan",
    "states.MN": "Minnesota",
    "states.MO": "Missouri",
    "states.MS": "Mississippi",
    "states.MT": "Montana",
    "states.NC": "North Carolina",
    "states.ND": "North Dakota",
    "states.NE": "Nebraska",
    "states.NH": "New Hampshire",
    "states.NJ": "New Jersey",
    "states.NM": "New Mexico",
    "states.NV": "Nevada",
    "states.NY": "New York",
    "states.OH": "Ohio",
    "states.OK": "Oklahoma",
    "states.OR": "Oregon",
    "states.PA": "Pennsylvania",
    "states.RI": "Rhode Island",
    "states.SC": "South Carolina",
    "states.SD": "South Dakota",
    "states.TN": "Tennessee",
    "states.TX": "Texas",
    "states.UT": "Utah",
    "states.VA": "Virginia",
    "states.VT": "Vermont",
    "states.WA": "Washington",
    "states.WI": "Wisconsin",
    "states.WV": "West Virginia",
    "states.WY": "Wyoming",
    "trustpilot.businessunit_id": "",
    "trustpilot.show": "",
    "mollie.profile_id": "",
    "mollie.locale": "en_US",
    "facebook.url": "https://www.facebook.com/outspotUS/",
    "instagram.url": "",
    "linkedin.url": "",
    "header.hello": "",
    "header.hello_name": "Hi <b>{firstName}</b>",
    "subscribe.title": "Sign up for free and don't miss a single deal!",
    "subscribe.text": "Discover the best deals from Outspot. Sign up for free and enjoy exclusive and personalized discounts on unique products and experiences.",
    "subscribe.agree": "By subscribing to our newsletter, you agree to the <a href=\"{conditions}\" target=\"_blank\">general conditions</a> and <a href=\"{privacy}\" target=\"_blank\">privacy statement</a>.",
    "subscribe.sent": "Thank you! We have received your application correctly. You will receive an email shortly to confirm your registration.",
    "footer.follow": "Follow Us",
    "footer.payment_methods_logos": "visa,mastercard,paypal,amex,applepay",
    "footer.copyright": "Outspot Inc — 1521 Concord Pike #201 Wilmington, Delaware 19803",
    "home.new": "Newest deals",
    "home.ending": "Popular deals",
    "home.other": "All deals",
    "countdown.prefix": "Only",
    "countdown.days": "day|days",
    "countdown.hours": "hours",
    "countdown.minutes": "min",
    "countdown.seconds": "sec left",
    "countdown.days_compact": "d",
    "date.years": "{count} years ago|{count} years ago",
    "date.months": "{count} month ago|{count} months ago",
    "date.weeks": "{count} week ago|{count} weeks ago",
    "date.days": "{count} day ago|{count} days ago",
    "date.hours": "{count} hours ago|{count} hours ago",
    "date.minutes": "{count} minute ago|{count} minutes ago",
    "date.seconds": "Less than 1 minute ago",
    "countdown.suffix": "",
    "onboard.language_title": "Enjoy personalised deals deals with high discounts",
    "onboard.language_text": "",
    "onboard.notifications_title": "Stay informed:",
    "onboard.notifications_usp_1": "The best and latest deals",
    "onboard.notifications_usp_2": "Updates on your orders",
    "onboard.notifications_usp_3": "Personalised discount codes & vouchers",
    "order.shipping_title": "Shipping",
    "order.shipping_text": "Due to rising transportation costs and environmental impact, we are forced to charge shipping fees. However, Outspot Inc. still guarantees you great deals where you still benefit. Moreover, your shipping cost does not increase when you purchase more items. If you are an Outspot PLUS member or you buy within the hour of your previous purchase, these costs will be deducted in the next step.",
    "order.thank_you": "Thank you for your order!",
    "order.overview": "Overview of your order",
    "order.thank_you_bank": "Thank you very much for your purchase. You will receive the transfer details in your inbox or in spam. Please transfer the amount as soon as possible and always within 7 days. You will receive the voucher by e-mail as soon as the payment reaches us and has been processed.",
    "order.thank_you_with_shipping": "Your purchase is complete. We will send your order to the address below. You will get your purchase confirmation in your inbox or in spam. Enjoy your order.",
    "order.thank_you_without_shipping": "Your purchase is complete. You will get your purchase confirmation in your mailbox or in junk mail. Enjoy your order.",
    "order.code": "Order number: {code}",
    "order.shipping_address": "Delivery address",
    "order.save_payment_info_title": "Save bank details for my next purchases.",
    "order.save_payment_info_text": "If you choose this option, you won’t have to enter your details every time. Your information will be sorted securely.",
    "order.shipped_to_service_point": "",
    "my_outspot_plus.title": "My Outspot PLUS",
    "my_outspot_plus.subtitle": "This page allows you to manage your Outspot PLUS membership.",
    "my_outspot_plus.overview": "Overview of your Outspot PLUS membership",
    "my_outspot_plus.cancel_are_you_sure": "Are you sure you want to cancel your membership?",
    "my_outspot_plus.cancel_you_saved_prefix": "You already saved",
    "my_outspot_plus.cancel_you_saved_suffix": "shipping and filing fees with your Outspot PLUS membership.",
    "my_outspot_plus.no_membership": "You do not currently have an Outspot PLUS membership.",
    "plus.title": "Outspot PLUS",
    "plus.popup_text": "With Outspot PLUS, you benefit from free shipping on all items and pay no filing fees. After every order, you will receive an email giving you the opportunity to become an Outspot PLUS member. However, you have to be quick because this promotion is only valid for 7 days. Don't forget to check your next purchase confirmation email.",
    "plus.name": "Outspot PLUS",
    "plus.highlight_1": "Enjoy hassle-free ordering with no shipping or filing charges. ",
    "plus.highlight_2": "Your subscription will automatically renew for {renewPrice} every three months. ",
    "plus.highlight_3": "You may cancel at any time. ",
    "plus.question_1": "What are the benefits of Outspot PLUS? ",
    "plus.answer_1": "With Outspot PLUS, you get free shipping on all items and pay no filing fees.  ",
    "plus.question_2": "How do I become an Outspot PLUS member?  ",
    "plus.answer_2": "After each order you will receive an e-mail giving you the opportunity to become an Outspot PLUS member. Be quick because this promotion is only valid for 7 days. If you decide to activate Outspot PLUS, the benefits will start immediately. This means that on your next order, you can immediately enjoy free shipping on all items and you won't have to pay a filing fee.  ",
    "plus.question_3": "How much does Outspot PLUS membership cost?",
    "plus.answer_3": "Outspot PLUS costs just {price} for the first 3 months. This is a one-time payment. After this period, your membership will automatically renew every 3 months for {renewPrice}. This allows you to save significantly on shipping and filing costs.  ",
    "plus.question_4": "When and how will my Outspot PLUS be renewed?\r  ",
    "plus.answer_4": "Outspot PLUS is automatically renewed every three months. To manage this, you can always go to <a href=\"{myOutspotPlus}\">MY PURCHASES</a>. The amount will be automatically deducted from your account every 3 months.",
    "plus.question_5": "How do I cancel my Outspot PLUS? Will I receive a refund?\r  ",
    "plus.answer_5": "You can cancel your Outspot PLUS membership at any time via: <a href=\"{myOutspotPlus}\">MY PURCHASES</a>. Please note that no refund will be issued for the months when your membership was active. However, if you decide to cancel Outspot PLUS, the next scheduled bill will not be charged.  ",
    "plus.thank_you": "Your purchase is complete. From now on, you can enjoy hassle-free ordering without shipping and handling fees. You can view and manage your membership through <a href=\"{myOutspotPlus}\">My Outspot PLUS</a>.\r  ",
    "my_coupons.title": "My vouchers",
    "my_coupons.subtitle": "Your vouchers are on this page.",
    "my_coupons.no_coupons": "You currently have no vouchers.",
    "deal.sold": "Already bought {sold} times",
    "deal.retour_label": "30-day returns for just $ 0.95",
    "deal.retour_title": "Revocation",
    "deal.retour_text": "Don't like your product after all? Contact our helpdesk within 30 days of receiving it and return it. And no need to give a reason! We will refund your purchase and you can order stress-free. Another great idea, from Outspot of course!",
    "deal.guarantee_label": "6 months refund guarantee",
    "deal.guarantee_title": "Unique : 100% refund guarantee",
    "deal.guarantee_text": "Unbelievable but true. You no longer have to hesitate when buying an experience for your friends/colleagues/family or yourself. If you change your mind afterwards, or if the recipient is not completely satisfied with the gift voucher, there is no problem.<br><br>With Outspot.us you can cancel your purchase up to 6 months after ordering! You or the person who received the voucher can get a full refund within 6 months. This is applicable provided that the voucher has not been reserved, used or expired. This is exceptional as a guarantee and never seen. But that's just how Outspot works.<br><br> Please note that for all actions where the refund guarantee is not explicitly mentioned, the guarantee does not apply. This is because in some cases we cannot recover deliveries/tickets from our partner ourselves.",
    "deal.option_count": "{count} option|{count} options",
    "deal.campaign_number": "Campaign number",
    "deal.your_details": "Your details",
    "deal.payment_methods_title": "Payment method",
    "deal.shipping_methods_title": "",
    "deal.privacy": "By providing your details you consent to <a target='_blank' href='{privacy}'>our privacy policy</a>.",
    "deal.payment_methods_subtitle": "How would you like to pay for your order?",
    "deal.your_order": "Your order",
    "deal.from": "From",
    "help.title": "Need help?",
    "help.subtitle": "Let us know how we can help you.",
    "help.subject": "Your question via our website or app",
    "help.sent": "We have received your message correctly. You will get a reply from us within 4 business days at the latest.",
    "login.title": "Log in",
    "login.subtitle": "Welcome back! How would you like to log in?",
    "login.or": "or",
    "login.usps_title": "Why should I create an account?",
    "login.usps_title_cta": "Make it easier for yourself and log in now or create an account.",
    "login.usps_text": "Click on the \"Log in\" button at the top of the page to log in. If you don't have an account yet, you can also register on the login page. Having an Outspot account is 100% advantages:",
    "login.usp_manage_orders": "Viewing and tracking orders",
    "login.usp_remember_info": "Saving contact details for later",
    "login.usp_returns": "Reporting defects and requesting product returns",
    "login.usp_store_payments": "Save payment methods and pay faster",
    "login.short_cta": "<a href=\"{loginLink}\">Log in</a> or continue below as guest.",
    "forgot.title": "Request a new password",
    "forgot.subtitle": "Enter your email address below and we will send you a link to reset your password.",
    "forgot.sent": "We have sent you an email with a link to reset your password.",
    "password.title": "Change password",
    "password.subtitle": "Choose a new and secure password.",
    "password.updated": "Your password has been changed. You can now log in with your new password.",
    "register.title": "Register",
    "register.subtitle": "Create an account to make it even easier to order and track your orders.",
    "register.personal_info": "Personal details",
    "register.login_info": "Login details",
    "register.agree": "I agree to the <a href=\"/enl/help/general-conditions</a> and <a href=\"/enl/help/privacy\" target=\"_blank\">privacy statement</a>.",
    "register.sent": "You're almost there. We've sent you an email to activate your account.",
    "register.email_known": "Is your email address already registered with Outspot? <a href=\"{forgot}\">Click here</a> to request a new password. ",
    "my_orders.title": "My orders",
    "my_orders.subtitle": "This is an overview of all the orders you have placed at Outspot.",
    "my_orders.title_detail": "Details of your order",
    "my_travels.title": "My travels",
    "my_info.title": "My data",
    "my_info.subtitle": "Here you can edit your personal information",
    "my_info.sent": "Your information has been updated.",
    "notifications.title": "Newsletter and notifications",
    "notifications.subscription_mail": "How often would you like to receive our newsletter?",
    "notifications.subscription_notification": "How often would you like to receive notifications?",
    "notifications.frequency_0": "Never",
    "notifications.frequency_4": "Once a week",
    "notifications.frequency_56": "Twice a day",
    "notifications.sent": "Your preferences have been updated.",
    "combined_purchase_banner.text": "Pay no shipping fees for your subsequent orders during:",
    "plus_banner.text_welcome": "{firstName}, welcome to Outspot PLUS.",
    "plus_banner.text_welcome_anonimous": "Welcome to Outspot PLUS.",
    "plus_banner.text_savings_prefix": "{firstName}, you already saved",
    "plus_banner.text_savings_prefix_anonimous": "You already saved",
    "plus_banner.text_savings_suffix": "to shipping and filing fees through Outspot PLUS.",
    "cookie_banner.title": "Cookies",
    "cookie_banner.text": "This website uses cookies. Strictly necessary cookies ensure the website functions properly and cannot be refused. Functional, analytical & advertising cookies are used for functional, statistical, and marketing purposes, respectively. They are only downloaded if you consent to them. Read more in our <a href=\"{cookies}\" target=\"_blank\">cookie policy</a>.",
    "cookie_banner.decline": "Reject optional cookies",
    "cookie_banner.accept_all": "Accept all cookies",
    "cookie_banner.accept_selected": "Accept selected",
    "cookie_banner.settings": "Settings",
    "cookie_banner.functional_cookies_title": "Strictly necessary cookies",
    "cookie_banner.functional_cookies_text": "Cookies necessary for the basic functionality of the website.",
    "cookie_banner.analytical_cookies_title": "Analytical & functional cookies",
    "cookie_banner.analytical_cookies_text": "Cookies enable the website to provide additional features and personal settings as well as visits and traffic.",
    "cookie_banner.personal_cookies_title": "Advertising cookies",
    "cookie_banner.personal_cookies_text": "Cookies set by advertisers to build a profile of your interests without storing direct personal information.",
    "review.title": "Share it and win",
    "review.subtitle": "Post your review of your purchase below for an instant chance to win a voucher (we draw one voucher every quarter).",
    "review.agree": "I agree that my review may be published on the website",
    "review.sent": "Thank you for your review. You now have a chance to win a voucher.",
    "review.title_thank_you": "Thank you. We appreciate your feedback.",
    "review.subtitle_thank_you": " Thanks to your feedback, we can make Outspot even better. Leave your review of your purchase below and have the chance to win a voucher (every month we draw a voucher).",
    "confirm.title": "Your registration is confirmed",
    "confirm.subtitle": "Your subscription to the newsletter is hereby confirmed. Thank you! To serve you even better, we offer you the opportunity to share the following data with us without any obligation.",
    "popup_widget.title": "The best deals, tested for you.",
    "popup_widget.usp_1": "Receive our best offers daily.",
    "popup_widget.usp_2": "Selected and tested for best price and quality.",
    "popup_widget.usp_3": "Substantial discounts of up to -70%.",
    "popup_widget.footer_1": "Subscribe to our newsletter for free now and save immediately.",
    "popup_widget.footer_2": "You can unsubscribe again at any time.",
    "promo.title": "Our top promos",
    "promo.countdown": "2024-12-31 23:59:59",
    "promo.empty": "We currently have no top promos. Go to our home page for our latest deals.",
    "stars.info_title": "Product rating",
    "stars.info_text": "This rating is an average of our own collected reviews and those from other parties.",
    "stars.counter": "1 review | {count} reviews",
    "label.accept_selected": "Accept selected",
    "label.edit": "Change",
    "label.edit_address": "Change address",
    "label.address": "Address",
    "label.accept_all": "Accept all",
    "label.account": "Account",
    "label.help": "Help",
    "label.my_orders": "My orders",
    "label.my_travels": "My travels",
    "label.my_coupons": "My vouchers",
    "label.my_info": "My data",
    "label.change_password": "Change password",
    "label.notifications": "Newsletter and notifications",
    "label.logout": "Log out",
    "label.login": "Log in",
    "label.login_have_account": "Already have an account? Log in",
    "label.login_with_facebook": "Log in with Facebook",
    "label.login_with_google": "Log in with Google",
    "label.login_with_apple": "Log in with Apple",
    "label.password": "Password",
    "label.new_password": "New password",
    "label.repeat_password": "Repeat password",
    "label.repeat_new_password": "Repeat new password",
    "label.register_no_account": "No account yet? Register now",
    "label.register": "Register",
    "label.forgot_password": "Forgotten your password?",
    "label.general_conditions": "General conditions",
    "label.jobs": "",
    "label.faq": "Frequently Asked Questions",
    "label.returns": "Return",
    "label.travel_conditions": "Specific travel conditions",
    "label.privacy_policy": "Privacy Statement",
    "label.cookie_policy": "Cookie Policy",
    "label.subscribe": "Sign me up",
    "label.all_deals": "All deals",
    "label.other_deals": "Other deals",
    "label.discount": "OFF",
    "label.close": "Close",
    "label.order_now": "Buy now",
    "label.add": "Add",
    "label.book": "Book now",
    "label.upselling_next_step": "Also, don't forget this later",
    "label.upselling": "Also, don't forget this",
    "label.also_available": "Also available ",
    "label.reviews": "What our customers say",
    "label.automatically_translated": "Automatically translated",
    "label.show_all_reviews": "See all reviews",
    "label.back": "Back",
    "label.make_your_choice": "Make your choice",
    "label.sold_out": "Sold Out",
    "label.order_more": "Order more",
    "label.subtotal": "Subtotal",
    "label.total_shipping": "Shipping costs",
    "label.total_file": "File costs",
    "label.total_payable": "Total",
    "label.no_options_selected": "Your order is currently empty. Click \"Add\" below to continue.",
    "label.no_options_selected_extra": "You can only select this option when purchasing the product.",
    "label.total_saved_prefix": "(You save ",
    "label.total_saved_suffix": ")",
    "label.continue": "Continue",
    "label.usp_quality": "Selected on price and quality",
    "label.usp_discounts": "Hefty discounts of up to 70%",
    "label.usp_payment_methods": "Pay safely and easily with",
    "label.person": "Person",
    "label.free": "Free",
    "label.first_name": "First name",
    "label.last_name": "Last name",
    "label.birthdate": "Birthdate",
    "label.birthdate_why": "",
    "label.birthdate_why_text": "",
    "label.email": "Email address",
    "label.phone": "Phone number",
    "label.address_finder": "Address Finder",
    "label.address_finder_help": "Enter your full delivery address",
    "label.select": "Select",
    "label.street": "Street address",
    "label.building": "Street address 2",
    "label.number": "Number",
    "label.zip": "Zip code",
    "label.city": "City",
    "label.state": "State",
    "label.country": "Country",
    "label.finish_order": "Complete order",
    "label.finish_order_help": "Click the \"Complete Order\" button to complete your payment.",
    "label.enter_address": "Enter my address manually",
    "label.add_address": "Add an address",
    "label.add_payment_method": "Add a method of payment",
    "label.year": "YYYY",
    "label.month": "MM",
    "label.day": "DD",
    "label.business_order": "Business order",
    "label.company_name": "Company name",
    "label.tax_id": "Tax Id",
    "label.invoice_reason": "Reason",
    "label.invoice_reasons": "Corporate gifts,Team building,Incentives,Seminar,Other",
    "label.delete": "Remove",
    "label.i_have_coupons": "I have vouchers",
    "label.email_outspot_plus": "Outspot PLUS member? Enter your email address.",
    "label.please_wait": "Please be patient...",
    "label.please_wait_2": "Please bear with us a little longer...",
    "label.please_wait_3": "Sorry, it's taking a little longer than expected....",
    "label.please_wait_4": "Just a few more minutes, we're almost there....",
    "label.please_wait_5": "Apologies for the inconvenience and thank you for your patience. We are almost there....",
    "label.continue_shopping": "Continue shopping",
    "label.my_code": "My code",
    "label.validate": "Validate",
    "label.total_coupons": "Total vouchers",
    "label.coupons": "Vouchers",
    "label.total_payable_with_coupons_prefix": "(Still to be paid ",
    "label.total_payable_with_coupons_suffix": ")",
    "label.rest_coupon_info": "The amount payable is less than the vouchers entered. You will receive a new voucher with the remaining value.",
    "label.contact_extra_info": "Order number, voucher number or structured communication",
    "label.contact": "Contact us",
    "label.message": "Message",
    "label.send": "Send",
    "label.save": "Save",
    "label.my_outspot_plus": "My Outspot PLUS",
    "label.order_date_prefix": "Ordered on",
    "label.order_date_suffix": "",
    "label.total_paid_with": "Total paid with {method}",
    "label.track_order": "Track your order with",
    "label.return_order": "Return and/or report defect",
    "label.download_invoice": "Download invoice",
    "label.ask_question": "Ask a question",
    "label.happy_hour_discount": "Happy Hour benefit",
    "label.outspot_plus_discount": "Outspot PLUS advantage",
    "label.cancel_membership": "Cancel membership",
    "label.membership_start_date": "Membership start date",
    "label.membership_price": "Price for 3 months",
    "label.membership_next_payment": "Next payment",
    "label.membership_end_date": "Membership end date",
    "label.total_savings": "Total shipping and filing costs saved",
    "label.confirm_cancel_membership": "Confirm cancellation",
    "label.continue_membership": "Do not cancel",
    "label.valid_till_prefix": "Valid until",
    "label.valid_till_suffix": "",
    "label.card_payment": "Card Payment",
    "label.place_review": "Place review",
    "label.name": "Name",
    "label.name_help": "we show these in the assessment",
    "label.review": "Review",
    "label.self_service": "",
    "label.update_cookie_preferences": "Change my cookie preferences",
    "label.more_info": "More info",
    "label.review_source": "Via {source}",
    "label.amount": "Amount",
    "label.iban": "IBAN",
    "label.bic": "BIC",
    "label.beneficiary": "Beneficiary",
    "label.structured_communication": "Structured communication",
    "label.later": "Later",
    "label.enable_notifications": "Yes, I want to receive notifications",
    "label.card_number": "Card number",
    "label.card_expiry": "Expiry",
    "label.card_cvc": "CVV",
    "label.cvc_info": "The card security code (also called CVV2) consists of a 3 or 4 digit number that appears on the credit card.",
    "label.sales_tax": "Sales tax",
    "label.calculating_sales_tax": "Calculating sales tax...",
    "label.sales_tax_will_be_calculated": "Sales tax will be calculated when you click \"Continue\"",
    "label.ship_to_selected_address": "",
    "label.ship_to_service_point": "",
    "label.change_service_point": "",
    "label.choose_rooms": "",
    "label.room": "",
    "label.room_occupancy": "",
    "label.add_another_room": "",
    "label.number_of_adults": "",
    "label.number_of_adults_title": "",
    "label.number_of_children": "",
    "label.number_of_children_title": "",
    "label.choose_arrival_and_departure_date": "",
    "label.arrival_date": "",
    "label.select_arrival_date": "",
    "label.departure_date": "",
    "label.select_departure_date": "",
    "label.rate_type_pc": "",
    "label.rate_type_pcpd": "",
    "label.rate_type_pcpn": "",
    "label.rate_type_pp": "",
    "label.rate_type_pppd": "",
    "label.rate_type_pppn": "",
    "label.rate_type_pp_": "",
    "label.rate_type_pa": "",
    "label.rate_type_papd": "",
    "label.rate_type_papn": "",
    "label.rate_type_pa_": "",
    "label.choose_supplements": "",
    "label.opengds_invalid": "",
    "error.unknown": "Unknown error, please try again later.",
    "error.required": "{field} cannot be empty.",
    "error.required_email": "{field} cannot be empty and must be a valid e-mail address.",
    "error.required_password": "{field} must be at least 8 characters long and contain at least one uppercase, one lowercase, and one number.",
    "error.required_repeat_password": "{field} must be equal to the password specified in the {field2} field .",
    "error.payment": "Unfortunately, your payment failed. This can happen if there is a problem with the payment details, the payment was canceled, your payment limit was reached or for other reasons. Consider contacting your bank or try another card or payment method.",
    "error.payment_creditcard": "Sorry. Your payment has failed. Please check the details below and try again.",
    "error.incomplete_order": "We cannot validate this voucher until you have completed all the fields above.",
    "error.coupon_already_added": "You have already added this coupon.",
    "error.invalid_coupon": "This voucher is invalid.",
    "error.coupon_not_cumulative": "You cannot use this voucher together with other vouchers.",
    "error.other_coupon_not_cumulative": "You cannot use the coupon you have already added together with this coupon.",
    "error.invalid_username_or_password": "The email address and/or password provided is invalid.",
    "error.test_order": "This is a test order. Normally, the customer would now go to the payment page.",
    "error.required_agree": "You must agree to our terms and conditions and privacy policy.",
    "error.required_review_agree": "You must agree to your review being published on our website.",
    "error.register": "There is already an account registered to this email address. Click \"Continue\" to log in or use another email address.",
    "error.email_in_use": "There is already an account registered to this email address. Please use a different email address.",
    "error.already_reviewed": "You have already posted a review for this order.",
    "error.zip_blocked": "It is not possible to ship to the specified postcode.",
    "error.details": "It seems that one or more products you are trying to order are no longer in stock. Please return to the previous step, update your order and try again.",
    "error.incomplete_cvc": "CVV is too short.",
    "error.incomplete_date": "Expiration date cannot be empty.",
    "error.date_in_past": "Card has expired.",
    "error.invalid_expiration_year": "Card has expired.",
    "error.incomplete_card_number": "Card number is too short.",
    "error.incorrect_card_number": "Card number is invalid.",
    "unsubscription.your_mail_address": "Your email address: ",
    "unsubscription.sad_message": "You are now unsubscribed.",
    "unsubscription.have_not_participated": "Have you never signed up? ",
    "unsubscription.sad_message_2": "We're sorry you've left us, but of course you're always welcome to come back.",
    "unsubscription.resubscribe_question": "Have you changed your mind? <span class='unsub-important-message'> Click below to subscribe to our newsletter again:</span>",
    "unsubscription.form_message": "Why are you leaving our Outspot family? Your answer can help us improve our services.",
    "unsubscription.report_not_participated": "Report abuse.",
    "unsubscription.coreg_date_no_screenshot": "You participated in a promotion on {date} (<a href=\"{coreg_site}\">{coreg_site}</a>) and thereby gave your consent to receive our newsletter with top offers at exceptional prices.",
    "unsubscription.coreg_date_no_coreg_data": "You participated in the promotion below on {date} and gave your consent to receive our newsletter with top offers at exceptional prices.",
    "unsubscription.coreg_date_screenshot": "You participated in the promotion below on {date} and gave your consent to receive our newsletter with top offers at exceptional prices.",
    "unsubscription.coreg_no_date_no_screenshot": "You participated in the promotion below and gave your consent to receive our newsletters with top offers at exceptional prices.",
    "unsubscription.coreg_no_date_screenshot": "You participated in the promotion below and gave your consent to receive our newsletters with top offers at exceptional prices.",
    "unsubscription.main_title": "Unsubscribe from the newsletter",
    "unsubscription.report_title": "Report an issue",
    "unsubscription.report_sent_message": "Thank you, your notification has been received.",
    "claim_coupon.welcome_message": "Just for you",
    "claim_coupon.info_line_message": "Enter the  personal code (see above) at checkout.",
    "claim_coupon.info_line_message_bis": "This coupon was also sent to you by email.",
    "claim_coupon.main_title": "Your coupon",
    "about.title": "About us",
    "about.intro": "Welcome to Outspot, your number one destination for irresistible deals! Since it was founded in 2008, Outspot has grown into the leading flash deal website. Outspot offers daily discounts on quality products and travel, which allows you, as a customer, to make enormous savings. Thanks to our warehouse in North Carolina , we can deliver your orders as quickly as possibly to your home.\"",
    "about.mission_title": "Mission",
    "about.mission_text": "At Outspot we believe in making exceptional offers available to everyone. Our goal is to give our customers the best possible value by carefully offering selected deals at unrivaled prices.",
    "about.history_title": "History",
    "about.history_text": "The Outspot adventure started in 2008. With a dedicated team of 25 employees spread across the world, we work every day to find and. offer the best deals. We operate in 13 countries and have 100,000 satisfied visitors every day.",
    "about.quality_title": "Our offer has been selected based on price and quality",
    "about.quality_text": "At Outspot we believe you deserve the best of both worlds: top quality at an affordable price. That's why every day we look for the most attractive deals for you.  We ensure that we work closely with our suppliers so we can negotiate the best prices without making concessions to the quality. <br /><br />  Every product and trip we offer is carefully selected based on a rigorous evaluation of price and quality. That way you can be sure that you always get the best value  for your money. At Outspot everything centers on your benefit and satisfaction! Below you can see a photo of a smart bell being tested with a decibel meter.",
    "about.tested_title": "All deals have been extensively tested by our Outspot colleagues",
    "about.tested_text": "At Outspot we believe in delivering only the very best. Before a deal appears on our website, it is tested by our own Outspot colleagues. They don't stop at superficial tests: products are completely taken apart and each component is carefully assessed for strength, noise level, material quality and more.<br /><br />  Our colleagues also personally visit many hotels to check the quality of the accommodation. This is how we can guarantee that everything meets our high standards. Whether it is a product or a trip, you can be sure that you will be satisfied. With Outspot you can shop with confidence.<br /><br />  In the photo below you can see how colleagues produce products view and test in detail: e.g. fabric quality check.",
    "about.guarantee_title": "",
    "about.guarantee_text": "",
    "about.returns_title": "30-day return policy on most products",
    "about.returns_text": "We understand that it can sometimes take time to decide whether you want to keep a product. That’s why we offer a 30-day return policy on most of our products. It is important that the product remains unused and unopened to be eligible for a return. This gives you the chance to reconsider your purchase at your own pace, while we ensure a smooth return process. This way, you can decide with peace of mind, as long as the product stays in its original condition.",
    "about.support_title": "Customer service in English",
    "about.support_text": "At Outspot, customer satisfaction comes first, and we do everything we can to ensure you have an excellent experience. If you have any questions or need help with your order,  our customer service team is always there for you. Our English-speaking employees are available to help you quickly, and offer friendly and efficient support. We strive to  help you in a personal and understandable way, so that you can enjoy your purchase without any worries. Your satisfaction is our priority, and we are here to make sure you get the best out of it, and benefit from our service.",
    "about.socials_title": "Stay connected",
    "about.socials_text": "Stay up-to-date with our latest deals and exclusive offers by signing up for our newsletter. Follow us on social media and share your Outspot experiences with us!<br /><br />  Do you have any questions or comments? Our helpdesk is ready to assist you. Contact us at <a href=\"{help}\">our contact page</a>.<br /><br />  Thank you for your trust in Outspot. We look forward to surprising you again and again with our fantastic offers!",
    "vat.error_required": "The field \"{field}\" must not be empty for a business order.",
    "vat.validation_message_format": "The field \"{field}\" is not in the correct format.",
    "vat.help_title": "Tax Identification Number",
    "vat.help_text": "The US Tax Identification Number (TIN) must consist of 9 characters, for example 123456789.",
    "label.download": "Download",
    "error.download_pdf": "Something went wrong while downloading the PDF. Please try again later.",
    "manage_address.title": "My delivery addresses",
    "error.select": "Select an {field}",
    "manage_cards.title": "My payment methods",
    "deal.quality_text_1": "Selected on price and quality",
    "deal.quality_text_2": "",
    "deal.delivery_text_days": "Delivery time: {days} working days",
    "deal.delivery_text": "",
    "maintenance.going_offline": "Our website will go temporarily offline for scheduled maintenance in a few minutes. Complete your order now, as you will not be able to place orders until the maintenance work is complete.",
    "maintenance.offline": "Our website is currently offline for scheduled maintenance. Please try again in a few minutes - we will be back online as soon as possible! As a gesture, we’re giving you GMJ2QPZ7 for $5 off your next purchase.",
    "sustainability.carpark_title": "",
    "sustainability.carpark_text": "",
    "sustainability.energy_title": "",
    "sustainability.energy_text": "",
    "sustainability.eco_packaging_title": "",
    "sustainability.eco_packaging_text": "",
    "sustainability.combined_packaging_title": "",
    "sustainability.combined_packaging_text": "",
    "sustainability.title": "",
    "sustainability.vision_title": "",
    "sustainability.vision_text": "",
    "sustainability.action_title": "",
    "sustainability.action_text": "",
    "sustainability.plant_text": "",
    "sustainability.treeplanters": "",
    "sustainability.action_text_believe": "",
    "sustainability.plant_title": "",
    "sustainability.trees": "",
    "sustainability.tree": "",
    "deal.advice_price": "",
    "sustainability.checkbox_title": "",
    "sustainability.checkbox_detail_text": ""
  }
};